import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PNLogo from "../../assets/logo/LPLogo.svg";
import LoginImage from "../../assets/images/loginImage.PNG";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import classes from "./Login.module.css";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";

import { useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPrescriberInfo, setUserPermissionsData } from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";
import { jsonToMap } from "../../utils/helpers/HelperFunctions";
import { useEffect } from "react";

const theme = createTheme();

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const inviteKey = searchParams.get("inviteKey");
  const regex = /[^a-z|^ ]/
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const location = useLocation();
  const session = async (key, value, mvfrd) => {
    let json = {};
    let val = value;
    let ky = key;
    json[ky] = JSON.stringify(val);
    const httpResponse = await sendHttpRequest({
      url: "/session",
      method: "POST",
      data: json,
    });
    if (!httpResponse.error) {
      if (mvfrd) {
        let response = value;

        login({
          loggedIn: true,
          role: response?.role ?? "",
          timezone: response?.timezoneOffset,
          prescription: response?.Identification?.Prescription,
        });

        // if (localStorage.getItem("sessionexp") === null) {
        // localStorage.setItem("sessionexp", false);
        // localStorage.setItem("role", response.roles);
        // localStorage.setItem(
        //   "timezone",
        //   JSON.stringify(response?.timezoneOffset)
        // );
        // }
        setIsAuthenticated(true);
      }
    }
  };

  const getUserPermissionsMap = (permissions) => {
    let permissionsMap = new Map();
    let keys = Object.keys(permissions);
    let values = Object.values(permissions);

    for (let i = 0; i < keys?.length; i++) {
      permissionsMap.set(keys[i], values[i])
    }
    return permissionsMap;

  }
  const loginProvider = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/signin",
      method: "POST",
      data: {
        username: email,
        password: password,
        ...(inviteKey !== undefined && { inviteKey: inviteKey })
      },
    });
    if (!httpResponse.error) {
      let response = httpResponse.data;

      // console.log(response);
      if (response?.twoFactorAuth) {
        if (localStorage.getItem("twoFac") === null) {
          localStorage.setItem("twoFac", response?.twoFactorAuth);
          localStorage.setItem("username", response?.username);
          localStorage.setItem("authLink", response.link);
          setTwoFactor(response?.twoFactorAuth);
        }
      } else {
        // let permissionsMap = getUserPermissionsMap(response?.permissions);
        // Object.fromEntries(permissionsMap)
        dispatch(setUserPermissionsData(response.permissions));
        login({
          loggedIn: true,
          role: response?.role ?? "",
          userId: response?.userId ?? "",
          selectedUserId: response?.userId ?? "",
          timezone: response?.timezoneOffset ?? "",
          timezoneText: response?.timezone ?? "",
          name: response?.name,
          firstName: response?.name[0]?.firstName ?? "",
          lastName: response?.name[0]?.lastName ?? "",
          prefix: response?.name[0]?.prefix ?? "",
          prescription: response?.Identification?.Prescription,
          spi: response?.providerTenantPracticeLocation?.spi,
          tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
          tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
          facility: response?.providerTenantPracticeLocation?.facility,
          practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId
        });
        setIsAuthenticated(true);
        if (location?.state?.code !== undefined || location?.state?.code !== null) {
          // tenantInvite(location?.state?.code); Removed after discussion with faizan
          // navigate(`/invite?key=${location?.state?.code}`, {replace: true});
        }

        // session("selected_user", response?.id, false);
        // session("user", response, true);
      }
    } else {
      if (httpResponse?.status === 400) {
        setErrorResponse(httpResponse.errorMessage.message);
      } else {
        setErrorResponse("An error has occurred");
      }
      // setErrorResponse("Something went wrong");
      navigate('/', { state: { msg: "" } }, { replace: true })

    }
    setIsLoading(false);
  };

  const tenantInvite = async (code) => {
    const httpResponse = await sendHttpRequest({
      url: "/provider/new-tenant",
      method: "GET",
      params: {
        key: code
      }
    });
    if (!httpResponse.error) {
      location.state.code = undefined;
      navigate(0);
    }
    else { }
  }

  const handleEmailInput = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const handlePasswordInput = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleLoginButton = () => {
    if (password !== "" && email !== "") {
      loginProvider();
    } else {
      setErrorResponse("Please enter valid credentials");
    }
  };
  window.history.replaceState({}, document.title)
  useEffect(() => {

  }, [])

  //   const classes = useStyles();
  return (
    <>
      {twoFactor === true && navigate("/two-factor-auth", { replace: true })}
      {isAuthenticated === true && jsonToMap(user).get("role") !== "patient"
        ? navigate("/dashboard", { replace: true })
        : jsonToMap(user).get("role") === "patient" &&
        navigate("/patient-portal/dashboard", { replace: true })}
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            component={Paper}
            sx={{
              backgroundImage: "linear-gradient(180deg, #FFFFFF, #F2F7F8)",
            }}
            elevation={6}
            square
          >
            <Box
              sx={{
                mx: 7,
                my: 5,
              }}
            >
              <img src={PNLogo} width={260} height={55} />
            </Box>
            <Box
              sx={{
                my: 8,
                mx: 4,
                px: {
                  xs:0,
                  sm:20,
                  md:20,
                  lg:20
                }
              }}
            >
              <Typography
                component="h1"
                className="text--terciary"
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "34px",
                  lineHeight: "50px",
                  mb: 2,
                }}
              >

                Login
              </Typography>
              <Typography
                component="h3"
                sx={{
                  color: "rgb(50, 50, 50, 0.7)",
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  mb: 1,
                }}
              >
                {/* Learn about opportunities to help change the conversation around
                mental health. */}
                For a better, faster and more streamlined e-prescribing
                experience.
              </Typography>
              <Typography
                component="h4"
                sx={{
                  color: "rgb(50, 50, 50, 0.7)",
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  // mb: 1,
                }}
              >

                <span className=" text--danger font-14">{location?.state !== undefined ? location?.state?.msg : ""}
                </span>
              </Typography>
              <Box component="div" noValidate sx={{ mt: 1 }}>
                <span className="text--danger font-12">{errorResponse}</span>
                <form
                  id="loginForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleLoginButton();
                  }}
                  autoComplete="off"

                >
                  <div className="form-group mb-2">
                    <label
                      htmlFor="email"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                      className="text--terciary font-12"
                    >
                      Email
                    </label>
                    <input
                      className="form-control fw-sb font-14 shadow-none br-10 input"
                      type="email"
                      autoFocus={true}
                      onChange={(e) => {
                        handleEmailInput(e);
                      }}
                      onKeyPress={(e) => {
                        if (e.code === "Enter" || e.charCode === 13) {
                          handleLoginButton();
                        }
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="password"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                      className="text--terciary font-12"
                    >
                      Password
                    </label>
                    <input
                      className="form-control shadow-none br-10 input"
                      type="password"

                      onChange={(e) => {
                        handlePasswordInput(e);
                      }}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (e.code === "Enter" || e.charCode === 13) {
                          handleLoginButton();
                        }
                      }}
                      required
                    />
                  </div>

                  <div className="d-grid gap-2">
                    <button
                      disabled={isLoading}
                      form="loginForm"
                      className={`btn br-10 mt-4 text-center btn-lg btn-block bg--blue text--white font-14 ${classes["loginBtn"]} ${isLoading && "pb-0"}`}
                      type="submit"
                    >
                      {!isLoading ? "Enter"
                        : (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: "#EC609B",
                              // position: "absolute",
                              // marginTop: "30px",
                              // marginLeft: "165px",
                              // textAlign: "center"
                            }}
                          />
                        )}
                    </button>
                  </div>
                </form>
                <Grid container>
                  <Grid sx={{ marginTop: 3 }} item>
                    {/* <Typography
                      component="h3"
                      sx={{
                        // color: "#336383",
                        color: "#dddddd",
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "26px",
                      }}
                    >
                      First access? &nbsp;
                      <span
                        disabled
                        className="font-14 fw-sb text-decoration-underline"
                      >
                        Create an account
                      </span>
                    </Typography> */}
                  </Grid>
                  <Grid container>
                    <Grid sx={{ marginTop: 0.5 }} item>
                      <Typography
                        component="h3"
                        sx={{
                          color: "#336383",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "26px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate("/get-email");
                        }}
                      >
                        <span
                          disabled
                          className="font-12 fw-sb text-decoration-underline"
                        >
                          Forgot Password?
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            sx={{
              backgroundImage: `url(${LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
      </ThemeProvider>
    </>
  );
}
