import ProviderDashboardPage from './components/pages/ProviderDashboardPage';
import MessagesPage from './components/pages/MessagesPage';
import Appointments from './components/pages/AppointmentsPage';
import PracticeUsersPage from './components/pages/PracticeUsersPage';
import UserRolesPage from './components/pages/UserRolesPage';
import EncountersPage from './components/pages/EncountersPage';
import PharmacyPage from './components/pages/PharmacyPage';
import TasksPage from './components/pages/TasksPage';
import SettingsPage from './components/pages/SettingsPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import PatientConsultPage from './components/pages/PatientConsultPage';
import LoginPage from './components/pages/LoginPage';
import PrescriptionRequests from './components/pages/PrescriptionRequests';
import PatientsPageNew from './components/pages/PatientsPageNew';
import MyAccountPage from './components/pages/MyAccountPage';
import { ProtectedRoutes } from './components/UI/General/ProtectedRoutes';
import ForgetPasswordPage from './components/pages/ForgetPasswordPage';
import EmailForForgetPasswordPage from './components/pages/EmailForForgetPasswordPage';
import TwoFactorAuthPage from './components/pages/TwoFactorAuthPage';
import PatientDashboard from './patientportal/patientpages/PatientDashboard';
import PatientMessagesPage from './patientportal/patientpages/PatientMessagesPage';
import PatientMedicalDictionaryPage from './patientportal/patientpages/PatientMedicalDictionaryPage';
import PatientPrescriptionsPage from './patientportal/patientpages/PatientPrescriptionsPage';
import PatientPharmacyPage from './patientportal/patientpages/PatientPharmacyPage';
import PatientAppointmentsPage from './patientportal/patientpages/PatientAppointmentsPage';
import { OutsideAppLayout } from './components/layouts/OutsideAppLayout';
import { PatientProtectedRoutes } from './components/UI/General/PatientProtectedRoutes';
import MyPatientAccountPage from './patientportal/patientpages/MyPatientAccountPage';
import AddUserPage from './components/pages/AddUserPage';
import NotificationPage from './components/notifications/NotificationPage';
import PatientPaymentPage from './patientportal/patientpages/PatientPaymentPage';
import ApptComponent from './components/forms/intakeform/Form/AppointmentForm/ApptComponent';
import PaymentComponent from './components/forms/intakeform/Form/PaymentComponent/PaymentComponent';
import AddPatientPage from './components/pages/AddPatientPage';
import EditPatientPage from './components/pages/EditPatientPage';
import IdMePage from './components/pages/IdMePage';
import JoinVideoSetup from './patientportal/patientvideoconsult/JoinVideoSetup';

import AgoraLocalVideoConfigurationPage from './components/UI/AgoraVideoComponents/AgoraLocalVideoConfigurationPage';
import AgoraConference from './components/UI/AgoraVideoComponents/AgoraConference';
import { IdleTimerProvider, useIdleTimer, useIdleTimerContext } from 'react-idle-timer';
import { useAuth } from './components/hooks/useAuth';
import FacilityPage from './components/pages/FacilityPage';
import AddFacilityPage from './components/pages/AddFacilityPage';
import accessToSettingsRoles from './utils/medicalAllowedCredentials';
import PatientPortalVideoCall from './patientportal/patientvideoconsult/PatientPortalVideoCall';
import TenantInvite from './components/pages/TenantInvite';
import LabRequisitionPage from './components/pages/LabRequisitionPage';
import ProviderNotePage from './components/pages/ProviderNotePage';
import PatientSoapNotePage from './components/patient/PatientSoapNotePage';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import ClaimsSubmissionPage from './components/pages/ClaimsSubmissionPage';
import TenantSettingsPage from './components/pages/TenantSettingsPage';
import VeeOneIndexPage from './veeone/entry-page/VeeOneIndexPage';
import ReportsPage from './components/pages/ReportsPage';
import ReportsSearchPage from './components/reports/ReportsSearchPage';
import HuddlePage from './components/pages/HuddlePage';
import InPatientDashboard from './components/pages/InPatientDashboard';
import AllForms from './components/pages/AllForms';
import VeeOneOrderMedication from './veeone/order-medication-page/VeeOneOrderMedication';
import VeeOneLabOrder from './veeone/lab-order-page/VeeOneLabOrder';
import PatientRegistrationDemographicsForm from './components/patient-registration/demographics/PatientRegistrationDemographicsForm';
import PatientRegistrationEmailVerificationForm from './components/patient-registration/email-verification/PatientRegistrationEmailVerificationForm';
import AppointmentOptions from './components/patient-registration/options/AppointmentOptions';
import PatientAccountComponent from './components/forms/intakeform/Form/AccountPage/MyAccountPage';
import VeeOnePrescriptionRequests from './veeone/veeone-prescription-requests/VeeOnePrescriptionRequests';
import VeeOneDashboardWrapper from './veeone/VeeOneDashboardWrapper';
import BillingAndClaimsIndex from './billing-and-claims/pages/BillingAndClaimsIndex';
import ClaimsPatientsPage from './claim-management-module/src/components/pages/ClaimsPatientsPage';
import ClaimsAddPatientPage from './claim-management-module/src/components/pages/ClaimsAddPatientPage';
import ClaimsEditPatientPage from './claim-management-module/src/components/pages/ClaimsEditPatientPage';

// const useStyle = makeStyles({
//   page: {
//     background: '#f9f9f9',
//     width: '100%',
//   },
//   root: {
//     display: 'flex',
//   },
// });

function App() {

  const { user, sessionExpired } = useAuth();
  const { inPersonVisitDashboard, pnErx3385ClaimsModuleEnabled } = useFlags();
  const idleTimerContext = useIdleTimerContext();
  console.log("user: ", user);
  const handleOnIdle = event => {

    if (isIdle() && user?.userId !== undefined) {
      sessionExpired();
    }
  };

  const handleOnActive = event => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = event => {
    // console.log('user did something', getRemainingTime() / 1000, event);
    reset();
  };

  const { isIdle, reset, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 10,
    stopOnIdle: true,
    leaderElection: false,
  });

  return (
    <>
      <IdleTimerProvider
        timeout={10000}
        onAction={handleOnAction}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
      >
        <Routes>
          {/* <Route path="/test" element={<TestPage />} /> */}
          <Route path="/veeone" element={<VeeOneIndexPage />} />
          <Route path="/veeone/order-medication" element={<VeeOneOrderMedication />} />
          <Route path="/veeone/lab-order" element={<VeeOneLabOrder />} />
          <Route path="/veeone/prescription-requests" element={<VeeOnePrescriptionRequests veeone={true} />} />
          <Route path="/veeone/px-now" element={<VeeOneDashboardWrapper />} />
          <Route path="/form/" element={<AllForms />} />
          {/* <Route path="/form/" element={<TeleconsultForm />} /> */}
          <Route path="/patient-registration/demographics/:tid" element={<PatientRegistrationDemographicsForm />} />
          <Route path="/patient-registration/email-verification/:tid" element={<PatientRegistrationEmailVerificationForm />} />
          <Route path="/patient-registration/options/:tid" element={<AppointmentOptions />} />
          <Route element={<OutsideAppLayout />}>
            <Route path="/" element={<LoginPage />} />

            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/forget-password"
              element={
                <ForgetPasswordPage
                  url={'/auth/forgetPassword/stepThree'}
                  setPass={false}
                />
              }
            />
            <Route
              path="/account-confirmation"
              element={
                <ForgetPasswordPage
                  url={'/auth/forgetPassword/stepThree'}
                  setPass={true}
                />
              }
            />
            <Route path="/get-email" element={<EmailForForgetPasswordPage />} />
            <Route path="/two-factor-auth" element={<TwoFactorAuthPage />} />
            <Route path="/invite" element={<TenantInvite />} />



            {/* Route for VeeOne */}

            {/*

          This route is now deprecated
          
          <Route
            path="/session-expired"
            element={<LoginAgainModal show={true} />}
          /> */}
          </Route>


          <Route element={<ProtectedRoutes
          />}>
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/all-patients">
              <Route index element={<PatientsPageNew />} />
              <Route path=":pId" element={<PatientsPageNew />} />
              {/* Addedd patient page from  claim-module */}
              {/* {
              //   user && 
              //   <>
              //     <Route index element={<ClaimsPatientsPage useAuthObject={user} />}/>
              //     <Route path=':pId' element={<ClaimsPatientsPage useAuthObject={user}/>}/>
              //   </>
              // }
                 */}

              <Route path=":pId/provider-note" element={<ProviderNotePage />} />
              <Route path=":patientId/soap-note" element={<PatientSoapNotePage />} />
            </Route>

            <Route path="/claims">
              <Route path=":encounterId/claim-submission" element={<ClaimsSubmissionPage />} />
            </Route>
            {
              pnErx3385ClaimsModuleEnabled &&
              <>
                <Route path="/billing-and-claims" element={<BillingAndClaimsIndex useAuthObject={user} />} />
                <Route path="/claim-add-new-patient" element={<ClaimsAddPatientPage useAuthObject={user} />} />
                <Route path="/claim-edit-patient" element={<ClaimsEditPatientPage useAuthObject={user} />} />
              </>
            }
            {/* <Route path="/add-new-patient" element={<AddPatientPage />} /> */}


            <Route path="/edit-patient" element={<EditPatientPage />} />
            <Route path="/add-new-patient" element={<AddPatientPage />} />

            <Route path="/lab-order/:patientId" element={<LabRequisitionPage />} />
            <Route path="/idme/provider" element={<IdMePage />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/roles" element={<UserRolesPage />} />
            <Route path="/tasks" element={<TasksPage tasksOnly={false} />} />
            <Route path="/users" element={<PracticeUsersPage />} />
            <Route path="/tenant-settings" element={<TenantSettingsPage />} />
            {/* <Route path="/patients" element={<PatientsPage />} /> */}
            <Route path="/encounter">
              <Route index element={<EncountersPage />} />
              <Route path=":patientId/soap-note" element={<PatientSoapNotePage />} />
            </Route>
            {/* <Route path="/patients" element={<PatientsPageNew />} /> */}
            <Route path="/pharmacy" element={<PharmacyPage />} />
            <Route path="/inpatient-dashboard" element={<InPatientDashboard />} />
            {/* <Route path="/scripts" element={<ScriptsPage />} /> */}
            {/* <Route path="/reports" element={<ReportsPage />} /> */}
            {accessToSettingsRoles?.includes(user?.role) && <Route path="/settings" element={<SettingsPage />} />}
            {<Route path="/facility" element={<FacilityPage />} />}
            <Route path="/add-user" element={<AddUserPage />} />
            <Route path="/edit-user" element={<AddUserPage editMode />} />
            <Route path="/add-facility" element={<AddFacilityPage />} />
            <Route path="/edit-facility" element={<AddFacilityPage editMode />} />


            <Route path="/dashboard" element={inPersonVisitDashboard === true && user?.role?.toLowerCase() === "staff" ? <InPatientDashboard /> : <ProviderDashboardPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/advanced-search" element={<ReportsSearchPage />} />
            <Route path="/reports/huddle-sheet" element={<HuddlePage />} />
            <Route
              path="/consult/:providerId/:encounterId/:patientId"
              element={<PatientConsultPage />}
            />
            <Route
              path="/prescription-requests/:encounterId/:patientId"
              element={<PrescriptionRequests />}
            />
            <Route path="/account" element={<MyAccountPage />} />
            <Route
              path="/join/:providerId/:encounterId/:patientId"
              element={<AgoraLocalVideoConfigurationPage />}
            />
            <Route path="/agora" element={<AgoraConference />} />
            <Route path="*" element={<Navigate to={'/dashboard'} />} />
          </Route>


          {/* PATIENT PORTAL ROUTES */}
          <Route element={<PatientProtectedRoutes
          />}>

            {/* <Route path="/form/" element={<TeleconsultForm />} /> */}

            <Route
              path="*"
              element={<Navigate to={'/patient-portal/dashboard'} />}
            />

            {/* <Route path="/new/appointment" element={<ApptComponent type={"NEW"} />} /> */}
            <Route path="/schedule/appointment" element={<ApptComponent />} />
            <Route path="/new/payment" element={<PaymentComponent />} />
            <Route
              path="/my-account"
              element={<PatientAccountComponent patientRegistrationHeader={true} />}
            />

            <Route
              path="/patient-portal/dashboard"
              element={<PatientDashboard />}
            />



            <Route
              path="/patient-portal/notifications"
              element={<NotificationPage patient={true} />}
            />

            <Route path="/video-consult" element={<PatientPortalVideoCall />} />
            <Route
              path="/patient-portal/appointments"
              element={<PatientAppointmentsPage />}
            />

            <Route
              path="/patient-portal/messages"
              element={<PatientMessagesPage />}
            />

            <Route
              path="/patient-portal/payment"
              element={<PatientPaymentPage />}
            />

            <Route
              path="/patient-portal/account"
              element={<MyPatientAccountPage />}
            />



            <Route
              path="/patient-portal/dictionary"
              element={<PatientMedicalDictionaryPage />}
            />

            <Route
              path="/patient-portal/prescriptions"
              element={<PatientPrescriptionsPage />}
            />

            <Route
              path="/patient-portal/pharmacy"
              element={<PatientPharmacyPage />}
            />

            <Route
              path="/patient-portal/join/:appId/:patientId"
              element={<JoinVideoSetup />}
            />

            <Route
              path="/patient-portal/consult/:providerId/:encounterId/:patientId"
              // element={<PatientVideoChat />}
              element={<PatientPortalVideoCall />}
            />
          </Route>
        </Routes>
      </IdleTimerProvider>

    </>
  );
}

export default withLDProvider({
  clientSideID: '63ac9dcd4f2a0710f209e5ad',
  options: {
    bootstrap: 'localStorage'
  }
})(App);
