import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: "bottom",
//       horizontal: "right",
//     }}
//     transformOrigin={{
//       vertical: "top",
//       horizontal: "right",
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   "& .MuiPaper-root": {
//     // borderRadius: 6,
//     marginTop: theme.spacing(0),
//     minWidth: 152,
//     background: "#D9E8EC",
//     color: "#004FD4",
//     marginTop: "auto",

//     //   theme.palette.mode === "light"
//     //     ? "rgb(55, 65, 81)"
//     //     : theme.palette.grey[300],
//     // boxShadow:
//     //   "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
//     // "& .MuiMenu-list": {
//     //   padding: "4px 0",
//     // },
//     "& .MuiMenuItem-root": {
//       fontFamily: "Montserrat",
//       fontSize: "14px",
//       fontWeight: "600",

//       "& .MuiSvgIcon-root": {
//         fontSize: "14px",
//         color: "#004FD4",

//         // marginRight: theme.spacing(1.5),
//       },
//       "&:active": {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           theme.palette.action.selectedOpacity
//         ),
//       },
//     },
//   },
// }));

export default function ScheduleDropdown(props) {
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleBlur = () => {
  //   setAnchorEl(null);
  //   open = false;
  // };
  const handleClose = () => {
    // setAnchorEl(null);
    props.setOpenModal(true);
    // alert("reached")
    // setAnchorEl(null);
    // open = false;
  };
  const handleViewClose = () => {
    // setAnchorEl(null);
    props.setOpenViewScheduleModal(true);
    // open = false;
  };

  return (
    <div hidden={userPermissions?.pps === false && userPermissions?.vps === false}>
      {/* <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // variant="contained"
        disableElevation

        onClick={handleClick}
        style={{
          color: "#004FD4",
          background: "#D9E8EC",
          fontSize: "14px",
          fontWeight: "600",
          borderRadius: "10px",
          height: "44px",
          width: "152px",
          fontFamily: "Montserrat",
          textTransform: "capitalize",
        }}
        className="font-14"
        endIcon={<KeyboardArrowDownIcon />}
      >
        Schedule
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleBlur}
      >
        <MenuItem
          onClick={handleViewClose}
          disableRipple
          // style={{ backgroundColor: "#dddddd" }}
          // disabled
        >
          View
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          Publish
        </MenuItem>
      </StyledMenu> */}
      <Dropdown>
        <Dropdown.Toggle
          //  id="dropdown-basic"
          id="demo-customized-button"
          style={{
            border: "1px solid #d9e8ec",
            color: "#004FD4",
            background: "#D9E8EC",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "10px",
            height: "3em",
            width: "12em",
            fontFamily: "Montserrat",
            textTransform: "capitalize",
            borderBottom:0,
          }}
          className=" shadow-none"
          endIcon={<KeyboardArrowDownIcon className="ms-2" />}
          
        >
          Schedule
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            background: "#D9E8EC",
            textColor: "#004FD4",
            borderRadius: "10px",
            // marginTop: "-2px",
            border:"#D9E8EC",
            boxShadow:"none",
            minWidth:"11.07em",
            
          }}
          className="font-12 "
        >
          <Dropdown.Item
            style={{ color: "#004FD4", fontWeight: "600" ,hover:{
              color:'#D9E8EC',
            }}}
            className="font-12"
            onClick={handleViewClose}
            hidden = {userPermissions?.vps === false ? true:false }
          >
            View
          </Dropdown.Item>
          <Dropdown.Item
            style={{ color: "#004FD4", fontWeight: "600" }}
            className="font-12"
            onClick={handleClose}
            hidden = {userPermissions?.psp === false  &&  userPermissions?.pps === false ? true: false}
          >
            Publish
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
