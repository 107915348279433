import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import classes from "../../../patient/HistoryOfPresentIllness.module.css";
import sendHttpRequest, { search } from "../../../hooks/sendHttpRequest";
import classesDI from '../../../prescription/DrugItem.module.css';
import { convertUTCtoTimzone } from "../../../../utils/dateTimeHelper";
import { useAuth } from "../../../hooks/useAuth";
import CustomDatePicker from "../../General/CustomDatePicker";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import { DateTime } from "luxon";
const MedicationHistoryEditModal = (props) => {
  
  const { user } = useAuth();
  const [medicineName, setMedicineName] = useState(props?.editMedicalHistory?.drugName);
  const [suggestions, setSuggestions] = useState([]);
  const [strengthList, setStrengthList] = useState([]);
  let joined = convertUTCtoTimzone(new Date(), user?.timezone,"YYYY-MM-DD");
  const [secheduleStartDate, setScheduleStartDate] = useState(joined);
  const [secheduleEndDate, setScheduleEndDate] = useState(joined);

  const clearStates = () => {
    setMedicineName("");
    setSuggestions([]);
    setScheduleStartDate("");
    setScheduleEndDate("");
    props.handleModal();
  }

  const handleScheduleEndDate = (endDateValue) => {
    props?.setMedicationEndDate(endDateValue);
  };

  const handleScheduleStartDate = (startDateValue) => {
    console.log("startDateValue: ",startDateValue);
    props?.setMedicationStartDate(startDateValue);
  };

  const handleMedicationDoseInput = (event) => {
    props.setDose(event.target.value);
  };

  const handleMedicationFrequency = (event) => {
    props.setFrequency(event.target.value);
  };

  const handleMedicationStrengthInput = (event) => {
    props.setStrength(event.target.value)
  }
  const handleStatus = (event) => {
    props.setMedicationStatus(event.target.checked)
  }

  const onChangeHandler = (drugsearch) => {
    setSuggestions([]);
    setMedicineName(drugsearch);
    props?.setMedicationName(medicineName);
    if (drugsearch.length > 2) {
      searchDrug(drugsearch);
    } else {
      setSuggestions([]);
    }
  };

  const onDrugClickHandler = (event) => {
    setMedicineName(event.target.innerText);
    props?.setMedicationName(event.target.innerText);
    setSuggestions([
      {
        drugMedicationId: "-1",
      },
    ]);

    setStrengthList([]);
    props?.setDdid(event.target.id);
    props?.setGpi(event.target.attributes["data-gpi"].value);
    props?.setDosageForm(event.target.attributes["data-dform"].value);
    getStrengthValue(event.target.id, event.target.attributes["data-dform"].value);
  };

  const getStrengthValue = async (drugName, dosageForm) => {
    let drgName = drugName !== undefined ? drugName : props?.ddid;
    let dsgFrm = dosageForm !== undefined ? dosageForm : props?.dosageForm;
    const httpResponse = await sendHttpRequest({
      url: `/drugs/strength`,
      method: "GET",
      params: {
        drugName: drgName,
        dosageForm: dsgFrm
      }
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setStrengthList(httpResponse.data);
    } else {
    }
  };

  const searchDrug = async (drugInputValue) => {
    // console.log(drugInputValue);

    const httpResponse = await search({
      url: "/drugs/drugName",
      method: "GET",
      params: {
        name: drugInputValue,
      },
    });
    if (!httpResponse.error) {
      setSuggestions(httpResponse.data);
    } else {
      // setSig([]);
      if (httpResponse?.status !== undefined) {
      setSuggestions([
        {
          drugMedicationId: "-1",
        },
      ]);
    }
    }
  };

  useEffect(() => {
    getStrengthValue();
  
    return () => {}

  }, [props?.show])

  console.log(props);
  

  return (
    <Modal
      show={props.show}
      onHide={() => {
        clearStates();
      }}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow medicaiton-history-modal-width"
    >
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  clearStates();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    {"Edit Medication History"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <form
            id="medicationHistoryEditForm"
            onSubmit={(e) => {
              e?.preventDefault();
              props?.editMedicationHistoryRequestHandler();
              clearStates();
              props?.handleModal();
            }}
          >
          

            <div className="row mt-3">
              <div className="col-sm-7 ">
                <label className="b2 text--secondary ">Name</label>
                <input
                type="text"
                className={`form-control col-sm-6 font-14 mb-2 br-10 shadow-none border--default ${classes.input}`}
                id="drugsearch"
                name="drugsearch"
                autoComplete="off"
                placeholder="Search for medication"
                value={medicineName}
                // disabled={updateCheck ? true : false}
                onChange={(event) => onChangeHandler(event.target.value)}
                onBlur={() => {
                  setTimeout(() => {
                    setSuggestions([]);
                  }, 5);
                }}
              />
                <ul
                  className=" col-sm-3 "
                  style={{
                    listStyleType: "none",
                    width: `50%`,
                    cursor: "pointer",
                    paddingLeft: "0",
                    margin: "2px 0 0",
                    position: "absolute",
                    listStyle: "none",
                    overflow: "auto",
                    maxHeight: "250px",
                    borderRadius: "4px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                    zIndex: "10",
                    backgroundColor: "white",
                  }}
                >
                  {suggestions?.length > 0 &&
                    suggestions?.map((suggestion) => {
                      return suggestion?.drugMedicationId !== "-1" ? (
                        <li
                          className={`border--default ${classesDI.li}`}
                          style={{
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "8px",
                            display: "flex",
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            borderBottom: "1px solid #d9e8ec",
                          }}
                          key={suggestion?.drugMedicationId}
                          id={suggestion?.drugName}
                          name={suggestion?.description}
                          data-gpi={suggestion?.gpi} 
                          data-dform={suggestion?.dosageForm}
                          onMouseDown={onDrugClickHandler}
                        >
                          <span id={suggestion?.drugName} data-gpi={suggestion?.gpi} data-dform={suggestion?.dosageForm} style={{ flexGrow: 1 }}>
                            {suggestion?.description}
                          </span>
                        </li>
                      ) : (
                        <li
                          style={{
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            paddingLeft: "8px",
                            display: "flex",
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            borderBottom: "1px solid #d9e8ec",
                          }}
                          key={suggestion?.drugMedicationId}
                        >
                          Medication not found
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="col-sm-5">
                <label className="b2 text--secondary ">Strength</label>
                <select
                  className={`form-select br-10 font-12 shadow-none`}
                  aria-label="strength"
                  onChange={(e) => handleMedicationStrengthInput(e)}
                  id="gender"
                  style={{ border: "1px solid #D9E8EC" }}
                >
                  <option className="font-12">Select</option>
                  {strengthList?.length > 0
                    ? strengthList?.map((strength) => {
                        return (
                          <option
                            id={strength?.strength}
                            key={strength?.strength}
                            value={strength?.strength}
                            className={"font-12"}
                            selected={strength?.strength === props?.editMedicalHistory?.strength}
                          >
                            {strength?.strength}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-7">
                <label className="b2 text--secondary ">Dose</label>
                <input
                  type="text"
                  className="form-control font-12 br-10 shadow-none"
                  defaultValue={props.editMedicalHistory?.dose ?? ""}
                  onChange={(e) => handleMedicationDoseInput(e)}
                  style={{
                    border: "1px solid #D9E8EC",
                  }}
                ></input>
              </div>
              <div className="col-sm-5">
                <label className="b2 text--secondary ">Frequency</label>
                <select
                  className="form-select br-10 font-12 shadow-none"
                  aria-label="strength"
                  onChange={(e) => handleMedicationFrequency(e)}
                  id="strength"
                  style={{ border: "1px solid #D9E8EC" }}
                >
                  <option className="font-12 ">Select</option>
                  {props?.frequencyList?.length > 0
                    ? props?.frequencyList?.map((frequency) => {
                        return (
                          <option
                            id={frequency?.medicationFrequencyId}
                            key={frequency?.medicationFrequencyId}
                            value={frequency?.frequency}
                            className={"font-12"}
                            selected={props?.editMedicalHistory?.frequency === frequency?.frequency}
                          >
                            {frequency?.frequency}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-5">
                <label className="b2 text--secondary ">Start Date</label>
                <CalenderDatePicker
                  dobHandler={handleScheduleStartDate}
                  dateFormat="MMM d, yyyy"
                  date={props?.editMedicalHistory?.startDate !== null ? props?.editMedicalHistory?.startDate : null}
                  // minDate={secheduleStartDate}
                  maxDate={DateTime.now()}
                  // borderColor="#fff"
                  // publishSchedule={true}
                  showNextYear={true}
                  // prescribeDrug = {true}
                />
              </div>
              <div className="col-sm-7">
                <div className="d-flex flex-row justify-content-between">
                  <div className="col-sm-8 m-0">
                    <label className="b2 text--secondary ">End Date</label>
                    <CalenderDatePicker
                      dobHandler={handleScheduleEndDate}
                      dateFormat="MMM d, yyyy"
                      date={props?.editMedicalHistory?.endDate !== null ? props?.editMedicalHistory?.endDate : null}
                      // minDate={secheduleStartDate}
                      maxDate={DateTime.now()}
                      // borderColor="#fff"
                      // publishSchedule={true}
                      showNextYear={true}
                      // prescribeDrug = {true}
                    />
                  </div>
                  <div className="mt-4 m-0">
                    <input
                      type="checkbox"
                      className={`form-check-input ms-0 ${classes["hpiinput"]} shadow-none`}
                      id="medicationStatus"
                      placeholder=""
                      name="medicationStatus"
                      defaultChecked={props?.editMedicalHistory?.status}
                      // value={props.edit && props.endDate &&  props.endDate }
                      onChange={(e) => handleStatus(e)}
                      style={{
                        border: "1px solid #D9E8EC",
                      }}
                    />
                    <label
                      className={`b2 text--secondary shadow-none  ms-2 ${classes["pi--margin-top"]}`}
                    >
                      Active
                    </label>
                    {/* <label className="b2 text--secondary ">Medication Status</label> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <button
          className="btn b2 px-4 br-10 py-1 blue_task_button"
          type="submit"
          form="medicationHistoryEditForm"
        >
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MedicationHistoryEditModal;
