import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { ThemeProvider } from "@mui/styles";

import classes from "./myAccount.module.css";
import NewPatientModal from "../UI/Modals/PatientModals/NewPatientModal";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import InsurancePage from "../../patientportal/patientpages/InsurancePage";
import { TabPanel, theme } from "../UI/Reusable/PnComponents";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";

import { fieldValidation, navigateOnDirtyBit, UnloadHandler, removeIfAllEmpty } from "../../utils/helpers/HelperFunctions";
import { common_strings, numbersEnum, addressType } from "../../res/strings";
import { payersListUrl, practiceCodeTypeListUrl, relationsListUrl } from "../../res/endpointurls";
import useEventListener from "../hooks/useEventListener";
import useModal from "../hooks/useModal";
import PatientAlreadyExistsModal from "../patient/PatientAlreadyExistsModal";

const AddPatientPage = () => {
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const dispatch = useDispatch();
  const _str = common_strings["en"];
  const _numberTypes = numbersEnum["en"];
  const _addressTypes = addressType["en"];
  const [value, setValue] = useState(0);
  const [insurancevalue, setInsurancevalue] = useState(0);
  const [payersList, setPayersList] = useState({ isLoading: false });
  const [serverErrors, setServerErrors] = useState();
  const [isSending, setIsSending] = useState(false);
  const [patientForm, setPatientForm] = useState("");
  const [primaryInsurance, setPrimaryInsurance] = useState("");
  const [primaryInsurance1, setPrimaryInsurance1] = useState({});
  const [secondaryInsurance, setSecondaryInsurance] = useState("");
  const [secondaryInsurance1, setSecondaryInsurance1] = useState({});
  const [tertiaryInsurance, setTertiaryInsurance] = useState("");
  const [tertiaryInsurance1, setTertiaryInsurance1] = useState({});
  const [relationsList, setRelationsList] = useState([]);
  const { user } = useAuth();
  const { open: isWarningModalOpen, handleOpen: handleWarningModalOpen, handleClose: handleWarningModalClose, handleModal: handleWarningModal } = useModal();
  const [patientJson, setPatientJson] = useState({
    FirstName: "",
    LastName: "",
    MiddleName: "",
    biologicalSex: "",
    dob: "",
    SSN: "",
    AddressLine1: "",
    City: "",
    state: "",
    ZipCode: "",
    pharmacy: "",
    CellPhone: "",
    Landline: "",
    EmergencyContact: "",
    Email: "",
    Consent: "",
    Insured: false,
  });

  const [primaryInsuranceJson, setPrimaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Primary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [secondaryInsuranceJson, setSecondaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Secondary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [tertiaryInsuranceJson, setTertiaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Tertiary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [s1, setS1] = useState(false);
  const [s2, setS2] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isValidSSN, setIsValidSSN] = useState('');
  const [patientAlreadyExists, setPatientAlreadyExists] = useState(false);
  const [existingPatientRecord, setExistingPatientRecord] = useState(null);
  const [newRecord, setNewRecord] = useState(null);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInsuranceChange = (event, newValue) => {
    setInsurancevalue(newValue);
  };



  const clearInsuranceData = () => {
    setPrimaryInsurance(p => ({
      insuranceName: "",
      payerCode: "",
      // policyNumber: "",
      memberId: "",
      groupNumber: "",
      effectiveDate: "",
      terminationDate: "",
      relationshipToInsured: "",
      contactNumber: "",
      type: "Primary",
      firstName: "",
      lastName: "",
      middleName: "",
      gender: "",
      dateOfBirth: "",
      city: "",
      state: "",
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
    }));
    setSecondaryInsurance(p => ({
      insuranceName: "",
      payerCode: "",
      // policyNumber: "",
      memberId: "",
      groupNumber: "",
      effectiveDate: "",
      terminationDate: "",
      relationshipToInsured: "",
      contactNumber: "",
      type: "Secondary",
      firstName: "",
      lastName: "",
      middleName: "",
      gender: "",
      dateOfBirth: "",
      city: "",
      state: "",
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
    }))
    setTertiaryInsurance(p => ({
      insuranceName: "",
      payerCode: "",
      // policyNumber: "",
      memberId: "",
      groupNumber: "",
      effectiveDate: "",
      terminationDate: "",
      relationshipToInsured: "",
      contactNumber: "",
      type: "Tertiary",
      firstName: "",
      lastName: "",
      middleName: "",
      gender: "",
      dateOfBirth: "",
      city: "",
      state: "",
      zipCode: "",
      addressLine1: "",
      addressLine2: "",
    }))
  }




  const addNewPatientRequestHandler = async (patientObj) => {
    dispatch(setDirtyBitInStore(false));

    const insurancesArray = [];
    if (patientObj?.insured === true) {
      if (removeIfAllEmpty(primaryInsuranceJson) === false) {
        insurancesArray.push(primaryInsuranceJson);
      }
      if (removeIfAllEmpty(secondaryInsuranceJson) === false) {
        insurancesArray.push(secondaryInsuranceJson);
      }
      if (removeIfAllEmpty(tertiaryInsuranceJson) === false) {
        insurancesArray.push(tertiaryInsuranceJson);
      }
    }


    setIsSending(true);

    const httpResponse = await sendHttpRequest({
      url: "/patient/create",
      method: "POST",
      data: {
        ...patientObj,
        patientInsurance: insurancesArray,
      },
    });

    if (!httpResponse.error) {
      setIsSent(true);
      setIsSending(false);
      navigate("/all-patients", { replace: true });
    } else {

      if (httpResponse.status === 409) {
        setExistingPatientRecord(httpResponse?.errorMessage?.object);
        handleWarningModalOpen();
      }

      if (httpResponse?.errorMessage?.errorDetails?.filter((error) => error.value === "PATIENT_ALREADY_EXISTS")?.at(0) !== undefined) {
        setPatientAlreadyExists(true);
        setIsError(true);
      }
      setServerErrors(httpResponse.errorMessage.errorDetails);
      setIsSending(false);
    }
  };

  const fetchPayersList = async () => {
    setPayersList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({ method: 'GET', url: payersListUrl() });
    if (!httpResponse.error) {

      setPayersList((prev) => ({ ...prev, list: httpResponse.data }));
    }
    setPayersList((prev) => ({ ...prev, isLoading: false }));
  }

  const fetchRelations = async () => {
    const httpResponse = await sendHttpRequest({ method: 'GET', url: relationsListUrl() });
    if (!httpResponse.error) {
      if (typeof httpResponse.data === "object") {
        setRelationsList(httpResponse.data);
      }
    }
  }

  const handleSubmit = () => {
    setValue(0);
    setInsurancevalue(0);
    if (patientForm?.reportValidity()) {

      // patientForm?.requestSubmit();
      addPatientHandler(patientJson);
      setPrimaryInsurance1(primaryInsuranceJson);
      setSecondaryInsurance1(secondaryInsuranceJson);
      setTertiaryInsurance1(tertiaryInsuranceJson);
    }

  };

  const getInsuranceTabValue = function (insuranceTabsTitle) {
    if (value === 1) {
      const insuranceTabsMap = new Map();
      insuranceTabsMap.set("Primary", 0);
      insuranceTabsMap.set("Secondary", 1);
      insuranceTabsMap.set("Tertiary", 2);
      return insuranceTabsMap.get(insuranceTabsTitle);
    }
  };

  const getTabValue = function (tabTitle) {
    const tabsMap = new Map();
    tabsMap.set("General", 0);
    tabsMap.set("Insurance", 1);
    return tabsMap.get(tabTitle);
  };

  const addPatientHandler = (patientJson) => {
    let tempAddress = {};
    let tempPatientObject = {
      emailConsent: patientJson?.Consent,
      communicationNumbers: { electronicMail: [], numbers: [], emergencyContact: [] },
    };

    if (
      patientJson?.biologicalSex !== "Select" &&
      fieldValidation(patientJson?.biologicalSex, "all")
    ) {
      tempPatientObject = {
        ...tempPatientObject,
        gender: patientJson?.biologicalSex,
      };
    }

    if (
      patientJson?.pharmacy !== "Select Pharmacy" &&
      fieldValidation(patientJson?.pharmacy, "all")
    ) {
      tempPatientObject = {
        ...tempPatientObject,
        preferredPharmacy: patientJson?.pharmacy,
      };
    }

    if (fieldValidation(patientJson?.dob, "all")) {
      tempPatientObject = {
        ...tempPatientObject,
        dateOfBirth: patientJson?.dob,
      };
    }

    if (fieldValidation(patientJson?.Landline, "all")) {
      tempPatientObject?.communicationNumbers?.numbers.push({
        number: patientJson?.Landline,
        type: _numberTypes.homeTelephoneExt,
      });
    }

    if (fieldValidation(patientJson?.CellPhone, "all")) {
      tempPatientObject?.communicationNumbers?.numbers.push({
        number: patientJson?.CellPhone,
        type: _numberTypes.cellPhoneExt,
      });
    }

    if (fieldValidation(patientJson?.EmergencyContact, "all")) {
      tempPatientObject?.communicationNumbers?.emergencyContact.push({
        phoneNumber: patientJson?.EmergencyContact,
        relationship: patientJson?.Relationship,
        name: patientJson?.emergencyContactName
      });
    }

    if (fieldValidation(patientJson?.Email, "all")) {
      tempPatientObject?.communicationNumbers?.electronicMail?.push(
        patientJson?.Email
      );
    }

    if (fieldValidation(patientJson?.SSN, "all")) {
      tempPatientObject.identification = { socialSecurity: patientJson?.SSN };
    }
    const tempName = {};
    if (fieldValidation(patientJson?.FirstName, "all")) {
      tempName.firstName = patientJson?.FirstName;
      tempName.type = "cn";
    }

    if (fieldValidation(patientJson?.LastName, "all")) {
      tempName.lastName = patientJson?.LastName;
      tempName.type = "cn";
    }

    if (fieldValidation(patientJson?.MiddleName, "all")) {
      tempName.middleName = patientJson?.MiddleName;
      tempName.type = "cn";
    }

    if (fieldValidation(patientJson?.suffix, "all")) {
      tempName.suffix = patientJson?.suffix;
      tempName.type = "cn";
    }

    if (Object.keys(tempName).length !== 0) {
      tempPatientObject.name = [tempName]

    }

    if (fieldValidation(patientJson?.AddressLine1, "all")) {
      tempAddress.addressLine1 = patientJson?.AddressLine1;
      tempAddress.type = _addressTypes.currentAddress; //NEED TO BE DISCUSSED
    }
    if (fieldValidation(patientJson?.AddressLine2, "all")) {
      tempAddress.addressLine2 = patientJson?.AddressLine2;
      tempAddress.type = _addressTypes.currentAddress; //NEED TO BE DISCUSSED
    }

    if (fieldValidation(patientJson?.City, "all")) {
      tempAddress.city = patientJson?.City;
    }

    if (fieldValidation(patientJson?.state, "all")) {
      tempAddress.stateProvince =
        patientJson?.state.length > 0 ? patientJson?.state[0]?.code : "";
    }

    if (fieldValidation(patientJson?.ZipCode, "all")) {
      tempAddress.postalCode = patientJson?.ZipCode;
    }

    if (Object.keys(tempAddress).length !== 0) {
      tempPatientObject.address = [tempAddress];
    }

    tempPatientObject.tenant = user?.tenant;
    tempPatientObject.insured = patientJson?.Insured;
    setNewRecord(tempPatientObject);
    addNewPatientRequestHandler(tempPatientObject);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
  };

  const handleErrorToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsError(false);
  };

  useEventListener('beforeunload', UnloadHandler(dirtyBitStatus));
  // window.addEventListener("beforeunload", UnloadHandler(dirtyBitStatus))

  useEffect(() => {
    fetchPayersList();
    fetchRelations();
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <>
        <div
          className={`container-fluid bg-white  justify-content-center px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div className="row">
            <div className="col-sm-4 text--terciary font-32 align-self-center ">
              <div className="d-flex flex-row">
                <div
                  style={{ width: "40px", height: "40px" }}
                  className={`bg--background br-10 me-3 border--default border align-self-center text-center my-auto`}
                  onClick={() => {
                    navigateOnDirtyBit(
                      dirtyBitStatus,
                      navigate,
                      -1,
                      dispatch,
                      setDirtyBitInStore
                    );
                  }}
                >
                  <ChevronLeft
                    style={{ color: "#EC609B" }}
                    className="my-auto cursor-pointer mb-2"
                  />
                </div>
                <div className="font-24 fw-sb text--terciary align-self-center"> Add Patient </div>
              </div>
            </div>
            <div className="col-sm-8">
              <Tabs
                indicatorColor="secondary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#EC609B",
                  },
                }}
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
              >
                <Tab
                  key={getTabValue("General")}
                  label={"General"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                />

                {patientJson?.Insured === true ? <Tab
                  key={getTabValue("Insurance")}
                  label={"Insurance"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                /> : null}
              </Tabs>
            </div>
          </div>
        </div>
        {patientJson?.Insured ? (<TabPanel value={value} index={getTabValue("Insurance")}>
          <div className={`container-fluid bg--background px-3 py-3`}>
            <div className="row justify-content-center">
              <div className="col-sm-4 col-md-4">
                <Tabs
                  indicatorColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#EC609B",
                    },
                  }}
                  value={insurancevalue}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleInsuranceChange}
                >
                  <Tab
                    key={getInsuranceTabValue("Primary")}
                    label={"Primary"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                  />

                  <Tab
                    key={getInsuranceTabValue("Secondary")}
                    label={"Secondary"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                  />

                  <Tab
                    key={getInsuranceTabValue("Tertiary")}
                    label={"Tertiary"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                  />
                </Tabs>
              </div>
            </div>
          </div>
        </TabPanel>) : null}
        <div className="container-fluid bg--background p-0 py-3 ">

          <TabPanel value={value} index={getTabValue("General")}>
            <NewPatientModal
              setPatientJson={setPatientJson}
              patientJson={patientJson}
              handleSubmit={handleSubmit}
              addNewPatientRequestHandler={addNewPatientRequestHandler}
              relationsList={relationsList}
              setPatientForm={setPatientForm}
              serverErrors={serverErrors}
              clearInsuranceData={clearInsuranceData}
              fetchPatients={null}
              setS2={setS2}
              isSending={isSending}
              isValidSSN={isValidSSN}
              setIsValidSSN={setIsValidSSN}
            />
          </TabPanel>

          {patientJson?.Insured ? (<><TabPanel
            value={insurancevalue}
            index={getInsuranceTabValue("Primary")}
            className="bg--background "
          >
            {payersList?.isLoading === false ? <InsurancePage
              payersList={payersList}
              insuranceJson={primaryInsuranceJson}
              setInsuranceJson={setPrimaryInsuranceJson}
              handleSubmit={handleSubmit}
              setPrimaryInsurance={setPrimaryInsurance}
              type={"primary"}
            /> : <Skeleton />}
          </TabPanel>

            <TabPanel
              value={insurancevalue}
              index={getInsuranceTabValue("Secondary")}
              className="bg--background "
            >
              {payersList?.isLoading === false ? <InsurancePage
                payersList={payersList}
                insuranceJson={secondaryInsuranceJson}
                setInsuranceJson={setSecondaryInsuranceJson}
                handleSubmit={handleSubmit}
                setSecondaryInsurance={setSecondaryInsurance}
                type={"secondary"}
              /> : <Skeleton />}
            </TabPanel>

            <TabPanel
              value={insurancevalue}
              index={getInsuranceTabValue("Tertiary")}
              className="bg--background "
            >
              {payersList?.isLoading === false ? <InsurancePage
                payersList={payersList}
                insuranceJson={tertiaryInsuranceJson}
                setInsuranceJson={setTertiaryInsuranceJson}
                handleSubmit={handleSubmit}
                setTertiaryInsurance={setTertiaryInsurance}
                type={"tertiary"}
              /> : <Skeleton />}
            </TabPanel> </>) : null}
        </div>
      </>

      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {isWarningModalOpen ? <PatientAlreadyExistsModal
        show={isWarningModalOpen}
        handleWarningModalClose={handleWarningModalClose}
        existingRecord={existingPatientRecord}
        newRecord={newRecord}
        primaryInsuranceJson={primaryInsuranceJson}
        secondaryInsuranceJson={secondaryInsuranceJson}
        tertiaryInsuranceJson={tertiaryInsuranceJson}
      /> : null}

      <CustomizedSnackbars
        message={"Data saved successfully"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />

      <CustomizedSnackbars
        message={"Patient already exists"}
        severity="error"
        open={isError}
        handleClose={handleErrorToastClose}
      />
    </ThemeProvider>
  );
};
export default AddPatientPage;
