import { Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Accordion, AccordionContext, Card, useAccordionButton } from "react-bootstrap";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from "react-redux";

const UserRolesItem = (props) => {

  const dispatch = useDispatch();


  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <span
        //   type="button"
        //   style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  }
  const allCheckRoles = (event) => {
    // let id = event.target.id.split(",");
    // dispatch(setDirtyBitInStore(true));
    props.setAreChangesMade(true);
    let doc = document.querySelectorAll(`[data-privilege-id]`)
    doc.forEach((d) => {
      // console.log("event.target: ",event.target);
      if (d.dataset.privilegeId === event.target.id && d.dataset.editable === "false")
        if (event.target.checked) {
          if (!d.checked) createJsonOnGroupClick(d);
        } else if (!event.target.checked) {
          if (d.checked) createJsonOnGroupClick(d)
        }
    })
  }

  const createJsonOnGroupClick = (d) => {
    d.checked = !d.checked;
    props.roleToEdit.push(
      { isActive: d.checked, id: d.id }
    )
  }

  const checkIfAllBoxesAreChecked = (grp_elem) => {
    let flag = true;
    let doc = document.querySelectorAll(`[data-privilege-id]`)
    doc.forEach((d) => {
      if (d.dataset.privilegeId == grp_elem.id) {
        if (!d.checked) {
          flag = false;
        } else {
          if (flag) {
            flag = true;
          }
        }
      }
    })
    return flag;
  }

  const checkIfAnyBoxIsChecked = (grp_elem) => {
    let flag = false;
    let doc = document.querySelectorAll(`[data-privilege-id]`)
    doc.forEach((d) => {
      if (d.dataset.privilegeId == grp_elem.id) {
        if (d.checked) {
          flag = true;
        }
      }
    })
    return flag;
  }



  const editRoles = (event) => {
    props.setAreChangesMade(true);
    let id = event.target.id;
    let checked = event.target.checked;
    // dispatch(setDirtyBitInStore(true));
    // let doc = document.querySelectorAll(`[data-privilege-group-id]`)
    let doc = document.querySelectorAll(`[id]`);
    let priv_id = event.target.dataset.privilegeId.split(",");
    doc.forEach((d) => {
      if (d.id == (priv_id[0] + "," + priv_id[1])) {
        if (checkIfAllBoxesAreChecked(d)) {
          d.checked = true;
          d.indeterminate = false;
        } else {
          if (checkIfAnyBoxIsChecked(d)) {
            d.checked = false;
            d.indeterminate = true;
          } else {
            d.checked = false;
            d.indeterminate = false;
          }
        }
      }
    })


    props.roleToEdit.push(
      { isActive: checked, id: id }
    )


    // props.roleToEdit?.Privileges?.length > 0 && (
    //   props.roleToEdit?.Privileges?.map((privilege, index) => {
    //     if (privilege.Id == idArr[0]) {
    //       privilege?.Tasks?.length > 0 && (
    //         privilege?.Tasks?.map((task, index) => {
    //           if (task.Id == idArr[1]) {
    //             // task?.Rights[idArr[2]] = event.target.checked;
    //           }
    //         })
    //       )
    //       for (var i = 0; i < privilege?.Tasks.length > 0; i++) {
    //         if (privilege?.Tasks[i].Rights[idArr[2]] === true) {
    //           privilege[index]?.AllChecked[idArr[2]] = true;
    //         } else {
    //           privilege[index]?.AllChecked[idArr[2]] = false;
    //           break;
    //         }
    //       }
    //       // privilege?.Tasks?.length > 0 && (
    //       //   privilege?.Tasks?.map((task, index) => {
    //       //     if (task?.Rights[idArr[2]] === true) {

    //       //     } else {
    //       //       privilege?.AllChecked[idArr[2]] = false;
    //       //     }
    //       //   })
    //       // )
    //     }
    //   })
    // )

  }
  // console.log(props);
  return (
    <>
      <div className={`row`}>
        <div className="col-12">
          {
            props.privlieges?.length > 0 && (
              props.privlieges?.map((priv, index) => {
                return (
                  // <Accordion defaultActiveKey="0" >
                  <Accordion   defaultActiveKey = {index === 0 ?  "0" : index} key={index} className={index === 0 ? "mt-2" : ""} >
                    <Card>
                      <Card.Header className="bg--d9e8ec p-0">
                        <div className="row justify-content-between ms-2">
                          <div className="col-md-3 py-3">
                            <ContextAwareToggle eventKey="0">
                              <span>
                                <ExpandLessIcon style={{ color: "#EC609C" }} />
                              </span>
                              <span className="ms-4 fw-sb font-14 align-self-center">
                                {priv?.name}
                              </span>
                            </ContextAwareToggle>
                          </div>
                          <div className="col-md-9 justify-content-between ">

                            {/* {checked ? <span id={priv.id} className="text-left fw-sb d-block text--primary"><CheckIcon /></span> : <span className="text--white fw-sb text-left d-block"><RemoveIcon /></span>} */}
                            {
                              props.privligesGroup?.length > 0 && (
                                props.privligesGroup?.map((privgrp, index) => {
                                  if (privgrp.privilegeGroupId == priv.privlegeGroupId) {
                                    return (
                                      privgrp.roleRightMatrix?.length > 0 && (
                                        privgrp.roleRightMatrix?.map((rrm, index) => {
                                          return (
                                            <TableCell width={"200px"} align={"left"} style={{ borderBottomColor: "#D9E8EC" }} >
                                              {
                                                props?.isEditRoles ?
                                                  <div className="form-check">
                                                    <input className="form-check-input d-block shadow-none" type="checkbox" defaultChecked={rrm.isActive} onChange={allCheckRoles} value="" data-privilege-group-id={privgrp.privilegeGroupId} id={privgrp.privilegeGroupId + "," + rrm.roleId} />
                                                    {/* <input class="form-check-input d-block shadow-none" type="checkbox" defaultChecked={right?.isActive} onChange={editRoles} value="" data-right-id={right?.id} id={right?.id} /> */}
                                                  </div>
                                                  :
                                                  rrm.isActive ? <span id={privgrp.privilegeGroupId + "," + rrm.roleId} className="text-left fw-sb d-block text--primary"><CheckIcon fontSize="small" /></span> : <span id={privgrp.privilegeGroupId + "," + rrm.roleId} className="text--white fw-sb text-left d-block"><RemoveIcon /></span>
                                              }
                                            </TableCell>
                                          )
                                        }))
                                    )
                                  }

                                }))
                            }
                          </div>
                        </div>

                      </Card.Header>
                      {
                        priv?.privilege?.length > 0 && (
                          priv?.privilege.map((spriv, index) => {
                            return (
                              <TableBody>
                                <TableRow
                                  key={index}
                                  sx={{ borderBottom: "#FFFFFF" }}
                                  id="userRoles"
                                >
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body style={{ padding: 0, borderBottom: "1px solid #D9E8EC" }}>
                                      <div className="row p-0 justify-content-between ms-2">
                                        <div className="col-md-3 mt-3">
                                          {/* <span className="ms-2 me-2 font-12 align-self-center "> */}
                                          <Typography component="span">
                                            <Box
                                              sx={{
                                                fontFamily: "Montserrat",
                                                ml: 1,
                                                mt: 1,
                                                // my: -1,
                                                fontSize: "12px",
                                                color: "#323232",
                                              }}
                                            >
                                              {spriv?.name}
                                            </Box>
                                          </Typography>
                                        </div>
                                        <div className="col-md-9 justify-content-between">
                                          {
                                            props?.roles?.length > 0 && (
                                              props?.roles.map((role, index) => {
                                                // right.map((singleRight, singleRight_index) => {
                                                return (
                                                  <TableCell width={"250px"} align={"left"} style={{ borderBottomColor: "#FFFFFF" }} data-single-privilege-id={spriv.id} data-role-id={role.id} id={priv.privlegeGroupId + "," + spriv.id + "," + role.id}>                                                    {
                                                    props?.rights?.length > 0 && (
                                                      props?.rights.map((right, index) => {
                                                        if ((spriv?.id == right?.privileges.id) && (role?.id == right?.roleId)) {
                                                          return (
                                                            props?.isEditRoles ?
                                                              <div className="form-check">
                                                                <input className="form-check-input d-block shadow-none" type="checkbox" defaultChecked={right?.active} onChange={editRoles} data-privilege-id={[priv.privlegeGroupId, role.id]} value="" data-right-id={right?.privilegeRoleMatrixId} id={right?.privilegeRoleMatrixId} disabled = {!right?.editable} data-editable = {!right?.editable}/>
                                                              </div>
                                                              :
                                                              right?.active ? <span id={right?.privilegeRoleMatrixId} className="text-left fw-sb d-block text--blue"><CheckIcon fontSize="small" /></span> : <span id={right?.privilegeRoleMatrixId} className="text--terciary fw-sb text-left d-block"><RemoveIcon fontSize="small" /></span>
                                                          )
                                                        }
                                                      }))
                                                  }
                                                  </TableCell>
                                                )
                                                // })
                                              })

                                            )
                                          }
                                          {/* <>{hydrateCells()}</> */}
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </TableRow>
                              </TableBody>
                            )
                          })
                        )
                      }
                    </Card >
                  </Accordion >
                )
                // if((props?.privlieges?.length-1) === index ){
                //   console.log("hydrate");
                //   hydrateCells();
                // }
              })
            )
          }
        </div>
      </div>
    </>
  );
};

export default UserRolesItem;
