import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";
import { RolesMap } from "../../utils/helpers/RolesMap";
import usePlaceOfServiceCodes from "../../../../components/claims/usePlaceOfServiceCodes";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { DateTime } from "luxon";
import useDiagnosisSearch from "../../../../components/diagnosis-search/useDiagnosisSearch";
import DiagnosisSearch from "../../../../components/diagnosis-search/DiagnosisSearch";



const ClaimEncountersTabPanel = (props) => {
    const user = props?.useAuthObject || require("../hooks/useAuth")?.user;
    const [renderingProvidersList, setRenderingProvidersList] = useState([]);
    const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] = useState([]);
    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = usePlaceOfServiceCodes();

    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedPlaceOfService, setSelectedPlaceOfService] = useState(null);
    const [selectedServiceLocation, setSelectedServiceLocation] = useState(null);

    const [serviceDate, setServiceDate] = useState(null);
    const [lastSeenDate, setLastSeenDate] = useState(null);
    const [dateOfCurrentIllness, setDateOfCurrentIllness] = useState(null);
    const [lastMensturalPeriodDate, setLastMensturalPeriodDate] = useState(null);

    // console.log(props?.encounterDto);
    const { suggestions, setSuggestions, isLoading, setIsLoading, isError, setIsError, searchInputValue, setSearchInputValue, diagnosisName, setDiagnosisName, selectedDiagnosis, setSelectedDiagnosis, searchDiagnosis, handleSearchInput, handleAddDiagnosis, handleRemoveDiagnosis } = useDiagnosisSearch();
    const [diagnosisList, setDiagnosisList] = useState([]);

    const handleDiagnosisList = (addedDiagnosis) => {
        handleAddDiagnosis(addedDiagnosis);
        if (diagnosisList?.length === 0) {
            addedDiagnosis["type"] = "P";
        }
        setDiagnosisList(prevList => [...prevList, addedDiagnosis]);
        props?.setEncounterDto((dto) => ({ ...dto, "diagnosis": [...diagnosisList, addedDiagnosis] }));
    }

    function handleProviderId(event) {
        setSelectedProvider(event.target.value);
        props?.setEncounterDto((dto) => ({ ...dto, "claimProviderDataId": event.target.value, "provider": { "providerId": event.target.value } }))
    }

    function handlePlaceOfService(event) {
        setSelectedPlaceOfService(event.target.value);
        props?.setEncounterDto((dto) => ({ ...dto, "encounterMetadata": { ...dto?.encounterMetadata, "placeOfService": event.target.value } }));
    }

    function handleServiceLocation(event) {
        setSelectedServiceLocation(event.target.value);
        props?.setEncounterDto((dto) => ({ ...dto, "encounterMetadata": { ...dto?.encounterMetadata, "serviceLocation": event.target.value } }));
    }

    function handleServiceDate(date) {
        const tempDate = `${date}T00:00:00`;
        setServiceDate(date);
        props?.setEncounterDto((dto) => ({ ...dto, "encounterMetadata": { ...dto?.encounterMetadata, "dateOfServiceTo": tempDate } }));
    }

    function handleCurrentIllnessDate(date) {
        const tempDate = `${date}T00:00:00`;
        setDateOfCurrentIllness(date);
        props?.setEncounterDto((dto) => ({ ...dto, "dateOfCurrentIllnessOrSymptom": `${date}T00:00:00` }));
    }

    function handleLastSeenDate(date) {
        setLastSeenDate(date);
        props?.setEncounterDto((dto) => ({ ...dto, "lastSeenDate": `${date}T00:00:00` }));
    }

    function handleLastMenstrualPeriodDate(date) {
        setLastMensturalPeriodDate(date);
        props?.setEncounterDto((dto) => ({ ...dto, "lastMenstrualPeriodDate": `${date}T00:00:00` }));
    }

    const fetchReneringProviders = async () => {
        setAreRenderingProvidersLoading(true);
        const httpResponse = await sendHttpRequest({
            // url: providerWithNpiUrl(user?.tenantId),
            url: `/claims/tenants/${user?.tenantId}/providers`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setRenderingProvidersList(httpResponse.data);
        } else {
            setRenderingProvidersList([]);
            console.log(httpResponse.error);
        }
        setAreRenderingProvidersLoading(false);
    };

    useEffect(() => {
        fetchReneringProviders();
    }, [])



    return (
        <>
            <div className="row py-1">
                <div className="col-sm-4">
                    <div className="form-group">
                        <label className="form-label text--secondary font-12 fw-sb">Rendering Provider</label>
                        {areRenderingProvidersLoading === true ? (
                            <Skeleton
                                animation="wave"
                                variant="text"
                                hidden={RolesMap(user?.role) === "doctor" ? true : false}
                            />
                        ) : (

                            <select
                                className={`form-select br-10 font-12 input border--default shadow-none`}
                                aria-label="Provider select"
                                onChange={(e) => handleProviderId(e)}
                                value={selectedProvider}
                            >
                                <option value="" disabled selected hidden>
                                    Select provider
                                </option>
                                {renderingProvidersList?.length > 0
                                    ? renderingProvidersList?.map((renderingProvider) => {
                                        return (
                                            <option
                                                value={renderingProvider?.providerId ?? ""}
                                                key={renderingProvider?.providerId ?? ""}
                                            >
                                                {renderingProvider?.prefix}{" "}
                                                {renderingProvider?.firstName}{" "}
                                                {renderingProvider?.lastName}{" "}

                                            </option>
                                        );
                                    })
                                    : "No prescribers available"}
                            </select>

                        )}
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label className="form-label text--secondary font-12 fw-sb">Place of Service</label>
                        {isPlaceOfServiceCodeLoading ? <Skeleton /> : <select
                            className={`input border--default br-10 form-select font-12 shadow-none`}
                            onChange={(e) => {
                                handlePlaceOfService(e)
                            }}
                            value={selectedPlaceOfService}

                        >
                            <option value="" disabled selected hidden>
                                Select Place of Service
                            </option>
                            {placeOfServiceCodes?.map((place) => {
                                return <option value={place.code}>{place.code} - {place.name}</option>
                            })}
                        </select>}
                    </div>
                </div>

                <div className="col-sm-4">
                    <div className="form-group">
                        <label className="form-label text--secondary font-12 fw-sb">Service Location</label>
                        <select
                            className={`input border--default br-10 form-select font-12 shadow-none`}
                            onChange={(e) => {
                                handleServiceLocation(e)
                            }}
                            value={selectedServiceLocation}

                        >
                            <option value="" disabled selected hidden>
                                Select Service Location
                            </option>

                            <option value={`${user?.tenant?.businessName}`}>{user?.tenant?.businessName}</option>
                            {/* <option value="surgery-center">Ambulatory Surgery Center</option>
                            <option value="urgent-care">Urgent Care Clinic</option>
                            <option value="physician-office">Physician's Office</option>
                            <option value="imaging-center">Imaging Center</option>
                            <option value="rehab-center">Rehabilitation Center</option>
                            <option value="nursing-home">Nursing Home</option>
                            <option value="home-health">Home Health Agency</option>
                            <option value="laboratory">Laboratory</option>
                            <option value="pharmacy">Pharmacy</option> */}

                        </select>
                    </div>
                </div>
            </div>
            <div className="row py-1">
                <div className="col-sm-3">
                    <div className="form-group">
                        <label className="font-12 fw-sb text--secondary">Service Date</label>
                        <CalenderDatePicker
                            name="serviceDate"
                            className="font-12 simple-placeholder"
                            label="Service Date"
                            f12={true}
                            dobHandler={handleServiceDate}
                            disableFuture={true}
                            maxDate={DateTime.now()}
                        />
                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="form-group">
                        <label className="font-12 fw-sb text--secondary">Last Seen Date</label>
                        <CalenderDatePicker
                            name="lastSeenDate"
                            className="font-12 simple-placeholder"
                            label="Service Date"
                            f12={true}
                            dobHandler={handleLastSeenDate}
                            disableFuture={true}
                            maxDate={DateTime.now()}
                        />
                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="form-group">
                        <label className="font-12 fw-sb text--secondary">Date of Current Illness</label>
                        <CalenderDatePicker
                            name="dateOfCurrentIllnes"
                            className="font-12 simple-placeholder"
                            label="Service Date"
                            f12={true}
                            dobHandler={handleCurrentIllnessDate}
                            disableFuture={true}
                            maxDate={DateTime.now()}
                        />
                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="form-group">
                        <label className="font-12 fw-sb text--secondary">Last Menstural Period Date</label>
                        <CalenderDatePicker
                            name="lastMensturalPeriodDate"
                            className="font-12 simple-placeholder"
                            label="Service Date"
                            f12={true}
                            dobHandler={handleLastMenstrualPeriodDate}
                            disableFuture={true}
                            maxDate={DateTime.now()}
                        />
                    </div>
                </div>
            </div>

            <div className="row py-1">
                <div className="col-sm-4">
                    <label className="form-label font-12 text--secondary fw-sb">Diagnoses</label>
                    <DiagnosisSearch
                        suggestions={suggestions}
                        setSuggestions={setSuggestions}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isError={isError}
                        setIsError={setIsError}
                        searchInputValue={searchInputValue}
                        setSearchInputValue={setSearchInputValue}
                        diagnosisName={diagnosisName}
                        setDiagnosisName={setDiagnosisName}
                        selectedDiagnosis={selectedDiagnosis}
                        setSelectedDiagnosis={setSelectedDiagnosis}
                        searchDiagnosis={searchDiagnosis}
                        handleSearchInput={handleSearchInput}
                        handleAddDiagnosis={handleDiagnosisList}
                        handleRemoveDiagnosis={handleRemoveDiagnosis}
                    />

                    <div className='py-2'>
                        {diagnosisList?.map((diagnosis, index) => (
                            <span key={diagnosis.code} className='font-12 px-2 text--terciary d-block'>{`${diagnosis?.code} - ${diagnosis.description} ${diagnosis?.type === "P" ? (`(Primary)`) : ''}`}</span>
                        ))}
                    </div>
                </div>
            </div>


        </>
    )
}

export default ClaimEncountersTabPanel