import { Offcanvas } from "react-bootstrap";
import IconFile from "../../assets/icons/Icon-file.svg";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./PatientProfileMessageContainer.module.css";
import PrintIcon from "../../assets/icons/Icon-print.svg";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import MessageContainerItem from "./MessageContainerItem";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import NewMessageModal from "../UI/Modals/ProviderModals/NewMessageModal";
import { useAuth } from "../hooks/useAuth";

const PatientProfileMessageContainer = (props) => {
  const navigate = useNavigate();
  const [isthreadLoading, setIsthreadLoading] = useState(false);
  const [threadData, setThreadData] = useState([]);
  const [openReplyMessageModal, setOpenReplyMessageModal] = useState(false);
  const [openForwardMessageModal, setOpenForwardMessageModal] = useState(false);
  const [isReplyMessageSent, setIsReplyMessageSent] = useState(false);
  const [isReplyMessageSentError, setIsReplyMessageSentError] = useState(false);
  const [fileToShow, setFileToShow] = useState("");
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  const [openFileShowModal, setOpenFileShowModal] = useState(false);
  const [fileTypeOnShow, setFileTypeOnShow] = useState("");
  const {user} = useAuth();

  const fetchThreadData = async (thread) => {
    setIsthreadLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages/thread",
      method: "GET",
      params: {
        userId: user?.userId,
        threadId: thread,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log("httpResponse.data", httpResponse.data);
      setThreadData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsthreadLoading(false);
  };
  const downloadAttachment = async (documentId) => {
    const httpResponse = await sendHttpRequest({
      url: "/patient/document",
      method: "GET",
      params: {
        documentId: documentId,
      },
    });
    if (!httpResponse.error) {
      downloadFile(httpResponse.data.filePath,httpResponse.data.name);
    } else {
      console.log(httpResponse.error);
    }
  };
  function downloadFile(url, fileName) {
    fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
    .then(res => res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
  }
  const handleReplyMessageModal = () => {
    setOpenReplyMessageModal(!openReplyMessageModal);
  };
  const handleForwardMessageModal = () => {
    setOpenForwardMessageModal(!openForwardMessageModal);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsReplyMessageSent(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsReplyMessageSentError(false);
  };
  const handleAttachmentDownload = (event) => {
    downloadAttachment(event.target.id);
  };
  function b64toBlob(base64, type = "application/octet-stream") {
    try {
      const binStr = window.atob(base64);
      const len = binStr?.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    } catch (e) {
      setIsLoadingFetch(true);
    }
  }
  const fileShowModalClose = () => {
    setOpenFileShowModal(false);
  };

  useEffect(() => {
    fetchThreadData(props?.threadId);
  }, [props?.threadId]);

  return (
    <div className="">
      {openReplyMessageModal && (
        <NewMessageModal
          show={openReplyMessageModal}
          handleMessageModal={handleReplyMessageModal}
          setIsMessageSent={setIsReplyMessageSent}
          setIsMessageSentError={setIsReplyMessageSentError}
          messageData={threadData[threadData?.length - 1]}
          reply={true}
          inbox={true}
        />
      )}
      {openForwardMessageModal && (
        <NewMessageModal
          show={openForwardMessageModal}
          handleMessageModal={handleForwardMessageModal}
          setIsMessageSent={setIsReplyMessageSent}
          setIsMessageSentError={setIsReplyMessageSentError}
          messageData={threadData[threadData?.length - 1]}
          forward={true}
          inbox={true}
        />
      )}
      <Offcanvas
        show={props?.show}
        onHide={props?.handleclose}
        {...props}
        className={`border--default ${classes["message__container"]}`}
      >
        <a id="download" download target={"_blank"} />
        <div className="bg--disabled  text--terciary fw-sb border--default">
          {/* Header */}
          <div className="d-flex flex-row p-2 justify-content-between ">
            <div>{isthreadLoading ? <Skeleton /> : threadData[0]?.subject}</div>
            <div className="d-flex flex-row ">
              <div className="me-3">
                {threadData[threadData.length - 1]?.attachment !== undefined &&
                  threadData[threadData.length - 1]?.attachment !== null && (
                    <img
                      id={threadData[threadData.length - 1]?.attachment}
                      className={`${classes["icon__file"]}`}
                      src={IconFile}
                      alt={"archieve icon"}
                      onClick={(e) => {
                        handleAttachmentDownload(e);
                      }}
                    ></img>
                  )}
              </div>
              <div>
                <span
                  className={`${classes["icon__close"]}`}
                  onClick={() => {
                    props?.handleclose();
                    // props.setThreadId(undefined);
                  }}
                >
                  {<CloseIcon />}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={`py-2 ${classes["message__container__item"]}`}>
          {/* {"Body"} */}
          {isthreadLoading ? (
            <Skeleton />
          ) : (
            threadData.length > 0 &&
            threadData?.map((threadDataItem) => {
              return (
                <>
                  <MessageContainerItem
                    threadData={threadDataItem}
                    setThreadId={props?.setThreadId}
                  />
                </>
              );
            })
          )}
        </div>

        {/* {"Footer"} */}
        <div className=" bg-white mb-4">
          <div
            className={`d-flex flex-row justify-content-between px-3 ${classes["message__container__footer"]}`}
          >
            <div className="d-flex flex-row align-self-center">
              <div className="br-10 border--default bg--background border p-2 ">
                <img
                  className={`icon__defualt`}
                  src={PrintIcon}
                  alt={"archieve icon"}
                ></img>
              </div>
            </div>
            {/* <div className="d-flex flex-row align-self-center">
              <div>
                <button
                  className="btn bg--d9e8ec text--blue  fw-sb font-14 me-2 br-10 py-2 shadow-none"
                  style={{ width: "160px" }}
                  onClick={() => {
                    handleForwardMessageModal();
                  }}
                >
                  Forward
                </button>
              </div>
              <div>
                <button
                  className=" bg--blue text--white font-14 fw-sb  br-10 py-2 shadow-none border-0"
                  style={{ width: "160px" }}
                  onClick={() => {
                    handleReplyMessageModal();
                  }}
                >
                  Reply
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </Offcanvas>
      <CustomizedSnackbars
        message={"Message sent successfully"}
        severity="success"
        open={isReplyMessageSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={"Message could not be sent"}
        severity="error"
        open={isReplyMessageSentError}
        handleClose={handleCloseError}
      />
    </div>
  );
};

export default PatientProfileMessageContainer;
