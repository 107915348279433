import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PNLogo from "../../assets/logo/LPLogo.svg";
import LoginImage from "../../assets/images/loginImage.PNG";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControl, InputBase } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import classes from "./Login.module.css";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";

import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPrescriberInfo, setUserPermissionsData } from "../../redux/actions/providerActions";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useAuth } from "../hooks/useAuth";
import { jsonToMap, sessionPostApiCall } from "../../utils/helpers/HelperFunctions";



const theme = createTheme();

export default function TwoFactorAuthPage() {
  const { key } = useParams();
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const [twoFact, setTwoFact] = useState(0);
  const [errorResponse, setErrorResponse] = useState("");
  const [newPasswordMatch, setNewPasswordMatch] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isCodeFault, setIsCodeFault] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [providerData, setProviderData] = useState("");

  const session = async (key, value, mvfrd) => {
    let json = {};
    let val = value
    let ky = key
    json[ky] = JSON.stringify(val);
    const httpResponse = await sendHttpRequest({
      url: "/session",
      method: "POST",
      data: json
    });
    if (!httpResponse.error) {
      if (mvfrd) {
        let response = value;

        login({
          loggedIn: true,
          role: response?.role,
          timezone: response?.timezoneOffset
        })
        

        if (response.roles !== "patient") {
          setUserRole("doctor");

          if (localStorage.getItem("sessionexp") === null) {
            localStorage.setItem("sessionexp", false);
            localStorage.setItem("role", response.roles);
          }
          localStorage.setItem(
            "timezone",
            JSON.stringify(response?.timezoneOffset)
          );
          // console.log(response);
          dispatch(
            setPrescriberInfo({
              ...prescriberInfo,
              prescriberId: response?.id,
              Name: response?.Name[0],
              profilePicture: response?.profilePicture ?? DefaultAvatarIcon,
              role: response?.role,
            })
          );
          // console.log(prescriberInfo);
          setProviderData(httpResponse.data);
          setIsAuthenticated(true);
        } else if (response.role === "patient") {
          setUserRole("patient");
          if (localStorage.getItem("sessionexp") === null) {
            localStorage.setItem("sessionexp", false);
            localStorage.setItem("role", response.roles);
            localStorage.setItem(
              "timezone",
              JSON.stringify(response?.timezoneOffset)
            );
          }
          // console.log(response);
          dispatch(
            setPrescriberInfo({
              ...prescriberInfo,
              prescriberId: response?.id,
              Name: response.Name[0],
              profilePicture: response?.profilePicture ?? DefaultAvatarIcon,
              role: response.roles,
            })
          );
          setIsAuthenticated(true);
        }
      }

    }
  }

  const postCode = async () => {
    setDisableButton(true);
    const httpResponse = await sendHttpRequest({
      url: localStorage.getItem("authLink"),
      method: "POST",
      data: {
        token : twoFact,
        username: localStorage.getItem("username")
      },
    });
    if (!httpResponse.error) {

      let response = httpResponse?.data;
      localStorage.removeItem("twoFac");
      localStorage.removeItem("username");
      localStorage.removeItem("authLink");
      dispatch(setUserPermissionsData(response.permissions));
      login({
        loggedIn: true,
        role: response?.role ?? "",
        userId: response?.userId ?? "",
        selectedUserId: response?.userId ?? "",
        timezone: response?.timezoneOffset ?? "",
        timezoneText: response?.timezone ?? "",
        name: response?.name,
        firstName: response?.name[0]?.firstName ?? "",
        lastName: response?.name[0]?.lastName ?? "",
        prefix: response?.name[0]?.prefix ?? "",
        prescription: response?.Identification?.Prescription,
        tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
        tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
        facility: response?.providerTenantPracticeLocation?.facility,
        practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId
      });
      // session("selected_user", response?.id, false);
      // session("user", response, true);
    } else {
      setIsCodeFault(true);
      setErrorResponse(httpResponse.errorMessage.message);
    }
    setDisableButton(false);
  };

  const handleTwoFactInput = (event) => {
    setTwoFact(event.target.value);
  };

  const handleSubmitButton = () => {
    postCode();
  };

  // const handlePasswordChangeToastClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setIsPasswordChanged(false);
  // };

  return (
    <>
      {isAuthenticated === true && jsonToMap(user).get("role") !== "patient"
        ? navigate("/dashboard", { replace: true })
        : jsonToMap(user).get("role") === "patient" &&
        navigate("/patient-portal/dashboard", { replace: true })}
      <ThemeProvider theme={theme}>
        <form
          id="twoFactForm"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitButton();
          }}
        >
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />

            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              component={Paper}
              sx={{
                backgroundImage: "linear-gradient(180deg, #FFFFFF, #F2F7F8)",
              }}
              elevation={6}
              square
            >
              <Box
                sx={{
                  mx: 7,
                  my: 5,
                }}
              >
                <img src={PNLogo} width={260} height={55} />
              </Box>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  px: 20,
                }}
              >
                <Typography
                  component="h1"
                  className="text--terciary"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "50px",
                    mb: 2,
                  }}
                >
                  Two-Step Authentication
                </Typography>
                <Typography
                  component="h3"
                  sx={{
                    color: "rgb(50, 50, 50, 0.7)",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "24px",
                    mb: 3,
                  }}
                >
                  You have enabled Two-Step Authentication for this account.
                </Typography>
                <Box component="div" noValidate sx={{ mt: 1 }}>
                  <div className="form-group mb-2">
                    <label
                      htmlFor="password"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                      className="text--terciary font-12"
                    >
                      Code
                    </label>
                    <input
                      className="form-control fw-sb font-14 shadow-none br-10 input"
                      type="number"
                      required
                      autoFocus={true}
                      onChange={(e) => {
                        handleTwoFactInput(e);
                      }}
                    />
                  </div>
                  <div>
                    <p className="text--blue font-12">
                      {isCodeFault === true &&
                        "Invalid code!!"}
                    </p>
                  </div>

                  <div className="d-grid gap-2">
                    <button
                      form="twoFactForm"
                      type="submit"
                      disabled={disableButton}
                      className={`btn br-10 mt-4 py-2 text-center btn-block blue_task_button ${classes.loginBtn}`}
                    >
                      {disableButton ? "Submitting..." : "Submit"}
                    </button>
                    {disableButton && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#EC609B",
                          position: "absolute",
                          marginTop: "30px",
                          marginLeft: "165px",
                        }}
                      />
                    )}
                  </div>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={false}
              sm={4}
              md={6}
              sx={{
                backgroundImage: `url(${LoginImage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Grid>
        </form>
        {/* <CustomizedSnackbars
          message={"Password changed successfully"}
          severity="success"
          open={isPasswordChanged}
          handleClose={handlePasswordChangeToastClose}
        /> */}
        {/* {console.log(localStorage.getItem("authLink"))} */}
      </ThemeProvider>
    </>
  );
}
