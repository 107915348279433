import placeholder from "./../../../assets/images/placeholder.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  addTimeHelper,
  dateTimeHelper,
  getAgeByDateOfBirth,
  getLocalTime,
  getLocalTimeFromUTC,
  calenderDateTimeHelper
} from "../../../utils/dateTimeHelper";
import { useState } from "react";
import { truncate } from '../../../utils/helpers/HelperFunctions';
import CancelAppointmentModal from "../Modals/CancelAppointmentModal";
import AppointmentRescheduleModal from "../Modals/ProviderModals/AppointmentRescheduleModal"


import CustomizedSnackbars from "./CustomizedSnackbars";
import { PnToolTip } from "../Reusable/PnComponents";
import { useSelector } from "react-redux";
const AppoinmentCard = (props) => {
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const [openCard, setOpenCard] = useState(props.active);
  const [openApptRescheduleModal, setOpenApptRescheduleModal] = useState(false)
  let firstName =
    props.appointmentItemData?.appointmentSlot?.patient?.name[0]?.firstName;
  let lastName =
    props.appointmentItemData?.appointmentSlot?.patient?.name[0]?.lastName;
  let gender = props.appointmentItemData?.appointmentSlot?.patient?.gender;
  let profilePicture =
    props?.appointmentItemData?.appointmentSlot?.patient?.profilePicture;
  let chiefComplaint = props?.appointmentItemData?.chiefComplaint;

  let startDateTime = props?.appointmentItemData?.appointmentSlot?.startDateTime;
  let startDate = props?.appointmentItemData?.appointmentSlot?.startDate;
  let duration = props?.appointmentItemData?.appointmentSlot?.duration;
  let dateOfBirth =
    props?.appointmentItemData?.appointmentSlot?.patient?.dateOfBirth;
  let endTime = dateTimeHelper(startDateTime, "LT");

  let appointmentItemTooltipText = firstName !== undefined && lastName !== undefined && gender !== undefined ? firstName + " " + lastName + " | " + gender + " -" + getAgeByDateOfBirth(dateOfBirth) + " years" : '';


  const [anchorEl, setAnchorEl] = useState(null);
  const [openRescheduleAppointmentSnackbar, setOpenRescheduleAppointmentSnackbar] = useState(false)
  const [rescheduleAptErrorMsg, setRescheduleAptErrorMsg] = useState()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //=======Cancel Modal=========//
  const [cancelModal, setCancelModal] = useState(false);

  const handleCancelModalShow = () => {
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleApptRescheduleModal = () => {
    setOpenApptRescheduleModal(!openApptRescheduleModal)
  }

  const handleRescheduleModalShow = () => {
    setOpenApptRescheduleModal(true)
  }

  const handleRescheduledAppointmentSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenRescheduleAppointmentSnackbar(false);
  };

  return (
    <>
      {
        openApptRescheduleModal &&
        <AppointmentRescheduleModal
          show={openApptRescheduleModal}
          handleModal={handleApptRescheduleModal}
          appointmentItemData={props.appointmentItemData}
          fecthAppointments={props.fecthAppointments}
          isAppointmentRescheduled={props.isAppointmentRescheduled}
          setIsAppointmentRescheduled={props.setIsAppointmentRescheduled}
          setOpenRescheduleAppointmentSnackbar={setOpenRescheduleAppointmentSnackbar}
          setRescheduleAptErrorMsg={setRescheduleAptErrorMsg}
          handleCurrentMonth={props?.handleCurrentMonth}
          currentCellAppointments={props?.currentCellAppointments}
          setCurrentCellAppointments={props?.setCurrentCellAppointments}
          setSelectedCellDate={props?.setSelectedCellDate}
          convertDateFormat={props?.convertDateFormat}
          selectedCellDate={props?.selectedCellDate}
        />
      }
      {/* {console.log("startDateTime",props?.appointmentItemData?.AvailableSlot)} */}
      {cancelModal && (
        <CancelAppointmentModal
          show={cancelModal}
          handleCancelModalClose={handleCancelModalClose}
          firstName={props?.appointmentItemData?.appointmentSlot?.patient?.name[0]?.firstName}
          lastName={props?.appointmentItemData?.appointmentSlot?.patient?.name[0]?.lastName}
          date={dateTimeHelper(props?.appointmentItemData?.appointmentSlot?.startDateTime, "dddd, MMM D, YYYY")}
          startDateTime={dateTimeHelper(props?.appointmentItemData?.appointmentSlot?.startDateTime, "LT")}
          endTime={dateTimeHelper(props?.appointmentItemData?.appointmentSlot?.endDateTime, "LT")}
          prescriberPrefix={props?.appointmentItemData?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.prefix}
          prescriberFirstName={props?.appointmentItemData?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.firstName}
          prescriberLastName={props?.appointmentItemData?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.lastName}
          appointmentId={props?.appointmentItemData?.id}
          setIsAppointmentCancelled={props.setIsAppointmentCancelled}
          setIsAppointmentCancelling={props.setIsAppointmentCancelling}
          setAppointmentCancellationFail={props.setAppointmentCancellationFail}
          setApppointmentCancellationError={props.setApppointmentCancellationError}
          isAppointmentCancelling={props?.isAppointmentCancelling}
          fecthAppointments={props.fecthAppointments}
          currentCellAppointments={props?.currentCellAppointments}
          setCurrentCellAppointments={props?.setCurrentCellAppointments}
        />
      )}
      <div
        className={`row p-2 mt-3 ${openCard === true ? "bg--white" : "bg--light"
          }`}
        style={{
          boxShadow: "0px 3px 16px #0000000F",
          border: "1px solid #D9E8EC",
          borderRadius: "10px",
        }}
      >
        <div className="align-self-center flex-fill  col-5">
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              <img
                src={
                  profilePicture != undefined && profilePicture != ""
                    ? profilePicture
                    : placeholder
                }
                width="40px"
                height="40px"
                alt="props.PatientName"
                className="rounded-circle"
                draggable={false}
              />
            </div>

            <div className="ms-2 g-0 align-self-center mt-2">
              {/* +' '+props.Encounter.Patient.Name.LastName */}
              <PnToolTip title={appointmentItemTooltipText}>
                <span
                  className="d-block p-0 font-14 fw-sb"
                  style={{ marginBottom: "-8px" }}
                >
                  {/* {FirstName + " " + LastName}{" "} */}
                  {firstName != undefined
                    ? truncate(firstName + " " + lastName, 40)
                    : ""}
                  {/* {firstName !== undefined ? firstName.concat(" ", lastName) : ""} */}
                  <span className="lh-0 ms-2 text--secondary text-wrap font-12 ">
                    {/* {Gender == "M" ? "Male" : "Female"} - {props.age} years */}
                    {gender != undefined && gender != "" && gender} -{" "}
                    {dateOfBirth !== undefined &&
                      dateOfBirth !== "" &&
                      getAgeByDateOfBirth(dateOfBirth) + " years"}
                  </span>
                </span>
              </PnToolTip>
              <div className="mt-1">
                <span className="m-0 p-0 text--terciary font-12 fw-thin d-block ">
                  {/* {ChiefComplaint ? ChiefComplaint : "No chief complaint found"} */}
                  {/* Chief complaint 1, chief complaint 2 */}
                  {chiefComplaint != undefined && chiefComplaint != "" ? <PnToolTip title={chiefComplaint}>
                    <span>{truncate(chiefComplaint, 25)}</span>
                  </PnToolTip> : 'No chief complaint found'}

                </span>
              </div>
              <div className="mt-1 d-flex flex-row">
                <span className="m-0 p-0 text--secondary font-14 fw-sb d-block  ">
                  {startDateTime && dateTimeHelper(startDateTime, "LT")}{" "}
                  <span className="fw-thin"></span> &nbsp;
                </span>
                <span className="text--secondary">&ndash; &nbsp;</span>
                <span className="m-0 p-0 text--secondary font-14 fw-sb d-block  ">
                  <span className="fw-thin">
                    {addTimeHelper(endTime, duration)}
                  </span>
                </span>
              </div>
            </div>

            <div style={{ marginLeft: "auto" }} >
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="ms-4"
                aria-label="more"
                hidden={userPermissions.csa === false && userPermissions.rap === false}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                dense
                onClose={handleClose}
                disableScrollLock={true}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  dense
                  onClick={() => {
                    handleClose();
                    handleRescheduleModalShow();
                  }}
                  sx={{ fontFamily: "Montserrat" }}
                  disabled={props?.appointmentItemData?.status?.toLowerCase() !== "pending" ? true : false}
                  hidden={userPermissions.rap === false ? true : false}
                >
                  Edit
                </MenuItem>

                <MenuItem
                  dense
                  onClick={() => {
                    handleCancelModalShow();
                    handleClose();
                  }}
                  sx={{ fontFamily: "Montserrat" }}
                  disabled={props?.appointmentItemData?.status?.toLowerCase() !== "pending" ? true : false}
                  hidden={userPermissions.csa === false ? true : false}
                >
                  Cancel
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <CustomizedSnackbars
        message={rescheduleAptErrorMsg}
        severity="error"
        open={openRescheduleAppointmentSnackbar}
        handleClose={handleRescheduledAppointmentSnackbar}
      />
      {/* {console.log("startDate",startDate)}
      {console.log("UTC to local time ",dateTimeHelper(startDate+"T"+startDateTime,"LT"),
      addTimeHelper(dateTimeHelper(startDate+"T"+startDateTime,"LT"),"hh:mm",duration)
      )} */}
      {/* {console.log("props.appointmentItemData",props.appointmentItemData)} */}
    </>
  );
};

export default AppoinmentCard;
