import { useState, useEffect } from "react";
import { DashLg } from "react-bootstrap-icons";
import { isEqual, isEmpty } from "lodash";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";

import classes from "./ChangeRx.module.css";
import { useAuth } from "../hooks/useAuth";
import { numbersEnum, renewalRequestStrings, common_strings } from "../../res/strings";
import {
    fieldValidation,
    formatPhoneNumber,
    formatPostalCode,
    genderMap,
    rxSubcodeMap,
} from "../../utils/helpers/HelperFunctions";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useUnsavedWorkPrompt } from "../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import TextArea from "../forms/TextArea";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import denialcodes from "./changerxdenialcodes";
import InfoIcon from '@mui/icons-material/Info';
import { removeValidationError } from "../validations/validationschemas";
import { EditedMedicationView } from "./EditedMedicationView";
import { EditMedicationForm } from "./EditMedicationForm";
import TaxonomyCodeForm from "./TaxonomyCodeForm";
import NPIForm from "./NPIForm";
import StateLicenseForm from "./StateLicenseForm";
import { WarningIcon } from "../UI/Reusable/WarningIcon";
import CustomText from "../UI/General/CustomText";
import { useSelector } from "react-redux";


const sendChangeResponse = async (pid, spi, body) => {
    const httpResponse = await sendHttpRequest({
        method: 'POST',
        url: `/rx/changeResponse/${pid}/spi/${spi}`,
        data: body
    })
    return httpResponse;
}

const singleMedicationDisplay = ['G', 'OS', 'D', 'S'];






const midWidthModalRequestCodes = ['U']


const ChangeRxModal = (props) => {
    const userPermissions = useSelector(
        (state) => state.UserPermissionData.permissionsMap
      );
    const task = props.taskDetails;
    const [requestedMed, setRequestedMed] = useState(task?.prescriptionReference.medicationRx[0]);
    const contentClasses = midWidthModalRequestCodes?.includes(props?.taskDetails?.rxRequest?.requestCode) ? 'w-75 mx-auto' : '';
    const { user } = useAuth();

    let identification = props?.taskDetails.prescriptionReference.providerTenantPracticeLocation.provider.identification;

    //====Request Code = U,  Subcode = I====//
    const [taxonomyCode, setTaxonomyCode] = useState('');
    const [stateLicense, setStateLicense] = useState('');
    const [stateLicenses, setStateLicenses] = useState(identification?.stateLicense !== undefined ? identification.stateLicense : []);
    const [taxonomyDescription, setTaxonomyDescription] = useState('');
    const [npi, setNpi] = useState(identification.npi ?? '');
    //=====Prior Authorization States START=====//
    const [priorAuthorizationReferenceNumber, setPriorAuthorizationReferenceNumber] = useState('');
    const [priorAuthorizationStatus, setPriorAuthorizationStatus] = useState('');
    const [denialReasonCode, setDenialReasonCode] = useState('');
    //=====Prior Authorization States END=====//
    const [editedMedication, setEditedMedication] = useState(undefined);

    const [selectedInterchangeMedication, setSelectedInterchangeMedication] = useState(undefined);
    const [showQuantityError, setShowQuantityError] = useState(false);
    const [showRefillsError, setShowRefillsError] = useState(false);
    const [showDaysSupplyError, setShowDaysSupplyError] = useState(false);
    const [selectedSig, setSelectedSig] = useState(undefined);
    const [note, setNote] = useState('');
    const [open, setOpen] = useState(false);
    const [sigs, setSigs] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [viewEditedMedicationOnly, setViewEditedMedicationOnly] = useState(false);
    const [quantity, setQuantity] = useState(editedMedication !== undefined ? editedMedication.quantity : undefined);
    const [daysSupply, setDaysSupply] = useState(editedMedication !== undefined ? editedMedication.daysSupply : undefined);
    const [refills, setRefills] = useState(editedMedication !== undefined ? editedMedication.refills : undefined);
    const [effectiveDate, setEffectiveDate] = useState(undefined);
    const [substitutions, setSubstitutions] = useState(editedMedication !== undefined ? editedMedication.substitution : false);
    const [daw, setDaw] = useState(editedMedication !== undefined ? editedMedication.daw : false);
    const [isSigLoading, setIsSigLoading] = useState(false);
    // const [denialNoteError, setDenialNoteError] = useState(false);
    const [isEditedMedicationUsed, setIsEditedMedicationUsed] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();

    const modalTitle = useRef();
    const [interchangeableMedicationsList, setInterchangeableMedicationsList] = useState(task?.prescriptionReference.medicationRx?.sort(function (medicationA, medicationB) {
        if (medicationA?.priority < medicationB?.priority) {
            return -1;
        }
        if (medicationA?.priority > medicationB?.priority) {
            return 1;
        }
        return 0;
    }) || undefined);
    // const [requestCode, setRequestCode] = useState(task?.rxRequest?.requestCode); // will use later



    let _str = { ...numbersEnum?.en, ...renewalRequestStrings.en, ...common_strings.en };
    // console.log(task);Ta

    let prescriber = task.prescriptionReference?.providerTenantPracticeLocation.provider;
    let facility = task.prescriptionReference.providerTenantPracticeLocation.facility;
    let patient = props.changeRequestTask?.prescriptionReference?.patient;
    let patientName = patient?.name[0];
    let pharmacy = task?.prescriptionReference?.medicationRx[0]?.pharmacy;
    let changeReasonText = task?.rxRequest?.note;
    let patientObservations = props?.patientObservations;
    let originalPrescription = task?.rxRequest?.originalPrescription;
    let requestedMedication = task?.prescriptionReference?.medicationRx[0];
    let prescriberPrimaryPhone = prescriber?.communicationNumbers?.numbers?.find((numberItem) => {
        return numberItem?.type === _str.cellPhoneExt;
    })?.number || null;


    let patientPrimaryPhone =
        patient?.communicationNumbers?.numbers?.find((numberItem) => {
            return numberItem?.type === _str.cellPhoneExt;
        })?.number || null;


    const fetchSigs = async (drugDbCode) => {
        setIsSigLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/drugs/${drugDbCode}/sig`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setSigs(httpResponse.data);
        }
        setIsSigLoading(false);
    }




    const handleTherapeuticInterchange = (event) => {
        const interchangeMedication = task?.prescriptionReference?.medicationRx?.find(med => med?.medicationRxId == event.target.getAttribute('data-id'));
        // console.log(interchangeMedication);
        setSelectedInterchangeMedication(p => interchangeMedication)
    }



    const cancelEditHandler = () => {
        setEditMode(false)
        setSelectedSig(p => undefined);
        setQuantity(p => undefined);
        setDaysSupply(p => undefined);
        setRefills(p => undefined)
        setSubstitutions(p => undefined);
        setEffectiveDate(p => undefined);
        setShowDaysSupplyError(p => undefined);
        setShowQuantityError(p => undefined);
        setShowDaysSupplyError(p => undefined);
        // if (editedMedication.keys().length === 0) {
        //     setEditedMedication(undefined)
        // }
    }

    const reloadHandler = () => {
        setSelectedSig(p => undefined);
        setQuantity(p => undefined);
        setDaysSupply(p => undefined);
        setRefills(p => undefined)
        setSubstitutions(p => undefined);
        setEffectiveDate(p => undefined);
        setSelectedInterchangeMedication(p => undefined);
        setEditedMedication(p => undefined);
    }

    const handleNpi = (e) => {
        const value = e.target.value;
        if (value.length === 10) {
            setNpi(value);
            setValidationErrors({ ...removeValidationError(validationErrors, 'npi') });
        }
        else {
            setNpi(value);
            setValidationErrors({ ...validationErrors, ['npi']: 'NPI must be exactly 10 characters long' })
        }
    }

    const saveEditHandler = () => {
        //Check if medication was really changed
        let medicationDto;
        if (task?.rxRequest?.requestCode === 'T' && selectedInterchangeMedication !== undefined) {
            medicationDto = structuredClone(selectedInterchangeMedication);
        }
        else if (task?.rxRequest?.requestCode !== 'T' && editedMedication !== undefined) {
            medicationDto = structuredClone(editedMedication);
        }
        else if (task?.rxRequest?.requestCode !== 'T') {
            medicationDto = structuredClone(requestedMedication);
        }
        let changedMedicationDto;
        let prescriptionDto;
        let form = document.getElementById("editMedicationForm");
        console.log("substitutions", substitutions);
        if (form.reportValidity()) {
            changedMedicationDto = {
                ...medicationDto,
                quantity: parseFloat(Number(quantity).toFixed(3)),
                refills: Number(refills),
                sigText: selectedSig?.textValue,
                substitution: substitutions,
                ...(!isNaN(Number(daysSupply)) && { daysSupply: Number(daysSupply).toString() }),
                ...(effectiveDate !== undefined && {
                    earliestFillDate: calenderDateTimeHelper(effectiveDate, "YYYY-MM-DD"),
                })
            }
            prescriptionDto = structuredClone(changedMedicationDto);
            // console.log('Requested Medication', medicationDto);
            // console.log('Changed DTO', prescriptionDto);
            // console.log('_.isEqual', isEqual(medicationDto, prescriptionDto));
            if (isEqual(medicationDto, prescriptionDto) === false) {
                setEditedMedication(prev => prescriptionDto);
                setEditMode(p => false);
                setViewEditedMedicationOnly(true);
            }
            else {

            }
        }
        else {
            return;
        }
    }

    const handleSigSelection = (s, qty) => {
        setSelectedSig(p => s);
        let parsedQuantity = parseInt(qty);
        if (parsedQuantity !== null && parsedQuantity !== undefined && !isNaN(parsedQuantity)) {
            calculateDaysSupply(s, parsedQuantity);
        }
    }


    const handleDispenseQuantity = (event) => {
        setQuantity(event.target.value); //Because it is a controlled component we need to set value first
        const value = event.target.value;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue) && parsedValue > 0) {
            const quantityLength = parsedValue.toString().length;
            if (quantityLength >= 1 && quantityLength <= 11) {
                setQuantity((p) => value);
                calculateDaysSupply(selectedSig, parsedValue);
                setShowQuantityError((p) => false);
            }
            else {
                setQuantity((p) => value);
                calculateDaysSupply(selectedSig, parsedValue);
                setShowQuantityError((p) => true);
            }
        } else if (isNaN(parsedValue) || parsedValue < 0) {
            setShowQuantityError(true);
        } else if (value === "") {
            setQuantity((p) => "");
            setShowQuantityError((p) => false);
        }
    };
    const handleRefills = (event) => {
        setRefills(event.target.value);
        const value = event.target.value;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue) && parsedValue >= 0) {
            const refillsValueLength = parsedValue.toString().length;
            if (refillsValueLength >= 1 && refillsValueLength <= 2) {
                setRefills((p) => parsedValue);
                setShowRefillsError((p) => false);
            }
            else {
                setRefills((p) => parsedValue);
                setShowRefillsError((p) => true);
            }
        } else if (isNaN(parsedValue) || parsedValue < 0) {
            setRefills((p) => undefined);
            setShowRefillsError(true);
        } else if (value === "") {
            setRefills((p) => undefined);
            setShowRefillsError((p) => false);
        }
    };

    const handleDaysSupply = (event) => {
        setDaysSupply(event.target.value);
        const value = event.target.value;
        const parsedValue = parseInt(value);
        // console.log(value, parsedValue)
        if (!isNaN(parsedValue) && parsedValue >= 0) {
            const numLength = parsedValue.toString().length;
            if (numLength >= 1 && numLength <= 3) {
                setDaysSupply((p) => parsedValue);
                setShowDaysSupplyError((p) => false);
            }
            else {
                setDaysSupply((p) => undefined);
                setShowDaysSupplyError(true);
            }
        } else if (value === "") {
            setDaysSupply((p) => undefined);
            setShowDaysSupplyError((p) => false);
        }
        else if (isNaN(parsedValue) || parsedValue < 0) {
            setDaysSupply((p) => undefined);
            setShowDaysSupplyError(true);
        }
    }

    const calculateDaysSupply = (currentSig, quantity) => {
        // console.log(quantity, 'selected sig', currentSig);
        if (currentSig !== undefined) {
            let highDoseForm = currentSig?.highDoseForm;
            let highFrequency = currentSig?.highFrequency;
            let days;
            let parsedQuantity = parseFloat(quantity);
            if (
                parsedQuantity !== undefined &&
                parsedQuantity !== "" &&
                !isNaN(parsedQuantity) &&
                highDoseForm !== undefined
            ) {
                // console.log('parsedQuantity', parsedQuantity);
                days = Math.ceil(parsedQuantity / (highFrequency * highDoseForm));
                // console.log('days', days);
            }
            setDaysSupply((p) => days);
        }
    };

    const approveChangeRequestHandler = async (action) => {
        let medicationDto = structuredClone(requestedMed);
        let prescriptionDto;
        let priorAuth = null;
        let authorizationProps = null;
        if (action === "approved_with_changes") {
            prescriptionDto = structuredClone(editedMedication);
        }
        else if (action === "validated") {
            prescriptionDto = structuredClone(requestedMed);
            if (task?.rxRequest?.requestSubCode?.length > 0) {
                authorizationProps = {};
                const subcode = task.rxRequest?.requestSubCode;
                subcode.forEach((s) => {
                    if (s === 'I') {
                        authorizationProps.taxonomyCode = taxonomyCode?.includes('-') ? taxonomyCode?.split('-')[0] : taxonomyCode;
                    }
                    if (s === 'A') {
                        authorizationProps.stateLicense = stateLicense?.includes('-') ? stateLicense?.split('-')[0] : stateLicense;
                    }
                    if (s === 'G') {
                        authorizationProps.npi = npi;
                    }
                });

            }
        }
        else if (task?.rxRequest?.requestCode === 'T') {
            prescriptionDto = structuredClone(selectedInterchangeMedication);
        }
        else {
            //handle prior authorization case
            if (task.rxRequest?.requestCode === 'P') {
                priorAuth = {};

                if (action === "denied") {
                    if (document.getElementById('priorAuthorizationForm').reportValidity()) {
                        if (denialReasonCode !== '' && denialReasonCode !== "other") {
                            priorAuth.denyCode = denialReasonCode;
                        }
                        else if (denialReasonCode === 'other') {
                            // Empty string will be replaced with an ENUM provided by the BE
                            priorAuth.denyCode = '';
                        }
                    }
                    else {
                        // setDenialNoteError(true);
                        return false;
                    }
                }
                else {
                    if (priorAuthorizationStatus === 'A') {
                        priorAuth.priorAuthorizationStatus = priorAuthorizationStatus;
                    }
                    if (priorAuthorizationReferenceNumber !== '') {
                        priorAuth.priorAuthorizationNumber = priorAuthorizationReferenceNumber;
                    }
                }
            }

            // Generic case, and other single medication without making changes in the medication
            prescriptionDto = structuredClone(requestedMed);
        }

        const approveChangeRequestDto = {
            taskId: task?.taskId,
            action: action,
            prescription: prescriptionDto,
            note: note,
            ...(priorAuth !== null && priorAuth),
            ...(authorizationProps !== null && authorizationProps),
        }

        setOpen(true);
        const response = await sendChangeResponse(user?.userId, user?.spi, approveChangeRequestDto);
        if (response.error === false) {
            props.showMessage(true);
            onPristine();
            props.reloadTasks(p => !p);
            props.handleModal();
        }
        else {
            props.showError(true);
        }
        setOpen(false);


    }
    const editHandler = async () => {
        if (editMode !== true && editedMedication === undefined) {
            console.log("requested medication", requestedMed.substitution, typeof (requestedMed.substitution));
            setEditMode(true);
            switch (task?.rxRequest?.requestCode) {
                case 'OS':
                case 'D':
                case 'S':
                case 'G':
                    setQuantity(p => requestedMed?.quantity);
                    setRefills(p => requestedMed?.refills);

                    setSubstitutions(p => requestedMed.substitution);
                    setDaw(p => requestedMed?.substitution == "0" ? false : true);
                    setDaysSupply(p => requestedMed?.daysSupply);
                    await fetchSigs(requestedMedication?.drugId || requestedMed?.drugId);
                    break;
                case 'T':
                    setQuantity(p => selectedInterchangeMedication?.quantity);
                    setRefills(p => selectedInterchangeMedication?.refills);
                    setSubstitutions(p => selectedInterchangeMedication.substitution);
                    setDaw(p => selectedInterchangeMedication?.substitution == "0" ? false : true);
                    setDaysSupply(p => selectedInterchangeMedication?.daysSupply);
                    await fetchSigs(selectedInterchangeMedication?.drugId); //undefined case ?
                    break;
            }

        }
        else if (editMode !== true && editedMedication !== undefined) {
            setEditMode(true);
            switch (task?.rxRequest?.requestCode) {
                case 'OS':
                case 'D':
                case 'S':
                case 'G':
                    setQuantity(p => editedMedication.quantity);
                    setRefills(p => editedMedication.refills);

                    setSubstitutions(p => editedMedication.substitution);
                    setDaw(editedMedication?.substitution == "0" ? false : true);
                    setDaysSupply(p => editedMedication.daysSupply);
                    await fetchSigs(requestedMedication?.drugId || editedMedication.drugId);
                    break;
                case 'T':
                    setQuantity(p => editedMedication?.quantity);
                    setRefills(p => editedMedication?.refills);

                    setSubstitutions(p => editedMedication.substitution);
                    setDaw(editedMedication?.substitution == "0" ? false : true);
                    setDaysSupply(p => editedMedication?.daysSupply);
                    await fetchSigs(editedMedication?.drugId); //undefined case ?
                    break;
            }
        }

    }

    //This map is different than the map available in the Helpers - don't change it
    const changeRxMap = new Map([
        ['G', 'Generic Substitution'],
        ['T', 'Therapeutic Interchange'],
        ['D', 'Drug Use Evaluation'],
        ['P', 'Prior Authorization'],
        ['S', 'Script Clarification'],
        ['OS', 'Out of Stock'],
        ['U', 'Prescriber Authorization '],
    ]);

    // if (singleMedicationDisplay.includes(task.rxRequest.requestCode) || task.rxRequest.requestCode === 'T') {
    //     modalTitle.current = `Change Request - ${changeRxMap.get(task?.rxRequest?.requestCode)}`;
    // }
    // else if (task.rxRequest.requestCode === 'U' && task.rxRequest.requestSubCode.length === 1) {
    //     modalTitle.current = `${changeRxMap.get(task?.rxRequest?.requestCode)} - ${rxSubcodeMap.get(task?.rxRequest?.requestSubCode[0]) ?? ''}`;
    // }
    // else if (task.rxRequest.requestCode === 'P' || (task.rxRequest.requestCode === 'U' && task.rxRequest.requestSubCode.length > 1)) {
    //     modalTitle.current = `${changeRxMap.get(task.rxRequest.requestCode)} Request`;
    // }
    // else {
    //     modalTitle.current = "Change Request";
    // }


    useEffect(() => {

        if (patient.userId !== 0 && props.taskDetails.rxRequest.requestCode === 'U') {
            let patientState = patient.address[0].stateProvince;
            let license = stateLicenses?.find((s) => s.state === patientState);
            if (license !== undefined) {
                setStateLicense(`${license.stateLicense}-${license.state}`);
                setTaxonomyCode(`${license.taxonomyCode}-${license.state}`);
            }
        }

    }, [])






    return (
        <>
            <Modal
                show={props.show}
                onHide={() => {
                    unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)
                    // props.handleModal();
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                backdropClassName="modal-backdrop"
                contentClassName={`modal-border modal-shadow ${contentClasses} ${classes["changeRxmodal__size"]}`}
                scrollable
                centered
            >
                <Modal.Header className="modal-header">
                    <span
                        style={{
                            lineHeight: "12px",
                            width: "18px",
                            fontSize: "8pt",
                            color: "#336383",
                            position: "absolute",
                            top: 15,
                            right: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)
                            // props.handleModal();
                        }}
                    >
                        {<CloseIcon />}
                    </span>
                    <Modal.Title className="font-20 fw-sb">

                        {singleMedicationDisplay.includes(task.rxRequest.requestCode) || task.rxRequest.requestCode === 'T' ? `Change Request - ${changeRxMap.get(task?.rxRequest?.requestCode)}` : task.rxRequest.requestCode === 'U' && task.rxRequest.requestSubCode.length === 1 ? `${changeRxMap.get(task?.rxRequest?.requestCode)} - ${rxSubcodeMap.get(task?.rxRequest?.requestSubCode[0])}` : task.rxRequest.requestCode === 'P' || (task.rxRequest.requestCode === 'U' && task.rxRequest.requestSubCode.length > 1) ? `${changeRxMap.get(task.rxRequest.requestCode)} Request` : 'Change Request'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: '0.5rem' }}>
                    <div className="row justify-content-start">
                        {patient.userId == 0 ? <div className="col-sm-6"><WarningIcon style={{ height: "18px", fill: "#f8a02e" }} /> <span className="font-12 text--terciary fw-sb">No patient record found.</span></div> : null}
                    </div>
                    <div className="row">
                        {/* ------------------Prescriber Information------------------- */}
                        <div className="col-12 col-md-12">
                            <h3 className="text--terciary font-14 m-0 fw-sb">{_str.prescriber}</h3>
                            <div className="text--terciary font-12 border--bottom">
                                <div>
                                    <span className="text--terciary font-12 ">
                                        {_str.prescriberName}: &nbsp;
                                    </span>
                                    <span>
                                        {"".concat(
                                            prescriber?.name[0]?.firstName,
                                            " ",
                                            prescriber?.name[0]?.lastName
                                        )}
                                    </span>
                                </div>

                                <div>
                                    <span className="text--terciary font-12 ">
                                        {_str.address}: &nbsp;
                                    </span>
                                    <span>{facility?.addressLine1}, {facility?.city}, {facility?.state}, {facility?.zipCode} </span>

                                </div>
                                <div className="mb-2">
                                    <span className="text--terciary font-12 ">
                                        {_str.phone}: &nbsp;
                                    </span>
                                    <span>{formatPhoneNumber(prescriberPrimaryPhone)}</span>
                                </div>
                            </div>
                        </div>

                        {/* ------------------Patient Information------------------- */}
                        <div className={`col-12 col-md-12 mt-2 `}>
                            <h3 className="text--terciary font-14 m-0 fw-sb">{_str.patient}</h3>
                            <div className="text--terciary font-12 border--bottom">
                                <div>
                                    <span className="text--terciary font-12 ">
                                        {_str.patientName}: &nbsp;
                                    </span>
                                    <span>
                                        {`${patientName?.firstName || ''} ${patientName?.middleName !== null ? patientName?.middleName : ''} ${patientName?.lastName || ''}`}
                                        {/* {(patientName?.firstName || '') + " " + (patientName?.middleName + " " || '') + (patientName?.lastName || '')} */}
                                    </span>
                                </div>

                                <div>
                                    <span className="text--terciary font-12 ">
                                        {_str.dob}: &nbsp;
                                    </span>
                                    <span>
                                        {calenderDateTimeHelper(
                                            patient?.dateOfBirth,
                                            "MMM DD, YYYY"
                                        )}{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span className="text--terciary font-12 ">
                                        {_str.gender}: &nbsp;
                                    </span>
                                    <span>{genderMap?.get(patient?.gender?.toLowerCase())}</span>

                                    {patientObservations !== null && patientObservations?.weight !== null && patientObservations?.height !== null &&
                                        patientObservations !== undefined && (
                                            <>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>{_str.height_str}: &nbsp;</span>
                                                <span className="text--terciary font-12 ">
                                                    {patientObservations?.height?.concat(" inches")}
                                                </span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>{_str.weight_str}: &nbsp;</span>
                                                <span className="text--terciary font-12 ">
                                                    {patientObservations?.weight?.concat(" lbs")}
                                                </span>
                                            </>
                                        )}
                                </div>

                                <div>
                                    <span className="text--terciary font-12 ">
                                        {_str.address}: &nbsp;
                                    </span>
                                    <span>  {"".concat(
                                        patient?.address[0]?.addressLine1,
                                        ", ",
                                        patient?.address[0]?.addressLine2 !== null ? patient?.address[0]?.addressLine2 + ", " : '',
                                        patient?.address[0]?.city,
                                        ", ",
                                        patient?.address[0]?.stateProvince,
                                        " ",
                                        patient?.address[0]?.postalCode
                                    )} </span>

                                </div>
                                <div className="mb-2">
                                    <span className="text--terciary font-12 ">
                                        {_str.phone}: &nbsp;
                                    </span>
                                    <span>{formatPhoneNumber(patientPrimaryPhone)}</span>
                                </div>
                            </div>
                        </div>

                        {/* ----------------------Pharmacy Information-------------------- */}
                        <div className="col-12 col-md-12 mt-2">
                            <h3 className="text--terciary font-14 m-0 fw-sb">{_str.pharmacy}</h3>
                            <div className="text--terciary font-12 border--bottom">
                                <div>
                                    <span className="text--terciary font-12 ">
                                        {_str.pharmacyLabelName}: &nbsp;
                                    </span>
                                    <span>{pharmacy?.standardizedOrganizationName}</span>
                                </div>

                                <div>
                                    <span className=" font-12">{_str.address}: &nbsp;</span>
                                    {"".concat(
                                        pharmacy?.standardizedAddressLine1?.concat(", ") || "",
                                        pharmacy?.standardizedAddressLine2
                                            ?.length > 0
                                            ? pharmacy?.standardizedAddressLine2.concat(", ")

                                            : "",
                                        pharmacy?.standardizedCity?.concat(", ") || "",
                                        pharmacy?.stateProvince?.concat(", ") || "",

                                        formatPostalCode(pharmacy?.standardizedPostalCode || "")
                                    )}
                                </div>
                                <div className="mb-2">
                                    <span className=" font-12">{"NPI: "} &nbsp;</span>
                                    {
                                        (pharmacy.npi !== undefined && pharmacy.npi !== null ? pharmacy.npi : '')
                                    }
                                    <span className=" font-12">&nbsp;&nbsp;&nbsp;&nbsp;{_str.phone}: &nbsp;</span>
                                    {
                                        formatPhoneNumber(pharmacy?.primaryTelephone)
                                    }
                                    <span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className=" font-12">{_str.fax}: &nbsp;</span>
                                        {formatPhoneNumber(pharmacy?.fax)}
                                    </span>
                                </div>
                                <div className="mb-2" hidden={fieldValidation(changeReasonText, "all") ? false : true}>
                                    <span className="m-0 p-0 font-12 text--terciary br-10">
                                        <InfoIcon style={{ fill: "#004fd4" }} /> {changeReasonText}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* ----------------------Drug Information------------------------- */}
                        {/* Not to be displayed in case of request code U */}
                        {task?.rxRequest?.requestCode !== 'U' ? <div className="col-12 col-md-12 mt-2 ">
                            <div className="row border--bottom">
                                {/* Original Prescription */}
                                <div className={`col-12 ${task?.rxRequest?.requestCode === 'P' ? 'col-sm-8' : 'col-sm-6'}`}>
                                    <h3 className="text--terciary font-14 fw-sb m-0">{_str.originalPrescription}</h3>
                                    {
                                        (originalPrescription?.drugDescription === undefined ||
                                            originalPrescription?.drugDescription === null) &&
                                        <>
                                            <WarningIcon style={{ height: "12px", fill: "#f8a02e" }} />
                                            <span className="font-12 ms-0 ps-0">{_str.notAvailable}</span>
                                        </>
                                    }
                                    <div className="text--terciary font-12" hidden={!fieldValidation(originalPrescription?.drugDescription, "all")}>
                                        <div>
                                            <span className="text--terciary font-12 ">{_str.drugDescription}: &nbsp;</span>
                                            <span >{originalPrescription?.drugDescription || _str.notAvailable}</span>
                                        </div>
                                        <div>
                                            <span className="">{_str.sig}: &nbsp;</span>
                                            {/* {"".concat(originalPrescription?.sigText)} */}
                                            <CustomText text={originalPrescription?.sigText || _str.notAvailable} showLimit={150} />
                                        </div>
                                        <div>
                                            <span className="">{_str.quantity}: &nbsp;</span>
                                            {"".concat("", parseFloat(originalPrescription?.quantity) || _str.notAvailable, " ", originalPrescription?.quantityUnitOfMeasure + 's' || "")}
                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="">{_str.daysSupply}: &nbsp;</span>
                                            {originalPrescription?.daysSupply || _str.notAvailable}
                                        </div>

                                        <div>
                                            <span className="">{_str.refills}: &nbsp;</span>
                                            {"".concat(originalPrescription?.refills)}
                                        </div>

                                        <div>
                                            {/* As of 03 Feb 23, in case, dispense as written is checked while sending rx, the backend stores the string true in the substitution column
                                                due to which there is another check that compares to the string true
                                            */}
                                            <span>
                                                <span className="">{_str.substitutions}: &nbsp;</span>
                                                {fieldValidation(
                                                    originalPrescription?.substitution,
                                                    "all"
                                                ) ? !isNaN(Number(originalPrescription?.substitution)) === true
                                                    ? "Allowed"
                                                    : originalPrescription?.substitution === "true" ? "Not allowed"
                                                        : 'Allowed' : _str.notAvailable}
                                            </span>
                                        </div>
                                        {
                                            originalPrescription.earliestFillDate !== undefined &&
                                            <div className="mb-2 d-none">
                                                <span className="">{_str.effectiveDate}: &nbsp;</span>
                                                {fieldValidation(
                                                    originalPrescription?.writtenDate,
                                                    "all"
                                                ) ? (
                                                    "".concat(
                                                        calenderDateTimeHelper(
                                                            originalPrescription?.writtenDate,
                                                            "YYYY-MM-DD"
                                                        )
                                                    )) : _str.notAvailable}
                                            </div>
                                        }
                                    </div>
                                </div>

                                {/* Requested Prescription */}
                                <div className="col-12 col-sm-6">
                                    {singleMedicationDisplay?.includes(task?.rxRequest?.requestCode) ?
                                        <div className="text--terciary font-14 fw-sb d-flex justify-content-between ">
                                            <h3 className="text--terciary font-14 fw-sb m-0">{_str.requestedMedication}</h3> {!editMode ?
                                                <button
                                                    onClick={editHandler}
                                                    className={`btn br-10 shadow-none ${task?.rxRequest?.requestCode === 'T' ? 'd-none' : ''}  text-decoration-underline lh-0 py-0 ps-0 pe-1  fw-sb text--blue font-12`}>Edit
                                                </button> :
                                                <div className="text-end">
                                                    <button
                                                        disabled={showDaysSupplyError || showRefillsError || showQuantityError}
                                                        onClick={() => saveEditHandler()}
                                                        className={`btn br-10 icon-btn shadow-none align-top text-decoration-underline lh-0 p-0 ${task?.rxRequest?.requestCode === 'T' ? 'd-none' : ''}  fw-sb text--dark-green ms-1 font-12`}
                                                    >
                                                        Save
                                                    </button>
                                                    <span>
                                                        <DashLg style={{ transform: 'rotate(90deg)' }} size={22} color="#70707070" />
                                                    </span>
                                                    <button
                                                        onClick={() => cancelEditHandler()}
                                                        className={`btn br-10 icon-btn align-top shadow-none text-decoration-underline lh-0 p-0 ${task?.rxRequest?.requestCode === 'T' ? 'd-none' : ''}  fw-sb text--dark-red font-12`}>

                                                        Cancel
                                                    </button>
                                                </div>}
                                        </div> :
                                        task.rxRequest.requestCode === 'T' ? <>
                                            <div className="text--terciary font-14 fw-sb d-flex justify-content-between ">{_str.requestedMedication} {!editMode && selectedInterchangeMedication !== undefined && editedMedication === undefined ? <button
                                                onClick={editHandler}
                                                className={`btn br-10 shadow-none text-decoration-underline lh-0 py-0 ps-0 pe-1  fw-sb text--blue font-12`}>Edit </button> : !editMode && editedMedication !== undefined ? <>
                                                    <div className="text-end">
                                                        <button
                                                            onClick={editHandler}
                                                            className={`btn br-10 shadow-none text-decoration-underline lh-0 py-0 ps-0 pe-1  fw-sb text--blue font-12`}>Edit </button>
                                                        <span>
                                                            <DashLg style={{ transform: 'rotate(90deg)' }} size={22} color="#70707070" />
                                                        </span>
                                                        <button
                                                            onClick={() => reloadHandler()}
                                                            className={`btn br-10 icon-btn align-top shadow-none text-decoration-underline lh-0 p-0   fw-sb text--blue font-12`}>

                                                            Show All
                                                        </button>
                                                    </div>
                                                </> :
                                                editMode && selectedInterchangeMedication !== undefined ? <div className="text-end">
                                                    <button
                                                        disabled={showDaysSupplyError || showRefillsError || showQuantityError}
                                                        onClick={() => saveEditHandler()}
                                                        className={`btn br-10 icon-btn shadow-none align-top text-decoration-underline lh-0 p-0   fw-sb text--dark-green ms-1 font-12`}
                                                    >
                                                        Save
                                                    </button>
                                                    <span>
                                                        <DashLg style={{ transform: 'rotate(90deg)' }} size={22} />
                                                    </span>
                                                    <button
                                                        onClick={() => cancelEditHandler()}
                                                        className={`btn br-10 icon-btn align-top shadow-none text-decoration-underline lh-0 p-0   fw-sb text--dark-red font-12`}>

                                                        Cancel
                                                    </button>
                                                </div> : null}
                                            </div>
                                        </> : null}

                                    {/* This will be repeated in case of T */}
                                    {isSigLoading ? <Skeleton /> : singleMedicationDisplay?.includes(task?.rxRequest?.requestCode) ? editMode && isSigLoading === false ? <EditMedicationForm
                                        requestedMedication={editedMedication ?? requestedMedication}
                                        _str={_str}
                                        sigs={sigs}
                                        quantity={quantity}
                                        setDaysSupply={setDaysSupply}
                                        daysSupply={daysSupply}
                                        setDaw={setDaw}
                                        daw={daw}
                                        showRefillsError={showRefillsError}
                                        setShowRefillsError={setShowRefillsError}
                                        handleDispenseQuantity={handleDispenseQuantity}
                                        showDaysSupplyError={showDaysSupplyError}
                                        setShowDaysSupplyError={setShowDaysSupplyError}
                                        setSelectedSig={setSelectedSig}
                                        handleDaysSupply={handleDaysSupply}
                                        selectedSig={selectedSig}
                                        showQuantityError={showQuantityError}
                                        setShowQuantityError={setShowQuantityError}
                                        effectiveDate={effectiveDate}
                                        setEffectiveDate={setEffectiveDate}
                                        substitutions={substitutions}
                                        refills={refills}
                                        handleRefills={handleRefills}
                                        setSubstitutions={setSubstitutions}
                                        handleSigSelection={handleSigSelection}
                                    /> : editedMedication !== undefined ? <EditedMedicationView _str={_str} requestedMedication={editedMedication} /> : <div className="text--terciary font-12 ">
                                        <div>
                                            <span className="text--terciary font-12 ">{_str.drugDescription}: &nbsp;</span>
                                            <span >{requestedMedication?.drugDescription}</span>
                                        </div>
                                        <div>
                                            <span className="">{_str.sig}: &nbsp;</span>
                                            {/* {"".concat(requestedMedication?.sigText)} */}
                                            <CustomText text={requestedMedication?.sigText} showLimit={150} />
                                        </div>

                                        <div>
                                            <span className="">{_str.quantity}: &nbsp;</span>
                                            {requestedMedication?.quantity + ` ${requestedMedication?.quantityUnitOfMeasure?.toLowerCase() === 'each' ? requestedMedication.quantityUnitOfMeasure : requestedMedication.quantityUnitOfMeasure + 's'}`}
                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            {requestedMedication?.daysSupply !== undefined ?
                                                <><span className="">{_str.daysSupply}: &nbsp;</span>
                                                    {requestedMedication?.daysSupply || _str.notAvailable}</> : null}
                                        </div>

                                        <div className="d-flex flex-row">
                                            <PnToolTip
                                                title={`Total quantity to be dispensed = ${isNaN((Number(requestedMedication?.refills) * Number(requestedMedication?.quantity)) + Number(requestedMedication?.quantity)) === true ? 0 : (Number(requestedMedication?.refills) * Number(requestedMedication?.quantity)) + Number(requestedMedication?.quantity)} ${"".concat(requestedMedication?.quantityUnitOfMeasure?.toLowerCase() + "(s)")}`}>
                                                <div>
                                                    <span className="">{_str.refills}: &nbsp;</span>
                                                    {"".concat("", requestedMedication?.refills)}
                                                </div>
                                            </PnToolTip>

                                        </div>

                                        <div>
                                            <span className="">Substitutions: &nbsp;</span>
                                            {Number(requestedMedication?.substitution) === 1
                                                ? "Not Allowed"
                                                : "Allowed"}

                                        </div>
                                        {
                                            requestedMedication.earliestFillDate !== undefined ?
                                                <div>
                                                    <span className="">Effective Date: &nbsp;</span>
                                                    {
                                                        requestedMedication?.earliestFillDate !== undefined && requestedMedication?.earliestFillDate !== null ? calenderDateTimeHelper(
                                                            requestedMedication?.earliestFillDate,
                                                            "YYYY-MM-DD"
                                                        ) : 'Not available'}

                                                </div> : null
                                        }

                                        {requestedMedication?.pharmacyNote ? <div>
                                            <span>Note: &nbsp;</span>
                                            {requestedMedication?.pharmacyNote}
                                        </div> : null}


                                    </div> : task?.rxRequest?.requestCode === "T" ? isSigLoading ? <Skeleton /> : editMode && selectedInterchangeMedication !== undefined && isSigLoading === false ?

                                        <EditMedicationForm
                                            requestedMedication={editedMedication !== undefined ? editedMedication : selectedInterchangeMedication}
                                            _str={_str}
                                            sigs={sigs}
                                            quantity={quantity}
                                            daysSupply={daysSupply}
                                            setDaw={setDaw}
                                            daw={daw}
                                            setDaysSupply={setDaysSupply}
                                            showRefillsError={showRefillsError}
                                            setShowRefillsError={setShowRefillsError}
                                            handleDaysSupply={handleDaysSupply}
                                            handleDispenseQuantity={handleDispenseQuantity}
                                            setSelectedSig={setSelectedSig}
                                            selectedSig={selectedSig}
                                            showQuantityError={showQuantityError}
                                            setShowQuantityError={setShowQuantityError}
                                            effectiveDate={effectiveDate}
                                            setEffectiveDate={setEffectiveDate}
                                            refills={refills}
                                            handleRefills={handleRefills}
                                            setSubstitutions={setSubstitutions}
                                            showDaysSupplyError={showDaysSupplyError}
                                            setShowDaysSupplyError={setShowDaysSupplyError}
                                            handleSigSelection={handleSigSelection}
                                            substitutions={substitutions}


                                        /> : editedMedication !== undefined ? <EditedMedicationView _str={_str} requestedMedication={editedMedication} /> :
                                            <div className={`${classes['scrollable-container']}`} style={{ maxHeight: '200px' }}>
                                                {interchangeableMedicationsList?.map((requestedMedication) => {
                                                    // { console.log(requestedMedication?.medicationRxId, selectedInterchangeMedication?.medicationRxId, requestedMedication?.medicationRxId == selectedInterchangeMedication?.medicationRxId) }
                                                    return (
                                                        <div key={requestedMedication?.medicationRxId} data-id={requestedMedication?.medicationRxId} onClick={e => handleTherapeuticInterchange(e)} className={`text--terciary font-12 br-10 ${classes['radio-element']} ${requestedMedication?.medicationRxId == selectedInterchangeMedication?.medicationRxId ? classes['selected'] : 'border--default'} mb-2 ps-1 py-1 `}>
                                                            <div data-id={requestedMedication?.medicationRxId}>
                                                                <span data-id={requestedMedication?.medicationRxId} className="text--terciary font-12 ">{_str.drugDescription}: &nbsp;</span>
                                                                <span data-id={requestedMedication?.medicationRxId} >{requestedMedication?.drugDescription}</span>
                                                            </div>
                                                            <div data-id={requestedMedication?.medicationRxId}>
                                                                <span data-id={requestedMedication?.medicationRxId} className="">{_str.sig}: &nbsp;</span>
                                                                {/* {"".concat(selectedSig?.textValue ?? requestedMedication?.sigText)} */}
                                                                <CustomText text={selectedSig?.textValue ?? requestedMedication?.sigText} showLimit={150} />
                                                            </div>

                                                            <div data-id={requestedMedication?.medicationRxId}>
                                                                <span data-id={requestedMedication?.medicationRxId}>{_str.quantity}: &nbsp;</span>
                                                                {"".concat("", requestedMedication?.quantity)}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;

                                                                <span data-id={requestedMedication?.medicationRxId}>{_str.daysSupply}: &nbsp;</span>
                                                                {requestedMedication?.daysSupply || _str.notAvailable}
                                                            </div>

                                                            <div data-id={requestedMedication?.medicationRxId} className="d-flex flex-row">
                                                                <div data-id={requestedMedication?.medicationRxId}>
                                                                    <span data-id={requestedMedication?.medicationRxId}>{_str.refills}: &nbsp;</span>
                                                                    {"".concat("", requestedMedication?.refills)}

                                                                </div>
                                                            </div>

                                                            {requestedMedication?.pharmacyNote ? <div data-id={requestedMedication?.medicationRxId} className="d-flex flex-row">
                                                                <div data-id={requestedMedication?.medicationRxId}>
                                                                    <span data-id={requestedMedication?.medicationRxId}>{_str.note}: &nbsp;</span>
                                                                    {"".concat("", requestedMedication?.pharmacyNote)}

                                                                </div>
                                                            </div> : null}




                                                        </div>
                                                    )

                                                })


                                                }
                                            </div>
                                        : null}


                                </div>


                            </div>

                        </div> : null}

                        {/* Request Code U and request subcode in A, G, I */}
                        {
                            task?.rxRequest?.requestCode === 'U' && task?.rxRequest?.requestSubCode.length > 0 &&
                            task.rxRequest.requestSubCode.includes('G') &&
                            (<NPIForm
                                npi={npi}
                                validationErrors={validationErrors}
                                handleNpi={handleNpi} />)
                        }

                        {
                            task?.rxRequest?.requestCode === 'U' && task?.rxRequest?.requestSubCode.length > 0 &&
                            task.rxRequest?.requestSubCode.includes('A') &&
                            (<StateLicenseForm
                                setStateLicense={setStateLicense}
                                stateLicense={stateLicense}
                                stateLicenses={stateLicenses} />)
                        }

                        {
                            task?.rxRequest?.requestCode === 'U' && task?.rxRequest?.requestSubCode.length > 0 &&
                            task.rxRequest?.requestSubCode.includes('I') &&
                            (<TaxonomyCodeForm
                                taxonomyCode={taxonomyCode}
                                setTaxonomyCode={setTaxonomyCode}
                                stateLicenses={stateLicenses}
                                setTaxonomyDescription={setTaxonomyDescription}
                                taxonomyDescription={taxonomyDescription} />)
                        }

                        {/* Prior Authorization case */}
                        {
                            task?.rxRequest?.requestCode === 'P' ? <div className="col-12 col-md-12 mt-2 pb-2">
                                <form id="priorAuthorizationForm" onSubmit={e => e.preventDefault()}>
                                    <div className="row ">
                                        <div className="col-12 col-sm-4">
                                            <h3 className="text--terciary font-14 fw-sb m-0">Prior Authorization Status</h3>
                                            <select
                                                className={`input form-select font-12 text--terciary  border--default shadow-none br-10  text-wrap`}
                                                aria-label="Select Prior Authorization Status"
                                                onChange={(e) => {
                                                    setPriorAuthorizationStatus((p) => e.target.value);
                                                    if (denialReasonCode !== '') {
                                                        setDenialReasonCode('');
                                                    }
                                                }}
                                                value={priorAuthorizationStatus}
                                            >
                                                <option value="" disabled> </option>
                                                <option value="A">Approved</option>
                                                <option value="D">Denied</option>

                                            </select>

                                        </div>

                                        <div className="col-12 col-sm-5">
                                            <h3 className="text--terciary font-14 m-0 fw-sb ">Prior Authorization Reference #</h3>
                                            <input type="text" minLength={2} maxLength={35} onChange={(e) => setPriorAuthorizationReferenceNumber(p => e.target.value)} className="form-control input shadow-none font-12 text--terciary br-10 border--default" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-9">
                                            <h3 className="text--terciary font-14 m-0 fw-sb ">Denial Reason {priorAuthorizationStatus === 'D' ? <span>&#42;</span> : null}</h3>
                                            <select
                                                className={`input form-select font-12 text--terciary  border--default shadow-none br-10  text-wrap`}
                                                aria-label="Select Prior Authorization Status"
                                                onChange={(e) => {
                                                    setDenialReasonCode((p) => e.target.value);
                                                }}
                                                required={priorAuthorizationStatus === 'D' ? true : false}
                                                value={denialReasonCode}
                                                disabled={priorAuthorizationStatus === 'A'}
                                            >
                                                <option value="" disabled> </option>
                                                {denialcodes?.map((denialCode) => {
                                                    return (<option key={denialCode?.code} value={denialCode?.code}>{denialCode?.description}</option>)
                                                })}

                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div> : null
                        }

                        {/* -------------------------Note to Pharmacy----------------------- */}
                        <div className="col-12 col-md-12 mt-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <div>
                                    <h3 className="font-14 m-0 fw-sb text--terciary">Note {props?.taskDetails?.rxRequest?.requestCode === 'P' && denialReasonCode === 'other' ? <span>&#42;</span> : null}</h3>
                                </div>
                                {props?.requestCode === 'P' && priorAuthorizationStatus === 'D' ? '' : <div className=" font-10 text--secondary p-0 my-auto">
                                    {singleMedicationDisplay?.includes(props?.requestCode) || props?.requestCode === 'T' ? note?.length + "/210" : note?.length + "/70"}
                                </div>}

                            </div>
                            <TextArea
                                className="form-control br-10 input font-14 border--default shadow-none"
                                // gettext={gettext}
                                {...(props?.requestCode === 'P' && { form: 'priorAuthorizationForm' })}
                                required={props?.requestCode === 'P' && denialReasonCode === 'other' ? true : false}
                                gettext={(text) => setNote(text)}
                                rows={2}
                                maxLength={singleMedicationDisplay?.includes(props?.requestCode) || props?.requestCode === 'T' ? 210 : props?.requestCode === 'P' || props?.requestCode === 'U' ? 70 : 5000}
                            />
                            {/* {props?.taskDetails?.rxRequest?.requestCode === 'P' && denialNoteError ? <span className="font-12 p-0 text--danger">Note is mandatory</span> : null} */}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer hidden = {userPermissions?.apr === false ? true:false}>




                    <button

                        className="btn d9e8ec--button"
                        disabled={props?.taskDetails?.rxRequest?.requestCode === 'P' ? (priorAuthorizationStatus === 'A') || (denialReasonCode == '') : editMode ? true : false}
                        onClick={(e) => {
                            // unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)
                            if (props?.taskDetails?.rxRequest?.requestCode === 'P') {
                                approveChangeRequestHandler("denied");
                            }
                            else {
                                props.handleModal();
                                props.handleDenyRequestModal();
                            }
                        }}
                        style={{
                            border: "#004FD4 0% 0% no-repeat padding-box",
                            width: "160px",
                            height: "40px",
                        }}
                    >
                        {_str.deny}
                    </button>



                    <button
                        disabled={editMode ? true : showRefillsError ||
                            showQuantityError || showDaysSupplyError || !isEmpty(validationErrors) ||
                            (props?.taskDetails?.rxRequest?.requestCode === 'T' && selectedInterchangeMedication === undefined) ||
                            (props?.taskDetails?.rxRequest?.requestCode === 'P' && priorAuthorizationStatus !== 'A') || props.isUnknownPatient}
                        className="btn blue_task_button"
                        onClick={(e) => {
                            if (editedMedication !== undefined) {

                                approveChangeRequestHandler("approved_with_changes");
                            }
                            else if (props?.taskDetails?.rxRequest?.requestCode === 'U') {
                                approveChangeRequestHandler("validated");
                            }
                            else {

                                approveChangeRequestHandler("approved");
                            }
                            // props.handleModal();
                        }}
                        style={{
                            border: "#004FD4 0% 0% no-repeat padding-box",
                            height: "40px",
                            width: "160px",
                        }}
                    >
                        {props?.taskDetails?.rxRequest?.requestCode !== 'U' ? _str.approve : _str.validate}
                    </button>
                </Modal.Footer>
            </Modal>

            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default ChangeRxModal;
