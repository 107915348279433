import moment from "moment";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { useAuth } from "../../hooks/useAuth";
const PatientGreeting = (props) => {
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const [hour, setHour] = useState("");
  const [dateString, setDateString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const { user } = useAuth();
  const getHour = () => {
    const date = new Date();
    const hour = date.getHours();
    setHour(hour);
  };
  const getDateString = () => {
    const date = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const fullYear = date.getFullYear();
    const month = months[date.getMonth()];
    const monthDay = date.getDate();
    const weekDay = days[date.getDay()];
    const output =
      weekDay +
      ", " +
      month +
      " " +
      monthDay.toString() +
      ", " +
      fullYear.toString();
    setDateString(output);
  };

  const updateGreetingMessage = () => {
    getHour();
    getDateString();
  };



  useEffect(() => {
    // getMapFromSession();
    updateGreetingMessage();
  }, []);

  return (
    <>
      <div className="mt-2">
        <p className="font-14 text-muted mb-0">
          {dateTimeHelper(moment(), "dddd, MMM D, YYYY")}
        </p>

        <h1 className="font-22 fw-sb">
          Good Day, {user?.prefix === undefined ? "" : user?.prefix}{" "}
          {user?.lastName === null
            ? ""
            : user?.lastName === undefined
              ? ""
              : user?.lastName}
        </h1>
      </div>
    </>
  );
};

export default PatientGreeting;
