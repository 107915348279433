import { useState } from 'react';
import { useEffect } from 'react';
import sendHttpRequest from '../../components/hooks/sendHttpRequest';
import { useAuth } from '../../components/hooks/useAuth';
import { useLocalStorage } from '../../components/hooks/useLocalStorage';
import useModal from '../../components/hooks/useModal';
import LabRequisitionFormModal from '../../components/UI/Modals/LabRequisitionFormModal';

const VeeOneLabOrder = (props) => {
    const { open: openLabOrderModal, handleOpen: handleOpenLabOrderModal, handleClose: handleCloseLabOrderModal } = useModal(false);
    const { user, loginWithoutNavigation } = useAuth();
    const [isLabOrderSent, setIsLabOrderSent] = useState(false);
    const [isToken, setIsToken] = useState(false);
    const [patientId, setPatientId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [isInitializing, setIsInitializing] = useState(false);

    const [message, setMessage] = useState();
    const [severity, setSeverity] = useState();

    const [openLabOrderSnackBar, setOpenLabOrderSnackBar] = useState(false);

    const handleLabOrderSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenLabOrderSnackBar(!openLabOrderSnackBar);
    };




    async function getInternalIds(patient_external_id, provider_external_id) {
        const response = await sendHttpRequest({
            method: 'GET',
            url: `/patients/${patient_external_id}/providers/${provider_external_id}`
        });
        if (!response.error) {
            // console.log(response.data);
            return response.data;
        }
    }

    async function getProviderDetails(provider_id) {
        const response = await sendHttpRequest({
            method: 'GET',
            url: `/provider/${provider_id}`
        });
        if (!response.error) {
            // console.log(response.data);
            return response.data;
        }
    }

    useEffect(() => {
        const handleMessage = async (event) => {
            // console.log(event.origin);
            setIsInitializing(true);
            // if (event.origin !== "http://127.0.0.1:5500") return;
            // let response = event.data.response;
            // console.log(event.data);
            if (event.data.type === 'iframe-params') {
                const patient_external_id = event.data.patientId;
                const provider_external_id = event.data.providerId;
                localStorage.setItem("tkn", event.data.jwt);
                setIsToken(true);
                const ids = await getInternalIds(patient_external_id, provider_external_id);
                setPatientId(ids.patient?.userId);
                setProviderId(ids.provider?.userId);
                const response = await getProviderDetails(ids?.provider?.userId);
                loginWithoutNavigation({
                    loggedIn: true,
                    role: response?.role ?? "",
                    userId: response?.userId ?? "",
                    selectedUserId: response?.userId ?? "",
                    timezone: response?.timezoneOffset ?? "",
                    timezoneText: response?.timezone ?? "",
                    name: response?.name,
                    firstName: response?.name[0]?.firstName ?? "",
                    lastName: response?.name[0]?.lastName ?? "",
                    prefix: response?.name[0]?.prefix ?? "",
                    prescription: response?.Identification?.Prescription,
                    spi: response?.providerTenantPracticeLocation?.spi,
                    tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
                    tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
                    facility: response?.providerTenantPracticeLocation?.facility,
                    practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId,
                    isFromIframe: true
                });
                setIsInitializing(false);
                handleOpenLabOrderModal();
            }

        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <>
            {openLabOrderModal && isToken && patientId !== null && providerId !== null ? <LabRequisitionFormModal
                show={openLabOrderModal}
                handleModal={handleCloseLabOrderModal}
                patientId={Number(patientId)}
                assessmentPlan={true}
                setIsLabOrderSent={setIsLabOrderSent}
                setMessage={setMessage}
                setSeverity={setSeverity}
                handleLabOrderSnackBar={handleLabOrderSnackBar}
            /> : null}
        </>
    )
}

export default VeeOneLabOrder;