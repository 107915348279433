import { Grid } from "@mui/material";
import { Modal } from "react-bootstrap";
import { dateTimeHelper } from "../../../../utils/dateTimeHelper";
import { Accordion, AccordionDetails, AccordionSummary } from "../../Reusable/PnComponents";
import { useSelector } from "react-redux";



const AddendumShowModal = (props) => {
    const userPermissions = useSelector(
        (state) => state.UserPermissionData.permissionsMap
      );

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => {
                    props.handleAddenModalClose();
                }}
                size="lg"
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                backdrop="static"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="font-18 text--terciary">
                        Addendums
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-sm-12">
                                <div>
                                    {
                                        props?.addendums?.length > 0 ? props?.addendums?.map((addendum, index) => {
                                            return (
                                                <Accordion key={addendum?.title?.concat(index)} defaultExpanded>
                                                    <AccordionSummary
                                                        aria-controls="panel2d-content"
                                                        id="panel2d-header"
                                                    >
                                                        <h1 className={addendum?.signedUser?.userId === addendum?.encounter?.signedBy?.userId ? "font-12 text--terciary m-0 fw-thin" : "m-0 text--blue font-12 fw-thin"}>{addendum?.title ?? ""}{" -- "}{dateTimeHelper(addendum?.createdAt ?? "", "MMM D, YYYY LT")}{" -- "}{addendum?.signedUser?.name[0]?.firstName}{" "}{addendum?.signedUser?.name[0]?.lastName}{addendum?.signedUser?.qualification && ", "}{addendum?.signedUser?.qualification ?? ""}</h1>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={5}>
                                                            <Grid item>
                                                                <p className={addendum?.signedUser?.userId === addendum?.encounter?.signedBy?.userId ? "m-0 text--terciary font-16" : "m-0 text--blue font-16"}>
                                                                    {addendum?.addendum ?? ""}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        }) : "No Addendums"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                <button
                    className={`btn blue_task_button`}
                    onClick={() => {
                        props?.handleAddenModal("");
                    }}
                    hidden = {userPermissions?.aae === false ?  true:false}
                    >
                    Add Addendum
                </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddendumShowModal;
