import TextArea from "../forms/TextArea";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPatientEncounterData,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import NewPrescriptionModal from "../UI/Modals/PrescriptionModal/NewPrescriptionModal";
import PrescriptionRequests from "../pages/PrescriptionRequests";
import LabRequisitionFormModal from "../UI/Modals/LabRequisitionFormModal";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";
import MedicationIcon from "@mui/icons-material/Medication";
import BiotechIcon from "@mui/icons-material/Biotech";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import useModal from "../hooks/useModal";
import DiagnosisModal from "../providernote/DiagnosisModal";
import { useCallback } from "react";
import { useToggle } from "../hooks/useToggle";
import ProviderNoteService from "../Services/ProviderNote.service";
import DiagnosisTableProviderNote from "../providernote/DiagnosisItem";
import DeleteDiagnosisModal from "../providernote/DeleteDiagnosisModal";
import EditDiagnosisModal from "../providernote/EditDiagnosisModal";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import useProviderNoteDiagnosis from "../hooks/useProviderNoteDiagnosis";
import { orderPrescriptionStrings } from "../../res/strings";


const AssesmentPlan = (props) => {
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Encounter
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const __orderPrescriptionStr = orderPrescriptionStrings.en;
  const addDiagnosis = useSelector((state) => state.PatientDiagnosis.Diagnosis);


  const dispatch = useDispatch();

  const { providerId, encounterId, patientId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [openMentalModal, setOpenMentalModal] = useState(false);

  const [diagnosisTextValue, setDiagnosisTextValue] = useState("");
  const [openNewPrescriptionModal, setOpenNewPrescriptionModal] =
    useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isPrescriptionSent, setIsPrescriptionSent] = useState(Math.random());
  const [openLabRequisitionModal, setOpenLabRequisitionModal] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();
  const [openLabOrderSnackBar, setOpenLabOrderSnackBar] = useState(false);
  let rxMedicationsList = patientEncounterInformation?.prescriptionReference?.encounter?.medicationRx;
  const [rxMedications, setRxMedications] = useState(rxMedicationsList || []);
  const [isRxMedicationLoading, setIsRxMedicationLoading] = useState(false);
  const [labOrders, setLabOrders] = useState([]);
  const [pastDiagnosis, setPastDiagnosis] = useState([]);
  const [pastDiagnosesStatus, setPastDiagnosesStatus] = useState({});
  const [isLabOrdersLoading, setIsLabOrdersLoading] = useState(false);
  const [openRxSnackBar, setOpenRxSnackBar] = useState(false);
  const [rxMessage, setRxMessage] = useState();
  const [rxSeverity, setRxSeverity] = useState();

  //====Diagnosis Code=====//
  const { open: openDiagnosisModal, handleModal: handleDiagnosisModal } = useModal(false);
  const [prescriptionReference, setPrescriptionReference] = useState(props?.prescriptionReferenceId);
  const [prescriptionReferenceData, setPrescriptionReferenceData] = useState(undefined);
  const [prescriptionReferenceIsLoading, setPrescriptionReferenceIsLoading] = useState(false);
  const [pastDiagnosisIsLoading, setPastDiagnosisIsLoading] = useState(false);
  const {
    openDiagnosisSnackbar,
    handleDiagnosisSnackBarClose,
    diagnosisAddedMessage,
    setDiagnosisAddedMessage,
    diagnosisSeverity,
    setDiagnosisSeverity,
    callApi,
    setCallApi,
    openDeleteDiagnosisModal,
    handleDeleteDiagnosisModal,
    openEditDiagnosisModal,
    handleEditDiagnosisModal,
    setOpenEditDiagnosisModal,
    setOpenDeleteDiagnosisModal,
    deleteDiagnosisLoading,
    deletedDiagnosis,
    deleteDiagnosis,
    editedDiagnosis,
    isDiagnosisUpdating,
    updateDiagnosis, setOpenDiagnosisSnackBar } = useProviderNoteDiagnosis();
  // console.log("patient encounter data", patientEncounterInformation);

  const fetchProviderNotes = async () => {
    setPrescriptionReferenceIsLoading(true);
    const data = await ProviderNoteService.getProviderNoteData(prescriptionReference ?? encounterId);
    console.log("Providernote", data);
    setPrescriptionReferenceData(data);
    setPrescriptionReferenceIsLoading(false);
  }

  let apString = "",
    json = "";

  function getAssesmentText(text) {
    props.setUnsavedChanges(true);
    apString = text;
    json = { assessmentNote: apString };
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        // AssessmentNote:updateEncounterData?.AssessmentNote + text,
        assessmentNote: text,
        assessmentNoteText: text,
      })
    );
    // dispatch(setPatientEncounterData({...patientEncounterInformation,Encounter:{...patientEncounterInformation?.Encounter,AssessmentNote:patientEncounterInformation?.Encounter?.AssessmentNote+text}}))
    dispatch(
      setPatientEncounterData({
        ...patientEncounterInformation,
        encounter: {
          ...patientEncounterInformation?.encounter,
          assessmentNote: text,
          assessmentNoteText: text,
        },
      })
    );

    return json;
  }

  function getRecomendationText(text) {
    props.setUnsavedChanges(true);
    apString = text;
    json = { recommendationNote: apString };
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        recommendationNote: text,
      })
    );
    dispatch(
      setPatientEncounterData({
        ...patientEncounterInformation,
        encounter: {
          ...patientEncounterInformation.encounter,
          recommendationNote: text,
        },
      })
    );
    return json;
  }

  const fetchPrescribedMedication = async () => {
    setIsRxMedicationLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/encounter/${encounterId}/medicationRx`,
      method: "GET",
    });
    // console.log("httpResponse", httpResponse);
    if (!httpResponse.error) {
      // console.log("medicationRx: ", httpResponse.data);
      if (httpResponse.data !== null && httpResponse.data !== undefined) {
        setRxMedications(httpResponse.data);
      } else {
        setRxMedications([]);
      }
    }
    setIsRxMedicationLoading(false);
  };
  const fetchLabOrders = async () => {
    setIsLabOrdersLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/prescription-reference/${encounterId}/lab-orders`,
      method: "GET",
    });
    if (!httpResponse.error) {
      let labOrderTest = await httpResponse.data[0]?.labOrderTest;
      if (labOrderTest !== undefined && labOrderTest?.length > 0) {
        setLabOrders(httpResponse.data);
      }
    }
    setIsLabOrdersLoading(false);
  };

  const fetchPatientPastDiagnosis = async () => {
    setPastDiagnosisIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url:`/patients/${patientId}/diagnoses`,
      method: "GET",
      params: {
        status: true,
        unique: true,
        excludeEncounter: encounterId
      },
    });
    if (!httpResponse.error) {
      if (httpResponse.data !== null && httpResponse.data !== undefined) {
        setPastDiagnosis(httpResponse.data);
      }
    }
    setPastDiagnosisIsLoading(false);
  };

  const handleToggleInactive = async (diagnosisId) => {
    const diagnosis = pastDiagnosis.find(d => d.diagnosisId === diagnosisId);
    if (diagnosis) {
      let status = diagnosis.status === true ? false : true;
      diagnosis.status = status;
      setPastDiagnosis([...pastDiagnosis]);
      
      // TODO handle diagnosis in state and update state only after saving encounter
      // let object = {...pastDiagnosesStatus};
      // object[diagnosisId] = status;
      // setPastDiagnosesStatus(object);
      // dispatch (
      //   setUpdateEncounterData({
      //     ...updateEncounterData,
      //     encounterId: encounterId,
      //     pastStatus: object
      //   })
      // )

      const httpResponse = await sendHttpRequest({
        url:`/diagnosis/${diagnosis?.diagnosisId}`,
        data: diagnosis,
        method: "PUT",
      });
      if (!httpResponse.error) {
        setPastDiagnosis([...pastDiagnosis]);
      }

    }
  };

  const handleDiagnosisTextValue = () => {
    let diagnosisText = "";

    if (addDiagnosis?.Primary?.length > 0) {
      diagnosisText += "Primary:\n";
      diagnosisText +=
        addDiagnosis.Primary[0].code +
        " - " +
        addDiagnosis.Primary[0].description +
        "\n";
    }
    if (addDiagnosis?.Secondary?.length > 0) {
      diagnosisText += "Secondary:\n";
    }
    for (let i = 0; i < addDiagnosis?.Secondary?.length; i++) {
      diagnosisText +=
        addDiagnosis.Secondary[i].code +
        " - " +
        addDiagnosis.Secondary[i].description +
        "\n";
    }
    if (addDiagnosis?.Note !== undefined && addDiagnosis?.Note?.length > 1) {
      diagnosisText += "Note:\n";
      diagnosisText += addDiagnosis?.Note + "\n";
    }
    setDiagnosisTextValue(diagnosisText);
  };
  const handleSetIsSent = () => {
    setIsSent(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  const handleNewPrescriptionModal = () => {
    dispatch(updatePrescribedMedication([]));
    setOpenNewPrescriptionModal(false);
  };
  const handleLabRequisitionModal = () => {
    setOpenLabRequisitionModal(!openLabRequisitionModal);
  };
  const handleLabOrderSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLabOrderSnackBar(!openLabOrderSnackBar);
  };
  const handleRxSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(!isSent);
  };
  useEffect(() => {
    handleDiagnosisTextValue();
    fetchPrescribedMedication();
    fetchLabOrders();
    fetchPatientPastDiagnosis();
  }, []);
  useEffect(() => {
    // if (openNewPrescriptionModal === false || openLabRequisitionModal) {
    // }
    fetchPrescribedMedication();
  }, [openNewPrescriptionModal]);


  useEffect(() => {
    // console.log("Getting provider note data");
    fetchProviderNotes();
  }, [callApi])
  return (
    <>
      {openNewPrescriptionModal ? (
        <NewPrescriptionModal
          // patientId={patientId}
          show={openNewPrescriptionModal}
          handleSetIsSent={handleSetIsSent}
          handleNewPrescriptionModal={handleNewPrescriptionModal}
          patientId={patientId}
          encounterId={encounterId}
          assessmentPlan={true}
          prescriptionRequestModal={
            <PrescriptionRequests
              handleNewPrescriptionModal={handleNewPrescriptionModal}
              handleSetIsSent={handleSetIsSent}
              setIsPrescriptionSent={setIsPrescriptionSent}
              patientId={patientId}
              encounterId={encounterId}
              assessmentPlan={true}
            />
          }
        />
      ) : null}

      {openLabRequisitionModal ? (
        <LabRequisitionFormModal
          show={openLabRequisitionModal}
          handleModal={handleLabRequisitionModal}
          patientId={patientId}
          prescriptionReference={encounterId}
          assessmentPlan={true}
          setMessage={setMessage}
          setSeverity={setSeverity}
          handleLabOrderSnackBar={handleLabOrderSnackBar}
          fetchLabOrders={fetchLabOrders}
        />
      ) : null}

      {openDiagnosisModal ? (
        <DiagnosisModal
          show={openDiagnosisModal}
          handleModal={handleDiagnosisModal}
          prescriptionReference={prescriptionReference ?? encounterId}
          setDiagnosisAddedMessage={setDiagnosisAddedMessage}
          setDiagnosisSeverity={setDiagnosisSeverity}
          setOpenDiagnosisSnackBar={setOpenDiagnosisSnackBar}
          setCallApi={setCallApi}
          assessmentPlan={true}
          currentActiveDiagnosis={pastDiagnosis}

        // diagnosisLength={patientEncounterInformation?.patientChart?.diagnosis?.filter(diag => diag?.type === 'P')?.length}

        />
      ) : null}

      {openDeleteDiagnosisModal ? (
        <DeleteDiagnosisModal
          show={openDeleteDiagnosisModal}
          handleModal={handleDeleteDiagnosisModal}
          deletedDiagnosis={deletedDiagnosis}
          handleDelete={deleteDiagnosis}
          setCallApi={setCallApi}
          deleteDiagnosisLoading={deleteDiagnosisLoading}

        />
      ) : null}

      {openEditDiagnosisModal ? (
        <EditDiagnosisModal
          show={openEditDiagnosisModal}
          handleModal={handleEditDiagnosisModal}
          editDiagnosis={editedDiagnosis}
          handleEditDiagnosis={handleEditDiagnosisModal}
          setCallApi={setCallApi}
          updateDiagnosis={updateDiagnosis}
          isDiagnosisUpdating={isDiagnosisUpdating}
          assessmentPlan={true}

        // diagnosisLength={prescriptionReferenceData?.patientChart?.diagnosis?.filter(diag => diag?.type === 'P')?.length}

        />
      ) : null}

      <div className="my-5">
        <h4 className="h18 my-3">Assessment</h4>

        <TextArea
          className="form-control input br-10 border--default shadow-none my-2"
          // placeholder="Write your note"
          id={"assessment"}
          rows={5}
          gettext={getAssesmentText}
          defaultValue={
            patientEncounterInformation?.encounter?.assessmentNote ||
            props?.encounterApiData?.encounter?.assessmentNote
          }
        />
        <div className="d-flex justify-content-end">
          <button
            onClick={handleDiagnosisModal}
            className="btn pn-button-icon-blue br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12 ms-3"
            hidden={userPermissions?.adg === false ? true : false}
          >
            <span className="pn-button-icon-blue pe-1">
              <MedicalInformationIcon color="#336383" size={14} />
            </span>
            <span
              className="d-inline-block pn-link-button"
              style={{ verticalAlign: "middle" }}
            >
              Add Diagnosis
            </span>
          </button>
        </div>
        <div className="row ">
          <div className="col-sm-6">
            {prescriptionReferenceIsLoading ? (
              <Skeleton style={{ width: '100%' }} animation="wave" />
            ) : (
              prescriptionReferenceData !== undefined &&
              prescriptionReferenceData?.patientChart !== null &&
              prescriptionReferenceData?.patientChart?.diagnosis?.length > 0 &&
              // Filter diagnosis based on the assessmentPlanDiagnosis condition
              prescriptionReferenceData?.patientChart?.diagnosis.some(d => d.assessmentPlanDiagnosis) && (
                <>
                  <h2 className="font-16 fw-sb text--terciary">Diagnoses</h2>
                  <DiagnosisTableProviderNote
                    diagnoses={prescriptionReferenceData?.patientChart?.diagnosis.filter(d => d.assessmentPlanDiagnosis)}
                    deleteDiagnosisConfirmation={handleDeleteDiagnosisModal}
                    handleEditDiagnosis={handleEditDiagnosisModal}
                  />
                </>
              )
            )}
          </div>
         
          <div className="col-sm-6">
            {pastDiagnosisIsLoading ? (
              <Skeleton style={{ width: '100%' }} animation="wave" />
            ) : (
              prescriptionReferenceData !== undefined &&
              prescriptionReferenceData?.patientChart !== null &&
              prescriptionReferenceData?.patientChart?.diagnosis?.length > 0 &&
                <>
                  <h2 className="font-16 fw-sb text--terciary">Past Diagnoses</h2>
                  <DiagnosisTableProviderNote
                    diagnoses={pastDiagnosis}
                    deleteDiagnosisConfirmation={handleDeleteDiagnosisModal}
                    handleEditDiagnosis={handleEditDiagnosisModal}
                    isPastDiagnosis={true}
                    handleToggleInactive={handleToggleInactive}
                  />
                </>
            )}
          </div>
        </div>



        <div className="my-4">
          <Divider sx={{ color: "#336383" }} />
        </div>
        <div className="">
          <h4 className="h18 my-3">{"Plan"}</h4>
        </div>
        <TextArea
          className="form-control input br-10 border--default shadow-none my-2"
          // placeholder="Write your note"
          id={"plan"}
          rows={5}
          gettext={getRecomendationText}
          defaultValue={
            patientEncounterInformation?.encounter?.recommendationNote ||
            props?.encounterApiData?.encounter?.recommendationNote
          }
        />
        <div className="text-end">
          <button
            className="btn pn-button-icon-blue br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
            onClick={() => {
              setOpenNewPrescriptionModal(true);
            }}
            hidden={userPermissions?.orx === false ? true : false}
          >
            <span className=" pn-button-icon-blue pe-1">
              <MedicationIcon color="#004FD4" size={14} />{" "}
            </span>
            <span
              className="d-inline-block pn-link-button"
              style={{ verticalAlign: "middle" }}
            >
              Add Medication
            </span>
          </button>
          <button
            onClick={() => {
              setOpenLabRequisitionModal(true);
            }}
            className="btn pn-button-icon-blue br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12 ms-3"
            hidden={userPermissions?.olp === false ? true : false}
          >
            <span className="pn-button-icon-blue pe-1">
              <BiotechIcon color="#004FD4" size={14} />{" "}
            </span>
            <span
              className="d-inline-block pn-link-button"
              style={{ verticalAlign: "middle" }}
            >
              Add Lab Order
            </span>
          </button>
        </div>

        {/* <div className="text-end">
          <a
            className=" px-2 mt-3 cursor--pointer text--blue"
            onClick={() => {
              setOpenNewPrescriptionModal(true);
            }}
          >
            Add Medication
          </a>
          <a
            className="cursor--pointer px-2 mt-3"
            onClick={() => {
              setOpenLabRequisitionModal(true);
            }}
          >
            Add Lab Order
          </a>
        </div> */}
        <div>
          {rxMedications?.length > 0 && (
            <h4 className="font-16 fw-sb text--terciary my-1">Medication(s) Ordered</h4>
          )}
          <div className="font-14 text--secondary">
            {isRxMedicationLoading ? (
              <Skeleton />
            ) : (
              rxMedications?.length > 0 &&
              rxMedications.map((item) => {

                return <span key={item?.orderNumber} className="d-block">{`${item?.drugDescription?.trim()}, ${item?.sigText}`}</span>;
              })
            )}
          </div>
        </div>
        <div>
          {labOrders?.length > 0 && <h4 className="font-16 fw-sb text--terciary my-1">Lab(s) Ordered</h4>}
          <div className="font-14 text--secondary">
            {isLabOrdersLoading ? (
              <Skeleton />
            ) : (
              labOrders?.length > 0 &&
              labOrders.map((item) => {
                return item?.labOrderTest?.map((labItem) => {
                  return <span className="d-block" key={Math.random()}>{labItem?.description}</span>;
                })
              })
            )}
          </div>
        </div>
      </div>
      <CustomizedSnackbars
        message={message}
        severity={severity}
        open={openLabOrderSnackBar}
        handleClose={handleLabOrderSnackBar}
      />

      <CustomizedSnackbar
        message={diagnosisAddedMessage}
        severity={diagnosisSeverity}
        open={openDiagnosisSnackbar}
        handleClose={handleDiagnosisSnackBarClose}
      />

      <CustomizedSnackbar
        message={__orderPrescriptionStr.eprescribe_msg}
        severity={"success"}
        open={isSent}
        handleClose={handleRxSnackBar}
      />

      {/* {console.log("AssessmentNote",patientEncounterInformation?.Encounter )} */}
      {/* {console.log("rxMEdication: ",rxMedications)} */}
      {/* {console.log("patientEncounterInformation: ",patientEncounterInformation)} */}
      {/* {console.log("Lab orders: ", labOrders)} */}
    </>
  );
};

export default AssesmentPlan;
