import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import HomeIcon from "../Drawer/DrawerItemIcons/HomeIcon";
import MessagesIcon from "../Drawer/DrawerItemIcons/MessagesIcon";
import AppointmentsIcon from "../Drawer/DrawerItemIcons/AppointmentsIcon";
import UsersIcon from "../Drawer/DrawerItemIcons/UsersIcon";
import PatientsIcon from "../Drawer/DrawerItemIcons/PatientsIcon";
import EncounterIcon from "../Drawer/DrawerItemIcons/EncounterIcon";
import PharmacyIcon from "../Drawer/DrawerItemIcons/PharmacyIcon";
import ReportsIcon from "../Drawer/DrawerItemIcons/ReportsIcon";
import TaskIcon from "../Drawer/DrawerItemIcons/TaskIcon";
import {
  List,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  ListItemButton,
  Collapse,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { ExpandLess, ExpandMore, ReceiptOutlined } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import {
  setDirtyBitInStore,
  setDrawerState,
  setPatientEncounterData,
  setUpdateEncounterData,
} from "../../../redux/actions/providerActions";
import { navigateOnDirtyBit } from "../../../utils/helpers/HelperFunctions";
import drawerButton from "../../../assets/icons/drawerButton.svg";
import { useAuth } from "../../hooks/useAuth";
import { featureFlagMap } from "../../../utils/helpers/ConfigMap";
import { useContext } from "react";
import { VideoCallContext } from "../AgoraHooks/VideoCallContext";
import SettingsIcon from "./DrawerItemIcons/SettingsIcon";
import { useFlags } from "launchdarkly-react-client-sdk";
const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "1px solid #D9E8EC",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  borderRight: "1px solid #D9E8EC",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderBottom: "1px solid #D9E8EC",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  zIndex: "0",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const useStyles = makeStyles({
  root: {
    "&$selected": {
      color: "#EC609B",
      borderRight: "3px solid #EC609B",
      backgroundColor: "#F2F7F8 !important",
    },
  },

  selected: {},
});

export default function Sidebar(props) {
  const drawerState = useSelector((state) => state.DrawerState);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const isVideoConsultationInProgress = useSelector(
    (state) => state.isConsultationInProgress.isConsultationInProgress
  );
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const videoCallContext = useContext(VideoCallContext);
  const featureFlags = featureFlagMap();
  const dispatch = useDispatch();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const [open, setOpen] = useState(drawerState);
  const [openAdmin, setOpenAdmin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const classes = useStyles();
  const {pnErx3385ClaimsModuleEnabled}  = useFlags();
  function clearStoreWhenInactiveVideoCall() {
    if (videoCallContext.getIsVideoCallActive() === false) {
      videoCallContext.updateAppointmentData(null);
      videoCallContext.updateAppointmentObject(null);
      dispatch(setUpdateEncounterData({ Pharmacy: "" }));
      dispatch(setPatientEncounterData({}));
    }
  }

  const handleDrawerOpen = () => {
    setOpen(!open);
    dispatch(setDrawerState(!open));
  };

  const handleClick = () => {
    setOpenAdmin(!openAdmin);
  };

  const closeNestedList = () => {
    if (openAdmin === true) {
      setOpenAdmin(false);
    }
  };

  const unreadMessageCount = useSelector(
    (state) => state.ProviderUnreadMessageCount.count
  );

  //If Video Consultation is in progress, collapse the drawer
  useEffect(() => {
    if (isVideoConsultationInProgress === true) {
      setOpen(false);
      dispatch(setDrawerState(false));
    } else {
      setOpen(true);
      dispatch(setDrawerState(true));
    }
  }, [isVideoConsultationInProgress]);

  // console.log(props.appointmentPassed)

  return (
    // const messageCount = useSelector(state=>state.pr)
    <Drawer variant="permanent" open={isMobile ? false : open} className="hide-scrolbar">
      <DrawerHeader className="pt-3" />
      <List
        id="drawer-list"
        className={`hide-scrolbar ${isVideoConsultationInProgress
          ? "h-100 position-relative padding-4em"
          : ""
          }`}
      >
        <ListItemButton className="drawer-toggle" onClick={handleDrawerOpen}>
          <ListItemIcon style={{ minWidth: "40px" }}>
            <img
              src={drawerButton}
              className={`${!open ? "drawer-toggle-rotate" : ""}`}
            />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            closeNestedList();
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/dashboard",
              dispatch,
              setDirtyBitInStore
            );

            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname === "/dashboard" && true}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <HomeIcon
              style={
                location.pathname === "/dashboard"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/dashboard" && "text--secondary"
                } menu__item`}
            >
              Home
            </span>
          </ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            closeNestedList();
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/all-patients",
              dispatch,
              setDirtyBitInStore,
              user
            );
            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname.includes("/all-patients") && true}
          classes={{ root: classes.root, selected: classes.selected }}
          hidden={
            userPermissions?.sfp === false &&
            userPermissions?.vlp === false &&
            userPermissions?.vpi === false
          }
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <PatientsIcon
              style={
                location.pathname === "/all-patients"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/all-patients" && "text--secondary"
                } menu__item`}
            >
              Patients
            </span>
          </ListItemText>
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            closeNestedList();
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/appointments",
              dispatch,
              setDirtyBitInStore
            );
            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname === "/appointments" && true}
          classes={{ root: classes.root, selected: classes.selected }}
          hidden={userPermissions?.vsa === false ? true : false}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <AppointmentsIcon
              style={
                location.pathname === "/appointments"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/appointments" && "text--secondary"
                } menu__item`}
            >
              Appointments
            </span>
          </ListItemText>
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            closeNestedList();
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/encounter",
              dispatch,
              setDirtyBitInStore
            );
            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname.includes("/encounter") && true}
          classes={{ root: classes.root, selected: classes.selected }}
          hidden={
            userPermissions?.vpe === false && userPermissions?.vse === false
              ? true
              : false
          }
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <EncounterIcon
              style={
                location.pathname.includes("/encounter")
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${!location.pathname.includes("/encounter") && "text--secondary"
                } menu__item`}
            >
              Encounters
            </span>
          </ListItemText>
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            closeNestedList();
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/tasks",
              dispatch,
              setDirtyBitInStore
            );
            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname === "/tasks" && true}
          classes={{ root: classes.root, selected: classes.selected }}
          hidden={userPermissions?.vts === false ? true : false}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <TaskIcon
              style={
                location.pathname === "/tasks"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/tasks" && "text--secondary"
                } menu__item `}
            >
              Tasks
            </span>
          </ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            closeNestedList();
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/messages",
              dispatch,
              setDirtyBitInStore
            );
            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname === "/messages" && true}
          classes={{ root: classes.root, selected: classes.selected }}
          hidden={userPermissions?.vmi === false ? true : false}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <MessagesIcon
              style={
                location.pathname === "/messages"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
                // { color: '#dddddd' }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/messages" && "text--secondary"
                } menu__item `}
            // disabled-link
            >
              Messages
            </span>
          </ListItemText>
          <ListItemText>
            {unreadMessageCount > 0 && (
              <span
                className="badge  rounded-pill "
                style={{
                  backgroundColor: "#336383",
                }}
              >
                {unreadMessageCount}
              </span>
            )}
          </ListItemText>
        </ListItemButton>

        {featureFlags.get("surescript") === "false" && (
          <ListItemButton
            onClick={() => {
              closeNestedList();
              navigateOnDirtyBit(
                dirtyBitStatus,
                navigate,
                "/pharmacy",
                dispatch,
                setDirtyBitInStore
              );
              clearStoreWhenInactiveVideoCall();
            }}
            selected={location.pathname === "/pharmacy" && true}
            classes={{ root: classes.root, selected: classes.selected }}
          >
            <ListItemIcon style={{ minWidth: "40px" }}>
              <PharmacyIcon
                style={
                  location.pathname === "/pharmacy"
                    ? { color: "#EC609B", fill: "#EC609B" }
                    : { color: "#336383", fill: "#336383" }
                }
              />
            </ListItemIcon>
            <ListItemText>
              <span
                className={`${location.pathname !== "/pharmacy" && "text--secondary"
                  } menu__item `}
              >
                Pharmacies
              </span>
            </ListItemText>
          </ListItemButton>
        )}

        <ListItemButton
          onClick={() => {
            navigateOnDirtyBit(
              dirtyBitStatus,
              navigate,
              "/reports",
              dispatch,
              setDirtyBitInStore
            );

            clearStoreWhenInactiveVideoCall();
          }}
          selected={location.pathname === "/reports" && true}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <ReportsIcon
              style={
                location.pathname === "/reports"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
                // {color:'#dddddd'}
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/reports" && "text--secondary"
                } menu__item `}
            >
              Reports
            </span>
          </ListItemText>
        </ListItemButton>
        {
          pnErx3385ClaimsModuleEnabled ? 
          <ListItemButton
            onClick={() => {
              navigateOnDirtyBit(
                dirtyBitStatus,
                navigate,
                "/billing-and-claims",
                dispatch,
                setDirtyBitInStore
              );

              clearStoreWhenInactiveVideoCall();
            }}
            selected={location.pathname === "/billing-and-claims" && true}
            classes={{ root: classes.root, selected: classes.selected }}
          >
            <ListItemIcon style={{ minWidth: "40px" }}>
              <ReceiptOutlined
                style={
                  location.pathname === "/billing-and-claims"
                    ? { color: "#EC609B", fill: "#EC609B" }
                    : { color: "#336383", fill: "#336383" }
                  // {color:'#dddddd'}
                }
              />
            </ListItemIcon>
            <ListItemText>
              <span
                className={`${location.pathname !== "/billing-and-claims" && "text--secondary"
                  } menu__item `}
              >
                Billing & Claims
              </span>
            </ListItemText>
          </ListItemButton>
          :null
        }
        <ListItemButton
          onClick={handleClick}
          hidden={
            userPermissions?.vpu === false &&
            userPermissions?.vur === false &&
            userPermissions?.vtf === false
          }
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <UsersIcon
              style={
                location.pathname === "/users" || location.pathname === "/roles"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span className="text--secondary menu__item">Admin</span>
          </ListItemText>
          {openAdmin ? (
            <ExpandLess className="text--primary" />
          ) : (
            <ExpandMore className="text--primary" />
          )}
        </ListItemButton>
        <Collapse
          in={openAdmin}
          timeout="auto"
          unmountOnExit
          hidden={
            userPermissions?.vpu === false &&
            userPermissions?.vur === false &&
            userPermissions?.vtf === false
          }
        >
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => {
                navigateOnDirtyBit(
                  dirtyBitStatus,
                  navigate,
                  "/users",
                  dispatch,
                  setDirtyBitInStore
                );

                clearStoreWhenInactiveVideoCall();
              }}
              selected={location.pathname === "/users" && true}
              classes={{ root: classes.root, selected: classes.selected }}
              hidden={userPermissions?.vpu === false ? true : false}
            >
              <ListItemText sx={{ pl: 6 }}>
                <span
                  className={`${location.pathname !== "/users" && "text--secondary"
                    } menu__item`}
                >
                  Practice Users
                </span>
              </ListItemText>
            </ListItemButton>
            {userPermissions?.vur === true ? (
              <ListItemButton
                onClick={() => {
                  navigateOnDirtyBit(
                    dirtyBitStatus,
                    navigate,
                    "/roles",
                    dispatch,
                    setDirtyBitInStore
                  );
                  clearStoreWhenInactiveVideoCall();
                }}
                selected={location.pathname === "/roles" && true}
                classes={{ root: classes.root, selected: classes.selected }}
                hidden={userPermissions?.vur === false ? true : false}
              >
                <ListItemText sx={{ pl: 6 }}>
                  <span
                    className={`${location.pathname !== "/roles" && "text--secondary"
                      } menu__item`}
                  >
                    User Roles
                  </span>
                </ListItemText>
              </ListItemButton>
            ) : null}
            {userPermissions?.aft === true && (
              <ListItemButton
                onClick={() => {
                  navigateOnDirtyBit(
                    dirtyBitStatus,
                    navigate,
                    "/facility",
                    dispatch,
                    setDirtyBitInStore
                  );

                  clearStoreWhenInactiveVideoCall();
                }}
                selected={location.pathname === "/facility" && true}
                classes={{ root: classes.root, selected: classes.selected }}
              >
                <ListItemText sx={{ pl: 6 }}>
                  <span
                    className={`${location.pathname !== "/facility" && "text--secondary"
                      } menu__item`}
                  >
                    Facilities
                  </span>
                </ListItemText>
              </ListItemButton>
            )}
          </List>
        </Collapse>
        {userPermissions?.vsg === true && (
          <ListItemButton
            onClick={() => {
              navigateOnDirtyBit(
                dirtyBitStatus,
                navigate,
                "/tenant-settings",
                dispatch,
                setDirtyBitInStore
              );

              clearStoreWhenInactiveVideoCall();
            }}
            selected={location.pathname === "/tenant-settings" && true}
            classes={{ root: classes.root, selected: classes.selected }}
          >
            <ListItemIcon style={{ minWidth: "40px" }}>
              <SettingsIcon
                style={
                  location.pathname === "/tenant-settings"
                    ? { color: "#EC609B", fill: "#EC609B" }
                    : { color: "#336383", fill: "#336383" }
                  // {color:'#dddddd'}
                }
              />
            </ListItemIcon>
            <ListItemText>
              <span
                className={`${location.pathname !== "/tenant-settings" && "text--secondary"
                  } menu__item `}
              >
                {"Settings"}
              </span>
            </ListItemText>
          </ListItemButton>
        )}

        {/* <ListItemButton
          // onClick={() => navigate("/medical-dictionary")}
          // selected={location.pathname === "/medical-dictionary" && true}
          // classes={{ root: classes.root, selected: classes.selected }}
          style={{color:'#dddddd'}}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <MedicalDictionaryIcon
              // style={
              //   location.pathname === "/medical-dictionary"
              //     ? { color: "#EC609B", fill: "#EC609B" }
              //     : { color: "#336383", fill: "#336383" }
              // }
              style={{color:'#dddddd'}}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${
                location.pathname !== "/medical-dictionary" && "text--secondary"
              } menu__item disabled-link`}
            >
              Medical Dictionary
            </span>
          </ListItemText>
        </ListItemButton> */}

        {/* <ListItemButton
          // onClick={() => navigate("/reports")}
          // selected={location.pathname === "/reports" && true}
          // classes={{ root: classes.root, selected: classes.selected }}
          style={{ color: "#dddddd" }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <ReportsIcon
              // style={
              //   location.pathname === "/reports"
              //     ? { color: "#EC609B", fill: "#EC609B" }
              //     : { color: "#336383", fill: "#336383" }
              // }
              style={{ color: "#dddddd" }}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${location.pathname !== "/reports" && "text--secondary"
                } menu__item disabled-link`}
            >
              Reports
            </span>
          </ListItemText>
        </ListItemButton> */}

        {/* {console.log("drawerState",drawerState.open)} */}
        {/* <ListItemButton
          onClick={() => navigate("/test")}
          selected={location.pathname === "/test" && true}
          classes={{ root: classes.root, selected: classes.selected }}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <ReportsIcon
              style={
                location.pathname === "/test"
                  ? { color: "#EC609B", fill: "#EC609B" }
                  : { color: "#336383", fill: "#336383" }
              }
            />
          </ListItemIcon>
          <ListItemText>
            <span
              className={`${
                location.pathname !== "/test" && "text--secondary"
              } menu__item`}
            >
              Test Page
            </span>
          </ListItemText>
        </ListItemButton> */}
      </List>
      {/* <Divider />
      <List>
        <ListItem button onClick={() => navigate("/")}>
          <ListItemIcon>{<img src={SignOutIcon} alt="signout" />}</ListItemIcon>
          <ListItemText primary={"Signout"} />
        </ListItem>
      </List> */}
    </Drawer>
  );
}
