import { useState } from "react";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CurrencyInput from "react-currency-input-field";
import { CircularProgress } from "@mui/material";
import { transferPayment } from "../../res/endpointurls";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";



const PaymentChequeForm = ({setIsPaymentSent,isPaymentSent,amountValue, prescribersList, userId, fetchBillingHistory, handleReceipt, isPrinting,fetchCurrentBill}) => {
    
    const [chequeNumber, setChequeNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState("");
    const [provider, setProvider] = useState();
    const [amount, setAmount] = useState(amountValue ?? 0);
    const {user} = useAuth();


    const handlePayment = async () => {

        setIsLoading(true);
        const httpResponse = await sendHttpRequest({
            url: transferPayment(user?.tenantId),
            method: "POST",
            data: {
                providerId:provider,
                userId:userId,
                amount:amount,
                cheque:chequeNumber.toString(),
                // cheque:"cheque",
            }
        });
        if (!httpResponse.error) {
            setIsSent(true);
            fetchBillingHistory(true, {description: "Paid via cheque", due: amount});
            setIsPaymentSent(true);
            setAmount(0)
            // fetchCurrentBill();
        } else {
            if (httpResponse?.status === 500) {
                setError({message:"Something went wrong", show:true});
            } else {
                setError({...httpResponse?.errorMessage, show:true} ?? {message:"Payment not completed", show:true});
            }
        }
        setIsLoading(false);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setIsSent(false);
        setError({show:false});
    };
    useEffect(()=>{
        setAmount(amountValue)
    },[amountValue])
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <label
                        htmlFor="checkNumber"
                        className="form-label font-12 text--secondary m-0 fw-sb"
                    >
                        {"Check Number"}
                    </label>
                    <input
                        type="number"
                        autoComplete={false}
                        placeholder="Enter check number"
                        className="form-control font-12 border--default br-10 shadow-none input"
                        id="checkNumber"
                        onChange={(e) => setChequeNumber(e.target.value)}
                        style={{textAlign:"center"}}
                    />
                </div>
                <div className="col-12">

                        <div className="row">

                            <div className="col-6">
                                <div className="col-12">
                                    <label htmlFor="amount" className="font-12 text--secondary">{"Amount"}</label>
                                    <CurrencyInput
                                        id="cheque"
                                        name="cheque"
                                        placeholder="$0.00"
                                        className="text--terciary text-center font-12 input form-control shadow-none border--default br-10"
                                        defaultValue={isPaymentSent ? 0 : amount}
                                        // decimalsLimit={2}
                                        // fixedDecimalLength={2}
                                        prefix="$"
                                        decimalSeparator="."
                                        groupSeparator=","
                                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                        onValueChange={(value, name) => setAmount(value)}
                                        // disabled = {true}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="col-12">
                                    <label htmlFor="provider" className="font-12 text--secondary">{"Provider"}</label>
                                    <select
                                        className={`form-select font-12 border--default br-10 shadow-none input`}
                                        aria-label="action select"
                                        style={{
                                        border: "1px solid #D9E8EC",
                                        //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                                        height: "36px",
                                        // fontStyle:"italic"
                                        }}
                                        onChange={(e) => setProvider(e.target.value)}
                                    >
                                        <option
                                            className="text--terciary"
                                            value={""}
                                            key={1}
                                            selected
                                            disabled
                                            hidden
                                        >
                                        {"Select Provider"}
                                        </option>
                                        {
                                        prescribersList?.length > 0 && 
                                        prescribersList.map((prescriberItem)=>{
                                            return (
                                            <option key={prescriberItem?.userId} id = {prescriberItem?.userId} value={prescriberItem?.userId}>
                                                {"".concat(prescriberItem?.name[0]?.prefix || ""," ",prescriberItem?.name[0]?.firstName || ""," ",prescriberItem?.name[0]?.lastName)}
                                            </option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="d-flex flex-row justify-content-end">
                                    <button
                                    // id="credit-card-form"
                                    onClick={handlePayment}
                                    type="btn"
                                    disabled={provider === undefined ? true: amount <= 0 ? true: chequeNumber.length <= 0 || isLoading}
                                    className="btn d9e8ec--button  ">
                                    {
                                        !isLoading ? "Submit Payment" :
                                            <CircularProgress
                                                size={24}
                                                sx={{color: "#ec609b"}}
                                            />
                                    }
                                    </button>
                                    <button
                                        onClick={() => handleReceipt(userId)}
                                        disabled={!isPaymentSent === true ? true: isPrinting}
                                        className="btn blue_task_button  ">
                                        {
                                        !isPrinting ? "Print Receipt" :
                                            <CircularProgress
                                                size={24}
                                                sx={{color: "#ec609b"}}
                                            />
                                    }
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
            </div>

            <CustomizedSnackbars
                    message={"Payment completed successfully."}
                    severity="success"
                    open={isSent}
                    handleClose={handleClose}
                />
            <CustomizedSnackbars
                message={error?.message}
                severity="error"
                open={error?.show}
                handleClose={handleClose}
            />
        </>
        

    )

}

export default PaymentChequeForm;