import React, { useEffect } from 'react'
import { useState } from 'react'
import { ChevronLeft, ChevronRight, Today } from '@mui/icons-material';
import { addDays, dateTimeHelper, decreaseDays } from '../../utils/dateTimeHelper';
import Greeting from '../UI/General/Greeting'
import { Skeleton, Table, TableBody, TableContainer, TableHead, ThemeProvider } from '@mui/material';
import { StyledTableCell, StyledTableRow, TabPanel, theme } from '../UI/Reusable/PnComponents';
import { useAuth } from '../hooks/useAuth';
import InPatientAppointmentService from '../Services/InPatientAppointment.service';
import { getPatientConcatenatedNameFromNameObject, getProviderConcatenatedNameFromNameObject } from '../../utils/helpers/HelperFunctions';
import { dateTimeFormats } from '../../utils/helpers/dateTimeConstants';
import { useNavigate } from "react-router-dom";
import { RefreshDataComponent } from '../UI/General/RefreshDataComponent';
import { useInterval } from '../hooks/useInterval';
import InPatientService from '../Services/InPatient.service';
import sendHttpRequest from '../hooks/sendHttpRequest';
import moment from 'moment';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InPatientExamRoomService from '../Services/InPatientExamRoomService';
import { useSelector } from 'react-redux';


const InPatientDashboard = () => {
    const INIT_DATE = dateTimeHelper(moment(), "YYYY-MM-DD");
    const { user } = useAuth();
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(INIT_DATE);

    const [appointments, setAppoinments] = useState([]);
    const [areAppointmentsLoading, setAreAppointmentsLoading] = useState(false);

    const [examRooms, setExamRooms] = useState([]);
    const [areExamRoomsLoading, setAreExamRoomsLoading] = useState(false);

    const [tabPanelValue, setTabPanelValue] = useState(0);
    const [tabsList, setTabsList] = useState([
        "Appointments",
        "Examination Rooms Status",
    ]);

    const TabsMap = function (tabTitle) {
        const tabsMap = new Map();

        tabsList.forEach((element, index) => {
            tabsMap.set(element, index);
            tabsMap.set(index, element);
        });
        return tabsMap.get(tabTitle);
    };

    const handleChange = (event, newValue) => {

        setTabPanelValue(newValue);
        if (newValue == 1) {
            fetchFacilityRooms(user?.facility?.facilityId);
        }
    };


    function getAppointments(day, tenantId, userId, noLoader = false) {
        if (!noLoader) {
            setAreAppointmentsLoading(true);
        }
        InPatientAppointmentService.getAppointments(day, tenantId, userId)
            .then((data) => {

                const transformedArray = data?.map((appointment) => {
                    const startTime = dateTimeHelper(appointment?.appointmentSlot?.startDateTime, dateTimeFormats?.time12HoursFormat);
                    const patient = appointment?.prescriptionReference?.patient;
                    const patientName = getPatientConcatenatedNameFromNameObject(patient?.name?.at(0));
                    const dateOfBirth = dateTimeHelper(patient?.dateOfBirth, dateTimeFormats?.dateOfBirth);
                    const biologicalSex = patient?.gender;
                    const reasonForVisit = appointment?.chiefComplaint;
                    const provider = getProviderConcatenatedNameFromNameObject(appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name?.at(0));
                    const examRoom = appointment?.patientVisit?.facilityRoom?.name;
                    const examRoomId = appointment?.patientVisit?.facilityRoom?.facilityRoomId;
                    const visitStatus = appointment?.patientVisit?.patientStatus?.displayValue;
                    const visitStatusValue = appointment?.status !== "cancelled" ? appointment?.patientVisit?.patientStatus?.internalValue : 'canceled'
                    const startDate = dateTimeHelper(appointment?.appointmentSlot?.startDateTime, "YYYY-MM-DD");
                    const noShow = appointment?.markedNoShow;
                    return {
                        id: appointment?.id,
                        patientId: patient?.userId,
                        startTime,
                        patientName,
                        dateOfBirth,
                        biologicalSex,
                        reasonForVisit,
                        provider,
                        examRoom,
                        visitStatus,
                        facilityRoomId: examRoomId,
                        visitStatusValue: visitStatusValue,
                        startDate,
                        noShow

                    }
                });
                setAppoinments(transformedArray);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                if (!noLoader) {
                    setAreAppointmentsLoading(false);
                }
            });
    }

    function previousDayAppointmentsHandler() {
        setCurrentDate(decreaseDays(currentDate, 1));
        getAppointments(decreaseDays(currentDate, 1), user?.tenantId, user?.userId);
    }

    function todayAppointmentsHandler() {
        setCurrentDate(dateTimeHelper(new Date(), "YYYY-MM-DD"));
        getAppointments(dateTimeHelper(new Date(), "YYYY-MM-DD"), user?.tenantId, user?.userId);
    }

    function nextDayAppointmentsHandler() {
        setCurrentDate(addDays(currentDate, 1))
        getAppointments(addDays(currentDate, 1), user?.tenantId, user?.userId);
    }

    function navigateToPatientProfile(patientId, appointmentId, examRoom) {
        navigate(`/all-patients/${patientId}`, {
            state: {
                referer: "in-patient-dashboard",
                appointmentId: appointmentId,
                examRoom: examRoom
            }
        });
    }

    async function fetchFacilityRooms(facilityId, noLoader = false) {
        if (!noLoader) {
            setAreExamRoomsLoading(true);
        }
        const data = await InPatientExamRoomService.getRoomsByFacilityId(facilityId);
        const transformedArray = data?.map((examRoom) => {
            const roomName = examRoom?.name;
            const facilityRoomId = examRoom?.facilityRoomId;
            const occupiedSince = examRoom?.occupiedSince;
            // const disabled = examRoom?.patientVisit === null ? true : false;
            const type = examRoom?.type;
            const examRoomStatus = examRoom?.status;
            let patientName = '';
            let medicalAssistantName = '';
            let providerName = '';
            let appointment = examRoom?.currentAppointment;
            if (examRoomStatus === "Available" && examRoom?.patientVisit !== null) {

            }
            else {
                patientName = getPatientConcatenatedNameFromNameObject(appointment?.prescriptionReference?.patient?.name?.at(0));
                medicalAssistantName = getProviderConcatenatedNameFromNameObject(appointment?.patientVisit?.medicalAssistant?.name?.at(0));
                providerName = getProviderConcatenatedNameFromNameObject(appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name?.at(0));

            }
            return ({
                roomName, facilityRoomId, occupiedSince, examRoomStatus, type, patientName, medicalAssistantName, providerName
            });
        });
        setExamRooms(transformedArray);
        if (!noLoader) {
            setAreExamRoomsLoading(false);
        }
    }

    useInterval(() => {
        getAppointments(currentDate, user?.tenantId, user?.userId, true);
        fetchFacilityRooms(user?.facility?.facilityId, true);
    }, 15000)

    useEffect(() => {
        getAppointments(currentDate, user?.tenantId, user?.userId);
        fetchFacilityRooms(user?.facility?.facilityId);
    }, []);

    return (
        <>
            <div className="container-fluid white_background">
                <Greeting
                    className="p-1-8 px-3 pt-3"
                />
            </div>
            <div className='container-fluid'>
                <ThemeProvider theme={theme}>
                    <div className='row'>
                        <div className='col-sm-10'>
                            <Tabs
                                indicatorColor="secondary"
                                value={tabPanelValue}
                                variant="scrollable"
                                scrollButtons="auto"
                                onChange={handleChange}
                                style={{
                                    backgroundColor: "white",
                                }}>

                                {tabsList.map((item, key) => {
                                    return (
                                        <Tab
                                            key={key}
                                            label={TabsMap(key)}
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                // font:'normal normal 600 14px/47px Montserrat',
                                                opacity: "1",
                                                textTransform: "initial",
                                                // color: `${key == tabsList.length - 1 ? "#dddddd" : "#336383"
                                                //   }`,
                                            }}
                                        // disabled={key == tabsList.length - 1 ? true : false}
                                        />
                                    );
                                })}



                            </Tabs>

                        </div>
                    </div>
                    <div className='row'>
                        <TabPanel
                            value={tabPanelValue}
                            index={TabsMap("Appointments")}
                            className="bg--background pb-5"
                        >
                            <div className="row">
                                <div className='col-sm-10'>
                                    <div className="d-flex justify-content-between py-3">
                                        <div className="align-self-center">
                                            <div className='d-flex justify-content- gap-2'>

                                                <div
                                                    style={{ height: "32px" }}
                                                    className={`bg--background d-flex px-1 cursor--pointer  br-10 border--default border align-self-center text-center my-auto `}
                                                    onClick={() => { todayAppointmentsHandler() }}
                                                >
                                                    <div>
                                                        <Today
                                                            style={{ color: "#336383", width: "20px", height: "20px" }}
                                                            className="cursor-pointer my-auto mt-1"
                                                        />
                                                    </div>
                                                    <div className='align-self-center font-14 fw-sb px-2'>
                                                        Today
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ width: "32px", height: "32px" }}
                                                    className={`bg--background  br-10 border--default border align-self-center text-center my-auto `}
                                                    onClick={() => { previousDayAppointmentsHandler() }}
                                                >
                                                    <ChevronLeft
                                                        style={{ color: "#EC609B", width: "20px", height: "20px" }}
                                                        className="cursor-pointer my-auto mt-1"
                                                    />
                                                </div>


                                                <div
                                                    style={{ width: "32px", height: "32px" }}
                                                    className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                                                    onClick={() => { nextDayAppointmentsHandler() }}
                                                >
                                                    <ChevronRight
                                                        style={{ color: "#EC609B", width: "20px", height: "20px" }}
                                                        className="cursor-pointer my-auto mt-1"
                                                    />
                                                </div>

                                                <div className='align-self-center'>
                                                    <span className="font-18 fw-sb m-0">{moment(currentDate).format("dddd, MMM D, YYYY")}</span>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='align-self-center me-1'>
                                            <RefreshDataComponent
                                                variant="without-bar"
                                                callback={() => getAppointments(currentDate, user?.tenantId, user?.userId)}
                                                isComponentDataLoading={areAppointmentsLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-10'>
                                    <AppointmentsTable
                                        columnHeadersList={["Appointment Time", "Patient", "Date of Birth", "Biological Sex", "Reason for Visit", "Provider", "Exam Room", "Visit Status"]}
                                        appointmentTableRows={appointments}
                                        areAppointmentsLoading={areAppointmentsLoading}
                                        navigateToPatientProfile={navigateToPatientProfile}
                                        currentDate={currentDate}
                                        INIT_DATE={INIT_DATE}
                                        fetchAppointments={() => { getAppointments(currentDate, user?.tenantId, user?.userId) }}
                                    />


                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel
                            value={tabPanelValue}
                            index={TabsMap("Examination Rooms Status")}
                            className="bg--background pb-5"
                        >
                            <div className="row">
                                <div className='col-sm-10'>
                                    <div className="d-flex justify-content-end py-3">
                                        {/* <div className="align-self-center">
                                            <div className='d-flex justify-content- gap-2'>

                                                <div
                                                    style={{ height: "32px" }}
                                                    className={`bg--background d-flex px-1 cursor--pointer  br-10 border--default border align-self-center text-center my-auto `}
                                                    onClick={() => { todayAppointmentsHandler() }}
                                                >
                                                    <div>
                                                        <Today
                                                            style={{ color: "#336383", width: "20px", height: "20px" }}
                                                            className="cursor-pointer my-auto mt-1"
                                                        />
                                                    </div>
                                                    <div className='align-self-center font-14 fw-sb px-2'>
                                                        Today
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ width: "32px", height: "32px" }}
                                                    className={`bg--background  br-10 border--default border align-self-center text-center my-auto `}
                                                    onClick={() => { previousDayAppointmentsHandler() }}
                                                >
                                                    <ChevronLeft
                                                        style={{ color: "#EC609B", width: "20px", height: "20px" }}
                                                        className="cursor-pointer my-auto mt-1"
                                                    />
                                                </div>


                                                <div
                                                    style={{ width: "32px", height: "32px" }}
                                                    className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                                                    onClick={() => { nextDayAppointmentsHandler() }}
                                                >
                                                    <ChevronRight
                                                        style={{ color: "#EC609B", width: "20px", height: "20px" }}
                                                        className="cursor-pointer my-auto mt-1"
                                                    />
                                                </div>

                                                <div className='align-self-center'>
                                                    <span className="font-18 fw-sb m-0">{moment(currentDate).format("dddd, MMM D, YYYY")}</span>
                                                </div>

                                            </div>

                                        </div> */}
                                        <div className='align-self-center me-1'>
                                            <RefreshDataComponent
                                                variant="without-bar"
                                                callback={() => fetchFacilityRooms(user?.facility?.facilityId)}
                                                isComponentDataLoading={areExamRoomsLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col-sm-10'>
                                    <ExaminationRoomTable
                                        columnHeadersList={["Exam Room", "Patient", "Provider", "Medical Assistant", "Occupied Since", "Status"]}
                                        examinationRoomTableRows={examRooms}
                                        areExaminationRoomsLoading={areExamRoomsLoading}
                                        fetchExaminationRooms={fetchFacilityRooms}
                                        INIT_DATE={INIT_DATE}
                                    />


                                </div>
                            </div>
                        </TabPanel>
                    </div>

                </ThemeProvider>

            </div>
        </>
    )
}

export default InPatientDashboard;

function ExaminationRoomTable({
    columnHeadersList,
    examinationRoomTableRows,
    areExaminationRoomsLoading,
    fetchExaminationRooms,
    INIT_DATE
}) {
    const { user } = useAuth();
    // const [examinationRooms, setExaminationRooms] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({});

    async function handleExamRoom(event) {
        const facilityRoomId = event.target.dataset.facility;
        const status = event.target.value;
        setSelectedStatus({ ...selectedStatus, [facilityRoomId]: status });
        const response = await sendHttpRequest({
            method: "PUT",
            url: `/facilityRooms/${facilityRoomId}/status`,
            data: { status }
        });
        if (!response.error) {
            // delete selectedRoom[appointmentId];
            await fetchExaminationRooms(user?.facility?.facilityId);
        }
    }

    return (
        <TableContainer sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}>
            <Table>
                <TableHead
                    sx={{
                        borderTopColor: "#FAFCFC",
                        borderRightColor: "#FAFCFC",
                        borderLeftColor: "#FAFCFC",
                        borderBottom: "1px solid #D9E8EC",
                    }}
                >
                    <StyledTableRow>
                        {columnHeadersList?.map((item, idx) => (
                            <StyledTableCell key={item?.concat(idx)}><span className="font-12 text--secondary">{item}</span></StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {areExaminationRoomsLoading ? <StyledTableRow
                        key={"examinationRooms-loading"}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <StyledTableCell
                        // sx={{ color: "#336383", fontWeight: "600" }}
                        >
                            <Skeleton />
                        </StyledTableCell>
                        <StyledTableCell
                        // sx={{ width: "100", color: "#336383" }}
                        >
                            <Skeleton />
                        </StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>

                        <StyledTableCell>
                            <Skeleton />
                        </StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>


                    </StyledTableRow> :
                        examinationRoomTableRows?.map((item, idx) => {
                            // const roomObject = Object.keys(selectedRoom).length !== 0 ? ({ "examRoomId": selectedRoom[item?.id]?.roomId, "examRoom": selectedRoom[item?.id]?.roomName }) : ({ "examRoom": item?.examRoom, "examRoomId": item?.facilityRoomId })

                            return (
                                <StyledTableRow
                                    key={item?.facilityRoomId}
                                    // onClick={() => { navigateToPatientProfile(item?.patientId, item?.id, roomObject) }}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        // "&:hover": { cursor: 'pointer' }
                                    }}
                                >
                                    <StyledTableCell

                                    // sx={{ color: "#336383", fontWeight: "600" }}
                                    >
                                        <div className="p-0 m-0 font-12 d-flex flex-column">
                                            {item?.roomName}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                    // sx={{ width: "100", color: "#336383" }}
                                    >
                                        {item?.patientName?.length === 0 ? <span className='font-12 text--secondary fw-sb text-center'>&mdash;</span> : item?.patientName}
                                    </StyledTableCell>

                                    <StyledTableCell>{item?.providerName?.length === 0 ? <span className='font-12 text--secondary text-center fw-sb'>&mdash;</span> : item?.providerName}</StyledTableCell>

                                    <StyledTableCell>
                                        {item?.medicalAssistantName?.length === 0 ? <span className='font-12 text--secondary fw-sb text-center'>&mdash;</span> : item?.medicalAssistantName}
                                    </StyledTableCell>

                                    <StyledTableCell>{item?.occupiedSince !== null ? dateTimeHelper(item?.occupiedSince, "LT") : <span className='font-12 text--secondary fw-sb text-center'>&mdash;</span>}</StyledTableCell>

                                    {/* {console.log(item?.startDate, INIT_DATE, item?.startDate == INIT_DATE)} */}


                                    <StyledTableCell onClick={e => e.stopPropagation()}>
                                        <div className="form-group">
                                            <select
                                                key={item?.facilityRoomId}
                                                className={`form-select font-13 shadow-none`}
                                                style={{
                                                    border: "1px solid #D9E8EC",
                                                    height: "36px",
                                                    width: "150px",
                                                    borderRadius: "10px",
                                                    // background: "#FAFCFC",
                                                }}
                                                required
                                                // disabled={item?.disabled}
                                                data-facility={item?.facilityRoomId ?? "Hi"}
                                                // disabled={item?.startDate !== INIT_DATE}
                                                onChange={handleExamRoom}
                                                value={selectedStatus[item?.facilityRoomId] !== undefined ? selectedStatus[item?.facilityRoomId] : item?.examRoomStatus}
                                            >
                                                {/* {console.log("selectedStatus", selectedStatus)} */}
                                                {/* <option value="">Select Status</option> */}
                                                <option value="Reserved">Occupied</option>
                                                <option value="Available">Available</option>
                                                <option value="Unavailable">Unavailable</option>

                                            </select>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    {/* </>} */}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function AppointmentsTable({
    columnHeadersList,
    appointmentTableRows,
    areAppointmentsLoading,
    navigateToPatientProfile,
    currentDate,
    fetchAppointments,
    INIT_DATE
}) {
    const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);
    const { user } = useAuth();
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});
    const [areRoomsLoading, setAreRoomsLoading] = useState(false);

    async function getRooms() {
        // console.log("Calling getRooms()...")
        setAreRoomsLoading(true);
        const data = await InPatientService.getFacilityRooms(user?.userId);
        // console.log(data);
        if (data?.length === 0) {
            // setIsCheckInDisabled(true);
        }
        setRooms(data);
        // setSelectedRoom(data?.at(0)?.facilityRoomId);
        setAreRoomsLoading(false);
    }


    async function handleExamRoom(event, id) {
        const appointmentId = event.target.dataset.appid;
        const roomId = event.target.value;
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedRoomData = selectedOption.getAttribute('data-room');

        // console.log('Selected room data:', selectedRoomData);
        // return;
        setSelectedRoom({ ...selectedRoom, [appointmentId]: { roomId, roomName: selectedRoomData } });
        const response = await sendHttpRequest({
            method: "POST",
            url: `/appointment/${appointmentId}/checkin`,
            data: {
                "roomId": roomId
            }
        });
        if (!response.error) {
            await getRooms();
        }
    }

    async function handleVisitStatus(event, id) {
        const appointmentId = event.target.dataset.appid;
        const status = event.target.value;
        setSelectedStatus({ ...selectedStatus, [appointmentId]: status });
        const response = await sendHttpRequest({
            method: "POST",
            url: `/appointment/${appointmentId}/${status}`,
        });
        if (!response.error) {
            delete selectedRoom[appointmentId];
            await fetchAppointments();
        }
    }


    useEffect(() => {
        getRooms();
    }, [appointmentTableRows]);

    return (
        <TableContainer sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}>
            <Table>
                <TableHead
                    sx={{
                        borderTopColor: "#FAFCFC",
                        borderRightColor: "#FAFCFC",
                        borderLeftColor: "#FAFCFC",
                        borderBottom: "1px solid #D9E8EC",
                    }}
                >
                    <StyledTableRow>
                        {columnHeadersList?.map((item, idx) => (
                            <StyledTableCell key={item?.concat(idx)}><span className="font-12 text--secondary">{item}</span></StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {areAppointmentsLoading ? <StyledTableRow
                        key={"appointments-loading"}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <StyledTableCell
                        // sx={{ color: "#336383", fontWeight: "600" }}
                        >
                            <Skeleton />
                        </StyledTableCell>
                        <StyledTableCell
                        // sx={{ width: "100", color: "#336383" }}
                        >
                            <Skeleton />
                        </StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>

                        <StyledTableCell>
                            <Skeleton />
                        </StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>

                        <StyledTableCell><Skeleton /></StyledTableCell>
                    </StyledTableRow> :
                        appointmentTableRows?.map((item, idx) => {
                            const roomObject = Object.keys(selectedRoom).length !== 0 ? ({ "examRoomId": selectedRoom[item?.id]?.roomId, "examRoom": selectedRoom[item?.id]?.roomName }) : ({ "examRoom": item?.examRoom, "examRoomId": item?.facilityRoomId })
                            return (
                                <StyledTableRow
                                    key={item?.patientId + idx}
                                    onClick={() => { navigateToPatientProfile(item?.patientId, item?.id, roomObject) }}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        "&:hover": { cursor: 'pointer' }
                                    }}
                                >
                                    <StyledTableCell

                                    // sx={{ color: "#336383", fontWeight: "600" }}
                                    >
                                        <div className="p-0 m-0 font-12 d-flex flex-column">
                                            {item?.startTime}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                    // sx={{ width: "100", color: "#336383" }}
                                    >
                                        {item?.patientName}
                                    </StyledTableCell>

                                    <StyledTableCell>{item?.dateOfBirth}</StyledTableCell>

                                    <StyledTableCell>
                                        {item?.biologicalSex}
                                    </StyledTableCell>

                                    <StyledTableCell>{item?.reasonForVisit}</StyledTableCell>

                                    <StyledTableCell>{item?.provider}</StyledTableCell>
                                    {/* {console.log(item?.startDate, INIT_DATE, item?.startDate == INIT_DATE)} */}
                                    <StyledTableCell onClick={e => e.stopPropagation()} >
                                        {areRoomsLoading ? <Skeleton /> : 
                                        <div className="form-group">
                                            {
                                                userPermissions?.arp === true ? 
                                                <select
                                                className={`form-select font-13 shadow-none`}
                                                aria-label="Provider select"
                                                style={{
                                                    border: "1px solid #D9E8EC",
                                                    height: "36px",
                                                    width: "150px",
                                                    borderRadius: "10px",
                                                    // background: "#FAFCFC",
                                                }}
                                                required
                                                data-appid={item?.id}
                                                disabled={item?.startDate !== INIT_DATE}
                                                onChange={handleExamRoom}
                                                value={selectedRoom[item?.id] !== undefined && selectedRoom[item?.id]?.roomId !== undefined ? selectedRoom[item?.id]?.roomId : item?.facilityRoomId !== null && item?.facilityRoomId !== undefined ? item?.facilityRoomId : ''}
                                            >
                                                {/* {console.log(selectedRoom[item?.id]?.roomId)} */}
                                                {/* {console.log(selectedRoom[item?.id] !== undefined ? selectedRoom[item?.id] : item?.facilityRoomId !== null && item?.facilityRoomId !== undefined ? item?.facilityRoomId : '')}
                                                {console.log("Item: ", item?.facilityRoomId)}
                                                {console.log("Selected: ", selectedRoom[item?.id])} */}
                                                {/* {console.log(selectedRoom[item?.id])} */}
                                                {/* {selectedRoom[item?.id] !== undefined && selectedRoom[item?.id]?.roomId !== undefined && console.log("Room Name", selectedRoom[item?.id]?.roomName)} */}
                                                <option key={"deselect-room"} data-room="0" value={0}></option>
                                                {selectedRoom[item?.id]?.roomId === undefined && item?.facilityRoomId !== null && item?.facilityRoomId !== undefined ? <option value={item?.facilityRoomId}>{item?.examRoom}</option> : selectedRoom[item?.id]?.roomId === undefined && item?.facilityRoomId === undefined ? <option value="">Select Room</option> : <option value={selectedRoom[item?.id]?.roomId}>{selectedRoom[item?.id]?.roomName}</option>}
                                                {rooms?.map((room, idx) => {
                                                    return <option key={idx} data-room={room?.name} value={room?.facilityRoomId}>{room?.name}</option>
                                                })}
                                            </select>:
                                                <span className='font-12 text--terciary'>{item?.examRoom !== undefined ? item?.examRoom : "-"}</span>
                                            }
                                        </div>}
                                    </StyledTableCell>

                                    <StyledTableCell onClick={e => e.stopPropagation()}>
                                        <div className="form-group">
                                            <select
                                                key={idx}
                                                className={`form-select font-13 shadow-none`}
                                                aria-label="Provider select"
                                                style={{
                                                    border: "1px solid #D9E8EC",
                                                    height: "36px",
                                                    width: "150px",
                                                    borderRadius: "10px",
                                                    // background: "#FAFCFC",
                                                }}
                                                required
                                                data-appid={item?.id}

                                                disabled={item?.startDate !== INIT_DATE}
                                                onChange={handleVisitStatus}
                                                value={selectedStatus[item?.id] !== undefined ? selectedStatus[item?.id] : item?.visitStatusValue !== undefined ? item?.visitStatusValue : item?.noShow === true ? 'no-show' : 'scheduled'}
                                            >
                                                {/* {console.log(item)} */}
                                                {/* {console.log("selectedStatus", selectedStatus)} */}
                                                <option value="">Select Status</option>
                                                <option value="scheduled">Scheduled</option>
                                                <option value="checkin">Check-in</option>
                                                <option value="ma-reviewed">MA Review</option>
                                                <option value="provider-ready">Provider Ready</option>
                                                <option value="in-consultation">In Consultation</option>
                                                <option value="consultation-completed">Consultation Complete</option>
                                                <option value="departed">Visit Complete</option>
                                                <option value="no-show">No-show</option>
                                                <option value="canceled">Canceled</option>
                                            </select>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    {/* </>} */}
                </TableBody>
            </Table>
        </TableContainer>
    );
}