import { useState } from "react";
import PatientInformation from "../patient/PatientInformation";
import EncounterTabs from "../UI/Tabs/NewTabs";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { WarningIcon } from "../UI/Reusable/WarningIcon";

const PatientConsultPage = () => {
  const location = useLocation();
  const [isNotFound, setIsNotFound] = useState(false);
  const [isPatientNotFound, setIsPatientNotFound] = useState(false);


  const [appointmentStatusLoading, setAppointmentStatusLoading] = useState(false);

  return (
    <>
      <Backdrop
        sx={{ color: '#EC609B', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={appointmentStatusLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Modal
        show={false}
        size="lg"
        contentClassName={`modal-border modal-shadow bg--background`}
        centered
      >
        <AgoraLocalVideoConfigurationPage />
      </Modal> */}
      {isNotFound === false && isPatientNotFound === false ? <>
        <PatientInformation
          setAppointmentStatusLoading={setAppointmentStatusLoading}
          setIsPatientNotFound={setIsPatientNotFound}
          isPatientNotFound={isPatientNotFound}
        />


        <EncounterTabs setIsNotFound={setIsNotFound} prescriptionReferenceId={location?.state?.prescriptionReferenceId} />
      </> : <EncounterNotFoundMessage />}
    </>
  );
}

export default PatientConsultPage;

function EncounterNotFoundMessage() {
  return <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "75vh",
    }}
  >
    <div className="text-center">
      <h2>
        <WarningIcon
          style={{ fontSize: "3rem", fill: "#f8a02e", verticalAlign: "bottom" }}
        />{" "}
        Encounter not found
      </h2>
    </div>
  </div>
}