import React, { useEffect, useState, useContext, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import sendHttpRequest from "../hooks/sendHttpRequest";
import {
  setConsulationInProgress,
  setDirtyBitInStore,
  setPatientEncounterData,
  setUpdateEncounterData,
  setUserPermissionsData,
} from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";
// import placeholder from "./../../assets/images/placeholder.jpg"
import { navigateOnDirtyBit, validateNullUndefined } from "../../utils/helpers/HelperFunctions";
import classes from "./Dropdown.module.css";
import { Backdrop, CircularProgress } from "@mui/material";

import accessToSettingsRoles from "../../utils/medicalAllowedCredentials";

import CheckCircleRoundedIcon from '@mui/icons-material/CheckRounded';
import useOnClickOutside from "../hooks/useOnClickOutside";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";


const CustomDropdown = (props) => {
  const { user, signout, updateUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const videoCallContext = useContext(VideoCallContext);
  const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);


  const toggleOpen = () => setIsOpen(!isOpen);
  // console.log(props)
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen(p => false));
  const [chevronIcon, setChevronIcon] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);


  const logout = async () => {
    setIsLoading(true);
    if (validateNullUndefined(videoCallContext.getVideoTrack()) && validateNullUndefined(videoCallContext.getAudioTrack())) {
      dispatch(setConsulationInProgress(false));
      videoCallContext.endSession().then(() => {
        console.log("Video call ended successfully");
      })
    } //AHMED COMMENTED OUT 
    const httpResponse = await sendHttpRequest({
      url: "/auth/logout",
      method: "POST",
    });
    if (!httpResponse.error) {
      dispatch(setDirtyBitInStore(false));
      dispatch(setUpdateEncounterData(''));
      dispatch(setPatientEncounterData(''));
      dispatch(setUserPermissionsData({}))
      signout();
      // localStorage.clear();
      // navigate("/", { replace: true });
    } else {
      // console.log(httpResponse);
    }
    setIsLoading(false);
  };

  function logMeOut() {
    logout();
  }

  const cleanUp = () => {
    setChevronIcon(true);
  };
  const updateUserPermissions = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/users/${user.userId}/permissions`,
      method: 'GET'
    })
    if (!httpResponse.error) {
      console.log("Permission data: ", httpResponse.data);
      dispatch(setUserPermissionsData(httpResponse.data))
    }
  }

  const switchNewTenant = async (switchTenant) => {
    await updateUserPermissions();
    await tenantSwitch(switchTenant);
  }

  const tenantSwitch = async (switchTenant) => {
    // await updateUserPermissions();
    if (switchTenant?.tenantId !== user?.tenantId) {
      let newUser = user;
      props?.providerTenantsList.find((provider) => {
        if (provider?.tenant?.tenantId == switchTenant?.tenantId) {
          newUser["tenantId"] = switchTenant?.tenantId;
          newUser["tenant"] = switchTenant;
          newUser["spi"] = provider?.spi;
          newUser["facility"] = provider?.facility;
          updateUser(newUser);
        }
      });
      navigate(0);
    }
  };

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);



  const menuClass = `dropdown-menu notifications-list ${classes["dropdown__menue__items"]
    }  ${isOpen ? "show" : ""}`;



  return (
    <>
      <div
        ref={dropdownRef}
        className="dropdown dropleft cursor--pointer"
        onClick={() => toggleOpen()}
        style={{ minWidth: 85 }}
      >
        <div>
          <div className="d-flex">
            <div>
              <span
                className="b2 fw-sb d-block "
                style={{ marginBottom: "-4px" }}
              >
                {user?.prefix === undefined ? "" : user?.prefix}{" "}
                {user?.lastName === undefined ? "" : user?.lastName}
              </span>
              <span className="b3 d-block">
                {user?.tenant?.businessName}
              </span>
            </div>
            <div className="align-self-center ps-2">
              <span style={{ color: "#EC609B" }}>
                {isOpen === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </span>
            </div>
          </div>
          {/* <> */}
        </div>
        <div
          className={menuClass}
          style={{ borderTop: "2px solid #EC609B", opacity: 1, zIndex: 1200 }}
        >
          {/* <Dropdown.Item eventKey="1" onClick={() => navigate(`/my-account/${prescriberInfo?.prescriberId}`)}>My Account</Dropdown.Item> */}
          <Dropdown.Item
            eventKey="1"
            onClick={() =>
              navigateOnDirtyBit(
                dirtyBitStatus,
                navigate,
                "/account",
                dispatch,
                setDirtyBitInStore
              )
            }
          >
            My Account
          </Dropdown.Item>
          {accessToSettingsRoles?.includes(user?.role) ? (
            <Dropdown.Item
              eventKey="2"
              onClick={() =>
                navigateOnDirtyBit(
                  dirtyBitStatus,
                  navigate,
                  "/settings",
                  dispatch,
                  setDirtyBitInStore
                )
              }
              hidden={userPermissions?.vtd === false && userPermissions?.uns === false && userPermissions?.aps === false && userPermissions?.usd === false}
            >
              Settings
            </Dropdown.Item>
          ) : null}

          <Dropdown.Item eventKey="3" className="" onClick={logMeOut}>
            Logout
          </Dropdown.Item>
          {props?.providerTenantsList.length > 1 && (<div className="" style={{ borderTop: '1px solid #d9e8ec' }}></div>)}
          {props?.providerTenantsList.length > 1 && (
            props?.providerTenantsList.map((tenant, index) => {
              return (
                <Dropdown.Item key={index} eventKey={index + 3} className={tenant?.tenant?.tenantId === user?.tenantId ? `ps-0 fw-sb font-13` : `font-13`} onClick={(e) => switchNewTenant(tenant?.tenant)}>
                  {tenant?.tenant?.tenantId === user?.tenantId && (<CheckCircleRoundedIcon style={{ color: "#ec609b", height: "16px" }} />)}
                  {tenant?.tenant?.businessName}
                </Dropdown.Item>
              )
            })
          )}
        </div>
      </div>
      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default CustomDropdown;
