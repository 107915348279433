import { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Divider, Skeleton } from "@mui/material";

import sendHttpRequest from "../../hooks/sendHttpRequest";
import { ERROR_MESSAGES, FIELD_NAMES, MIN_MAX_VALUES } from "../../validations/validationschemas";

import classes from "./VitalsTable.module.css";
import InputWithGroup from "./InputWithGroup";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useParams } from "react-router-dom";
import CustomizedSnackbar from "../../UI/General/CustomizedSnackbars";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import { useSelector } from "react-redux";



const PatientProfileVitals = (props) => {
    const isDesktop = useMediaQuery("(min-width: 1060px)");
    const { pId } = useParams();
    const userPermissions = useSelector(
        (state) => state.UserPermissionData.permissionsMap
      );

    const [vitalValidationErrors, setVitalValidationErrors] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [isVitalHistoryLoading, setIsVitalHistoryLoading] = useState(false);
    const [showApiStatusMessage, setShowApiStatusMessage] = useState({
        success: false,
        error: false,
    });
    const [vitalHistory, setVitalHistory] = useState([]);
    const [vitalsInput, setVitalsInput] = useState({});

    const field_Name = FIELD_NAMES.vitals;
    const VITAL_VALIDATION = MIN_MAX_VALUES.vitals;
    const validationErrorMsgs = ERROR_MESSAGES.vitals;


    const handleVitalsInput = (event) => {
        //destructured the required properties of event
        const { target: { name, value } } = event

        handleVitalsValidation(value, name);
        //if name is weight or height then check for calculating bmi
        if (name === 'weight' || name === 'height') {
            handleBMI(name, value, vitalsInput, calcBMI, setVitalsInput);
        }

        setVitalsInput((prev) => ({ ...prev, [name]: value }));
    }

    const fetchVitalsHistory = async (patientId) => {
        setIsVitalHistoryLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/patient/${patientId}/vitals`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setVitalHistory(httpResponse.data);
            // setVitalHistory(httpResponse.data);
        } else {
            console.log(httpResponse);
            console.log("VitalsL ", httpResponse.error);
        }
        setIsVitalHistoryLoading(false);
    };

    const addVitals = async (patientId) => {
        setShowBackdrop(true);
        const httpResponse = await sendHttpRequest({
            url: `/patients/${patientId}/vitals`,
            method: 'POST',
            data: vitalsInput
        });

        if (!httpResponse.error) {
            await fetchVitalsHistory(patientId);
            // setShowApiStatusMessage((prevStatus) => ({ ...prevStatus, success: true }));
            setVitalsInput(p => ({}));
        }
        else {
            setShowApiStatusMessage((prevStatus) => ({ ...prevStatus, error: true }));
        }
        setShowBackdrop(false);
    }

    const handleVitalsValidation = (fieldValue, fieldName) => {
        let value = fieldValue;
        if ((value < VITAL_VALIDATION[fieldName]?.min || value > VITAL_VALIDATION[fieldName].max) && value.length > 0) {
            let fieldExist = vitalValidationErrors.filter((item) => { return item.fieldName === field_Name[fieldName] });
            if (fieldExist.length === 0) {
                setVitalValidationErrors([...vitalValidationErrors, { "fieldName": field_Name[fieldName] }])
            }
        } else {
            let temp = vitalValidationErrors.filter((item) => { return item.fieldName !== field_Name[fieldName] });
            setVitalValidationErrors(temp);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowApiStatusMessage((prev) => ({ success: false, error: false }));
    };



    const handleVitalsUpdate = async () => {
        await addVitals(pId);
        fetchVitalsHistory(pId);
    }

    const calcBMI = (weight, height) => {
        const heightSqr = Math.pow(Number(height), 2);
        return ((Number(weight) / Number(heightSqr)) * 703).toFixed(1);
    };

    useEffect(() => {
        fetchVitalsHistory(pId);

    }, [pId]);

    return (
        <>
            {/* {console.log("vitalData", vitalData.length)}
      {console.log("reached")} */}
            <div className="container-fluid" hidden = {userPermissions?.apv === false ? true:false}>
                <div className="row mx-2 py-3">
                    <div className="col-12">
                        <h3 className="font-18 fw-sb m-0">Vitals</h3>
                        <p className="font-14 text--secondary m-0">

                        </p>
                    </div>
                </div>
                <div className=" row br-10 border mx-2">
                    <div className={`col-12 d-flex ${isDesktop ? 'flex-row' : 'flex-column m-0 p-0 justify-content-center align-items-center'}`}>
                        <div>
                            <InputWithGroup
                                label="Temp"
                                handleChange={handleVitalsInput}
                                name="temperature"
                                value={vitalsInput?.temperature || ''}
                                unit={`°F`} />

                        </div>
                        <InputWithGroup
                            label="Blood Pressure"
                            handleChange={handleVitalsInput}
                            name="systolic"
                            value={vitalsInput?.systolic || ''} />


                        <span className="text-center mt-2 d-inline-block text--secondary font-16 align-self-center">
                            &#47;
                        </span>
                        <InputWithGroup
                            label="&nbsp;"
                            name="diastolic"
                            handleChange={handleVitalsInput}
                            value={vitalsInput?.diastolic || ''}
                            unit="mmgh" />
                        <div>
                            <InputWithGroup
                                label="Pulse"
                                name="pulse"
                                handleChange={handleVitalsInput}
                                value={vitalsInput?.pulse || ''}
                                unit="bpm" />
                        </div>
                        <div>
                            <InputWithGroup
                                label="SpO2"
                                unit="%"
                                name="spo2"
                                handleChange={handleVitalsInput}
                                value={vitalsInput?.spo2 || ''}
                            />
                        </div>
                        <div>
                            <InputWithGroup
                                label="Height"
                                unit="in"
                                name="height"
                                handleChange={handleVitalsInput}
                                value={vitalsInput?.height || ''}
                            />
                        </div>
                        <div>
                            <InputWithGroup
                                label="Weight"
                                unit="lbs"
                                name="weight"
                                handleChange={handleVitalsInput}
                                value={vitalsInput?.weight || ''}
                            />
                        </div>
                        <div>
                            <InputWithGroup
                                label="BMI"
                                name="bmi"
                                disabled
                                value={vitalsInput?.bmi || ''}
                                handleChange={handleVitalsInput} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            {
                                vitalValidationErrors?.length > 0 &&
                                <>
                                    <span className="font-12 text--terciary">
                                        {"Some of the values input for patient vitals fall beyond the typical norms shown below:"}
                                    </span>
                                    <ul>
                                        {
                                            vitalValidationErrors.map((errorItem) => {
                                                return (
                                                    <li className="font-12 text--terciary" dangerouslySetInnerHTML={{ __html: validationErrorMsgs[errorItem.fieldName] }}></li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="">
                                        <span className="text--terciary font-12">{"Please confirm if you wish to proceed: "}</span>
                                        <button
                                            className="btn d9e8ec--button"
                                            onClick={() => {
                                                // handleVitalsUpdate();
                                                setVitalValidationErrors([]);
                                            }}>{"Confirm"}</button>
                                    </div>


                                </>
                            }
                        </div>

                    </div>
                    <div className="d-flex flex-row-reverse flex-end py-2">
                        <button
                            onClick={handleVitalsUpdate}
                            disabled={(vitalValidationErrors?.length > 0)} className="blue_task_button font-12 text-white fw-sb align-self-center br-10 shadow-none">Add</button>
                    </div>

                </div>
            </div>

            <div className="container-fluid" hidden = {userPermissions?.vpv === false ?  true:false}>
                <div className="row px-2">
                    <div className="col-12 sol-sm-12">
                        <h3 className="font-18 fw-sb m-0 py-3 px-2">History</h3>
                        {/* {console.log("vitalHistory.length", vitalHistory.length)} */}
                        {isVitalHistoryLoading === true ? <Skeleton animation="wave" /> : isVitalHistoryLoading === false && vitalHistory?.length === 0 ? (
                            "No vitals history available"
                        ) : (
                            <div className="table-responsive">
                                <table className={`br-10 ${classes.vt}`}>
                                    <thead className="text--secondary bg--background font-12">
                                        <tr>
                                            <th className="fw-thin ">Vitals</th>
                                            {/* {alert(vitalHistory.length)} */}
                                            {
                                                /* map over to print the dates and time stamps  */
                                                vitalHistory && vitalHistory?.length > 0
                                                    ? vitalHistory?.map((item, index) => {
                                                        return (
                                                            <th key={index}>
                                                                <span
                                                                    className="d-block"
                                                                    style={{ marginBottom: "-7px" }}
                                                                >
                                                                    {/* 10 Feb */}
                                                                    {item?.createdAt !== null &&
                                                                        item?.createdAt !== undefined
                                                                        ? dateTimeHelper(
                                                                            item?.createdAt,
                                                                            "MMM D, YYYY"
                                                                        )
                                                                        : ""}
                                                                </span>
                                                                <span className="d-block fw-thin">
                                                                    {/* 11:12am */}
                                                                    {item?.createdAt !== null &&
                                                                        item?.createdAt !== undefined
                                                                        ? dateTimeHelper(item?.createdAt, "LT")
                                                                        : ""}
                                                                </span>
                                                            </th>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={`${classes["border__tr"]} bg-white`}>
                                            <td
                                                className={`py-3  ${classes["table__tr--background"]} font-14`}
                                            >
                                                Temperature (&deg;F)
                                            </td>
                                            {
                                                /* Render vital values for temperature */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory?.map((item, index) => {
                                                        return (
                                                            <td
                                                                key={index}
                                                                className="py-3 font-14 fw-sb text-center"
                                                            >
                                                                {item?.temperature !== null && item?.temperature !== ''
                                                                    ? item?.temperature
                                                                    : "--"}
                                                                {item?.temperature != null &&
                                                                    item?.temperature != "" && <span>&deg;</span>}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                        <tr
                                            className={`${classes["border__tr"]} ${classes["tr--stripe"]}`}
                                        >
                                            <td
                                                className={`py-3  ${classes["table__tr--background"]} font-14`}
                                            >
                                                Blood Pressure (mmHg)
                                            </td>
                                            {
                                                /* Render vital values for Blood Pressure */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory.map((item, index) => {
                                                        return (
                                                            <td
                                                                className="py-3 font-14 fw-sb text-center"
                                                                key={index}
                                                            >
                                                                {item?.systolic !== null && item?.systolic !== "" ? item?.systolic : "-"}
                                                                {item?.systolic !== null &&
                                                                    item?.systolic !== "" && <span>&#47;</span>}
                                                                {item?.diastolic !== null && item?.diastolic !== ''
                                                                    ? item?.diastolic
                                                                    : "-"}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                        <tr className={`${classes["border__tr"]} bg-white`}>
                                            <td
                                                className={`py-3  ${classes["table__tr--background"]} font-14`}
                                            >
                                                Pulse (bpm)
                                            </td>

                                            {
                                                /* Render vital values for pulse */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory.map((item, index) => {
                                                        return (
                                                            <td
                                                                className="py-3 font-14 fw-sb text-center"
                                                                key={index}
                                                            >
                                                                {item?.pulse !== null && item?.pulse !== ""
                                                                    ? item?.pulse
                                                                    : "--"}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                        <tr
                                            className={`${classes["border__tr"]} ${classes["tr--stripe"]}`}
                                        >
                                            <td
                                                className={`py-3  ${classes["table__tr--background"]} font-14`}
                                            >
                                                SPO<sub>2</sub> (%)
                                            </td>
                                            {
                                                /* Render vital values for SPO2 */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory.map((item, index) => {
                                                        return (
                                                            <td
                                                                className="py-3 font-14 fw-sb text-center"
                                                                key={index}
                                                            >
                                                                {item?.spo2 !== null && item?.spo2 !== ""
                                                                    ? item?.spo2
                                                                    : "--"}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                        <tr className={`${classes["border__tr"]} bg-white`}>
                                            <td
                                                className={`py-3 ${classes["table__tr--background"]} font-14`}
                                            >
                                                Height (inches)
                                            </td>
                                            {
                                                /* Render vital values for Weight */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory.map((item, index) => {
                                                        return (
                                                            <td
                                                                className="py-3 font-14 fw-sb text-center"
                                                                key={index}
                                                            >
                                                                {item?.height !== null && item?.height !== '' ? item?.height : "--"}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                        <tr
                                            className={`${classes["border__tr"]} ${classes["tr--stripe"]}`}
                                        >
                                            <td
                                                className={`py-3 ${classes["table__tr--background"]} font-14`}
                                            >
                                                Weight (lbs)
                                            </td>
                                            {
                                                /* Render vital values for Weight */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory.map((item, index) => {
                                                        return (
                                                            <td
                                                                className="py-3 font-14 fw-sb text-center"
                                                                key={index}
                                                            >
                                                                {item?.weight !== null && item?.weight !== '' ? item?.weight : "--"}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                        <tr
                                            style={{ borderBottomLeftRadius: "30px" }}
                                            className={`${classes["border__tr"]} bg-white`}
                                        >
                                            <td
                                                className={`py-3 ${classes["table__tr--background"]} font-14`}
                                            >
                                                BMI
                                            </td>
                                            {
                                                /* Render vital values for BMI */
                                                vitalHistory?.length > 0
                                                    ? vitalHistory.map((item, index) => {
                                                        return (
                                                            <td
                                                                className="py-3 font-14 fw-sb text-center"
                                                                key={index}
                                                            >
                                                                {item?.bmi != null && item?.bmi !== ''
                                                                    ? item?.bmi
                                                                    : "--"}
                                                            </td>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <CustomizedSnackbar
                severity={showApiStatusMessage?.success === true ? "success" : showApiStatusMessage?.error === true ? "error" : 'info'}
                message={showApiStatusMessage?.success === true ? "Vitals added successfully" : "Error in adding vitals"}
                open={showApiStatusMessage?.success === true || showApiStatusMessage?.error === true}
                handleClose={handleClose}
            />

        </>
    );
};

export default PatientProfileVitals;

function handleBMI(name, value, vitalsInput, calcBMI, setVitalsInput) {
    let calculatedValue;
    if (name === 'weight') {
        if (value !== '') {
            if (vitalsInput?.height !== undefined && vitalsInput?.height !== '') {
                calculatedValue = calcBMI(value, vitalsInput?.height);
                setVitalsInput((prev) => ({ ...prev, "bmi": calculatedValue }));
            }
            else {
                setVitalsInput((prev) => ({ ...prev, "bmi": '' }));
            }
        }
        else {
            setVitalsInput((prev) => ({ ...prev, "bmi": '' }));

        }
    }
    else if (name === 'height') {
        if (value !== '') {
            if (vitalsInput?.weight !== undefined && vitalsInput?.weight !== '') {
                calculatedValue = calcBMI(vitalsInput?.weight, value);
                setVitalsInput((prev) => ({ ...prev, "bmi": calculatedValue }));
            }
            else {
                setVitalsInput((prev) => ({ ...prev, "bmi": '' }));
            }
        }
        else {
            setVitalsInput((prev) => ({ ...prev, "bmi": '' }));

        }
    }
}

