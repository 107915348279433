import { useCallback } from "react";
import { useState } from "react";
import { search } from "../hooks/sendHttpRequest";

export default function useModifiersSearch() {
    const [modifiersSuggestions, setModifiersSuggestions] = useState([]);
    const [isModifierLoading, setIsModifierLoading] = useState(false);
    const [isModifierError, setIsModifierError] = useState(false);
    const [modifierSearchInputValue, setModifierSearchInputValue] = useState("");
    const [modifierName, setModifierName] = useState("");
    const [selectedModifier, setSelectedModifier] = useState(undefined);

    const searchModifier = async (modifierInput) => {
        setIsModifierLoading(true);
        const httpResponse = await search({
            url: `/diagnosis`,
            method: "GET",
            params: {
                search: modifierInput,
            },
        });
        if (!httpResponse.error) {
            setIsModifierLoading(false);
            setModifiersSuggestions(httpResponse.data);
            setIsModifierError(false);
        } else {
            if (httpResponse?.status === 400) {
                setIsModifierError(true);
                setIsModifierLoading(false);
            }
        }
    };

    const handleModifierSearchInput = useCallback(
        (cptInput) => {
            if (cptInput.length > 2) {
                // setDiagnosisName(icdInput)
                searchModifier(cptInput);
                setModifierSearchInputValue(cptInput);
            }
            setModifierName(cptInput);
            setModifiersSuggestions([]);
            setIsModifierLoading(false);
        },
        [
            searchModifier,
            setModifierSearchInputValue,
            setModifierName,
            setModifiersSuggestions,
            setIsModifierLoading,
        ]
    );

    const handleRemoveModifier = (removeId) => {
        setSelectedModifier(undefined);
        setModifierName("");
    };

    const handleAddModifier = (modifier) => {
        setModifierName("");
        setSelectedModifier(modifier);
    };

    return {
        modifiersSuggestions,
        setModifiersSuggestions,
        isModifierLoading,
        setIsModifierLoading,
        isModifierError,
        setIsModifierError,
        modifierSearchInputValue,
        setModifierSearchInputValue,
        modifierName,
        setModifierName,
        selectedModifier,
        setSelectedModifier,
        searchModifier,
        handleModifierSearchInput,
        handleAddModifier,
        handleRemoveModifier

    }
}