import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useParams } from "react-router-dom";
import { ConfigMap } from "../../../../utils/helpers/ConfigMap";
import { encounterSignOffUrl, exportSummaryAsPdf } from "../../../../res/endpointurls";
import { useAuth } from "../../../hooks/useAuth";
import {
    setConsulationInProgress,
    setDisableSendRxButton,
    setPatientEncounterData,
    setPrescriptionNoteText,
    updatePrescribedMedication,
} from "../../../../redux/actions/providerActions";
import { useContext } from "react";
import { VideoCallContext } from "../../AgoraHooks/VideoCallContext";
import PatientSummary from "../../../patient/PatientSummary";
import PatientProviderNoteSummary from "../../../patient/PatientProviderNoteSummary";


const SummaryModal = (props) => {
    const { user } = useAuth();
    const patientEncounterInformation = useSelector(
        (state) => state.PatientEncounterData.appoinment
    );
    const userPermissions = useSelector(
        (state) => state.UserPermissionData.permissionsMap
    );
    const [duration, setDuration] = useState(15000);
    const videoCallContext = useContext(VideoCallContext);

    const dispatch = useDispatch();
    const summary = props.summary;

    const { providerId, encounterId, patientId } = useParams();
    const [isPdfExporting, setIsPdfExporting] = useState(false);
    const [signOff, setSignOff] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();

    const handleDownload = async () => {
        await fetch(ConfigMap("APP") + exportSummaryAsPdf(summary?.prescriptionReferenceId, user?.userId), {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/pdf",
                "Authorization": localStorage.getItem("tkn") !== null ? localStorage.getItem("tkn") : ""
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // console.log(blob);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `encounter-summary.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();
                link.parentNode.removeChild(link);
            })
    }

    const handleEncounterSignOff = async () => {
        setSignOff(true);
        if (videoCallContext.getIsVideoCallActive()) {

            let durationInSeconds = 15000;
            if (document.getElementById('minutes') !== undefined &&
                document.getElementById('minutes') !== null &&
                document.getElementById('seconds') !== undefined &&
                document.getElementById('seconds') !== null) {
                try {
                    durationInSeconds = Number(document.getElementById('minutes').innerText) * 60 + Number(document.getElementById('seconds').innerText);
                    setDuration(durationInSeconds ?? 15000);
                }
                catch (e) {
                    console.error(e);
                }
            }
            // await props?.videoTrack?.stop();
            // await props?.videoTrack?.setEnabled(false);
            // await props?.audioTrack?.setEnabled(false);
            // await client?.unpublish();
            // await client?.leave();
            videoCallContext.endSession().then(() => sendHttpRequest({
                method: "POST",
                url: `/video/SessionBreakRequest`,
                data: {
                    appiontmentId: patientEncounterInformation?.id,
                    videoJoinRequestType: "END",
                    callDuration: durationInSeconds,
                }
            }));

            dispatch(setConsulationInProgress(false));

        }
        const httpResponse = await sendHttpRequest({
            url: encounterSignOffUrl(encounterId, providerId),
            method: "GET",
        });
        if (!httpResponse.error) {
            dispatch(setPrescriptionNoteText(""));
            dispatch(setDisableSendRxButton(false));
            dispatch(updatePrescribedMedication([]));
            dispatch(setPatientEncounterData({}));
            // dispatch(
            //     setUpdateEncounterData({
            //         Diagnosis: {
            //             Note: "",
            //             Primary: [],
            //             Secondary: [],
            //         },
            //     })
            // );
            // dispatch(
            //     setDiagnosis({
            //         Note: "",
            //         Primary: [],
            //         Secondary: [],
            //     })
            // );
            navigate('/encounter', { replace: true });
            props?.handleModal();
        }
        else {
            console.log(httpResponse);
            setErrorMessage(httpResponse.errorMessage.message);
            scrolToTop();
        }
        setSignOff(false);
        // resetTracks();
    };

    function getObjectKeys(params) {
        if (params !== undefined) {
            return Object.keys(params);
        }
    }

    const scrolToTop = () => {
        let elementRef = document.getElementById("error-div");
        elementRef = document.getElementById("error-div");
        elementRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })

    }



    return (
        <>
            <Modal
                show={props?.show}
                onHide={() => {
                    props?.handleModal();
                }}
                size="lg"
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="font-18 text--terciary">
                        Patient Encounter Note
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        summary?.encounter?.encounterNoteType === "SOAP_NOTE" ?
                            <PatientProviderNoteSummary summary={summary} errorMessage={errorMessage} props={props} />
                            :
                            <PatientSummary summary={summary} errorMessage={errorMessage} props={props} />
                    }

                </Modal.Body>


                <Modal.Footer style={props?.pendingTask === true ? { display: "none" } : { border: "none" }} >
                    {/* <button onClick={()=>{exportSummaryAsPdf(encounterId)}} className={`btn bg--blue ${isPdfExporting ? "disabled" : ''} text-white b2 px-4 br-10 py-1 shadow-none`}>
                        Export as pdf
                    </button> */}
                    <button type="button" className={`btn pt-2 blue_task_button ${isPdfExporting || signOff ? "disabled" : ''}`} onClick={handleDownload} target="_blank" hidden={userPermissions?.ees === false ? true : false}
                    >
                        Export as PDF
                    </button>
                    {
                        summary?.encounter?.encounterNoteType.toLowerCase() !== "provider_note" ?
                            summary?.encounter?.status?.toLowerCase() === "closed" || props.providerNote
                                ?
                                <>
                                    <button
                                        className={`btn blue_task_button ${isPdfExporting || signOff ? "disabled" : ''}`}
                                        onClick={() => {
                                            props?.handleAddenModal(summary?.encounter?.encounterId);
                                        }}
                                        hidden={userPermissions.aae === false ? true : false}
                                    >
                                        Add Addendum
                                    </button>
                                </>
                                :
                                <button
                                    className={`btn blue_task_button ${isPdfExporting || signOff ? "disabled" : ''}`}
                                    onClick={() => {
                                        handleEncounterSignOff();
                                    }}
                                    hidden={userPermissions.spe === false ? true : false}
                                >
                                    {!signOff ? "Sign" : "Signing..."}
                                </button>
                            : null
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SummaryModal;
