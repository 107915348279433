import { useEffect, useState } from "react";
import { cardsMap, truncate } from "../../utils/helpers/HelperFunctions";
import { client, hostedFields } from "braintree-web";
import { Avatar, Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import classes from "../../patientportal/patientpages/PatientPaymentPage.module.css"
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { useCallback } from "react";
import CurrencyInput from "react-currency-input-field";
import { transferPayment, getPaymentToken, providerWithNpiUrl } from "../../res/endpointurls";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import placeholder from "../../assets/images/placeholder.png";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import options from "../../utils/data";

const PaymentForm = ({ setIsPaymentSent, isPaymentSent, amountValue, prescribersList, userId, appointment }) => {

    const [nameOnCC, setNameOnCC] = useState("");
    const [provider, setProvider] = useState(appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider?.userId);
    const [isLoading, setIsLoading] = useState(false);
    const [creditCardType, setCreditCardType] = useState(null);
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState("");
    const [amount, setAmount] = useState(amountValue ?? 0);
    const [token, setToken] = useState("");
    const [error, setError] = useState("");
    const [tokenizeErr, setTokenizeErr] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);

    const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const confirmButtonRef = useRef();

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [selectedState, setSelectedState] = useState([]);
    const [zipcode, setZipcode] = useState("");
    const addressHandler = (e) => {
        setAddress(e.target.value);
    };

    const cityHandler = (e) => {
        setCity(e.target.value);
    };

    const zipcodeHandler = (e) => {
        setZipcode(e.target.value);
    };

    const { user } = useAuth();

    const handlePayment = () => {
        tokenizeFields();
    }


    const handleReceipt = async (appointmentId) => {
        setIsPrinting(true);
        const httpResponse = await sendHttpRequest({
            url: `/appointments/${appointmentId}/receipt`,
            method: 'GET',
            responseType: "arraybuffer",
            params: {
                userId: user?.userId
            }

        })
        if (!httpResponse.error) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = `Receipt_${appointmentId}`;
            const href = URL.createObjectURL(
                new Blob([httpResponse.data], {
                    type: "application/pdf",
                    encoding: "UTF-8",
                })
            );
            link.href = href;
            link.click();
            link.remove();
            URL.revokeObjectURL(href);
        }
        setIsPrinting(false);
    }

    function displayErrorMessage(container, message) {
        // console.log("container", container);
        // Remove existing error message if present
        removeErrorMessage(container);

        // Create error message element
        var errorMessage = document.createElement('span');
        errorMessage.className = 'error-message font-10 text--danger fw-thin';
        errorMessage.textContent = message;
        errorMessage.style.display = 'block';
        errorMessage.style.marginTop = '6px';
        errorMessage.style.marginLeft = '-3px';
        setDisableButton(true);

        confirmButtonRef.current.disabled = true;

        // Append error message below the container
        // container.parentNode.insertBefore(errorMessage, container.nextSibling);
        container.appendChild(errorMessage)
    }

    function removeErrorMessage(container) {
        var errorMessage = container.parentNode.querySelector('.error-message');
        if (errorMessage) {
            // Remove error message if found
            if (checkAllFieldsFilled) {
                setDisableButton(false);
                confirmButtonRef.current.disabled = false;
            }
            errorMessage.parentNode.removeChild(errorMessage);
        }
    }


    const checkAllFieldsFilled = (fields) => {
        const fieldNames = Object.keys(fields);
        for (const fieldName of fieldNames) {
            if (fields[fieldName].isEmpty) {
                return false;
            }
        }
        return true;
    };

    const getErrorMessage = (container) => {
        const errorElement = container.querySelector('.error-message');
        if (errorElement) {
            return errorElement.textContent;
        }
        return null;
    };



    const handleFieldBlur = (event) => {
        const field = event.fields[event.emittedBy];

        if (field.isEmpty) {
            displayErrorMessage(field.container, 'Field is required');
        } else if (!field.isValid) {
            // Check if there is already an error message and the field is invalid
            const existingErrorMessage = getErrorMessage(field.container);
            if (!existingErrorMessage) {
                displayErrorMessage(field.container, 'Invalid field');
            }
        } else {
            removeErrorMessage(field.container);
        }

        const allFieldsFilled = checkAllFieldsFilled(event.fields);
        setIsAllFieldsFilled(allFieldsFilled);
    };


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsSent(false);
        setError({ show: false });
    };

    const cardNameHolderHandler = (e) => {
        setNameOnCC(e.target.value);
    };

    const hostedForm = useCallback((token) => {
        client.create({
            authorization: token
        }).then(function (clientInstance) {
            hostedFields.create(
                {
                    client: clientInstance,
                    styles: {
                        'input': {
                            'font-size': '12px',
                            'font-style': 'Montserrat'
                        },
                        'input.invalid': {
                            'color': '#FD0D1B'
                        },
                        'input.valid': {
                            'color': "#323232"
                        },
                        '::-webkit-input-placeholder': {
                            'color': '#70707070'
                        },
                        ':-ms-input-placeholder': {
                            'color': '#70707070'
                        },
                        // 'input.placeholder': {
                        //     'color': "#dddddd"
                        // }
                    },
                    fields: {
                        // cardholderName: {
                        //     container: '#cardholderName',
                        //     placeholder: 'John Doe',
                        // },
                        number: {
                            container: '#card-number',
                            // placeholder: '4111 1111 1111 1111',
                        },
                        cvv: {
                            container: '#cvv',
                            placeholder: '•••'
                        },
                        expirationDate: {
                            container: '#expiration-date',
                            placeholder: 'MM/YYYY'
                        }
                    }
                }, function (hostedFieldsErr, hostedFieldsInstance) {
                    if (hostedFieldsErr) {
                        // console.error(hostedFieldsErr);
                        return;
                    }
                    // function findLabel(field) {
                    //     return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
                    // }
                    setHostedFieldsInstance(hostedFieldsInstance);
                    hostedFieldsInstance.on('notEmpty', function (event) { });
                    hostedFieldsInstance.on('empty', function (event) {
                        if (event.emittedBy === "number") {
                            setCreditCardType(null);
                        }
                    });
                    hostedFieldsInstance.on('cardTypeChange', function (event) {

                        // var field = event.fields[event.emittedBy];
                        // var label = findLabel(field);
                        // console.log(label);
                        // if (field.isPotentiallyValid) {
                        //     label.classList.remove('invalid');
                        // } else {
                        //     label.classList.add('invalid');
                        // }
                        // Change card bg depending on card type
                        if (event.cards.length === 1) {
                            setCreditCardType(event.cards[0].type);
                            //   $('header').addClass('header-slide');

                            // Change the CVV length for AmericanExpress cards
                            if (event.cards[0].code.size === 4) {
                                hostedFieldsInstance.setAttribute({
                                    field: 'cvv',
                                    attribute: 'placeholder',
                                    value: '••••'
                                });
                            }
                        } else {
                            hostedFieldsInstance.setAttribute({
                                field: 'cvv',
                                attribute: 'placeholder',
                                value: '•••'
                            });
                        }
                    });


                    hostedFieldsInstance.on('blur', handleFieldBlur);

                    // Add event listener for input validation
                    hostedFieldsInstance.on('validityChange', function (event) {
                        var field = event.fields[event.emittedBy];

                        if (field.isValid) {
                            // Field is valid, remove error message
                            removeErrorMessage(field.container);
                        } else if (field.isPotentiallyValid) {
                            // Field is potentially valid, remove error message if displayed
                            removeErrorMessage(field.container);
                        } else {
                            // Field is invalid, display error message
                            // card-number
                            // expiration-date
                            // cvv
                            if (field.container.id === "card-number") {
                                displayErrorMessage(field.container, 'Invalid card number');
                            }
                            else if (field.container.id === "expiration-date") {
                                displayErrorMessage(field.container, 'Invalid expiration date');
                            }
                        }

                        const initialFields = hostedFieldsInstance.getState().fields;
                        const allFieldsFilled = checkAllFieldsFilled(initialFields);
                        setIsAllFieldsFilled(allFieldsFilled);


                    });
                });
        });
    });

    const tearDownFields = () => {
        if (hostedFieldsInstance !== "") {
            hostedFieldsInstance.teardown(function (teardownErr) {
                if (teardownErr) {
                    // console.error('Could not tear down Hosted Fields!');
                } else {
                    // console.info('Hosted Fields has been torn down!');
                }
            });
        }
    }

    async function getToken() {
        const httpResponse = await sendHttpRequest({
            // url: `/braintree/${userId}/get_token`,
            url: getPaymentToken(user?.tenantId),
            method: "GET",
        });
        if (!httpResponse.error) {
            setToken(httpResponse.data);
            hostedForm(httpResponse.data);
            // braintreeClient(httpResponse.data)
            // setIsApptCreated(true);
            // timeExpired();
        } else {
            setError(httpResponse.errorMessage);
            // console.log(httpResponse);
        }
        // getExtras();
    };

    const sendNounce = async (nounce) => {
        setIsLoading(true);
        const httpResponse = await sendHttpRequest({
            url: transferPayment(user?.tenantId),
            method: "POST",
            data: {
                nounce: nounce,
                providerId: provider,
                amount: amount,
                nameOnCC: nameOnCC,
                transactionType: "CreditCard",
                appointmentId: appointment?.id,
                userId: user?.userId

            },
        });
        if (!httpResponse.error) {
            setIsSent(true);
            tearDownFields();
            // fetchBillingHistory(true, {description: "Paid via credit card", due: amount});
            setIsPaymentSent(true);
            setAmount(0);
            // fetchCurrentBill();
        } else {
            if (httpResponse?.status === 500) {
                setError({ message: "Something went wrong", show: true });
            } else {
                setError({ ...httpResponse?.errorMessage, show: true } ?? { message: "Payment not completed", show: true });
            }
        }
        setIsLoading(false);
        // setIsTimeOver(false);
    };

    const tokenizeFields = () => {
        // let state = hostedFieldsInstance.getState();
        // let fields = Object.keys(state.fields);
        // console.log(state);
        hostedFieldsInstance.tokenize({
            // billingAddress: isNewCreditCard === true ? {
            //     firstName: nameOnCC.split(" ").length > 0 ? nameOnCC.split(" ")[0] : "",
            //     lastName: nameOnCC.split(" ").length > 0 ? nameOnCC.split(" ")[nameOnCC.split(" ").length - 1] : "",
            //     // company: '',
            //     postalCode: zipcode,
            //     streetAddress: address,
            //     // extendedAddress: 'Unit 1',
            //     locality: city,
            //     countryName: selectedState?.length > 0 ? selectedState[0].name : "",
            //     countryCodeAlpha2: selectedState?.length > 0 ? selectedState[0].code : "",
            //     // countryCodeAlpha3: 'USA',
            //     // countryCodeNumeric: '840'
            // } : {}

        }, function (tokenizeErr, payload) {
            if (tokenizeErr) {
                setTokenizeErr(tokenizeErr)
                return;
            }
            else {
                sendNounce(payload.nonce);
            }
        });
    }

    useEffect(() => {

        getToken();
        return () => {
            // clearStates();
            // tearDownFields();
        }
    }, []);

    useEffect(() => {
        setAmount(amountValue)
    }, [amountValue])


    return (
        <>
            <form id="credit-card-form" onSubmit={(e) => {
                e.preventDefault();
            }}>
                <div className="col-md-12 mb-2" >
                    <label
                        htmlFor="physician"
                        className="form-label m-0 font-10 text--secondary fw-sb"
                    >
                        Card Holder Name
                    </label>
                    {/* <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                        <div id="cardholderName" className={`${classes.hostedFields}`}></div>
                    </div> */}

                    <input
                        type="text"
                        className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                        id="physician"
                        onChange={cardNameHolderHandler}
                    />
                </div>

                <div className="col-md-12 mb-2">
                    <label
                        htmlFor="physician"
                        className="form-label m-0 font-10 text--secondary fw-sb"
                    >
                        Credit Card Number
                    </label>
                    <Stack direction="row" justifyContent={"between"} spacing={2}>
                        <div className="col-sm-12" style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                            <div className="row">
                                <div className="col-sm-8 justify-content-start align-self-center" >
                                    <span className="font-12 fw-sb text--terciary">
                                        <div>
                                            <div id="card-number" className={`${classes.hostedFields}`}></div>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-sm-4 align-self-center">
                                    <div className="d-flex flex-row justify-content-end me-2">
                                        {
                                            creditCardType !== null && (cardsMap(creditCardType))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Stack>

                </div>

                <div className="col-md-10 mb-2">
                    <h6 className="text--secondary font-12 fw-sb">Expiration Date</h6>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="Month"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    MM/YYYY
                                </label>
                                {/* <select
                            className={`form-select  br-10 border--default input font-12 shadow-none`}
                            aria-label="Month"
                            onChange={(event) => { monthHandler(event); }}
                        >
                            <option selected disabled hidden value="">Month</option>
                            {months !== undefined && months?.length > 0 ? months?.map((month, index) => {
                                return (
                                    <option title={month} className="text-truncate w-100" value={index + 1} key={index + 1}>
                                        {month}
                                    </option>
                                )
                            }) : ''}
                        </select> */}
                                <span className="font-12 fw-sb text--terciary">
                                    <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                                        <div id="expiration-date" className={`${classes.hostedFields}`}></div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                    <div className="col-md-12 mb-2">
                        <label
                            htmlFor="Year"
                            className="form-label m-0 font-10 text--secondary fw-sb"
                        >
                            Year
                        </label>
                        <select
                            className={`form-select  br-10 border--default input font-12 shadow-none`}
                            aria-label="Year"
                            id="Year"
                            onChange={(event) => { yearHandler(event); }}
                        >
                            <option selected disabled hidden value="">Year</option>
                            {years !== undefined && years?.length > 0 ? years?.map((year) => {
                                return (
                                    <option title={year} className="text-truncate w-100" value={year} key={year}>
                                        {year}
                                    </option>
                                )
                            }) : ''}
                        </select>
                    </div>
                </div> */}
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="ccv"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    {"CVV"}
                                    {/* {creditCardType?.code?.name} */}
                                </label>
                                {/* <input
                            type="text"
                            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                            id="cvv"
                            maxLength={creditCardType?.code?.size}
                            onChange={cvvHandler}
                        /> */}
                                <span className="font-12 fw-sb text--terciary">
                                    <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                                        {/* <CreditCardInput
                                        onChange={cardNumberHandler}
                                        id="card-number"
                                        // defaultValue={ccNum}
                                        required
                                        style={{
                                            border: "1px solid #FFFFFF"
                                        }}
                                    /> */}
                                        <div id="cvv" className={`${classes.hostedFields}`}></div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">

                    <div className="row">

                        <div className="col-6">
                            <div className="col-12">
                                <label htmlFor="amount" className="font-12 text--secondary">{"Amount"}</label>
                                <CurrencyInput
                                    id="cc_amount"
                                    name="cc_amount"
                                    placeholder="$0.00"
                                    className="text--terciary text-center font-12 input form-control shadow-none border--default br-10"
                                    defaultValue={isPaymentSent ? 0 : amount}
                                    // decimalsLimit={2}
                                    // fixedDecimalLength={2}
                                    prefix="$"
                                    decimalSeparator="."
                                    groupSeparator=","
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    onValueChange={(value, name) => setAmount(value)}
                                // disabled = {true}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="col-12">
                                <label htmlFor="provider" className="font-12 text--secondary">{"Provider"}</label>
                                <select
                                    className={`form-select font-12 border--default br-10 shadow-none input`}
                                    aria-label="action select"
                                    style={{
                                        border: "1px solid #D9E8EC",
                                        //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                                        height: "36px",
                                        // fontStyle:"italic"
                                    }}
                                    value={provider}
                                    onChange={(e) => setProvider(e.target.value)}
                                >
                                    <option
                                        className="text--terciary"
                                        value={""}
                                        key={1}
                                        selected
                                        disabled
                                        hidden
                                    >
                                        {"Select Provider"}
                                    </option>
                                    {
                                        prescribersList?.length > 0 &&
                                        prescribersList.map((prescriberItem) => {
                                            return (
                                                <option key={prescriberItem?.userId} id={prescriberItem?.userId} value={prescriberItem?.userId}>
                                                    {"".concat(prescriberItem?.name[0]?.prefix || "", " ", prescriberItem?.name[0]?.firstName || "", " ", prescriberItem?.name[0]?.lastName)}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>


                    </div>

                </div>

                <div className="col-12 mb-2">
                    <div className="horizontal-rule my-3" />
                </div>
                <div className="col-md-10 mb-2">
                    <Typography component="span">
                        <Box
                            sx={{
                                fontFamily: "Montserrat",
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#336383",
                            }}
                        >
                            Billing Address
                        </Box>
                    </Typography>
                </div>
                <div className="col-md-12">
                    <div className="col-md-12 mb-2">
                        <label
                            htmlFor="address"
                            className="form-label m-0 font-10 text--secondary fw-sb"
                        >
                            Address
                        </label>
                        {/* <div id="street-address" className={`${classes.hostedFields}`}></div> */}
                        <input
                            type="text"
                            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                            id="address"
                            onChange={addressHandler}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="city"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    City
                                </label>
                                <input
                                    type="text"
                                    className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                    id="city"
                                    onChange={cityHandler}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="state"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    State
                                </label>
                                <Typeahead
                                    id="state"
                                    labelKey="code"
                                    onChange={setSelectedState}
                                    options={options}
                                    className="rbt-menu my-select"
                                    style={{ borderRadius: "10px", fontSize: 12 }}
                                    autoComplete="false"
                                    onInvalid={(e) => { e.target.setCustomValidity('Please fill out this field') }}
                                    onInput={(e) => { e.target.setCustomValidity('') }}
                                    inputProps={{ required: false, className: "font-12" }}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="ZipCode"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    ZipCode
                                </label>
                                {/* <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                                                                                            <div id="postal-code" className={`${classes.hostedFields}`}></div>
                                                                                        </div> */}
                                <input
                                    type="text"
                                    className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                    id="zipcode"
                                    autoComplete="false"
                                    minLength="5"
                                    maxLength="5"
                                // onChange={zipcodeHandler}
                                />
                                {/* <Typography component="span">
                                                                                    <Box
                                                                                        sx={{
                                                                                            fontFamily: "Montserrat",
                                                                                            fontWeight: 600,
                                                                                            fontSize: "12px",
                                                                                            color: "#323232",
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                                                                                            <div id="postalCode" className={`${classes.hostedFields}`}></div>
                                                                                        </div>
                                                                                    </Box>
                                                                                </Typography> */}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-3">
                    <div className="d-flex flex-row justify-content-end">

                        <button
                            disabled={!isPaymentSent === true ? true : isPrinting}
                            onClick={() => handleReceipt(appointment?.id)}
                            className="btn blue_task_button  ">
                            {
                                !isPrinting ? "Print Receipt" :
                                    <CircularProgress
                                        size={24}
                                        sx={{ color: "#ec609b" }}
                                    />
                            }
                        </button>

                        <button
                            // id="credit-card-form"
                            onClick={handlePayment}
                            ref={confirmButtonRef}
                            type="btn"
                            disabled={provider === undefined ? true : amount <= 0 || isLoading || !isAllFieldsFilled || disableButton}
                            className="btn blue_task_button  ">
                            {
                                !isLoading ? "Submit Payment" :
                                    <CircularProgress
                                        size={24}
                                        sx={{ color: "#ec609b" }}
                                    />
                            }
                        </button>
                    </div>
                </div>


            </form>
            {/* {hostedForm()} */}

            <CustomizedSnackbars
                message={"Payment processed successfully."}
                severity="success"
                open={isSent}
                handleClose={handleClose}
            />
            <CustomizedSnackbars
                message={error?.message}
                severity="error"
                open={error?.show}
                handleClose={handleClose}
            />
        </>
    )

}

function MakePaymentModal({ amountValue, show, handleModal, appointment, isLoading, foodAllergy, drugAllergy, strings }) {
    const [isPaymentSent, setIsPaymentSent] = useState(false);
    const { user } = useAuth();

    const [isPrescriberLoading, setIsPrescriberLoading] = useState(false);

    const [prescribersList, setPrescribersList] = useState([]);

    const fetchAllPrescribers = async () => {
        setIsPrescriberLoading(true);
        const httpResponse = await sendHttpRequest({
            url: providerWithNpiUrl(user?.tenantId),
            method: "GET",
        });
        if (!httpResponse.error) {
            // setisPrescribersFetched(true);
            setPrescribersList(httpResponse.data);
        } else {
            setPrescribersList([]);
            // console.log(httpResponse.error);
        }
        setIsPrescriberLoading(false);
    };

    useEffect(() => {
        fetchAllPrescribers();
    }, [])
    return (
        <Modal
            show={show}
            onHide={() => {
                handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered
        >
            <Modal.Header>
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Invoice # <span className="fw-thin">{appointment?.patientBill?.invoiceNumber}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Grid key={1} item xs={12}>
                                <Stack direction="row" spacing={6}>
                                    <Box>
                                        <Stack direction="row">
                                            <Avatar
                                                src={
                                                    appointment?.appointmentSlot?.provider
                                                        ?.profilePicture || placeholder
                                                }
                                                alt="provider picture"
                                            ></Avatar>
                                            <Stack sx={{ ml: 1 }}>

                                                <span className="fw-sb font-16 lh-0 m-0 d-block">
                                                    {
                                                        appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider
                                                            ?.name[0]?.prefix
                                                    }{" "}
                                                    {
                                                        appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider
                                                            ?.name[0]?.firstName
                                                    }{" "}
                                                    {
                                                        appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider
                                                            ?.name[0]?.lastName
                                                    }
                                                </span>

                                                <span className="font-10 lh-0 d-block text--secondary m-0">
                                                    {
                                                        appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider?.speciality?.length > 0 ?
                                                            appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider?.speciality?.[0]?.specialization : ""
                                                    }
                                                </span>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Grid>
                            <div className="row mt-4">
                                <div className="col-sm-6 col-12">
                                    <Stack direction="column" spacing={0}>
                                        <label
                                            className="text--secondary font-12 fw-sb m-0"
                                            style={{ marginBottom: "-12px" }}
                                        >
                                            {strings.en.date}
                                        </label>
                                        <p
                                            className="m-0 text--terciary font-12"
                                            style={{ marginTop: "-8px" }}
                                        >
                                            {appointment?.appointmentSlot?.startDateTime !== undefined &&
                                                appointment?.appointmentSlot?.startDateTime !== "" ? (
                                                <>
                                                    {dateTimeHelper(
                                                        appointment?.appointmentSlot?.startDateTime,
                                                        "MMM D, YYYY"
                                                    )}
                                                    <br />
                                                    {dateTimeHelper(
                                                        appointment?.appointmentSlot
                                                            ?.startDateTime,
                                                        "LT"
                                                    ) +
                                                        " - " +
                                                        dateTimeHelper(
                                                            appointment?.appointmentSlot
                                                                ?.endDateTime,
                                                            "LT"
                                                        )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                    </Stack>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <Stack direction="column" spacing={0}>
                                        <label
                                            className="text--secondary font-12 fw-sb m-0"
                                            style={{ marginBottom: "-12px" }}
                                        >
                                            {strings.en.chiefcomplaint}
                                        </label>
                                        <p
                                            className="m-0 text--terciary font-12"
                                            style={{ marginTop: "-8px" }}
                                        >
                                            {
                                                appointment?.chiefComplaint
                                            }
                                            {/* {encounter?.date !== null && encounter?.date !== undefined && encounter?.date !== '' ? dateTimeHelper(encounter?.date,"MMM DD, YYYY") : ""} */}
                                        </p>
                                    </Stack>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-6 col-12">
                                    <Stack style={{ fontSize: 12 }}>
                                        <label className="text--secondary fw-sb font-12 m-0">
                                            {strings.en.allergies}
                                        </label>
                                        {foodAllergy !== "" ? (
                                            <PnToolTip
                                                title={foodAllergy?.length > 0 ? foodAllergy : strings.en.nofoodallergies}
                                                arrow
                                                placement="top"
                                            >
                                                <span>
                                                    {truncate("Food: " + foodAllergy, 35)}
                                                </span>
                                            </PnToolTip>
                                        ) : (
                                            strings.en.nofoodallergies
                                        )}
                                    </Stack>
                                    <Stack style={{ fontSize: 12 }}>
                                        {drugAllergy !== "" ? (
                                            <PnToolTip
                                                title={drugAllergy?.length > 0 ? drugAllergy : strings.en.nofoodallergies}
                                                arrow
                                                placement="top"
                                            >
                                                <span>
                                                    {truncate("Drug: " + drugAllergy, 35)}
                                                </span>
                                            </PnToolTip>
                                        ) : (
                                            strings.en.nodrugallergies
                                        )}
                                    </Stack>
                                    {/* <Stack style={{ fontSize: 14 }}>
              Common:
              {isLoading ? <Skeleton animation="wave" /> :
                appointment?.Encounter?.PatientHistory?.Allergies !== undefined &&
                  appointment?.Encounter?.PatientHistory?.Allergies.length !== 0 ?
                  appointment?.Encounter?.PatientHistory?.Allergies.map((allergy, index) => {
                    if (index < 3) {
                      return <p
                        key={allergy?.Id}
                        className="m-0 text--terciary font-16"
                        style={{ marginTop: "-8px" }}
                      >
                        {allergy?.DrugProductCoded?.Text}
                      </p>
                    }
                    // if (index > 2) {
                    //   string += allergy?.DrugProductCoded?.Text + ' ';
                    // }
                    // if ((appointment?.Encounter?.PatientHistory?.Allergies.length - 3 > 0) && (appointment?.Encounter?.PatientHistory?.Allergies.length - 1 === index)) {
                    //   return <Tooltip title={
                    //     <>
                    //       <ul className='list-unstyled ms-0'>
                    //         {string.split().map((item) => { return item !== " " && <li>{item}</li> })}
                    //       </ul>
                    //     </>
                    //   }><p
                    //     key={allergy?.Id}
                    //     className="m-0 text--secondary font-14"
                    //   >
                    //       {appointment?.Encounter?.PatientHistory?.Allergies.length - 3}{" more..."}
                    //     </p>
                    //   </Tooltip>
                    // }

                  }) : 'No allergies found'}
            </Stack> */}
                                </div>
                                <div className="col-sm-6 col-12">
                                    {appointment?.paymentProcessed === false ? <>
                                        <label className="text--secondary fw-sb font-12 m-0">
                                            Payment Due
                                        </label>
                                        <span className="font-12 d-block text--danger">
                                            ${appointment?.patientBill?.outstandingBalance}
                                        </span>
                                    </> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-2">
                    <div className="row">

                        <div className="col-12 mb-2">
                            <div className="horizontal-rule my-3" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <PaymentForm
                                setIsPaymentSent={setIsPaymentSent}
                                isPaymentSent={isPaymentSent}
                                amountValue={amountValue}
                                userId={user?.id}
                                prescribersList={prescribersList}
                                isPrinting={false}
                                appointment={appointment}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12 text-center">

                    <span className="font-12">Powered by </span><img width={90} src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png" />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default MakePaymentModal;