import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import  AddButtonIcon  from "../../../src/assets/icons/Icon-Add.svg";
import MedicationHistoryModal from "../UI/Modals/PatientModals/MedicationHistoryModal";
import MedicationHistoryEditModal from "../UI/Modals/PatientModals/MedicationHistoryEditModal";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import TrashIcon from "../../assets/icons/TrashIcon";
import { getMedicationHistoryUrl, addMedicationHistoryUrl, updateMedicationHistoryUrl } from "../../res/endpointurls";
import { medicalHistoryStrings } from "../../res/strings";
import DeleteConfirmationModal from "../UI/Modals/PatientModals/DeleteConfirmationModal";
import EditIconComponent from "../../assets/iconComponents/EditIconComponent";
import EditIcon from "../../assets/icons/EditIcon";

function getObjectKeys(params) {
  // return [Object.keys(params)[0]];
  return Object.keys(params);
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: 130,
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const MedicationHistoryTable = (props) => {
  const { providerId, encounterId, patientId } = useParams();
  const classes = useStyles();
  const _str = medicalHistoryStrings.en;

  const [medicationHistory, setMedicationHistory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [medicationName, setMedicationName] = useState("");
  const [strength, setStrength] = useState();
  const [frequency, setFrequency] = useState();
  const [dose, setDose] = useState();
  const [startDate, setStartDate] = useState("");
  const [medicationStatus, setMedicationStatus] = useState(true);
  const [endDate, setEndDate] = useState("");
  const [editMedicalHistory, setEditMedicalHistory] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [frequencyList, setFrequencyList] = useState([]);
  const [gpi, setGpi] = useState("");
  const [dosageForm, setDosageForm] = useState("");
  const [ddid, setDdid] = useState("");
  const [updateMedicationHisyoryId, setUpdateMedicationHisyoryId] = useState();
  const [deleteMedicationHistoryId, setDeleteMedicationHistoryId] = useState();
  const [deleteMedicationHistoryModal, setDeleteMedicationHistoryModal] = useState(false);
  const [medicationStartDate, setMedicationStartDate] = useState(null);
  const [medicationEndDate, setMedicationEndDate] = useState(null);
  const [medicationHistoryStatusCode, setMedicationHistoryStatusCode] =
    useState(null);

  const fetchMedicationHistory = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: "/medicationHistory",
      url: getMedicationHistoryUrl(patientId || props?.patientId),
      method: "GET",
      // params: {
      //   patientid: patientId || props?.patientId,
      // },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setMedicationHistory(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setMedicationHistoryStatusCode(httpResponse.status);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const OpeneditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const addMedicationHistoryRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: addMedicationHistoryUrl(encounterId),
      method: "POST",
      data: {
        drugName: medicationName,
        strength: strength,
        frequency: frequency,
        dose: dose,
        drugDdidCode: gpi,
        status: medicationStatus,
        dosageForm: dosageForm,
        drugShortName: ddid,
        startDate: medicationStartDate,
        endDate: medicationEndDate,
      }
    });
    if (!httpResponse.error) {
      emptyStates();
      fetchMedicationHistory(patientId);
    } else {
      emptyStates();
      console.log(httpResponse.error);
    }
  };

  const getFrequencyValue = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/encounter/medication/frequency`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setFrequencyList(httpResponse.data);
    } else {

    }
  };

  const editMedicationHistoryRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: updateMedicationHistoryUrl(encounterId, updateMedicationHisyoryId),
      method: "PUT",
      data: {
        medicationHistoryId: updateMedicationHisyoryId,
        drugName: medicationName,
        strength: strength,
        frequency: frequency,
        dose: dose,
        drugDdidCode: gpi,
        status: medicationStatus,
        dosageForm: dosageForm,
        drugShortName: ddid,
        startDate: medicationStartDate,
        endDate: medicationEndDate,
      },
    }
    );

    if (!httpResponse.error) {
      emptyStates();
      fetchMedicationHistory(patientId);
      // console.log(httpResponse.data);
    } else {
      emptyStates();
      console.log(httpResponse.error);
    }
  };

  const handleDeleteModal = (id) => {
    setDeleteMedicationHistoryModal(!deleteMedicationHistoryModal);
    if (id !== undefined) {
      setDeleteMedicationHistoryId(id);
    } else {
      setDeleteMedicationHistoryId(null);
    }
  }
  
  const deleteMedicationHistoryRequestHandler = async (medicationHisyoryId) => {
    const httpResponse = await sendHttpRequest({
      url: `/encounter/medicationHistory/${medicationHisyoryId}`,
      method: "DELETE",
    });
    if (!httpResponse.error) {
      handleDeleteModal();
      fetchMedicationHistory(patientId);
    } else {
      emptyStates();
    }
  };

  const emptyStates = () => {
    setMedicationName("");
    setStrength("");
    setFrequency("");
    setDose("");
    setStartDate("");
    setEndDate("");
    setMedicationStatus(true);
    setMedicationStartDate(null);
    setMedicationEndDate(null);
  };

  const handleEditModal = (event) => {
    let id = event.target.id;
    setUpdateMedicationHisyoryId(id);

    const mhcResult = medicationHistory?.filter((item) => {
      return item.medicationHistoryId == id;
    });

    if (mhcResult?.length > 0) {
      setEditMedicalHistory(mhcResult[0]);
      setMedicationName(mhcResult[0].drugName);
      setStrength(mhcResult[0].strength);
      setFrequency(mhcResult[0].frequency);
      setDose(mhcResult[0].dose);
      setGpi(mhcResult[0].drugDdidCode);
      setDdid(mhcResult[0].drugShortName);
      setDosageForm(mhcResult[0].dosageForm);
      setMedicationStatus(mhcResult[0].status)
      setMedicationStartDate(mhcResult[0].startDate)
      setMedicationEndDate(mhcResult[0].endDate)
    }

    setOpenEditModal(!openModal);
  };

  useEffect(() => {
    fetchMedicationHistory(patientId || props?.patientId);
    getFrequencyValue();
  }, [props?.patientId, patientId]);

  return (
    <>
    
    <MedicationHistoryModal
        show={openModal}
        handleModal={handleModal}
        setMedicationName={setMedicationName}
        setStrength={setStrength}
        setFrequency={setFrequency}
        setDose={setDose}
        frequencyList={frequencyList}
        setGpi={setGpi}
        setDdid={setDdid}
        setDosageForm={setDosageForm}
        dosageForm={dosageForm}
        setMedicationStatus={setMedicationStatus}
        medicationStatus={medicationStatus}
        setMedicationStartDate={setMedicationStartDate}
        setMedicationEndDate={setMedicationEndDate}
        addMedicationHistoryRequestHandler={addMedicationHistoryRequestHandler}
      />

      <DeleteConfirmationModal
      show = {deleteMedicationHistoryModal}
      handleModal = {handleDeleteModal}
      modalText = {"Are you sure you want to remove this entry from the patient’s medication history?"}
      deleteMedicaitonHistoryId={deleteMedicationHistoryId}
      deleteMedicationHistoryRequestHandler={
        deleteMedicationHistoryRequestHandler
      }
      />

      {openEditModal && (
        <MedicationHistoryEditModal
          show={openEditModal}
          handleModal={OpeneditModal}
          setStrength={setStrength}
          setMedicationName={setMedicationName}
          setMedicationStatus={setMedicationStatus}
          editMedicalHistory={editMedicalHistory}
          frequencyList={frequencyList}
          setFrequency={setFrequency}
          setDose={setDose}
          setGpi={setGpi}
          setDdid={setDdid}
          setDosageForm={setDosageForm}
          dosageForm={dosageForm}
          frequency={frequency}
          strength={strength}
          gpi={gpi}
          ddid={ddid}
          setMedicationStartDate={setMedicationStartDate}
          setMedicationEndDate={setMedicationEndDate}
          editMedicationHistoryRequestHandler={
            editMedicationHistoryRequestHandler
          }
        />
      )}

      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className=" align-self-center">
          <h4 className="h18">Medication History</h4>
          {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
        </div>
        <div className="d-flex align-self-center " style={{height:"40px", width:"40px"}}>
            {!props?.viewOnly && (
              <img
                style={{ color: "var(--primary)" }}
                className="btn shadow-none"
                onClick={() => handleModal()}
                src={AddButtonIcon}
              >
                {/* <AddButtonIcon /> */}
              </img>
            )}
        </div>
      </div>

          <TableContainer
            sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
          >
            <Table>
              <TableHead
                sx={{
                  borderTopColor: "#FAFCFC",
                  borderRightColor: "#FAFCFC",
                  borderLeftColor: "#FAFCFC",
                  borderBottom: "1px solid #D9E8EC",
                }}
              >
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Strength</StyledTableCell>
                  <StyledTableCell>Dose</StyledTableCell>
                  <StyledTableCell>Frequency</StyledTableCell>
                  <StyledTableCell>Medication Status</StyledTableCell>
                  {!props?.viewOnly && (
                    <>
                      {/* <StyledTableCell></StyledTableCell> */}
                      <StyledTableCell></StyledTableCell>{" "}
                    </>
                  )}
                </StyledTableRow>
              </TableHead>
              {
              medicationHistory.length > 0 ?
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    {!props?.viewOnly && (
                      <>
                        {/* <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell> */}
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                ) :
                  medicationHistoryStatusCode === 200 ? (
                    medicationHistory?.length !== 0 ? (
                      medicationHistory?.map((row, index) => {
                        return (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell sx={{ maxWidth: 200, minWidth: 150, fontWeight: 600 }}>
                              {row?.drugName !== undefined &&
                                row?.drugName !== ""
                                ? row.drugName
                                : "Not available"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              {row?.strength !== undefined &&
                                row?.strength !== ""
                                ? row.strength
                                : "Not available"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              {row?.dose !== undefined &&
                                row?.dose !== ""
                                ? row.dose
                                : "Not available"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              {row?.frequency !== undefined &&
                                row?.frequency !== ""
                                ? row.frequency
                                : "Not available"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              <span
                                className={` px-2 py-1 fw-sb font-14 br-10 ${row?.status === true
                                  ? "bg--mild text--secondary"
                                  : "bg--severe text--primary"
                                  }  d-inline-block`}
                              >
                                {row?.status !== undefined &&
                                  row?.status === true
                                  ? "Active"
                                  : "Inactive"}
                              </span>
                            </StyledTableCell>
                            {!props?.viewOnly && (
                              <>
                                {/* <StyledTableCell /> */}
                                <StyledTableCell style={{ cursor: "pointer" }}>
                                  <div className="d-flex flex-row col-10 justify-content-end">
                                    <div className="row">
                                      <div className="col-4">
                                      {
                                        row.currentMedication ? (
                                          <EditIcon
                                            style={{ color: "#336383", height: "15px", width: "15px", pointerEvents: "none", opacity: 0.5 }}
                                          />
                                        ) : (
                                          <EditIcon
                                            style={{ color: "#336383", height: "15px", width: "15px" }}
                                            onClick={(e) => handleEditModal(e)}
                                            id={row.medicationHistoryId}
                                          />
                                        )
                                      }
                                      </div>
                                      <div className="col-4">
                                      {
                                        row.currentMedication ? (
                                          <TrashIcon
                                            style={{ color: "#ec609b", height: "15px", width: "15px", pointerEvents: "none", opacity: 0.5 }}
                                          />
                                        ) : (
                                          <TrashIcon
                                            style={{ color: "#ec609b", height: "15px", width: "15px" }}
                                            id={row.medicationHistoryId}
                                            onClick={(e) => handleDeleteModal(e.target.id)}
                                          />
                                        )
                                      }
                                      </div>
                                    </div>
                                  </div>
                                </StyledTableCell>
                              </>
                            )}
                          </StyledTableRow>
                        );
                      })
                    )
                      : " "
                  ) : ''
                }





              </TableBody>
              : <p className="m-2 font-14 p-1">No data available</p>
            }
            </Table>
          </TableContainer>
    </>
  );
};
export default MedicationHistoryTable;
