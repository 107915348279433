import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { calenderDateTimeHelper, dateTimeHelper } from "../../utils/dateTimeHelper";

import TextArea from "../forms/TextArea";
import {
  numbersEnum,
  renewalRequestStrings,
  common_strings,
  reusable_strings,
} from "../../res/strings";
import {
  fieldValidation,
  formatPhoneNumber,
  formatPostalCode,
  genderMap,
  isValidInteger,
} from "../../utils/helpers/HelperFunctions";
import classes from "./RenewalRx.module.css";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import {
  acceptRenewalRequestUrl,
  fetchPatientObservationsURL,
  fetchRxTask,
} from "../../res/endpointurls";
import { useAuth } from "../hooks/useAuth";
import { Backdrop, CircularProgress } from "@mui/material";
import { useUnsavedWorkPrompt } from "../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import { updatePrescribedMedication } from "../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { WarningIcon } from "../UI/Reusable/WarningIcon";

const rxRenewalResponseApiCall = async (userId, taskId, action, refills, note, spi) => {
  const body = {
    taskId: taskId,
    action: action,
    refills: refills,
    note: note,
  };
  const httpResponse = await sendHttpRequest({
    url: acceptRenewalRequestUrl(userId, spi),
    method: "POST",
    data: body,
  });
  return httpResponse;
};

const RenewalRxModal = (props) => {
  const dispatch = useDispatch();
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  let _str = {
    ...numbersEnum?.en,
    ...renewalRequestStrings.en,
    ...common_strings.en,
    ...reusable_strings,
  };
  const { user } = useAuth();
  const task = props?.renewalTask;
  let prescriber = props?.renewalTask?.prescriptionReference?.providerTenantPracticeLocation?.provider;
  let facility = props?.renewalTask?.prescriptionReference?.providerTenantPracticeLocation?.facility;
  let patient = props?.renewalTask?.prescriptionReference?.patient;
  let patientName = props?.renewalTask?.prescriptionReference?.patient?.name[0];
  let pharmacy = props?.renewalTask?.prescription?.pharmacy;
  let originalPrescription =
    props?.renewalTask?.rxRenewalRequest?.originalPrescription;

  let requestedMedication = props?.renewalTask?.prescription;
  let prescriberPrimaryPhone =
    prescriber?.communicationNumbers?.numbers?.find((numberItem) => {
      return numberItem?.type === _str.cellPhoneExt;
    })?.number || null;

  let patientPrimaryPhone =
    patient?.communicationNumbers?.numbers?.find((numberItem) => {
      return numberItem?.type === _str.cellPhoneExt;
    })?.number || null;

  const [refills, setRefills] = useState(
    requestedMedication?.refills === 0 ? "" : requestedMedication?.refills
  );
  const [open, setOpen] = useState(false);
  const [regexError, setRegexError] = useState({ refills: false });
  const [patientObservations, setPatientObservations] = useState();
  const [note, setNote] = useState('');
  const [patientDB, setPatientDB] = useState(null);
  const [patientVitals, setPatientVitals] = useState(null);
  const [demographic, setDemographic] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isAddressChanged, setIsAddressChanged] = useState(false);
  const [isGenderChanged, setIsGenderChanged] = useState(false);
  const [isNumberChanged, setIsNumberChanged] = useState(false);
  let __str = numbersEnum?.en;

  const handleRefillChange = (event) => {
    setRefills(event.target.value);
    onDirty();
    if (isValidInteger(event.target.value)) {
      setRegexError((previousSnapshot) => ({
        ...previousSnapshot,
        refills: false,
      }));
    } else {
      setRegexError((previousSnapshot) => ({
        ...previousSnapshot,
        refills: true,
      }));
    }
  };

  const handleRenewalRequest = async (event) => {
    const selectedAction = event.target.innerText;
    if (selectedAction === _str.approve) {
      if (document.getElementById("refillsForm").reportValidity()) {
        setOpen(true);
        const response = await rxRenewalResponseApiCall(
          user?.userId,
          task?.taskId,
          _str.approved,
          refills,
          note,
          user?.spi
        );
        if (response.error === false) {
          props.showMessage(true);
          onPristine();
          props.reloadTasks(p => !p);
          props.handleModal();
        } else {
          props.showError(true);
        }
        setOpen(false);
      }
    } else if (selectedAction === _str.deny) {
      //Denial Reason Modal
      // unsavedWorkHandler(
      //   "modal",
      //   "There are unsaved changes, do you wish to continue?",
      //   false,
      //   props.handleModal,
      //   onPristine,
      //   props.handleModal
      // );
      props.handleDenyRequestModal();
    } else if (selectedAction === _str.approveWithChanges) {
      setOpen(true);
      const response = await rxRenewalResponseApiCall(
        user?.userId,
        task?.taskId,
        _str.approved_with_changes,
        refills,
        note,
        user?.spi
      );
      if (response.error === false) {
        props.showMessage(true);
        props.reloadTasks(p => !p);
        props.handleModal();
      } else {
        props.showError(true);
      }
      setOpen(false);
    } else if (selectedAction === _str.replace) {
      //Navigate to page with state containing task
      console.log("Refill Value", requestedMedication?.refills);
      dispatch(
        updatePrescribedMedication([
          {
            DDID: requestedMedication?.drugId,
            drugDbCode: requestedMedication?.drugId,
            drugDescription: originalPrescription?.drugDescription,
            ndc: requestedMedication?.ndc,

            Sig: {
              SigText: requestedMedication?.sigText,
            },
            Quantity: parseFloat(requestedMedication?.quantity),
            daySupply: requestedMedication?.daysSupply,
            NumberOfRefills:
              requestedMedication?.refills !== undefined &&
                requestedMedication?.refills !== "" &&
                requestedMedication?.refills !== 0
                ? requestedMedication?.refills
                : 1,
            RefillAsNeeded: false,
            DispenseAsWritten: false,
            // Note: requestedMedication?.pharmacyNote !== undefined ? requestedMedication?.pharmacyNote : "",
            Note: "",
            DoseForm: requestedMedication?.quantityUnitOfMeasure || "",
            PrescriptionStartDate: {
              Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
            },
            EarliestFillDate: {
              Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
            },
            WrittenDate: {
              Date: calenderDateTimeHelper(
                requestedMedication?.writtenDate,
                "YYYY-MM-DD"
              ),
            },
            alertPhysician72: requestedMedication?.alertPhysician,
            PatientDirections: "",
            flag: true,
            quantityUom: requestedMedication?.quantityUnitOfMeasure,
            quantityUnitOfMeasureCode:
              requestedMedication?.quantityUnitOfMeasureCode,
          },
        ])
      );
      props.handleReplaceRenewalRequest();
      props.handleModal();
    }
  };

  const fetchPatientObservations = async (patientId) => {
    const httpResponse = await sendHttpRequest({
      url: fetchPatientObservationsURL(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPatientObservations(httpResponse.data);
    } else {
      console.log(httpResponse);
    }
  };

  const convertToString = (string) => {

    if (string?.length === 10) {
      string = string.replace("-", "");
    }

    return string;

  }

  const checkDemographics = (data) => {

    if (data?.rxRequest?.originalPrescription.prescriptionReference.patient !== null) {
      let patientNameLocal;
      let patientNamePharmacy;
      let patientAddressLocal;
      let patientAddressPharmacy;
      let patientGenderLocal;
      let patientGenderPharmacy;
      let patientNumberLocal;
      let patientNumberPharmacy;
      let patientAddressLocalPostalCode;
      let patientAddressPharmacyPostalCode;

      try {
        patientNameLocal = structuredClone(data?.rxRequest?.originalPrescription.prescriptionReference.patient.name[0]);
        patientNameLocal = fieldValidation(patientNameLocal?.middleName, "all") ? `${patientNameLocal?.firstName?.trim()} ${patientNameLocal?.middleName?.trim()} ${patientNameLocal?.lastName?.trim()}` : `${patientNameLocal?.firstName?.trim()} ${patientNameLocal?.lastName?.trim()}`
        patientNamePharmacy = fieldValidation(patientName?.middleName, "all") ? `${patientName?.firstName?.trim()} ${patientName?.middleName?.trim()} ${patientName?.lastName?.trim()}` : `${patientName?.firstName?.trim()} ${patientName?.lastName?.trim()}`;

        patientAddressLocal = structuredClone(data?.rxRequest?.originalPrescription.prescriptionReference.patient.address[0]);
        patientAddressLocal = fieldValidation(patientAddressLocal?.addressLine2, "all") ? `${patientAddressLocal?.addressLine1?.trim()} ${patientAddressLocal?.addressLine2?.trim()} ${patientAddressLocal?.city?.trim()} ${patientAddressLocal?.stateProvince?.trim()} ${convertToString(patientAddressLocal?.postalCode?.trim())}` : `${patientAddressLocal?.addressLine1?.trim()} ${patientAddressLocal?.city?.trim()} ${patientAddressLocal?.stateProvince?.trim()} ${convertToString(patientAddressLocal?.postalCode?.trim())}`;
        console.log(patientAddressLocal);
        patientAddressPharmacy = structuredClone(patient.address[0]);
        patientAddressPharmacy = fieldValidation(patientAddressPharmacy?.addressLine2, "all") ? `${patientAddressPharmacy?.addressLine1?.trim()} ${patientAddressPharmacy?.addressLine2?.trim()} ${patientAddressPharmacy?.city?.trim()} ${patientAddressPharmacy?.stateProvince?.trim()} ${convertToString(patientAddressPharmacy?.postalCode?.trim())}` : `${patientAddressPharmacy?.addressLine1?.trim()} ${patientAddressPharmacy?.city?.trim()} ${patientAddressPharmacy?.stateProvince?.trim()} ${convertToString(patientAddressPharmacy?.postalCode?.trim())}`;
        console.log(patientAddressPharmacy);

        patientGenderLocal = genderMap?.get(data?.rxRequest?.originalPrescription.prescriptionReference.patient.gender?.toLowerCase()) ?? data?.rxRequest?.originalPrescription.prescriptionReference.patient.gender;
        patientGenderPharmacy = genderMap?.get(patient.gender?.toLowerCase()) ?? patient.gender;

        patientNumberLocal = structuredClone(data?.rxRequest?.originalPrescription.prescriptionReference.patient?.communicationNumbers.numbers);
        patientNumberLocal = patientNumberLocal?.numbers?.find((numberItem) => { return numberItem?.type === __str.cellPhoneExt; }) || null;
        patientNumberLocal = formatPhoneNumber(patientNumberLocal?.number);
        patientNumberPharmacy = structuredClone(patient?.communicationNumbers.numbers);
        patientNumberPharmacy = patientNumberPharmacy?.numbers?.find((numberItem) => { return numberItem?.type === __str.cellPhoneExt; }) || null;
        patientNumberPharmacy = formatPhoneNumber(patientNumberPharmacy?.number);
      }
      catch (err) {
        //nothing
      }

      if (patientNameLocal !== patientNamePharmacy && (fieldValidation(patientNameLocal, "all") && fieldValidation(patientNamePharmacy, "all"))) {
        setIsNameChanged(true);
      }
      if (patientAddressLocal !== patientAddressPharmacy && (fieldValidation(patientAddressLocal, "all") && fieldValidation(patientAddressPharmacy, "all"))) {
        setIsAddressChanged(true);
      }
      if (patientGenderLocal !== patientGenderPharmacy && (fieldValidation(patientGenderLocal, "all") && fieldValidation(patientGenderPharmacy, "all"))) {
        setIsGenderChanged(true);
      }
      if (patientNumberLocal !== patientNumberPharmacy && (fieldValidation(patientNumberLocal, "all") && fieldValidation(patientNumberPharmacy, "all"))) {
        setIsNumberChanged(true);
      }

      if (((JSON.stringify(patientNameLocal) !== JSON.stringify(patientNamePharmacy)) && (fieldValidation(patientNameLocal, "all") && fieldValidation(patientNamePharmacy, "all")))
        || ((JSON.stringify(patientAddressLocal) !== JSON.stringify(patientAddressPharmacy)) && (fieldValidation(patientAddressLocal, "all") && fieldValidation(patientAddressPharmacy, "all")))
        || ((JSON.stringify(patientGenderLocal) !== JSON.stringify(patientGenderPharmacy)) && (fieldValidation(patientGenderLocal, "all") && fieldValidation(patientGenderPharmacy, "all")))
        || ((JSON.stringify(patientNumberLocal) !== JSON.stringify(patientNumberPharmacy)) && (fieldValidation(patientNumberLocal, "all") && fieldValidation(patientNumberPharmacy, "all")))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const checkForDOB = (portalDob, patient) => {

    let localDob = fieldValidation(portalDob?.dateOfBirth, "all") ? calenderDateTimeHelper(portalDob?.dateOfBirth, "MMM DD, YYYY") : null;
    let pharmacyDob = fieldValidation(patient?.dateOfBirth, "all") ? calenderDateTimeHelper(patient?.dateOfBirth, "MMM DD, YYYY") : null;

    if ((localDob !== pharmacyDob) && (fieldValidation(localDob, "all") && (fieldValidation(pharmacyDob, "all")))) {
      return [true, pharmacyDob];
    } else {
      return [false, localDob];
    }

  }

  let substitutionMap = (key) => {
    const obj = {
      "0": "Allowed",
      "1": "Not Allowed",
      "false": "Allowed",
      "true": "Not Allowed",
      0: "Allowed",
      1: "Not Allowed",
    };
    return obj[key];
  };

  const alwaysfetch = async (taskId) => {
    const httpResponse = await sendHttpRequest({
      url: fetchRxTask(taskId),
      method: "GET",
      params: {
        tenantId: user?.tenantId
      }
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      let [isEqual, dob] = checkForDOB(httpResponse.data.rxRequest.originalPrescription.prescriptionReference.patient, patient);
      // console.log(dob);
      let vitals = httpResponse.data?.rxRequest?.vital !== undefined && httpResponse.data?.rxRequest?.vital !== null ? httpResponse.data?.rxRequest?.vital : null;
      // let demographic = checkDemographics(httpResponse.data);
      // if (isEqual) {
      //   setDemographic(false);
      setPatientDB(isEqual);
      // } else {
      //   setDemographic(demographic);
      // }
      setPatientVitals(vitals);
    } else {
      console.log(httpResponse);
    }
  };

  useEffect(() => {
    alwaysfetch(props?.renewalTask?.taskId);
    // fetchPatientObservations(patient?.userId);
    // return (() => {
    //   setPatientDB(null);
    // })
  }, []);

  // let patient = props?RxObject?.patient;
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          unsavedWorkHandler(
            "modal",
            "There are unsaved changes, do you wish to continue?",
            isDirty,
            props.handleModal,
            onPristine,
            props.handleModal
          );
          // props.handleModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName={`modal-border modal-shadow ${classes["renewalRxmodal__size"]}`}
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes, do you wish to continue?",
                isDirty,
                props.handleModal,
                onPristine,
                props.handleModal
              );
              // props.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            {_str.renewalRequest}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0.5rem' }}>
          {/* {patientDB !== null ?
            <div className="row justify-content-center">
              <div className="col-sm-12 mb-3">
                <div className="m-0 p-0 font-13 fw-sb text--terciary br-10">
                  <WarningIcon style={{ height: "18px", fill: "#f8a02e" }} />{_str.renewalRequestTitle}
                </div>
              </div>
            </div> : ""} */}
          {patientDB === null && demographic ? ""
            // <div className="row justify-content-center">
            //   <div className="col-sm-12 mb-3">
            //     <div className="m-0 p-0 font-13 fw-sb text--terciary br-10">
            //       <WarningIcon style={{ height: "18px", fill: "#f8a02e" }} />{_str.renewalRequestInfoChange}
            //     </div>
            //   </div>
            // </div> 
            : ""}
          {patient.userId == 0 ?
            <div className="row justify-content-start">
              <div className="col-sm-6"><WarningIcon style={{ height: "18px", fill: "#f8a02e" }} /> <span className="font-12 text--terciary fw-sb">No patient record found.</span></div>
            </div> : ""}
          <div className="row">
            {/* ------------------Prescriber Information------------------- */}
            <div className="col-12 col-md-12">
              <h3 className="text--terciary font-14 fw-sb m-0">
                {_str.prescriber}
              </h3>
              <div className="text--terciary font-12 border--bottom">
                <div>
                  <span className="text--terciary font-12 ">
                    {_str.prescriberName}: &nbsp;
                  </span>
                  <span>
                    {"".concat(
                      prescriber?.name[0]?.firstName,
                      " ",
                      prescriber?.name[0]?.lastName
                    )}
                  </span>
                </div>

                <div>
                  <span className="text--terciary font-12 ">
                    {_str.address}: &nbsp;
                  </span>
                  <span>
                    {facility?.addressLine1},{" "}
                    {facility?.addressLine2?.length > 0 ? facility?.addressLine2 + ", " : ""}
                    {facility?.city},{" "}
                    {facility?.state},{" "}
                    {facility?.zipCode}{" "}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text--terciary font-12 ">
                    {_str.phone}: &nbsp;
                  </span>
                  <span>{formatPhoneNumber(prescriberPrimaryPhone)}</span>
                </div>
              </div>
            </div>

            {/* ------------------Patient Information------------------- */}
            <div className="col-12 col-md-12 mt-2">
              <h3 className="text--terciary font-14 fw-sb m-0">{_str.patient}</h3>
              <div className="text--terciary font-12 border--bottom">
                <div>
                  <span className="text--terciary font-12 ">
                    {_str.patientName}: &nbsp;
                  </span>
                  <span className={isNameChanged ? `text--red font-12` : `text--terciary font-12`}>
                    {`${patientName?.firstName || ''} ${patientName?.middleName !== null ? patientName?.middleName : ''} ${patientName?.lastName || ''}`}
                  </span>
                </div>
                {
                  // patientDB === null ?
                  <div>
                    <span className="text--terciary font-12 ">
                      {_str.dob}: &nbsp;
                    </span>
                    <span>
                      {calenderDateTimeHelper(
                        patient?.dateOfBirth,
                        "MMM DD, YYYY"
                      )}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <span className="text--terciary font-12 ">
                      {_str.gender}: &nbsp;
                    </span>
                    <span className={isGenderChanged ? `text--red font-12` : `text--terciary font-12`}>{genderMap.get(patient?.gender?.toLowerCase())}</span>
                    {patientVitals?.height !== undefined &&
                      patientVitals?.height !== null && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>{_str.height_str}: &nbsp;</span>
                          <span className="text--terciary font-12 ">
                            {patientVitals?.height?.concat(" inches")}
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>{_str.weight_str}: &nbsp;</span>
                          <span className="text--terciary font-12 ">
                            {patientVitals?.weight?.concat(" lbs")}
                          </span>
                        </>
                      )}
                  </div>
                  // :
                  // <>
                  //   <div>
                  //     <span className="text--red font-12 ">
                  //       {_str.dob_on_file}: &nbsp;
                  //     </span>
                  //     <span className="text--red font-12 ">
                  //       {calenderDateTimeHelper(
                  //         patient?.dateOfBirth,
                  //         "YYYY-MM-DD"
                  //       )}{" "}
                  //       &nbsp;&nbsp;&nbsp;&nbsp;
                  //     </span>
                  //     <span className="text--red font-12 ">
                  //       {_str.dob_from_pharmacy} : &nbsp;
                  //     </span>
                  //     <span className="text--red font-12 ">
                  //       {calenderDateTimeHelper(
                  //         patientDB,
                  //         "YYYY-MM-DD"
                  //       )}{" "}
                  //     </span>
                  //   </div>
                  //   <div>
                  //     <span className="text--terciary font-12 ">
                  //       {_str.gender}: &nbsp;
                  //     </span>
                  //     <span className={isGenderChanged ? `text--red font-12` : `text--terciary font-12`}>{genderMap.get(patient?.gender?.toLowerCase())}</span>
                  //     {patientVitals?.height !== undefined &&
                  //       patientVitals?.height !== null && (
                  //         <>
                  //           &nbsp;&nbsp;&nbsp;&nbsp;
                  //           <span>{_str.height_str}: &nbsp;</span>
                  //           <span className="text--terciary font-12 ">
                  //             {patientVitals?.height?.concat(" inches")}
                  //           </span>
                  //           &nbsp;&nbsp;&nbsp;&nbsp;
                  //           <span>{_str.weight_str}: &nbsp;</span>
                  //           <span className="text--terciary font-12 ">
                  //             {patientVitals?.weight?.concat(" lbs")}
                  //           </span>
                  //         </>
                  //       )}
                  //   </div>
                  // </>
                }

                <div>
                  <span className="text--terciary font-12 ">
                    {_str.address}: &nbsp;
                  </span>
                  <span className={isAddressChanged ? `text--red font-12` : `text--terciary font-12`}>
                    {" "}
                    {"".concat(
                      patient?.address[0]?.addressLine1,
                      ", ",
                      patient?.address[0]?.addressLine2?.length > 0 ? patient?.address[0]?.addressLine2 + ", " : '',
                      patient?.address[0]?.city,
                      ", ",
                      patient?.address[0]?.stateProvince,
                      " ",
                      patient?.address[0]?.postalCode
                    )}{" "}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text--terciary font-12 ">
                    {_str.phone}: &nbsp;
                  </span>
                  <span className={isNumberChanged ? `text--red font-12` : `text--terciary font-12`}>{formatPhoneNumber(patientPrimaryPhone)}</span>
                </div>
              </div>
            </div>

            {/* ----------------------Pharmacy Information-------------------- */}
            <div className="col-12 col-md-12 mt-2">
              <h3 className="text--terciary font-14 fw-sb m-0">{_str.pharmacy}</h3>
              <div className="text--terciary font-12 border--bottom">
                <div>
                  <span className="text--terciary font-12 ">
                    {_str.pharmacyLabelName}: &nbsp;
                  </span>
                  <span>{pharmacy?.standardizedOrganizationName}</span>
                </div>

                <div>
                  <span className=" font-12">{_str.address}: &nbsp;</span>
                  {"".concat(
                    pharmacy?.standardizedAddressLine1?.concat(", ") || "",
                    pharmacy?.standardizedAddressLine2
                      ?.length > 0
                      ? pharmacy?.standardizedAddressLine2.concat(", ")

                      : "",
                    pharmacy?.standardizedCity?.concat(", ") || "",
                    pharmacy?.stateProvince?.concat(", ") || "",

                    formatPostalCode(pharmacy?.standardizedPostalCode || "")
                  )}
                </div>
                <div className="mb-2">
                  <span className=" font-12">{"NPI: "} &nbsp;</span>
                  {(pharmacy.npi !== undefined && pharmacy.npi !== null ? pharmacy.npi : '')}
                  <span className=" font-12">&nbsp;&nbsp;&nbsp;&nbsp;{_str.phone}: &nbsp;</span>
                  {formatPhoneNumber(pharmacy?.primaryTelephone)}
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className=" font-12">{_str.fax}: &nbsp;</span>
                    {formatPhoneNumber(pharmacy?.fax)}
                  </span>
                </div>
              </div>
            </div>

            {/* ----------------------Drug Information------------------------- */}
            <div className="col-12 col-md-12 mt-2 ">
              <div className="row border--bottom">
                <div className="col-12 col-sm-6">
                  <h3 className="text--terciary font-14 fw-sb m-0">
                    {_str.originalPrescription}
                  </h3>
                  {
                    (originalPrescription?.drugDescription === undefined ||
                      originalPrescription?.drugDescription === null) &&
                    <>
                      <WarningIcon style={{ height: "12px", fill: "#f8a02e" }} />
                      <span className="font-12 ms-0 ps-0">{_str.notAvailable}</span>
                    </>
                  }
                  <div className="text--terciary font-12 " hidden={!fieldValidation(originalPrescription?.drugDescription, "all")}>
                    <div>
                      <span className="text--terciary font-12 ">
                        {_str.drugDescription}: &nbsp;
                      </span>
                      <span>{originalPrescription?.drugDescription || ""}</span>
                    </div>
                    <div>
                      <span className="">{_str.sig}: &nbsp;</span>
                      {"".concat(originalPrescription?.sigText || "")}
                    </div>
                    <div>
                      <span className="">{_str.quantity}: &nbsp;</span>
                      {"".concat("", parseFloat(originalPrescription?.quantity) || "", " ", originalPrescription?.quantityUnitOfMeasure || "")}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="">{_str.daysSupply}: &nbsp;</span>
                      {originalPrescription?.daysSupply || ""}
                    </div>

                    <div className="d-flex flex-row">
                      <label
                        htmlFor="refills"
                        className="font-12 text--terciary col-form-label col-form-label-sm"
                      >
                        {_str.refills}:
                      </label>
                      <div className="col-sm-3 ms-2">
                        <input
                          disabled
                          type="number"
                          defaultValue={originalPrescription?.refills}
                          className="form-control br-10 text-center font-12 shadow-none input form-control-sm"
                          id="refills"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div>
                      <span>
                        <span className="">{_str.substitutions}: &nbsp;</span>
                        {
                          fieldValidation(
                            originalPrescription?.substitution,
                            "all"
                          ) ? substitutionMap(originalPrescription.substitution) : ""}
                      </span>
                    </div>
                    {
                      originalPrescription?.earliestFillDate !== undefined ? 
                      <div>
                        <span className="">{_str.effectiveDate}: &nbsp;</span>
                        {fieldValidation(
                          originalPrescription?.earliestFillDate,
                          "all"
                        ) ? (
                          "".concat(
                            calenderDateTimeHelper(
                              originalPrescription?.earliestFillDate,
                              "YYYY-MM-DD"
                            )
                          )) : ""}
                      </div>
                    : null
                    }
                    <div className="mb-2">
                      {fieldValidation(
                        originalPrescription?.pharmacyNote,
                        "all"
                      ) && (
                          <div>
                            <span className="">{_str?.note}: &nbsp;</span>
                            {"".concat("", originalPrescription?.pharmacyNote)}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6">
                  <h3 className="text--terciary font-14 fw-sb m-0">
                    {_str.requestedMedication}
                  </h3>
                  <div className="text--terciary font-12 ">
                    <div>
                      <span className="text--terciary font-12 ">
                        {_str.drugDescription}: &nbsp;
                      </span>
                      <span>{requestedMedication?.drugDescription}</span>
                    </div>
                    <div>
                      <span className="">{_str.sig}: &nbsp;</span>
                      {"".concat(requestedMedication?.sigText)}
                    </div>
                    <div>
                      <span className="">{_str.quantity}: &nbsp;</span>
                      {"".concat("", parseFloat(requestedMedication?.quantity), " ", requestedMedication?.quantityUnitOfMeasure)}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="">{_str.daysSupply}: &nbsp;</span>
                      {requestedMedication?.daysSupply || _str.notAvailable}
                    </div>

                    <div className="d-flex flex-row">
                      <label
                        htmlFor="refills"
                        className="font-12 text--terciary col-form-label col-form-label-sm"
                      >
                        {_str.refills}:
                      </label>
                      <div className="col-sm-3 ms-2">
                        <form
                          id="refillsForm"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <PnToolTip
                            title={`Total quantity to be dispensed = ${Number(refills) * parseFloat(requestedMedication?.quantity)} ${requestedMedication?.quantityUnitOfMeasure}`}>
                            <input
                              required
                              form="refillsForm"
                              type="number"
                              value={refills}
                              onChange={handleRefillChange}
                              className="form-control text-center font-12 br-10 shadow-none input form-control-sm"
                              id="refills"
                            />
                          </PnToolTip>
                        </form>
                        {regexError?.refills === true ? (
                          <span className="text--danger py-1 font-10 d-block">
                            {"Invalid value"}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <span>
                        <span className="">{_str.substitutions}: &nbsp;</span>
                        {substitutionMap(requestedMedication?.substitution)}
                      </span>
                    </div>

                    {fieldValidation(
                      requestedMedication?.pharmacyNote,
                      "all"
                    ) && (
                        <div>
                          <span className="">{_str?.note}: &nbsp;</span>
                          {"".concat("", requestedMedication?.pharmacyNote)}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* -------------------------Note to Pharmacy----------------------- */}
            <div className="col-12 col-md-12 mt-2">
              <h3 className="font-14 text--terciary m-0">Note</h3>
              <TextArea
                className="form-control br-10 input font-14 border--default shadow-none"
                gettext={(text) => setNote(text)}
                rows={2}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer hidden={userPermissions?.apr === false ? true : false}>
          <button
            disabled={regexError?.refills === true || patientDB === true || patient?.userId === 0 ? false : false}
            className="btn d9e8ec--button br-10 py-2"
            onClick={(e) => {
              handleRenewalRequest(e);
              // props.handleModal();
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "40px",
            }}
          >
            {_str.replace}
          </button>

          <button
            disabled={regexError?.refills === true ? true : false}
            className="btn d9e8ec--button br-10 py-2"
            onClick={(e) => {
              handleRenewalRequest(e);
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "40px",
            }}
          >
            {_str.deny}
          </button>

          <button
            disabled={regexError?.refills === true || patient?.userId === 0 ? true : false}
            className="btn blue_task_button br-10 py-2"
            onClick={(e) => {
              handleRenewalRequest(e);
              // props.handleModal();
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              height: "40px",
              width: "160px",
            }}
          >
            {_str.approve}
          </button>
        </Modal.Footer>
      </Modal>

      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
    </>
  );
};

export default RenewalRxModal;
