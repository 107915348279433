
import { useRef, useState } from 'react';
import { ChevronBarDown, ChevronDown, X } from 'react-bootstrap-icons';
import CptCodeSearch from '../cptcodes-search/CptCodeSearch';
import useCptCodeSearch from '../cptcodes-search/useCptCodeSearch';
import useOnClickOutside from '../hooks/useOnClickOutside';
import ModifiersSearch from '../modifiers-search/ModifiersSearch';
import useModifiersSearch from '../modifiers-search/useModifiersSearch';
import NdcCodeSearch from '../ndc-search/NdcCodeSearch';
import useNdcCodeSearch from '../ndc-search/useNdcCodeSearch';
import CustomDatePicker from '../UI/General/CustomDatePicker';
import classes from "./ClaimService.module.css";
import CalenderDatePicker from '../UI/General/CalenderDatePicker';



export default function ClaimService(props) {
    const { cptSuggestions, setCptSuggestions, isCptLoading, setIsCptLoading, isCptError, setIsCptError, cptSearchInputValue, setCptSearchInputValue, cptCodeName, setCptCodeName, selectedCptCode, setSelectedCptCode, searchCptCode, handleCptCodeSearchInput, handleAddCptCode, handleRemoveCptCode } = useCptCodeSearch();

    const { ndcCodeSuggestions,
        setNdcCodeSuggestions,
        isNdcCodeLoading,
        setIsNdcCodeLoading,
        isNdcCodeError,
        setIsNdcCodeError,
        ndcCodeSearchInputValue,
        setNdcCodeSearchInputValue,
        ndcCodeName,
        setNdcCodeName,
        selectedNdcCode,
        setSelectedNdcCode,
        handleNdcCodeSearchInput,
        handleAddNdcCode,
        handleRemoveNdcCode } = useNdcCodeSearch();

    const {
        modifiersSuggestions,
        setModifiersSuggestions,
        isModifierLoading,
        setIsModifierLoading,
        isModifierError,
        setIsModifierError,
        modifierSearchInputValue,
        setModifierSearchInputValue,
        modifierName,
        setModifierName,
        selectedModifier,
        setSelectedModifier,
        searchModifier,
        handleModifierSearchInput,
        handleAddModifier,
        handleRemoveModifier

    } = useModifiersSearch();

    const [cptCodesList, setCptCodesList] = useState([]);
    const addCptCodeToList = (addedCptCode) => {
        handleAddCptCode(addedCptCode);
        setCptCodesList(prevList => [...prevList, addedCptCode]);
    }

    const removeCptCodeFromList = (removedCptCode) => {
        handleRemoveCptCode(removedCptCode);
        const tempList = cptCodesList.filter(cpt => cpt.code !== removedCptCode);
        setCptCodesList(prevList => [...tempList]);

    }

    const ref = useRef();
    const [toggleList, setToggleList] = useState(false);
    const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
    const handleSelectedDiagnoses = (e, diagnosis) => {
        if (e.target.checked) {
            setSelectedDiagnoses(d => [...d, diagnosis]);
        }
        else {
            const filteredDiagnoses = selectedDiagnoses?.filter(d => d?.code !== diagnosis?.code);
            setSelectedDiagnoses(filteredDiagnoses)
        }
    }


    useOnClickOutside(ref, (e) => {
        // console.log(e.target.id);
        if (e.target.id !== 'toggler' && e.target.id !== 'togglericon' && e.target.id !== 'parentdiv' && e.target.id !== 'tbox') {
            setToggleList(p => !p);
        }
    });

    return (
        <>
            <div className='row pt-3'>
                <div className='col-12'>
                    <h2 className='font-16 fw-sb m-0 p-0'>Service {props.serviceNumber}</h2>
                </div>
            </div>
            {/* Cpt and Diagnoses section */}
            <div className='row py-2'>
                <div className='col-12 col-sm-6'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>CPT Codes</span>
                    <CptCodeSearch
                        suggestions={cptSuggestions}
                        setSuggestions={setCptSuggestions}
                        isLoading={isCptLoading}
                        setIsLoading={setIsCptLoading}
                        isError={isCptError}
                        setIsError={setIsCptError}
                        searchInputValue={cptSearchInputValue}
                        setSearchInputValue={setCptSearchInputValue}
                        cptCodeName={cptCodeName}
                        setCptCodeName={setCptCodeName}
                        selectedCptCode={selectedCptCode}
                        setSelectedCptCode={setSelectedCptCode}
                        searchCptcode={searchCptCode}
                        handleSearchInput={handleCptCodeSearchInput}
                        handleAddCptCode={addCptCodeToList}
                    />
                    {cptCodesList?.map((cpt, index) => (
                        <div key={cpt.code} className='font-12 px-2 py-1 text--terciary d-flex justify-content-between'>
                            <div>{`${cpt?.code} - ${cpt.description}`}</div>
                            <div className='cross-sign' onClick={() => removeCptCodeFromList(cpt.code)}><X color="#aa0505" size={20} /></div>
                        </div>
                    ))}
                </div>
                <div className='col-12 col-sm-6'>
                    <span className='d-block font-12 text--secondary '>
                        Diagnoses
                    </span>
                    <div>
                        <div
                            className={`input-group border--default  br-10 ${classes["search__container"]}`}
                            id="parentdiv"
                            onClick={() => setToggleList(p => !p)}
                        >
                            <input
                                type="text"
                                className={`form-control not-writable  font-14 border-0 br-10 shadow-none ${classes.input}`}
                                placeholder="Select Diagnoses"
                                id="tbox"
                                disabled
                            // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}

                            />
                            <button
                                className={`btn br-10 shadow-none`}
                                id="toggler"

                            >
                                <ChevronDown id="togglericon" className="font-14 fw-sb text--primary" />
                            </button>
                        </div>
                        {/* {console.log(props?.diagnoses, toggleList)} */}
                        {toggleList ? <div
                            ref={props?.diagnoses?.length > 0 ? ref : null}
                            className={`  ${classes["suggestion__list"]} hide-scrolbar`}
                            style={{ width: `${document.getElementById('parentdiv')?.offsetWidth}px` }}
                        >
                            {
                                props?.diagnoses?.length > 0 &&
                                props?.diagnoses?.map((diagnosis) => {
                                    return (
                                        <li
                                            className={`cursor--pointer border--default ${classes.li}`}
                                            // onClick={() => props.prescribeDrug(props.selectedDrug)}
                                            key={diagnosis?.code}
                                        >
                                            <input
                                                type="checkbox"
                                                className="cursor--pointer"
                                                id={diagnosis?.code}
                                                value={diagnosis?.code}
                                                onChange={(e) => handleSelectedDiagnoses(e, diagnosis)}
                                                //   defaultChecked={checkExamIdMatched(diagnosis)}
                                                defaultChecked={selectedDiagnoses.includes(diagnosis)}
                                            />
                                            <label className="ms-1" >
                                                {`${diagnosis?.code} - ${diagnosis?.description} ${diagnosis?.type === "P" ? (`(Primary)`) : ''}`}
                                            </label>
                                        </li>
                                    )
                                }
                                )}

                        </div> : null}
                        {selectedDiagnoses?.map(d => {
                            return <div className='ms-1 py-1 font-12 d-flex'>{`${d.code} - ${d.description} ${d?.type === "P" ? (`(Primary)`) : ''}`}</div>
                        })}
                    </div>

                </div>
            </div>
            {/* Ndc and Modifiers section */}
            <div className='row py-2'>
                <div className='col-12 col-sm-6'>

                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>NDC Codes</span>
                    <NdcCodeSearch
                        suggestions={ndcCodeSuggestions}
                        setSuggestions={setNdcCodeSuggestions}
                        isError={isNdcCodeError}
                        setIsError={setIsNdcCodeError}
                        isLoading={isNdcCodeLoading}
                        setIsLoading={setIsNdcCodeLoading}
                        handleAddNdcCode={handleAddNdcCode}
                        handleSearchInput={handleNdcCodeSearchInput}
                        searchInputValue={ndcCodeSearchInputValue}
                        setSearchInputValue={setNdcCodeSearchInputValue}
                        handleAddCptCode={handleAddCptCode}
                        ndcCodeName={ndcCodeName}
                        setNdcCodeName={setNdcCodeName}
                        selectedNdcCode={selectedNdcCode}
                        setSelectedNdcCode={setSelectedNdcCode}
                    />
                </div>
                <div className='col-12 col-sm-6'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Modifiers</span>
                    <ModifiersSearch
                        suggestions={modifiersSuggestions}
                        setSuggestions={setModifiersSuggestions}
                        isError={isModifierError}
                        setIsError={setIsModifierError}
                        isLoading={isModifierLoading}
                        setIsLoading={setIsModifierLoading}
                        handleAddModifier={handleAddModifier}
                        handleSearchInput={handleModifierSearchInput}
                        searchInputValue={modifierSearchInputValue}
                        setSearchInputValue={setModifierSearchInputValue}
                    // handleAddModifier={handleAddModifier}
                    />
                </div>
            </div>
            {/* Last section */}
            <div className='row py-2'>
                <div className='col-sm-3'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Start Date</span>
                    <CalenderDatePicker dobHandler={(val) => console.log(val)} />
                </div>
                <div className='col-sm-3'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>End Date</span>
                    <CalenderDatePicker dobHandler={(val) => console.log(val)} />
                </div>
                <div className='col-sm-2'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Amount</span>
                    <input type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                </div>
                <div className='col-sm-2'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Quantity</span>
                    <input type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                </div>
                <div className='col-sm-2'>
                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Total</span>
                    <input type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                </div>
            </div>
        </>
    )
}