import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import classes from "../patient/PatientItem.module.css";
import { changeDateTimeFormat, dateTimeHelper, getAgeByDateOfBirth } from "../../utils/dateTimeHelper";
import { useNavigate } from "react-router-dom";
import { taskCompletedByStrings, taskTypesEnum } from "../../res/strings";
import { changeRxMap, genderMap, rxSubcodeMap, truncate } from "../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { no_patient_record } from '../../res/strings';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from "react-redux";
import FileShowModal from "../UI/Modals/ProviderModals/FileShowModal";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { fetchPatientSingleDocumentUrl, reviewPatientDocumentUrl } from "../../res/endpointurls";

const TaskItem = (props) => {

  const { user } = useAuth();
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const navigate = useNavigate();
  const taskTypes = taskTypesEnum.en;
  const autoDenyStrings = taskCompletedByStrings.en;
  const task = props?.task;
  const _str = no_patient_record;
  const truncateValue = props?.inModal !== undefined ? 14 : 19;


  const [fileToShow, setFileToShow] = useState("")
  const [openFileShowModal, setOpenFileShowModal] = useState(false)
  const [review, setReview] = useState(false);
  const [fileTypeOnShow, setFileTypeOnShow] = useState("");
  const [fileShowDocId, setFileShowDocId] = useState("");
  const [providerRole, setProviderRole] = useState(user?.role);
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  const [fileNameShow, setFileNameShow] = useState("");
  const [statusCodeAttachments, setStatusCodeAttachments] = useState(null);


  const createFileData = (documentData) => {
    setIsLoadingFetch(false);
    setFileToShow(documentData);
  };
  const fetchDocument = async (fileId) => {
    setIsLoadingFetch(true);
    const httpResponse = await sendHttpRequest({
      // url: "/patient/document",
      url: fetchPatientSingleDocumentUrl(props?.task?.prescriptionReference?.patient?.userId, fileId),
      method: "GET"
    });
    if (!httpResponse.error) {
      createFileData(httpResponse.data);
      // console.log("httpResponse.data: ", httpResponse.data);
      // setIsLoadingAtt(false);
    } else {
      console.log(httpResponse.error);
      // setIsLoadingAtt(false);
    }
    setStatusCodeAttachments(httpResponse.status);
  };




  const handleAction = (event, enc_id, ptnt_id, prnt_enc_id) => {
    if (event.target.value === 'sign_encounter') {
      props.handlePatientEncouterData(enc_id, ptnt_id);
    } else if (event.target.value === 'review_request') {
      props.handleRefillModal(enc_id);
      // props.handlePendingTaskModal();
    } else if (event.target.value === 'review_encounter_summary') {
      // props.handlePendingTaskModal();
      props.handleSummaryModal(prnt_enc_id);
    } else if (event.target.value === 'no_show') {
      props?.markApptAsNoShow(props?.task?.taskId);
    }
    else if (event.target.value === 'refill_request') {
      console.log("props.task.prescriptionReference", props.task.prescriptionReference);
      props.handleRefillModal(props.task.prescriptionReference);
    }
    event.target.value = '';
  }

  let patient = props?.task?.prescriptionReference?.patient;
  let provider = props?.task?.prescriptionReference?.providerTenantPracticeLocation?.provider;
  let encounter = props?.task?.prescriptionReference?.encounter;

  // Change Rx medications
  let providerName = {};
  // let medicationRx; -deferred 
  let firstMedicationFromList;
  try {
    providerName = structuredClone(props?.task?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]);
    // medicationRx = props?.task?.prescriptionReference?.medicationRx; -- deferred for now
    firstMedicationFromList = props?.task?.rxRequest?.originalPrescription;
  }
  catch (err) {
    //nothing
  }
  const handleRenewalAction = (event) => {
    props.handleRenewalTask(task);
    props.handleRenewalModal();
    event.target.value = '';
  }
  const fileToShowModal = (isReviewed, fileName, fileCategory, fileId) => {
    if (fileCategory !== "") {
      setFileTypeOnShow(fileCategory);
    }

    setFileShowDocId(fileId);
    setFileNameShow(fileName);
    setReview(isReviewed);
    setOpenFileShowModal(true);
    fetchDocument(fileId);
  };

  const handleChangeAction = async (event) => {
    if (event.target.value === "change_request") {
      const taskData = await props.handleChangeRxTask(task, task?.taskId);
      // await props.getPatientObservations(task?.prescriptionReference?.patient?.userId);
      if (taskData?.error === false) {
        props.handleChangeRequestModal();
      }
      else {
        props.showTaskLoadFailedError(true);
      }
    }
    event.target.value = '';
  }

  const customSortList = (a, b) => {
    if (a === "G") return -1;
    if (b === "G") return 1;
    if (a === "A") return -1;
    if (b === "A") return 1;
    return 0;
  }

  let subCodesList = props?.task?.rxRequest?.requestSubCode.length > 1 ? props?.task?.rxRequest?.requestSubCode?.sort(customSortList) : [];


  let taskMarkup;


  const reviewFile = async () => {
    const httpResponse = await sendHttpRequest({
      // url: "/patient/document/review",
      url: reviewPatientDocumentUrl(props?.task?.prescriptionReference?.patient?.userId, fileShowDocId),
      method: "POST",
      data: {
        patientDocumentId: fileShowDocId,
        documentReview: [
          {
            reviewedBy: {
              userId: user?.userId,
            },
          },
        ],
      },
    });
    if (!httpResponse.error) {
      // emptyStates();
      // fetchAttachments();
      props?.setReloadTasks(p => !p);
    }
  };
  const fileShowModalClose = () => {
    setFileToShow("");
    setOpenFileShowModal(false);
  };



  switch (task?.type) {
    case taskTypes?.changerx_type:
      taskMarkup = <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2 ${props?.tasksOnly && props.isFirst ? classes['border--top'] : ''}  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
      >
        <Grid item xs={`${props?.tasksOnly ? 1.5 : 1.2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                m: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* {console.log(task)} */}
              {dateTimeHelper(task?.entryDate, "MMM D, YYYY")} <br />
              {dateTimeHelper(task?.entryDate, "LT")}
              {/* {"8:19 AM"} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: props.tasksOnly ? 'none' : 'block' }}>
          <Typography component="div">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                textAlign: "left"
              }}
            >
              {providerName?.firstName} {providerName?.lastName}{", "} {props?.task?.prescriptionReference?.providerTenantPracticeLocation?.provider?.qualification}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#323232",
              }}
            >
              {taskTypes?.changerx}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={`${props?.tasksOnly ? 2.5 : 1.5}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                ml: 2,
              }}
            >
              <div onClick={() => { if (Number(patient?.userId) !== 0 && (props?.veeone === undefined || props?.veeone === false)) { navigate(`/all-patients/${patient?.userId}`) } }}>
                {
                  (patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName)?.length > truncateValue
                    ?
                    <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName !== undefined ? truncate(patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName, truncateValue) : null}
                      </span>
                    </PnToolTip>
                    :
                    Number(patient?.userId) !== 0 ? <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                      {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                    </span> : <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                      </span>
                    </PnToolTip>
                }
                <span className={`font-10 text--secondary d-block`} >
                  {genderMap?.get(patient?.gender?.toLowerCase())} {"-"} {getAgeByDateOfBirth(patient?.dateOfBirth)} {"years -"} {changeDateTimeFormat(patient?.dateOfBirth, "MMM D, YYYY")}
                </span>
              </div>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
              }}
            >
              {

                <>
                  <div className="d-flex justify-content-start" style={{ marginBottom: "-2px" }}>
                    <span className={`font-12 fw-sb text--terciary me-2`}>
                      {props?.task?.rxRequest?.requestCode !== 'U' ? `${changeRxMap.get(props?.task?.rxRequest?.requestCode)} request` : `${changeRxMap.get(props?.task?.rxRequest?.requestCode)} ${props?.task?.rxRequest?.requestSubCode?.length === 1 ? '- ' + rxSubcodeMap.get(props?.task?.rxRequest?.requestSubCode?.at(0)) : ` - ${subCodesList?.map((c, i) => { return ' ' + rxSubcodeMap.get(c) })} `}`}
                    </span>
                    {/* <span className={`font-10 text--secondary`} style={{ marginTop: 5.5 }}>
                      {firstMedicationFromList?.sigText}
                    </span> */}
                  </div>
                  <div className="d-flex justify-content-start">
                    <span className={`font-10 text--secondary`}>
                      {firstMedicationFromList?.drugDescription}
                      {/* {"Number of Refills: "} {firstMedicationFromList?.drugDescription} */}
                    </span>
                    {/* <span className={`font-10 text--secondary ms-3`}>
                      {"Last Refill: "} {props?.task?.encounter2?.medicationRx[0]?.lastRefillDate ? dateTimeHelper(props?.task?.encounter2?.medicationRx[0]?.lastRefillDate, "MMM D, YYYY") : ""}
                    </span> */}
                  </div>
                </>
              }
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={`${props?.tasksOnly ? 2.5 : 2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 4,
                fontFamily: "Montserrat",
              }}
            >
              {
                task?.status === "completed" ?
                  task?.action === "auto_denied" ?
                    <>
                      <div className="d-flex justify-content-start" style={{ lineHeight: "0px" }}>
                        <span className={`font-10 d-block text--secondary`} style={{ marginTop: 6.5 }}>
                          {autoDenyStrings.auto_denied_status}
                          <PnToolTip title={task?.denyReason}>
                            <InfoIcon style={{ height: "10px", weight: "10px", fill: "#004fd4" }} />
                          </PnToolTip>
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {autoDenyStrings.auto_denied_by}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                          {"Completed"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {task?.completedBy?.name[0]?.firstName} {task?.completedBy?.name[0]?.lastName}{", "} {task?.completedBy?.qualification}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                  : props.task?.status === "expired" ?
                    <div className="text--terciary font-14"><span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                      {"Expired"}
                    </span>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(props?.task?.expirationTime, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </div> :
                    props?.pendingTaskModal === true ? null : props?.task?.status === "pending" &&
                      <div>
                        <select
                          className={`form-select br-10 font-12 shadow-none`}
                          aria-label="action select"
                          style={{
                            border: "1px solid #D9E8EC",
                            width: `${props?.tasksOnly ? '100%' : '80%'}`,
                            height: "36px",
                            color: "#336383",
                            fontWeight: 600,
                          }}
                          onChange={(e) => handleChangeAction(e)}
                        >
                          {/* <option value="" disabled selected hidden>Status</option> */}
                          <option
                            className="text--secondary"
                            value={""}
                            key={1}
                            disabled selected hidden
                          >
                            {"Select Action"}
                          </option>
                          <option
                            className={`text--secondary `}
                            value={'change_request'}
                            key={2}
                          >
                            Review
                          </option>

                        </select>
                      </div>
              }
            </Box>
          </Typography>
        </Grid>
      </Grid >
      break;
    case taskTypes?.renewal_type:
    case taskTypes?.cancelType:
      taskMarkup = <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2 ${props?.tasksOnly && props.isFirst ? classes['border--top'] : ''}  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
      >
        <Grid item xs={`${props?.tasksOnly ? 1.5 : 1.2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                m: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* {console.log(task)} */}
              {dateTimeHelper(task?.entryDate, "MMM D, YYYY")} <br />
              {dateTimeHelper(task?.entryDate, "LT")}
              {/* {"8:19 AM"} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: props.tasksOnly ? 'none' : 'block' }}>
          <Typography component="div">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                textAlign: "left"
              }}
            >
              {providerName?.firstName} {providerName?.lastName}{", "} {props?.task?.prescriptionReference?.providerTenantPracticeLocation?.provider?.qualification}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#323232",
              }}
            >
              {task?.type === taskTypes.renewal_type ? taskTypes.renewal : task?.type === taskTypes.cancelType ? taskTypes?.cancel : ''}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={`${props?.tasksOnly ? 2.5 : 1.5}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                ml: 2,
                // fontSize: "14px",
                // color: "#336383",
              }}
            >
              <div onClick={() => { if (Number(patient?.userId) !== 0) { navigate(`/all-patients/${patient?.userId}`) } }}>
                {
                  (patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName)?.length > truncateValue
                    ?
                    <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName !== undefined ? truncate(patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName, truncateValue) : null}
                      </span>
                    </PnToolTip>
                    :
                    Number(patient?.userId) !== 0 ? <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                      {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                    </span> : <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                      </span>
                    </PnToolTip>
                }
                <span className={`font-10 text--secondary d-block`} >
                  {genderMap?.get(patient?.gender?.toLowerCase())} {"-"} {getAgeByDateOfBirth(patient?.dateOfBirth)} {"years -"} {changeDateTimeFormat(patient?.dateOfBirth, "MMM D, YYYY")}
                </span>
              </div>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
              }}
            >
              {

                <>
                  <div className="d-flex justify-content-start" style={{ marginBottom: "-2px" }}>
                    <span className={`font-12 fw-sb text--terciary me-2`}>
                      {task?.prescription?.drugDescription}
                    </span>
                    <span className={`font-10 text--secondary`} style={{ marginTop: 5.5 }}>
                      {task?.prescription?.sigText}
                    </span>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span className={`font-10 text--secondary`}>
                      {"Number of Refills: "} {task?.prescription?.refills ?? 0}
                    </span>
                    {/* <span className={`font-10 text--secondary ms-3`}>
                        {"Last Refill: "} {props?.task?.encounter2?.medicationRx[0]?.lastRefillDate ? dateTimeHelper(props?.task?.encounter2?.medicationRx[0]?.lastRefillDate, "MMM D, YYYY") : ""}
                      </span> */}
                  </div>
                </>
              }
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={`${props?.tasksOnly ? 2.5 : 2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 4,
                fontFamily: "Montserrat",
              }}
            >
              {
                task?.status === "completed" ?
                  task?.action === "auto_denied" ?
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 d-block text--secondary`} style={{ marginTop: 6.5 }}>
                          {autoDenyStrings.auto_denied_status}
                          <PnToolTip title={task?.denyReason}>
                            <InfoIcon style={{ height: "10px", weight: "10px", fill: "#004fd4" }} />
                          </PnToolTip>
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {autoDenyStrings.auto_denied_by}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                          {"Completed"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {task?.completedBy?.name[0]?.firstName} {task?.completedBy?.name[0]?.lastName}{", "} {task?.completedBy?.qualification}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                  : props.task?.status === "expired" ?
                    <div className="text--terciary font-14"><span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                      {"Expired"}
                    </span>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(props?.task?.expirationTime, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </div> :
                    props?.pendingTaskModal === true ? null : props?.task?.status === "pending" &&
                      <div>
                        <select
                          className={`form-select br-10 font-12 shadow-none ${classes["custom-select"]}`}
                          aria-label="action select"
                          style={{
                            border: "1px solid #D9E8EC",
                            width: `${props.tasksOnly ? "100%" : "80%"}`,
                            height: "36px",
                            color: "#336383",
                            fontWeight: 600,
                          }}
                          onChange={(e) => handleRenewalAction(e)}
                        >
                          {/* <option value="" disabled selected hidden>Status</option> */}
                          <option
                            className="text--secondary"
                            value={""}
                            key={1}
                            disabled selected hidden
                          >
                            {"Select Action"}
                          </option>
                          {
                            <>
                              <option
                                className="text--secondary"
                                value={"renewal_request"}
                                key={2}
                              >
                                Review
                              </option>
                            </>
                          }
                        </select>
                      </div>
              }
            </Box>
          </Typography>
        </Grid>
      </Grid >
      break;
    case taskTypes?.document_review:
      taskMarkup = <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2 ${props?.tasksOnly && props.isFirst ? classes['border--top'] : ''}  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
      >
        <Grid item xs={`${props?.tasksOnly ? 1.5 : 1.2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                m: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* {console.log(task)} */}
              {dateTimeHelper(task?.entryDate, "MMM D, YYYY")} <br />
              {dateTimeHelper(task?.entryDate, "LT")}
              {/* {"8:19 AM"} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: props.tasksOnly ? 'none' : 'block' }}>
          <Typography component="div">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                textAlign: "left"
              }}
            >
              {providerName?.firstName} {providerName?.lastName}{", "} {props?.task?.prescriptionReference?.providerTenantPracticeLocation?.provider?.qualification}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#323232",
              }}
            >
              {task?.type === taskTypes.renewal_type ? taskTypes.renewal : task?.type === taskTypes.cancelType ? taskTypes?.cancel : task?.type === taskTypes.document_review ? "Document Review" : ''}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={`${props?.tasksOnly ? 2.5 : 1.5}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                ml: 2,
                // fontSize: "14px",
                // color: "#336383",
              }}
            >
              <div onClick={() => { if (Number(patient?.userId) !== 0) { navigate(`/all-patients/${patient?.userId}`) } }}>
                {
                  (patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName)?.length > truncateValue
                    ?
                    <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName !== undefined ? truncate(patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName, truncateValue) : null}
                      </span>
                    </PnToolTip>
                    :
                    Number(patient?.userId) !== 0 ? <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                      {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                    </span> : <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                      </span>
                    </PnToolTip>
                }
                <span className={`font-10 text--secondary d-block`} >
                  {genderMap?.get(patient?.gender?.toLowerCase())} {"-"} {getAgeByDateOfBirth(patient?.dateOfBirth)} {"years -"} {changeDateTimeFormat(patient?.dateOfBirth, "MMM D, YYYY")}
                </span>
              </div>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
              }}
            >
              {

                <>
                  <div className="d-flex justify-content-start" style={{ marginBottom: "-2px" }}>
                    {
                      task?.status?.toLowerCase() !== 'completed' ?
                        <span className={`font-12  text--terciary me-2`}>
                          {task?.patientDocument?.category?.name.concat(": ", task?.patientDocument?.alias, " is awaiting your review")}
                        </span>
                        :
                        <span className={`font-12  text--terciary me-2`}>
                          {"Reviewed and Signed"}
                        </span>
                    }

                  </div>
                </>
              }
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={`${props?.tasksOnly ? 2.5 : 2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 4,
                fontFamily: "Montserrat",
              }}
            >
              {
                task?.status === "completed" ?
                  task?.action === "auto_denied" ?
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 d-block text--secondary`} style={{ marginTop: 6.5 }}>
                          {autoDenyStrings.auto_denied_status}
                          <PnToolTip title={task?.denyReason}>
                            <InfoIcon style={{ height: "10px", weight: "10px", fill: "#004fd4" }} />
                          </PnToolTip>
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {autoDenyStrings.auto_denied_by}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                          {"Completed"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {task?.completedBy?.name[0]?.firstName} {task?.completedBy?.name[0]?.lastName}{", "} {task?.completedBy?.qualification}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                  : props.task?.status === "expired" ?
                    <div className="text--terciary font-14"><span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                      {"Expired"}
                    </span>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(props?.task?.expirationTime, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </div> :
                    props?.pendingTaskModal === true ? null : props?.task?.status === "pending" &&
                      <div>
                        <select
                          className={`form-select br-10 font-12 shadow-none ${classes["custom-select"]}`}
                          aria-label="action select"
                          style={{
                            border: "1px solid #D9E8EC",
                            width: `${props.tasksOnly ? "100%" : "80%"}`,
                            height: "36px",
                            color: "#336383",
                            fontWeight: 600,
                          }}
                          onChange={() => fileToShowModal(
                            false,
                            task?.patientDocument?.alias,
                            task?.patientDocument?.category?.id ?? "Not Available",
                            task?.patientDocument?.patientDocumentId
                          )}
                        >
                          {/* <option value="" disabled selected hidden>Status</option> */}
                          <option
                            className="text--secondary"
                            value={""}
                            key={1}
                            selected={fileToShow === "" ? true : false}
                            disabled hidden
                          >
                            {"Select Action"}
                          </option>
                          {
                            <>
                              <option
                                className="text--secondary"
                                value={"Document Review"}
                                key={2}
                              >
                                Review
                              </option>
                            </>
                          }
                        </select>
                      </div>
              }
            </Box>
          </Typography>
        </Grid>
      </Grid >
      break;
    case taskTypes?.refill_request:
      taskMarkup = <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2 ${props?.tasksOnly && props.isFirst ? classes['border--top'] : ''}  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
      >
        <Grid item xs={`${props?.tasksOnly ? 1.5 : 1.2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                m: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* {console.log(task)} */}
              {dateTimeHelper(task?.entryDate, "MMM D, YYYY")} <br />
              {dateTimeHelper(task?.entryDate, "LT")}
              {/* {"8:19 AM"} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: props.tasksOnly ? 'none' : 'block' }}>
          <Typography component="div">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                textAlign: "left"
              }}
            >
              {providerName?.firstName} {providerName?.lastName}{", "} {props?.task?.prescriptionReference?.providerTenantPracticeLocation?.provider?.qualification}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#323232",
              }}
            >
              {task?.type === taskTypes.renewal_type ? taskTypes.renewal : task?.type === taskTypes.cancelType ? taskTypes?.cancel : task?.type === taskTypes.document_review ? "Document Review" : task?.type === taskTypes.refill_request ? "Refill Request" : ''}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={`${props?.tasksOnly ? 2.5 : 1.5}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                ml: 2,
                // fontSize: "14px",
                // color: "#336383",
              }}
            >
              <div onClick={() => { if (Number(patient?.userId) !== 0) { navigate(`/all-patients/${patient?.userId}`) } }}>
                {
                  (patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName)?.length > truncateValue
                    ?
                    <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName !== undefined ? truncate(patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName, truncateValue) : null}
                      </span>
                    </PnToolTip>
                    :
                    Number(patient?.userId) !== 0 ? <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                      {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                    </span> : <PnToolTip title={Number(patient?.userId) !== 0 ? patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName : _str.unknown_patient_msg}>
                      <span className={Number(patient?.userId) !== 0 ? `font-12 fw-sb text--blue d-block` : `font-12 fw-sb text--grey d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                      </span>
                    </PnToolTip>
                }
                <span className={`font-10 text--secondary d-block`} >
                  {genderMap?.get(patient?.gender?.toLowerCase())} {"-"} {getAgeByDateOfBirth(patient?.dateOfBirth)} {"years -"} {changeDateTimeFormat(patient?.dateOfBirth, "MMM D, YYYY")}
                </span>
              </div>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
              }}
            >
              {

                <>
                  <div className="d-flex justify-content-start" style={{ marginBottom: "-2px" }}>
                    <span className={`font-12 fw-sb text--terciary me-2`}>
                      {task?.prescriptionReference?.medicationRx[0]?.drugDescription}
                    </span>
                    <span className={`font-10 text--secondary`} style={{ marginTop: 5.5 }}>
                      {truncate(task?.prescriptionReference?.medicationRx[0]?.sigText, 50).concat(" - Number of Refills: ", task?.prescriptionReference?.medicationRx[0]?.refills ?? 0)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-start">
                    {/* <span className={`font-10 text--secondary`}>
                      {"Number of Refills: "} {task?.prescriptionReference?.medicationRx[0]?.refills ?? 0}
                    </span> */}
                    {/* <span className={`font-10 text--secondary ms-3`}>
                        {"Last Refill: "} {props?.task?.encounter2?.medicationRx[0]?.lastRefillDate ? dateTimeHelper(props?.task?.encounter2?.medicationRx[0]?.lastRefillDate, "MMM D, YYYY") : ""}
                      </span> */}
                  </div>
                </>
              }
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={`${props?.tasksOnly ? 2.5 : 2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 4,
                fontFamily: "Montserrat",
              }}
            >
              {
                task?.status === "completed" ?
                  task?.action === "auto_denied" ?
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 d-block text--secondary`} style={{ marginTop: 6.5 }}>
                          {autoDenyStrings.auto_denied_status}
                          <PnToolTip title={task?.denyReason}>
                            <InfoIcon style={{ height: "10px", weight: "10px", fill: "#004fd4" }} />
                          </PnToolTip>
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {autoDenyStrings.auto_denied_by}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                          {"Completed"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {task?.completedBy?.name[0]?.firstName} {task?.completedBy?.name[0]?.lastName}{", "} {task?.completedBy?.qualification}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(task?.completionDate, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </>
                  : props.task?.status === "expired" ?
                    <div className="text--terciary font-14"><span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                      {"Expired"}
                    </span>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(props?.task?.expirationTime, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </div> :
                    props?.pendingTaskModal === true ? null : props?.task?.status === "pending" &&
                      <div>
                        <select
                          className={`form-select br-10 font-12 shadow-none ${classes["custom-select"]}`}
                          aria-label="action select"
                          style={{
                            border: "1px solid #D9E8EC",
                            width: `${props.tasksOnly ? "100%" : "80%"}`,
                            height: "36px",
                            color: "#336383",
                            fontWeight: 600,
                          }}
                          onChange={(e) => handleAction(e)}
                        >
                          {/* <option value="" disabled selected hidden>Status</option> */}
                          <option
                            className="text--secondary"
                            value={""}
                            key={1}
                            selected disabled hidden
                          >
                            {"Select Action"}
                          </option>
                          {
                            <>
                              <option
                                className="text--secondary"
                                value={taskTypes?.refill_request}
                                key={2}
                              >
                                {"Review"}
                              </option>
                            </>
                          }
                        </select>
                      </div>
              }
            </Box>
          </Typography>
        </Grid>
      </Grid >
      break;
    case taskTypes?.encounter_type:

    case undefined:
      taskMarkup = <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
        hidden={(props.task.status.toLowerCase() === "closed" || props.task.status.toLowerCase() === "completed") ? userPermissions?.vse === false ? true : false : props.hidden}
      >
        <Grid item xs={`${props?.tasksOnly ? 1.5 : 1.2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                m: 1,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {dateTimeHelper(props?.task?.entryDate, "MMM D, YYYY")} <br />
              {dateTimeHelper(props?.task?.entryDate, "LT")}
              {/* {"8:19 AM"} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: props.tasksOnly ? 'none' : 'block' }}>
          <Typography component="div">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                textAlign: "left"
              }}
            >
              {provider?.name[0]?.firstName} {provider?.name[0]?.lastName}{", "} {provider?.qualification}
              {/* {"Shibu Sakaria, MD"} */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#323232",
              }}
            >
              {taskTypes.encounter}
              {/* {"Pending Encounter"} */}
              {/* </span> */}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={`${props?.tasksOnly ? 2.5 : 1.5}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                ml: 2,
                // fontSize: "14px",
                // color: "#336383",
              }}
            >
              <div onClick={() => { navigate(`/all-patients/${patient?.userId}`) }}>
                {
                  (patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName)?.length > truncateValue
                    ?
                    <PnToolTip title={patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}>
                      <span className={`font-12 fw-sb text--blue d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                        {patient?.name[0]?.firstName !== undefined ? truncate(patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName, truncateValue) : null}
                      </span>
                    </PnToolTip>
                    :
                    <span className={`font-12 fw-sb text--blue d-block`} style={{ cursor: "pointer", marginBottom: "-2px" }}>
                      {patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName}
                    </span>
                }
                <span className={`font-10 text--secondary d-block`} >
                  {genderMap?.get(patient?.gender?.toLowerCase())} {"-"} {getAgeByDateOfBirth(patient?.dateOfBirth)} {"years -"} {changeDateTimeFormat(patient?.dateOfBirth, "MMM D, YYYY")}
                  {/* {"Male - 60 years - 11/09/1937"} */}
                </span>
              </div>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
              }}
            >
              {
                encounter ?
                  <>
                    <div className="d-flex justify-content-start mt-2" style={{ marginBottom: "-2px" }}>
                      <span className={`font-10 text--secondary`}>
                        {"Chief Complaint"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-start">
                      <span className={`font-12 fw-sb text--terciary`}>
                        {encounter?.chiefComplaint}
                      </span>
                    </div>
                  </>
                  :
                  <>
                    <div className="d-flex justify-content-start" style={{ marginBottom: "-2px" }}>
                      <span className={`font-12 fw-sb text--terciary me-2`}>
                        {encounter?.medicationRx[0]?.drugId}
                      </span>
                      <span className={`font-10 text--secondary`} style={{ marginTop: 5.5 }}>
                        {encounter?.medicationRx[0]?.sigText}
                      </span>
                    </div>
                    <div className="d-flex justify-content-start">
                      <span className={`font-10 text--secondary`}>
                        {"Number of Refills: "} {encounter?.medicationRx[0]?.totalRefill ?? 0}
                      </span>
                      <span className={`font-10 text--secondary ms-3`}>
                        {"Last Refill: "} {encounter?.medicationRx[0]?.lastRefillDate ? dateTimeHelper(encounter?.medicationRx[0]?.lastRefillDate, "MMM D, YYYY") : ""}
                      </span>
                    </div>
                  </>
              }
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={`${props?.tasksOnly ? 2.5 : 2}`}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                ml: 4,
                fontFamily: "Montserrat",
              }}
            >
              {
                props.task.status === "completed" ?
                  <>
                    <div className="d-flex justify-content-start">
                      <span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                        {"Completed"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-start">
                      <span className={`font-10 text--secondary`}>
                        {task?.completedBy?.name[0]?.firstName} {task?.completedBy?.name[0]?.lastName}{", "} {task?.completedBy?.qualification}
                        {/* {"April 21, 2020 - 8:19 am"} */}
                      </span>
                    </div>
                    <div className="d-flex justify-content-start">
                      <span className={`font-10 text--secondary`}>
                        {dateTimeHelper(props?.task?.completionDate, "MMM D, YYYY - LT")}
                        {/* {"April 21, 2020 - 8:19 am"} */}
                      </span>
                    </div>
                  </>
                  :
                  props.task?.status === "expired" ?
                    <div className="text--terciary font-14"><span className={`font-10 text--secondary`} style={{ marginTop: 6.5 }}>
                      {"Expired"}
                    </span>
                      <div className="d-flex justify-content-start">
                        <span className={`font-10 text--secondary`}>
                          {dateTimeHelper(props?.task?.expirationTime, "MMM D, YYYY - LT")}
                          {/* {"April 21, 2020 - 8:19 am"} */}
                        </span>
                      </div>
                    </div> :
                    props?.pendingTaskModal === true ? null : props?.task?.status === "pending" &&
                      <div>
                        <select
                          className={`form-select br-10 font-12 shadow-none ${classes["custom-select"]}`}
                          aria-label="action select"
                          style={{
                            border: "1px solid #D9E8EC",
                            width: `${props?.tasksOnly ? '90%' : '80%'}`,
                            height: "36px",
                            color: "#336383",
                            fontWeight: 600,
                          }}
                          onChange={(e) => handleAction(e, props?.task?.prescriptionReference?.prescriptionReferenceId, props?.task?.prescriptionReference?.patient?.userId, props?.task?.prescriptionReference?.parent?.encounterId ?? null)}
                        >
                          {/* <option value="" disabled selected hidden>Status</option> */}
                          <option
                            className="text--secondary"
                            value={""}
                            key={1}
                            disabled selected hidden
                          >
                            {"Select Action"}
                          </option>
                          {
                            encounter?.encounterType === "Refill Request" ?
                              <>
                                <option
                                  className="text--secondary"
                                  value={"review_request"}
                                  key={2}
                                >
                                  {"Review Request"}
                                </option>
                                <option
                                  value={"review_encounter_summary"}
                                  key={1}
                                // selected={cookies.providerId == prescriberItem?.NonVeterinarian?.id}
                                >
                                  {"Review Encounter Summary"}
                                </option>
                              </>
                              :
                              <>
                                <option
                                  className="text--secondary"
                                  value={"sign_encounter"}
                                  key={2}
                                >
                                  {"Sign Encounter"}
                                </option>
                                {
                                  task?.noShowable === true &&
                                  <option
                                    className="text--secondary"
                                    value={"no_show"}
                                    key={3}
                                  >
                                    {"Mark as no show"}
                                  </option>
                                }
                              </>
                          }
                          {/* ); */}
                          {/* }) : "No prescribers available"} */}
                        </select>
                      </div>
              }
            </Box>
          </Typography>
        </Grid>
      </Grid >
      break;
  }

  return (
    <>
      {fileToShow != "" ? <FileShowModal
        show={openFileShowModal}
        review={review}
        reviewFile={reviewFile}
        fileTypeOnShow={fileTypeOnShow}
        file={fileToShow}
        fileShowModalClose={fileShowModalClose}
        providerRole={providerRole}
        isLoadingFetch={isLoadingFetch}
      /> : null}
      {props?.tasksOnly && (task?.type === undefined || task?.type === taskTypes?.cancelType || task?.type === taskTypes?.encounter_type || task?.type === taskTypes?.document_review || task?.type === taskTypes?.refill_request) ? null : taskMarkup}
    </>
  );
}

export default TaskItem;