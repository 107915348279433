import { Box, Grid, Typography, Stack, Skeleton } from "@mui/material";
import classes from "../patient/PatientItem.module.css";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import UserRolesItem from "../patient/UserRolesItem";
import { UnloadHandler } from "../../utils/helpers/HelperFunctions";
import { useAuth } from "../hooks/useAuth";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import PageHeader from "../../patientportal/generic/PageHeader";
import { X } from "react-bootstrap-icons";


const UserRolesPage = (props) => {
  const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);
  const [isLoading, setIsLoading] = useState(true);
  const [statusCodePrescriber, setStatusCodePrescriber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [facesheetUploaded, setFacesheetUploaded] = useState(false);
  const [facesheetUploadedFail, setFacesheetUploadedFail] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isEditSent, setIsEditSent] = useState(false);
  const { user } = useAuth();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  //Provider Dropdown code

  const [rolesList, setRolesList] = useState([]);
  const [rolesList2, setRolesList2] = useState([]);
  const [rights, setRights] = useState([]);
  const [rolesListForEdit, setRolesListForEdit] = useState([]);
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isPriviligesFetched, setisPriviligesFetched] = useState(false);
  const [isEditRoles, setIsEditRoles] = useState(false);
  const [privligesGroup, setPrivligesGroup] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [isUpdatingRoles, setIsUpdatingRoles] = useState(false);
  const [areChangesMade, setAreChangesMade] = useState(false);



  const fetchAllPrivliges = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/privileges",
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setisPriviligesFetched(true);
      setRolesList(httpResponse.data);
    } else {
      setRolesList([]);
      setRolesListForEdit([]);
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodePrescriber(httpResponse.status);
  };

  const fetchAllRoles = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/roles/role",
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      // setisPriviligesFetched(true);
      setRolesList2(httpResponse.data);
    } else {
      setRolesList2([]);
      setRolesListForEdit([]);
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodePrescriber(httpResponse.status);
  };

  const fetchAllRights = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/privileges/rights/tenant/${user?.tenantId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setRights(httpResponse.data);
    } else {
      setRights([]);
      setRolesListForEdit([]);
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodePrescriber(httpResponse.status);
  };

  const fetchPrivligesGroup = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/privileges/rights/group/tenant/${user?.tenantId}`,
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (!httpResponse.error) {
      console.log(httpResponse.data);
      setPrivligesGroup(httpResponse.data);
    } else {
      setPrivligesGroup([]);
      setRolesListForEdit([]);
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodePrescriber(httpResponse.status);
  };

  const putAllPrivliges = async () => {
    setIsUpdatingRoles(true);
    let privilegeUpdateDTO = rolesListForEdit;
    const httpResponse = await sendHttpRequest({
      url: `/privileges/rights/tenant/${user?.tenantId}`,
      method: "PUT",
      data: privilegeUpdateDTO,
    });
    if (!httpResponse.error) {
      setIsEditSent(true);
    } else {
      setRolesList([]);
      setRolesListForEdit([]);
      console.log(httpResponse.error);
    }
    fetchPrivligesGroup();
    fetchAllRights();
    fetchAllRoles();
    fetchAllPrivliges();
    setIsLoading(false);
    setStatusCodePrescriber(httpResponse.status);
    setIsEditRoles(false);
    setIsUpdatingRoles(false);
  };



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
  };

  const handleEditClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsEditSent(false);
  };

  const handleFacesheetClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFacesheetUploaded(false);
  };

  const handleFacesheetUploadFailClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFacesheetUploadedFail(false);
  };



  const handleEditRoles = () => {
    if (isEditRoles) {
      putAllPrivliges();
    } else {
      setIsEditRoles(true);
    }
  };



  if (isSessionUpdated === true) {
    fetchAllPrivliges();
    setIsSessionUpdated(false);
  }

  window.addEventListener("beforeunload", UnloadHandler(dirtyBitStatus));

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", UnloadHandler(dirtyBitStatus));
    };
  });

  useEffect(() => {
    fetchPrivligesGroup();
    fetchAllRights();
    fetchAllRoles();
    fetchAllPrivliges();
  }, []);

  return (
    <>
      {/* <div
        className="p-2 mt-2"
        style={{ borderBottom: "1px solid #D9E8EC ", height: "8vh" }}
      >
        <div className="d-flex justify-content-between">
          <span className=" font-22 align-self-center text--terciary fw-sb ps-3">User Roles</span>
          <div className="d-flex flex-row">
            <button
              className="btn font-12 text-white b2 fw-sb px-5 br-10 me-5 task_button"
              onClick={() => handleEditRoles()}
              disabled={isUpdatingRoles}
              hidden={userPermissions?.epr === false ? true : false}
            >
              {isEditRoles ? "Save Changes" : "Edit User Roles"}
            </button>
          </div>
        </div>
      </div> */}
      <PageHeader title="User Roles">
        <div className="align-self-center">
          <button
            className={`btn font-12 text-white b2 fw-sb px-5 br-10 ${isEditRoles ? "me-2" : "me-5"} task_button`}
            onClick={() => handleEditRoles()}
            disabled={isUpdatingRoles || (isEditRoles && !areChangesMade)}
            hidden={userPermissions?.epr === false ? true : false}
          >
            {isEditRoles ? "Save Changes" : "Edit User Roles"}
          </button>
          {/* {isEditRoles && <button
            onClick={() => setIsEditRoles(false)}
            className={`btn br-10 shadow-none text-decoration-underline lh-0 p-0 fw-sb text--dark-red font-12`}>
            <X />
          </button>} */}
        </div></PageHeader>

      <div className="container" style={{
        "height": '75vh'
      }}>
        <div className="row mt-3 mb-5 pt-3 px-2 justify-content-center">
          <div className="col-md">
            <Grid
              container
              sx={{ marginBottom: "-8px", zIndex: '50 !important' }}
              p={1}
              className={`sticky-top bg-white ${classes["row__head"]} `}
            >
              <Grid item xs={3}>
                
              </Grid>
              {
                rolesList2?.length > 0 ?
                  rolesList2.map((roleItem) => {
                    return (
                      <Grid item 
                      xs={1.245}
                      sx={{alignSelf:"center"}}>
                        <Typography component="div">
                          <Box
                            sx={{
                              textAlign: "center",
                              fontWeight: 600,
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              color: "#323232",
                            }}
                          >
                            {/* Patient Age */}
                            {RolesTextConversion(roleItem?.name)}
                          </Box>
                        </Typography>
                      </Grid>
                    )
                  }) : null
              }

            </Grid>

            {isLoading ? (
              <Skeleton animation="wave" />
            ) : statusCodePrescriber === 200 ? (

              <UserRolesItem
                // key={index}
                privlieges={rolesList}
                rights={rights}
                roles={rolesList2}
                roleToEdit={rolesListForEdit}
                isEditRoles={isEditRoles}
                privligesGroup={privligesGroup}
                setIsRendered={setIsRendered}
                areChangesMade={areChangesMade}
                setAreChangesMade={setAreChangesMade}
              />
            ) : (
              //       );
              //     }
              //   })
              // ) : ""
              ""
            )}
          </div>
        </div>
      </div>

              {console.log("rolesList2: ",rolesList2)}

      <CustomizedSnackbars
        message={"User added successfully."}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={"User roles updated successfully."}
        severity="success"
        open={isEditSent}
        handleClose={handleEditClose}
      />
      <CustomizedSnackbars
        message={"User added successfully."}
        severity="success"
        open={facesheetUploaded}
        handleClose={handleFacesheetClose}
      />
      <CustomizedSnackbars
        message={errorMessage}
        severity="error"
        open={facesheetUploadedFail}
        handleClose={handleFacesheetUploadFailClose}
      />
    </>
  );
};

export default UserRolesPage;
