import ScheduleDropdown from "../forms/scheduleDropdown";
import AppoinmentCard from "../UI/General/AppointmentCard";
import { changeDateTimeFormat, dateTimeHelper } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import NewAppointmentModal from "../UI/Modals/NewAppointmentModal";
import moment from "moment";
import { Skeleton } from "@mui/material";
import AppointmentScheduleModal2 from "../UI/Modals/ProviderModals/AppointmentScheduleModal2";
import ViewScheduleModal from "../UI/Modals/ProviderModals/ViewScheduleModal";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { setPatientID } from "../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import 'react-big-calendar/lib/css/react-big-calendar.css'

import classes from "./AppointmentsPage.module.css";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import { useAuth } from "../hooks/useAuth";
import { providerWithNpiUrl } from "../../res/endpointurls";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useMemo } from "react";
import { convertToTimezone, getUTCFromTimezone } from "../../utils/helpers/HelperFunctions";
import 'moment-timezone' // or 'moment-timezone/builds/moment-timezone-with-data[-datarange].js'. See their docs
import CustomToolbar from "../big-calendar-custom-components/CustomToolbar";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AppointmentEventModal from "./AppointmentEventModal";


function getDate(str, momentObj) {
  return momentObj(str, 'YYYY-MM-DD').toDate()
}

const defaultDateStr = '2015-4-13'


function convertDateFormat(inputDate) {
  // Parse the input date string
  const parsedDate = new Date(inputDate);

  // Format the date to yyyy-MM-dd
  const formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;

  return formattedDate;
}

const Appointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const { user, getSecondaryUser, selectUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [providerAppoinments, setProviderAppoinments] = useState([]);
  const date = new Date();
  let curentDate = dateTimeHelper(date, "YYYY-MM");
  const [appointmentDate, setCurrentDate] = useState(curentDate);
  const [currentCellAppointments, setCurrentCellAppointments] = useState();
  const [calendarMonth, setCalendarMonth] = useState(appointmentDate);
  const [openViewScheduleModal, setOpenViewScheduleModal] = useState(false);
  const [isSentAppointment, setIsSentAppointment] = useState(false);
  const [isSentSchedule, setIsSentSchedule] = useState(false);
  const [isSentScheduleFail, setIsSentScheduleFail] = useState(false);
  const [isTimeSlotOverlap, setIsTimeSlotOverlap] = useState(false);
  const [openNewAppointmentModal, setOpenNewAppointmentModal] = useState(false);
  const [isAppointmentCancelled, setIsAppointmentCancelled] = useState(false);
  const [scheduleFileUploadFail, setScheduleUploadFail] = useState(false);
  const [scheduleTimeSLotFail, setScheduleTimeSLotFail] = useState(false);
  const [isPrescribersFetched, setIsPrescribersFetched] = useState(false);
  const [appointmentCancellationFail, setAppointmentCancellationFail] =
    useState(false);
  const [prescribersList, setPrescribersList] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState();
  const [appointmentCancellationError, setApppointmentCancellationError] =
    useState("");
  const [isAppointmentCancelling, setIsAppointmentCancelling] = useState(false);
  const [isAppointmentRescheduled, setIsAppointmentRescheduled] =
    useState(false);
  const [isAppointmentNotRescheduled, setIsAppointmentNotRescheduled] =
    useState(false);
  const [appointmentNotScheduledError, setAppointmentNotScheduledError] =
    useState("");

  const [timeSlotOverlapErrorMessage, setTimeSlotOverlapErrorMessage] = useState("");
  const [selectedCellDate, setSelectedCellDate] = useState(
    dateTimeHelper(moment(), "dddd, MMM D, YYYY")
  );

  const [openAppointmentEvent, setOpenAppointmentEvent] = useState(false);
  const [event, setEvent] = useState(null);
  function handleAppointmentEvent(selectedEvent) {
    setEvent(selectedEvent);
    if (!openAppointmentEvent) {
      setOpenAppointmentEvent(true);
    }
  }




  const [response, setResponse] = useState("");
  const [currentView, setCurrentView] = useState('month');

  const { getNow, localizer, scrollToTime } =
    useMemo(() => {
      moment.tz.setDefault(getUTCFromTimezone("iana_name"))
      return {
        getNow: () => moment().toDate(),
        localizer: momentLocalizer(moment),
        scrollToTime: moment().toDate(),
        defaultDate: getDate(defaultDateStr, moment),

      }
    }, [])

  const handleNavigate = (date) => {
    const formattedDate = dateTimeHelper(date, "dddd, MMM D, YYYY");
    const newDate = new Date(formattedDate);

    setSelectedCellDate(formattedDate);
    const currentDate = moment().tz(getUTCFromTimezone("iana_name")).format("YYYY-MM");
    const calendarDate = moment(date).format("YYYY-MM");
    // if (currentDate !== calendarDate) {
    refetchAppointments(moment(newDate).format("YYYY-MM")).then(() => {
      console.log("newDate", newDate);
      const year = newDate.getFullYear();
      const month = String(newDate.getMonth() + 1).padStart(2, '0');
      const day = String(newDate.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;


      const appointmentsForSlotDate = response?.filter(item => {
        return item?.date == formattedDate
      })?.at(0)?.appointments;
      appointmentsForSlotDate?.sort((a, b) => {
        const dateA = new Date(a.appointmentSlot.startDateTime);
        const dateB = new Date(b.appointmentSlot.startDateTime);

        // Compare dates
        return dateA - dateB;
      });
      if (appointmentsForSlotDate.length > 0) {
        setCurrentCellAppointments(p => appointmentsForSlotDate);
      }
    });


    // }
    // else {
    // fetchAppointments(moment(date).format("YYYY-MM"));
    // }
  };

  const CustomEvent = ({ event, view }) => {

    // const view = event && event.view;

    // Customize event title based on the current view
    const title = view === 'day' || view === 'week' ? event.title?.split("-")[1] : `${event.title}`;
    // console.log(view, title)
    return (
      <div class="rbc-row-segment p-0"><div class="rbc-event p-0"><div class="rbc-event-content ms-1" title={title}>{title}</div></div></div>
    );
  };

  async function refetchAppointments(currentDate) {
    setCurrentCellAppointments([]);
    let providerId = getSecondaryUser();
    // setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment",
      method: "GET",
      params: {
        month: currentDate,
        providerId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      let response = httpResponse.data;
      // console.log("Response: ", response);



      let appointments = [];
      for (let i = 0; i < response.length; i++) {
        appointments.push({ date: response[i].date, appointments: [] });
      }

      for (let i = 0; i < response.length; i++) {
        if (response[i].appointments.length > 0) {
          // console.log("response[i].appointments", response[i].appointments);
          for (let p = 0; p < response[i].appointments.length; p++) {
            let localDate = dateTimeHelper(
              response[i].appointments[p]?.appointmentSlot?.startDateTime,
              "YYYY-MM-DD"
            );
            for (let a = 0; a < appointments.length; a++) {
              if (appointments[a].date === localDate) {
                appointments[a].appointments.push(response[i].appointments[p]);
              }
            }
          }
        }
      }
      setResponse(p => appointments);



      // // console.log("Events: ", appointments);
      const transformedArray = appointments.flatMap(item => {
        return item.appointments.map(appointment => {

          return {
            id: appointment.id,
            start: convertToTimezone(appointment.appointmentSlot.startDateTime, getUTCFromTimezone(), getUTCFromTimezone("iana_name")),
            end: convertToTimezone(appointment.appointmentSlot.endDateTime, getUTCFromTimezone(), getUTCFromTimezone("iana_name")),
            title: `${dateTimeHelper(appointment.appointmentSlot.startDateTime, "LT")} - ${appointment.appointmentSlot.patient.name.at(0).firstName} ${appointment.appointmentSlot.patient.name.at(0).lastName}`,

            data: appointment.appointmentSlot,
            appointment: appointment
          };
        });
      });
      // console.log(transformedArray, getUTCFromTimezone());
      setProviderAppoinments(transformedArray || []);

      // console.log("appointment response", response);
      // console.log("appointments empty", appointments);
    } else {
      console.log(httpResponse.error);
    }
    // setIsLoading(false);
  };

  // console.log("Provider appointments", providerAppoinments)

  async function fetchAppointments(currentDate) {
    setCurrentCellAppointments([]);
    let providerId = getSecondaryUser();
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment",
      method: "GET",
      params: {
        month: currentDate,
        providerId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      let response = httpResponse.data;
      // console.log("Response: ", response);



      let appointments = [];
      for (let i = 0; i < response.length; i++) {
        appointments.push({ date: response[i].date, appointments: [] });
      }

      for (let i = 0; i < response.length; i++) {
        if (response[i].appointments.length > 0) {
          // console.log("response[i].appointments", response[i].appointments);
          for (let p = 0; p < response[i].appointments.length; p++) {
            let localDate = dateTimeHelper(
              response[i].appointments[p]?.appointmentSlot?.startDateTime,
              "YYYY-MM-DD"
            );
            for (let a = 0; a < appointments.length; a++) {
              if (appointments[a].date === localDate) {
                appointments[a].appointments.push(response[i].appointments[p]);
              }
            }
          }
        }
      }
      setResponse(appointments);

      // // console.log("Events: ", appointments);
      const transformedArray = appointments.flatMap(item => {
        return item.appointments.map(appointment => {
          // console.log(appointment);
          return {
            id: appointment.id,
            start: convertToTimezone(appointment.appointmentSlot.startDateTime, getUTCFromTimezone(), getUTCFromTimezone("iana_name")),
            end: convertToTimezone(appointment.appointmentSlot.endDateTime, getUTCFromTimezone(), getUTCFromTimezone("iana_name")),
            title: `${dateTimeHelper(appointment.appointmentSlot.startDateTime, "LT")} - ${appointment.appointmentSlot.patient.name.at(0).firstName} ${appointment.appointmentSlot.patient.name.at(0).lastName}`,
            data: appointment.appointmentSlot,
            appointment: appointment
          };
        });
      });
      // console.log(transformedArray, getUTCFromTimezone());
      setProviderAppoinments(transformedArray || []);

      setIsLoading(false);
      return appointments;

      // console.log("appointment response", response);
      // console.log("appointments empty", appointments);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };

  const fetchAllPrescribers = async () => {
    setIsPrescribersFetched(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      console.log(httpResponse.error);
    }
    setIsPrescribersFetched(false);
  };


  const handleCurrentMonth = () => {
    setSelectedCellDate(dateTimeHelper(new Date(), "dddd, MMM D, YYYY"));
    setCalendarMonth(appointmentDate);
    fetchAppointments(appointmentDate);
  };





  const handleModal = () => {

    setOpenModal(!openModal);
  };
  const handleViewScheduleModal = () => {
    setOpenViewScheduleModal(!openViewScheduleModal);
  };
  const handleNewAppointmentModal = () => {
    dispatch(setPatientID(-1));
    setOpenNewAppointmentModal(!openNewAppointmentModal);
  };
  const handleAppointmentToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSentAppointment(false);
  };
  const handleScheduleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSentSchedule(false);
  };
  const handleScheduleFailToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSentScheduleFail(false);
  };

  const handleTimeSlotOverlapToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsTimeSlotOverlap(false);
  };

  const handleCancelAppointmentClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAppointmentCancelled(false);
  };
  const handlePublishScheduleFailToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setScheduleUploadFail(false);
  };

  const handlePublishScheduleTimeSlotFailToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setScheduleTimeSLotFail(false);
  };

  const handleAppointmentCancellationToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAppointmentCancellationFail(false);
  };

  const handleProviderId = (event) => {
    // console.log("Selected provider id: ", event.target.value);
    if (event.target.value !== getSecondaryUser()) {
      setSelectedProviderId(event.target.value);
      selectUser(event.target.value);
      fetchAppointments(appointmentDate)
    }

  };



  const handleRescheduledAppointmentClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAppointmentRescheduled(false);
  };
  const handleNotRescheduledAppointmentClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAppointmentNotRescheduled(false);
  };


  function todayHandler() {
    const appointmentsForSlotDate = response?.filter(item => {
      return item?.date == dateTimeHelper(moment(), "yyyy-MM-dd");
    })?.at(0)?.appointments;
    appointmentsForSlotDate?.sort((a, b) => {
      const dateA = new Date(a.appointmentSlot.startDateTime);
      const dateB = new Date(b.appointmentSlot.startDateTime);

      // Compare dates
      return dateA - dateB;
    });
    if (appointmentsForSlotDate.length > 0) {
      setCurrentCellAppointments(p => appointmentsForSlotDate);
    }
  }





  useEffect(() => {
    // getMapFromSession();
    // if(userPermissions?.vpu === true){
    fetchAppointments(appointmentDate).then((appRes) => {
      const appointmentsForSlotDate = appRes?.filter(item => {
        return item?.date == dateTimeHelper(moment(), "yyyy-MM-dd");
      })?.at(0)?.appointments;
      appointmentsForSlotDate?.sort((a, b) => {
        const dateA = new Date(a.appointmentSlot.startDateTime);
        const dateB = new Date(b.appointmentSlot.startDateTime);

        // Compare dates
        return dateA - dateB;
      });
      if (appointmentsForSlotDate.length > 0) {
        setCurrentCellAppointments(p => appointmentsForSlotDate);
      }
    });


    fetchAllPrescribers();
    // }

    return () => {
      setProviderAppoinments(null);
      setPrescribersList(null);
      // moment.tz.setDefault() // reset to browser TZ on unmount
    };
  }, []);



  return (
    <>
      {openNewAppointmentModal && (
        <NewAppointmentModal
          show={openNewAppointmentModal}
          handleModal={handleNewAppointmentModal}
          setIsSentAppointment={setIsSentAppointment}
          fecthAppointments={fetchAppointments}
          appointmentDate={appointmentDate}
          selectedPrescriberId={prescriberInfo.selectedPrescriberId}
          handleCurrentMonth={handleCurrentMonth}
          setIsAppointmentNotRescheduled={setIsAppointmentNotRescheduled}
          setAppointmentNotScheduledError={setAppointmentNotScheduledError}
          setCurrentCellAppointments={setCurrentCellAppointments}
          setSelectedCellDate={setSelectedCellDate}
          convertDateFormat={convertDateFormat}
          selectedCellDate={selectedCellDate}
        />
      )}
      {openModal && (
        <AppointmentScheduleModal2
          show={openModal}
          handleModal={handleModal}
          setIsSentSchedule={setIsSentSchedule}
          setIsSentScheduleFail={setIsSentScheduleFail}
          setScheduleUploadFail={setScheduleUploadFail}
          setScheduleTimeSLotFail={setScheduleTimeSLotFail}
          setIsTimeSlotOverlap={setIsTimeSlotOverlap}
          setTimeSlotOverlapErrorMessage={setTimeSlotOverlapErrorMessage}
          selectedPrescriberId={user?.userId}
        />
      )}
      {openViewScheduleModal && (
        <ViewScheduleModal
          show={openViewScheduleModal}
          handleModal={handleViewScheduleModal}
          selectedPrescriberId={user?.userId}
        />
      )}
      <div
        className="p-2"
        style={{ borderBottom: "1px solid #D9E8EC ", height: "11vh" }}
      >
        <div className="d-flex justify-content-between py-3">
          <div className="col-sm-3 align-self-center">
            <div className="d-flex flex-row">
              <div
                style={{ width: "32px", height: "32px" }}
                className={`bg--background  br-10 me-2 ms-2 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ChevronLeft
                  style={{ color: "#EC609B", height: "20px", weight: "20px" }}
                  className="cursor-pointer my-auto mt-1"
                />
              </div>
              <div className="font-22 fw-sb text--terciary align-self-center">
                Appointments
              </div>
            </div>
          </div>

          <div className="d-flex flex-row pe-4">
            <ScheduleDropdown
              setOpenModal={setOpenModal}
              setOpenViewScheduleModal={setOpenViewScheduleModal}
            />


            <button
              className="blue_task_button "
              style={{
                // border: "1px solid #D9E8EC",
              }}
              onClick={() => handleNewAppointmentModal()}
              hidden={userPermissions?.spa === false ? true : false}
            >
              New Appointment
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-md-8 mt-4">
            <div className="d-flex flex-row justify-content-between align-items-baseline">
              <div className="d-flex flex-row">

              </div>
              <div>
                <div
                  style={{ width: "15vw" }}
                  className="d-flex text--terciary "
                  hidden={RolesMap(user?.role) === "doctor" ? true : false}
                >
                  {isPrescribersFetched === true ? (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width={"70%"}
                      height={40}
                      hidden={RolesMap(user?.role) === "doctor" ? true : false}
                    />
                  ) : (
                    <div
                      hidden={RolesMap(user?.role) === "doctor" ? true : false}
                    >
                      <select
                        className={`form-select br-10 font-14 shadow-none ${classes["custom-select"]}`}
                        aria-label="Provider select"
                        style={{
                          border: "1px solid #D9E8EC",
                          width: "20vw",
                        }}
                        onChange={(e) => handleProviderId(e)}
                        // value={()=>getSecondaryUser()}
                      >
                        <option value="" disabled selected hidden>
                          Select provider
                        </option>
                        {prescribersList?.length > 0
                          ? prescribersList?.map((prescriberItem) => {
                            return (
                              <option
                                value={prescriberItem?.userId ?? ""}
                                key={prescriberItem?.userId ?? ""}
                                selected={
                                  getSecondaryUser() == prescriberItem?.userId
                                }
                              >
                                {prescriberItem?.name[0]?.prefix}{" "}
                                {prescriberItem?.name[0]?.firstName}{" "}
                                {prescriberItem?.name[0]?.lastName}{" "}
                                {RolesTextConversion(prescriberItem?.role) !==
                                  undefined
                                  ? "(" +
                                  RolesTextConversion(
                                    prescriberItem?.role
                                  ) +
                                  ")"
                                  : ""}
                              </option>
                            );
                          })
                          : "No prescribers available"}
                      </select>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <RefreshDataComponent
                  variant="without-bar"
                  callback={() => {
                    fetchAppointments(appointmentDate).then((appRes) => {
                      const appointmentsForSlotDate = appRes?.filter(item => {
                        return item?.date == convertDateFormat(selectedCellDate);
                      })?.at(0)?.appointments;
                      appointmentsForSlotDate?.sort((a, b) => {
                        const dateA = new Date(a.appointmentSlot.startDateTime);
                        const dateB = new Date(b.appointmentSlot.startDateTime);

                        // Compare dates
                        return dateA - dateB;
                      });
                      if (appointmentsForSlotDate.length > 0) {
                        setCurrentCellAppointments(p => appointmentsForSlotDate);
                      }
                    });

                  }}
                  isComponentDataLoading={isLoading}
                />
              </div>
            </div>

            <div className="my-3">
              {isLoading ? <Skeleton /> : <Calendar
                onView={(v) => {
                  setCurrentView(p => v);
                }}
                localizer={localizer}
                events={providerAppoinments}


                formats={{
                  dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
                    localizer.format(start, 'ddd D') +
                    ' - ' +
                    localizer.format(end, 'ddd D'),
                  dateFormat: (date, culture, localizer) =>
                    localizer.format(date, 'D'),

                }}
                fetchAppointments={refetchAppointments}
                onNavigate={handleNavigate}
                startAccessor="start"
                onSelectSlot={slot => {
                  // const currentCellDate = dateTimeHelper(slot.start, "YYYY-MM-DD");

                  const currentCellDate = changeDateTimeFormat(slot.start, "YYYY-MM-DD")
                  setSelectedCellDate(changeDateTimeFormat(moment(slot.start), "dddd, MMM D, YYYY"));
                  const appointmentsForSlotDate = response.filter(item => item.date === currentCellDate)?.at(0)?.appointments;
                  appointmentsForSlotDate.sort((a, b) => {
                    const dateA = new Date(a.appointmentSlot.startDateTime);
                    const dateB = new Date(b.appointmentSlot.startDateTime);

                    // Compare dates
                    return dateA - dateB;
                  });

                  setCurrentCellAppointments(appointmentsForSlotDate);
                }}
                onSelectEvent={selectedEvent => {
                  handleAppointmentEvent(selectedEvent)
                }}
                endAccessor="end"
                style={{ height: 600, fontSize: '14px' }}
                views={{
                  month: true,
                  week: true,
                  day: true,
                }}
                selectable
                getNow={getNow}
                // localizer={localizer}
                scrollToTime={scrollToTime}
                popup
                components={{
                  toolbar: (props) => (<CustomToolbar
                    {...props}

                    handler={() => {
                      const appointmentsForSlotDate = response?.filter(item => {
                        return item?.date == dateTimeHelper(moment(), "yyyy-MM-dd");
                      })?.at(0)?.appointments;
                      appointmentsForSlotDate?.sort((a, b) => {
                        const dateA = new Date(a.appointmentSlot.startDateTime);
                        const dateB = new Date(b.appointmentSlot.startDateTime);

                        // Compare dates
                        return dateA - dateB;
                      });
                      if (appointmentsForSlotDate?.length > 0) {
                        setCurrentCellAppointments(p => appointmentsForSlotDate);
                      }
                      setSelectedCellDate(dateTimeHelper(moment(), "dddd, MMM D, YYYY"))
                    }
                    }

                    daysHandler={(selectedDate) => {
                      console.log("Raw", selectedDate);
                      const appointmentsForSlotDate = response?.filter(item => {
                        return item?.date == selectedDate;
                      })?.at(0)?.appointments;
                      appointmentsForSlotDate?.sort((a, b) => {
                        const dateA = new Date(a.appointmentSlot.startDateTime);
                        const dateB = new Date(b.appointmentSlot.startDateTime);

                        // Compare dates
                        return dateA - dateB;
                      });
                      if (appointmentsForSlotDate?.length > 0) {
                        setCurrentCellAppointments(p => appointmentsForSlotDate);
                      }
                      else {
                        setCurrentCellAppointments(p => []);
                      }
                      console.log("Selected Cell Date: ", changeDateTimeFormat(selectedDate, "dddd, MMM D, YYYY"));
                      setSelectedCellDate(changeDateTimeFormat(selectedDate, "dddd, MMM D, YYYY"))
                    }}

                  />),
                  event: (props) => (<CustomEvent {...props} view={currentView} />)
                }}


              />}
            </div>
          </div>
          <div
            className="col-md-4  "
            style={{ borderLeft: "1px solid #D9E8EC" }}
          >
            <div className="row mt-5 ms-2">
              <div className="col-8 ">
                <div>
                  <span className="text--secondary font-16 fw-sb">
                    {/* Saturday, March 03 2022 */}
                    {/* {dateTimeHelper(new Date(), "dddd, MMM DD YYYY")} */}
                    {selectedCellDate}
                  </span>
                </div>

              </div>
            </div>
            <div className="row p-2 ms-2">
              <div className="col-12">
                {currentCellAppointments != undefined &&
                  currentCellAppointments.length > 0 &&
                  currentCellAppointments.map((appointmentItem, index) => {

                    return <AppoinmentCard
                      key={index}
                      active={index === 0 ? true : false}
                      appointmentItemData={appointmentItem}
                      fecthAppointments={fetchAppointments}
                      setIsAppointmentCancelled={setIsAppointmentCancelled}
                      appointmentDate={appointmentDate}
                      currentCellAppointments={currentCellAppointments}
                      setCurrentCellAppointments={setCurrentCellAppointments}
                      setAppointmentCancellationFail={
                        setAppointmentCancellationFail
                      }
                      setApppointmentCancellationError={
                        setApppointmentCancellationError
                      }
                      isAppointmentCancelling={isAppointmentCancelling}
                      isAppointmentRescheduled={isAppointmentRescheduled}
                      setIsAppointmentCancelling={
                        setIsAppointmentCancelling
                      }
                      setIsAppointmentRescheduled={
                        setIsAppointmentRescheduled
                      }
                      setAppointmentNotScheduledError={
                        setAppointmentNotScheduledError
                      }
                      handleCurrentMonth={handleCurrentMonth}
                      setSelectedCellDate={setSelectedCellDate}
                      convertDateFormat={convertDateFormat}
                      selectedCellDate={selectedCellDate}
                    />


                  })}

              </div>
            </div>
          </div>
        </div>

      </div>

      {openAppointmentEvent ? <AppointmentEventModal setOpenAppointmentEvent={setOpenAppointmentEvent} placement={"end"} show={openAppointmentEvent} data={event} handleClose={handleAppointmentEvent} /> : null}


      <CustomizedSnackbars
        message={"Appointment saved"}
        severity="success"
        open={isSentAppointment}
        handleClose={handleAppointmentToastClose}
      />
      <CustomizedSnackbars
        message={"Schedule published"}
        severity="success"
        open={isSentSchedule}
        handleClose={handleScheduleToastClose}
      />
      <CustomizedSnackbars
        message={"Please fill all fields"}
        severity="error"
        open={isSentScheduleFail}
        handleClose={handleScheduleFailToastClose}
      />

      <CustomizedSnackbars
        message={"Appointment canceled successfully"}
        severity="success"
        open={isAppointmentCancelled}
        handleClose={handleCancelAppointmentClose}
      />
      <CustomizedSnackbars
        message={"Incorrect time slot or weekly schedule"}
        severity="error"
        open={scheduleFileUploadFail}
        handleClose={handlePublishScheduleFailToastClose}
      />
      <CustomizedSnackbars
        message={"Start time should be before end time"}
        severity="error"
        open={scheduleTimeSLotFail}
        handleClose={handlePublishScheduleTimeSlotFailToastClose}
      />
      <CustomizedSnackbars
        message={appointmentCancellationError}
        severity="error"
        open={appointmentCancellationFail}
        handleClose={handleAppointmentCancellationToastClose}
      />
      <CustomizedSnackbars
        message={"Appointment rescheduled successfully"}
        severity="success"
        open={isAppointmentRescheduled}
        handleClose={handleRescheduledAppointmentClose}
      />
      <CustomizedSnackbars
        message={appointmentNotScheduledError}
        severity="error"
        open={isAppointmentNotRescheduled}
        handleClose={handleNotRescheduledAppointmentClose}
      />

      <CustomizedSnackbars
        message={timeSlotOverlapErrorMessage}
        severity="error"
        open={isTimeSlotOverlap}
        handleClose={handleTimeSlotOverlapToastClose}
      />
    </>
  );
};

export default Appointments;
