export let getMedicationHistoryUrl = (patientId) => (`/patient/${patientId}/medicationHistory`);
export let getDiagnosisHistoryUrl = (patientId) => (`/patient/${patientId}/diagnosis-history`);
export let addMedicationHistoryUrl = (encounterId) => (`/encounter/${encounterId}/medicationHistory`);
export let updateMedicationHistoryUrl = (encounterId, medicationHistoryId) => (`/encounter/${encounterId}/medicationHistory/${medicationHistoryId}`);

export let getSurgeryHistoryUrl = (patientId) => (`/patient/${patientId}/surgery`);
export let addSurgeryUrl = (encounterId) => (`/encounter/${encounterId}/surgery`);
export let updateSurgeryUrl = (encounterId, surgeryId) => (`/encounter/${encounterId}/surgery/${surgeryId}`);

export let getFamilyMedicalHistoryUrl = (patientId) => (`/patient/${patientId}/familyMedicalHistory`);
export let addFamilyMedicalHistoryUrl = (encounterId) => (`/encounter/${encounterId}/familyMedicalHistory`);
export let updateFamilyMedicalHistoryUrl = (encounterId, familyMedicalHistoryId) => (`/encounter/${encounterId}/familyMedicalHistory/${familyMedicalHistoryId}`);

export let getHospitalizationUrl = (patientId) => (`/patient/${patientId}/hospitalisation`);
export let addHospitalizationUrl = (encounterId) => (`/encounter/${encounterId}/hospitalization`);
export let updateHospitalizationUrl = (encounterId, hospitalizationId) => (`/encounter/${encounterId}/hospitalization/${hospitalizationId}`);

export let getPatientRecentVitalsUrl = (encounterId) => (`/prescription-reference/${encounterId}/vitals`);
export let updateEncounterUrl = (encounterId) => (`/prescription-reference/${encounterId}`);

export let addendumUrl = (encounterId) => (`/encounter/${encounterId}/addendum`);
export let fetchEncountersUrl = (userId) => (`/provider/${userId}/prescription-references`);
export let markAsNoShowUrl = (taskId) => (`/tasks/noshow/${taskId}`);

export let providerUrl = (tenantId) => (`/provider/tenant/${tenantId}`);

export let providerWithNpiUrl = (tenantId) => (`/provider/tenant/${tenantId}/all`);

export let providerSettingsUrl = (userId) => (`/provider/${userId}/settings`)
export let providerSettingSignaturesUrl = (userId) => (`/provider/${userId}/signature`)


//Patient Search
export let patientSearchUrl = (tenantId) => (`/patient/tenant/${tenantId}`);
export let encounterMetadataUrl = (encounterId) => (`/encounter/${encounterId}/metadata`);
export let patientEncountersMetadataUrl = (patientId, prescriptionRefId) => (`/patient/${patientId}/encounter-metadata/${prescriptionRefId}`);
export let patientChiefComplaintUrl = (encounterId) => (`/encounter/${encounterId}/chief-complaint`);

export let fetchSocialHistoryQuestionsUrl = (encounterId) => (`/encounter/${0}/socialHistory/questions`);
export let fetchSocialHistoryUrl = (encounterId) => (`/patient/${encounterId}/socialHistory`);
export let addSocialHistoryUrl = (encounterId) => (`/patient/${encounterId}/socialHistory`);
export let fetchPatientSocialHistoryUrl = (patientId) => (`/patient/${patientId}/socialHistory`);
export let patientVitalsHistoryUrl = (encounterId, patientId) => (`/prescription-reference/${encounterId}/patient/${patientId}/vitals`);
export let patientRecentEncounters = (patientId) => (`/prescription-reference/patient/${patientId}/closed`);
export let encounterSummaryUrl = (encounterId) => (`/prescription-reference/${encounterId}`);
export let encounterSignOffUrl = (encounterId, userId) => (`/encounter/${encounterId}/provider/${userId}/signOff`);
export let getPatientAllergiesUrl = (patientId) => (`/patient/${patientId}/allergies`);
export let exportSummaryAsPdf = (prescriptionReferenceId, userId) => (`/prescription-reference/${prescriptionReferenceId}/summary/pdf/${userId}`);
export let searchProviderUrl = (tenantId) => (`/provider/search/tenant/${tenantId}`);

export let acceptRenewalRequestUrl = (userId, spi) => (`/rx/renewalResponse/${userId}/spi/${spi}`);
export let fetchAvailableStrengthsUrl = () => (drugDbCode) => (`/drugs/${drugDbCode}/available-strengths`);

//Cancel Rx
export let cancelRxURL = (medicationId, patientId, providerId, spi, note) => (`/cancel-prescription/medication/${medicationId}/patient/1/provider/1/${spi}/${note}`);

export let pharmacySearchURL = (tenantId) => (`/pn-pharmacy/tenant/${tenantId}`);



export let fetchPatientObservationsURL = (patientId) => (`/patient/${patientId}/patientObservation`);

export let searchAllProvidersUrl = (tenantId) => (`/provider/search/tenant/${tenantId}`);

export let fetchEncounterMedicationsURL = (encounterId) => (`/encounter/${encounterId}/medicationRx`)
export let addressStandardizationUrl = () => (`/standardize-address`);
export let practiceTypeCodeUrl = () => (`/claimsManagement/serviceTypeCodes/list`);
export let payersListUrl = () => (`/claimsManagement/payers/list`);
export let relationsListUrl = () => (`/patient/relations`);
export let eligibilitySummaryUrl = () => (`/claimsManagement/eligibility/submit`);
export let fetchCurrentMedicationURL = (patientId) => (`/patient/${patientId}/current-medications`);
export let fetchRx = (providerId) => (`/tasks/rx/${providerId}`);
export let fetchRxTask = (taskId) => (`/tasks/${taskId}`);
export let deleteAvailableSlot = (slotId) => (`/availabilities/availableSlots/${slotId}`);

export let fetchPatientGeneralInfoURL = (patientId) => (`/patient/${patientId}/general-info`)
export let fecthPatientInformationURL = () => (`/patient/information`)

export let downloadPatientAfterVisitSummaryURL = (prescriptionReferenceId) => (`/encounter/${prescriptionReferenceId}/after-summary/pdf`);
export let fetchLabOrdersURL = (patientId) => (`/patient/${patientId}/lab-orders`)
export let rePrintLabOrderURL = (id) => (`/lab-test-request/${id}/print`)
export let fetchPastSOAPSURLS = (patientId, providerId) => (`/prescription-reference?patientId=${patientId}&providerId=${providerId}`)
export let fetchPastAptURL = (patientId, providerId) => (`/patient/${patientId}/past-appointments?providerId=${providerId}`)
export let fetchAppointmentConsentFormsURL = (appointmentId) => (`/appointment-consent-forms/${appointmentId}`)

export let practiceCodeTypeListUrl = () => (`/claimsManagement/practiceTypeCodes/list`)

export let fetchFacilityById = (id) => (`/facilities/${id}`);
export let fetchTenantById = (id) => (`/practice/${id}`);
export let fetchlicenceByNPI = (npi) => (`/provider/${npi}/taxonomy`);
export let patientAllEncounters = (patientId) => (`/prescription-reference/patient/${patientId}`);

export let transferPayment = (tenantId) => (`/payments/transfer/${tenantId}`);
export let getPaymentToken = (tenantId) => (`/payments/token/${tenantId}`);


export let billingHistoryURL = (patientId) => (`/patients/${patientId}/bills/history`)
export let currentbillURL = (patientId) => (`/patients/${patientId}/bills/current`)


//============================Apointment URLS=========================
export let aptServiceURL = (tenantId) => (`/tenants/${tenantId}/services`)
export let aptServicePNTURL = (tenantId,displayable) => (`/tenants/${tenantId}/services?displayable=${displayable}`)

// ==========================Patient Documents URLS =========================
// export let patientDocumentsUrl = (patientId) => (`/patients/${patientId}/documents`)
// export let getchSinglePatientDocumentUrl = (patientId, documentId) => (`/patients/${patientId}/documents/${documentId}`)

export let fetchPatientDocumentsUrl = (patientId) => (`/patients/${patientId}/documents`);
export let fetchPatientSingleDocumentUrl = (patientId, documentId) => (`/patients/${patientId}/documents/${documentId}`);
export let reviewPatientDocumentUrl = (patientId, documentId) => (`/patients/${patientId}/documents/${documentId}/review`);