import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";
import SearchProviderChip from "../UI/General/SearchProviderChip";
// import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";
import classes from "./ReplyForwardContainer.module.css";
import TextArea from "../forms/TextArea";
import { useDispatch, useSelector } from "react-redux";
import IconFile from "../../assets/icons/Icon-file.svg";
import {
  setPatientID,
  setUnreadMessageCount,
} from "../../redux/actions/providerActions";
import { Skeleton } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useAuth } from "../hooks/useAuth";

const ReplyForwardContainer = (props) => {
  const [isuserInfoLoading, setIsUserInfoLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [prescriberList, setPrescriberList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  let patientId = useSelector((state) => state.searchedPatientId.patientId);
  const [patientName, setPatientName] = useState();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState([]);
  const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "PDF"];
  const [isPrescriber, setIsPrescriber] = useState(true);
  const [isPatient, setIsPatient] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState(
    props?.forward && props?.messageData?.body
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [fileType, setFileType] = useState("");
  const [showUploadData, setShowUploadData] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [addToChart, setAddToChart] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const dispatch = useDispatch();
  const [requiredFieldErorrMessage, setRequiredFieldErorrMessage] = useState();
  const [isRecipientDataLoading, setIsRecipientDataLoading] = useState(false);
  const [recipientData, setRecipientData] = useState();
  const { user } = useAuth();
  const apiData = new FormData();

  const getMapFromSession = async () => {
    setIsUserInfoLoading(false);
    const httpResponse = await sendHttpRequest({
      url: "/session",
      method: "GET",
    });
    if (!httpResponse.error) {
      setUserInfo(JSON.parse(httpResponse.data.user));
      setIsUserInfoLoading(true);
    } else {
    }
  };
  const mesasgeHandler = async () => {
    setIsMessageSending(true);
    let recipientList = [];
    // console.log("patientList", recipientData);
    // console.log("prescriberList", prescriberList);
    // console.log([...patientList, ...prescriberList]);

    if (isPatient) {
      recipientList = createRecipientIdList([
        ...patientList,
        ...prescriberList,
      ]);
      // console.log(recipientList);
    } else {
      recipientList = createRecipientIdList(prescriberList);
    }
    const setApiData = () => {
      apiData.append("chart", addToChart);
      apiData.append(`urgency`, urgent);
      apiData.append("from", user?.userId);
      apiData.append("subject", subject);
      apiData.append("body", message === undefined ? " " : message);
      apiData.append("to", recipientData?.role !== "patient" ? recipientList : [patientList[0]?.id]);
      apiData.append("threadId", props?.messageData?.threadId);
      apiData.append("tenantId", user?.tenantId);
      if (fileName !== null && fileName?.length !== 0) {
        apiData.append("file", file);
      }
    };
    if (patientId !== -1) {
      apiData.append("patientId", patientId);
    } else {
      if (
        props?.messageData?.patientId !== null &&
        props?.messageData?.patientId !== undefined
      ) {
        apiData.append("patientId", props?.messageData?.patientId);
        apiData.append("threadId", props?.messageData?.threadId);
      }
    }

    if (props?.reply === true) {
      apiData.append("parentId", props?.messageData?.persistentMessageId);
    }
    setApiData();
    if (apiData.get("to")?.length > 0) {
      const httpResponse = await sendHttpRequest({
        url: "/messages",
        method: "POST",
        data: apiData,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });

      if (!httpResponse.error) {
        props.setIsMessageSent(true);
        fecthUnreadMessagesCount();
        setPrescriberList([]);
        props?.handleClose();
        // props.fetchSentMessagesCount();
        // props.fetchSentMessages();
      } else {
        props.setIsMessageSent(false);
        props.setIsMessageSentError(true);
        // console.log(httpResponse.error);
      }
    } else {
      setRequiredFieldErorrMessage("Please select a recipient");
    }
    setIsMessageSending(false);
  };
  const sendFileAndType = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      convertToMultipart(file, e.target.result);
    };
  };
  const convertToMultipart = (fileV, trimmedDataURL) => {
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];
    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name, {
          type: mimeType,
        });
        console.log(file);
        setFile(file);
      });
  };
  const removeFile = (event) => {
    const dummy = fileName.filter((file, index) => index != event.target.id);
    setFileName(dummy);
    setFile("");
  };
  const handleFileInput = (file) => {
    const temp = [];
    temp.push(file);
    setFileName(temp);
    sendFileAndType(file);
    // console.log(temp);
  };

  const createRecipientIdList = (recipeints) => {
    let recipientList = recipeints.map((recipientItem) => recipientItem.userId);
    return recipientList;
  };
  const fecthUnreadMessagesCount = async () => {
    const httpResponse = await sendHttpRequest({
      url: "messages/unread",
      method: "GET",
      params: {
        userId: user?.userId
      }
    });
    if (!httpResponse.error) {
      dispatch(setUnreadMessageCount(httpResponse?.data));
    } else {
      console.log(httpResponse.error);
    }
  };
  const clearState = () => {
    setPatientList([]);
    setPrescriberList([]);
    setIsLoading(true);
    setIsUploading(false);
    setFileUploadModal(false);
    setFileType("");
    setShowUploadData("");
    setSelectedFile("");
    setIsPrescriber(true);
    setIsPatient(false);
    // setIsMessageSent(false);
    setIsMessageSending(false);
    setSubject("");
    setMessage("");
    setFile("");
    setFileName([]);
    setPatientName("");
  };

  const downloadAttachment = async (documentId) => {
    const httpResponse = await sendHttpRequest({
      url: "/patient/document",
      method: "GET",
      params: {
        documentId: documentId,
      },
    });
    if (!httpResponse.error) {
      downloadFile(httpResponse.data.filePath, httpResponse.data.name);
    } else {
      console.log(httpResponse.error);
    }
  };
  function downloadFile(url, fileName) {
    fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
      .then(res => res.blob())
      .then(res => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }

  const setInPrescriberList = (prescriber) => {
    const tempList = [...prescriberList];
    tempList.push(prescriber);
    const unique = [
      ...new Map(tempList.map((item) => [item.id, item])).values(),
    ];
    setPrescriberList(unique);
  };
  const handleMessage = (value) => {
    setMessage(value);
  };

  const setInPatientList = (patient) => {
    const tempList = [...patientList];
    tempList.push(patient);
    const unique = [
      ...new Map(tempList.map((item) => [item.id, item])).values(),
    ];
    setPatientList(unique);
  };
  const fetchReceiverData = async (userId) => {
    setIsRecipientDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      if (httpResponse.data.role === "patient") {
        setRecipientData(httpResponse.data);
      } else {
        setInPrescriberList({
          id: userId,
          ...httpResponse?.data,
        });
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsRecipientDataLoading(false);
  };
  // const getProviderData = async (pid) => {
  //   setIsRecipientDataLoading(true);
  //   const httpResponse = await sendHttpRequest({
  //     url: "/prescriber",
  //     method: "GET",
  //     params: {
  //       id: pid,
  //     },
  //   });
  //   if (!httpResponse?.error) {
  //     setInPrescriberList({
  //       id: httpResponse?.data?.NonVeterinarian?.id,
  //       ...httpResponse?.data?.NonVeterinarian?.Name,
  //     });
  //   } else {
  //     console.log(httpResponse?.error);
  //   }
  //   setIsRecipientDataLoading(false);
  // };
  const fetchPatientData = async (userId) => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      patientId = userId;
      setPatientName(
        httpResponse?.data?.name[0]?.firstName +
        " " +
        httpResponse?.data?.name[0]?.lastName
      );
      setInPatientList({
        id: patientId,
        ...httpResponse,
      });
    } else {
      console.log(httpResponse.error);
    }
  };
  useEffect(() => {
    dispatch(setPatientID(-1));
    // getMapFromSession();
    fetchPatientData(props?.messageData?.patientId);
    if (props?.reply === true) {
      setSubject(props?.messageData?.subject);
      if (props?.inbox === true) {
        fetchReceiverData(props?.messageData?.from);
      } else {
        fetchReceiverData(props?.messageData?.to);
      }
    }
    if (props?.forward === true) {
      if (props?.messageData?.subject?.toLowerCase().indexOf("fw") === -1) {
        setSubject("FW:" + props?.messageData?.subject);
      } else {
        setSubject(props?.messageData?.subject);
      }
      if (props?.messageData?.attachment !== null) {
        downloadAttachment(props?.messageData?.attachment);
      }
    }
    return () => {
      //flush states on component unmount
      clearState();
    };
  }, [props?.messageData?.persistentMessageId]);

  console.log(props);

  return (
    <div className={`${classes["rf-container"]}  hide-scrolbar`}>
      <div>
        {
          props?.patientPortal ?
            <div className="bg--background text--secondary font-14 p-2">Please call 911 in case of an emergency</div>
            : <></>
        }
        {isRecipientDataLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="row p-2">
              <div className="col-12">
                <div className="d-flex flex-row">
                  <div className="">
                    <img
                      src={userInfo?.profilePicture ?? DefaultAvatarIcon}
                      width="40px"
                      height="40px"
                      alt="Provider"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="text--secondary font-12  mt-2 px-3">
                    <span>{props?.headerText}</span>
                  </div>
                  <div className={`${classes["recipient-container"]}`}>
                    {
                      recipientData?.role !== "patient" ?
                        <SearchProviderChip
                          inMessage={true}
                          handleProvidersAdd={setInPrescriberList}
                          setUserList={setPrescriberList}
                          defaultUserList={prescriberList}
                        // messageData = {props?.messageData}
                        /> :
                        <>
                          <div className={`${classes["tag"]} mt-1`}>{recipientData?.name[0]?.firstName + " " + recipientData.name[0]?.lastName}</div>
                        </>
                    }
                  </div>
                </div>
              </div>
              <div >
                <div className="col-sm-12 mt-3 mb-3">
                  <TextArea
                    type="text"
                    className={`form-control font-14 m-0 border--default text--terciary br-10 shadow-none msg-input`}
                    id="message"
                    maxLength="2048"
                    gettext={handleMessage}
                    rows={4}
                    defaultValue={props?.forward && props?.messageData?.body}
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    {fileName.length > 0
                      ? fileName.map((file, index) => {
                        return (
                          <>
                            <div className="row mt-2">
                              <div className="col-sm-6 font-12 text--terciary">
                                {file?.name && file.name}
                              </div>
                              <div
                                key={index}
                                className="col-sm-2"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <TrashIcon
                                  id={index}
                                  style={{ color: "red", height: 13 }}
                                  onClick={(e) => removeFile(e)}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })
                      : ""}
                  </div>
                </div>

              </div>
            </div>
            {/* Footer */}
            <div
              className={`d-flex flex-row justify-content-between px-3 mb-0 ${classes["container__footer"]}`}
            >
              <div className="d-flex flex-row align-self-center">
                <div className="br-10 border--default bg--background border p-2">
                  <FileUploader
                    handleChange={handleFileInput}
                    name="file"
                    label="Drop a file or click here"
                    types={fileTypes}
                  >
                    <img
                      src={IconFile}
                      alt={"File icon"}
                      className="icon__defualt"
                    ></img>
                  </FileUploader>
                </div>
              </div>
              <div className="d-flex flex-row align-self-center">
                <div>
                  <button
                    className="btn  text--blue d9e8ec--button fw-sb font-14 me-2 br-10  "
                    style={{ width: "160px" }}
                    onClick={() => {
                      props?.setCloseFooter(false);
                      props?.setOpenContainer(!props?.openContainer);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className=" bg--blue text--white blue_task_button font-14 fw-sb  br-10  border-0"
                    style={{ width: "160px" }}
                    onClick={() => {
                      props?.setCloseFooter(false);
                      props?.setOpenContainer(!props?.openContainer);
                      mesasgeHandler();
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* {console.log("props?.messageData",props?.messageData)} */}
      {/* {console.log("patientList",patientList[0]?.id)} */}
    </div>
  );
};

export default ReplyForwardContainer;
