import { Skeleton } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import { dateTimeHelper } from "../../../../utils/dateTimeHelper";
import { RolesMap } from "../../../../utils/helpers/RolesMap";
import { useSelector } from "react-redux";

const FileShowModal = (props) => {
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  let isReviewed = false;
  const checkReview = (event) => {
    if (event.target.checked) {
      isReviewed = true;
    } else {
      isReviewed = false;
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props?.fileShowModalClose();
        }}
        size="lg"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-show"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="w-100">
          <Modal.Title className="w-100 font-18 text--terciary">
            Document Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row text-center justify-content-center">
              <div className="col-sm-12">
                {/* <div className={`${classes["responsive-iframe"]}`}> */}
                {
                  props?.isLoadingFetch ? (
                    <Skeleton
                      width="100%"
                      height="550px"
                      max-width="100%"
                      max-height="calc((100vw - 40px) / (16/9))"
                      variant={"rectangular"}
                    />
                  ) : (
                    // "cannot find the file"
                    <iframe
                      id="file_iframe"
                      src={props?.file?.filePath}
                      width="100%"
                      height="550px"
                      max-width="100%"
                      max-height="calc((100vw - 40px) / (16/9))"
                    />
                  )
                  // <img id="file_iframe" src={"http://localhost:8082/prescribenow/patientDocuments/1244/Exception1666950841715.PNG"} width="100%" height="550px" max-width="100%" max-height="calc((100vw - 40px) / (16/9))" />
                }

                {/* </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer
          style={
            RolesMap(props?.providerRole) === "staff"
              ? {}
              : { border: "none", justifyContent: `${userPermissions?.psd === false ? 'end':'space-between'}` }
          }
        >
          <div
            className="form-check"
            // hidden={RolesMap(props?.providerRole) === "staff" ? true : false}
            hidden = {userPermissions?.psd === false ? true:false}
          >
            {!props?.review && (
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={props?.review}
                disabled={props?.review ? true : false}
                id="fileReview"
                onChange={checkReview}
              />
            )}
            {props?.review ? (
              <label className={"font-14 text--terciary"} for="fileReview">
                {props?.fileTypeOnShow !== undefined &&
                props?.fileTypeOnShow === "TCM"
                  ? `Discharge medication reviewed by 
                  ${
                    props?.file?.documentReview?.length > 0
                      ? props?.file.documentReview[0]?.reviewedBy?.name[0]
                          ?.firstName
                      : ""
                  } 
                  ${
                    props?.file.documentReview?.length > 0
                      ? props?.file.documentReview[0]?.reviewedBy?.name[0]
                          ?.lastName
                      : ""
                  }${
                      props?.file.documentReview[0]?.reviewedBy?.role ===
                      "Physician"
                        ? ", " +
                          props?.file.documentReview[0]?.reviewedBy
                            ?.qualification
                        : ""
                    } 
                  on 
                  ${
                    props?.file.documentReview?.length > 0 &&
                    props?.file.documentReview[0]?.reviewedDate != null &&
                    props?.file.documentReview[0]?.reviewedDate != undefined
                      ? dateTimeHelper(
                          props?.file.documentReview[0]?.reviewedDate,
                          "MMM D, YYYY - LT"
                        )
                      : ""
                  }`
                  : `Reviewed by 
                  ${
                    props?.file.documentReview?.length > 0
                      ? props?.file.documentReview[0]?.reviewedBy?.name[0]
                          ?.firstName
                      : ""
                  } 
                  ${
                    props?.file.documentReview?.length > 0
                      ? props?.file.documentReview[0]?.reviewedBy?.name[0]
                          ?.lastName
                      : ""
                  }${
                      props?.file.documentReview[0]?.reviewedBy?.role ===
                      "Physician"
                        ? ", " +
                          props?.file.documentReview[0]?.reviewedBy
                            ?.qualification
                        : ""
                    }
                    on 
                    ${
                      props?.file.documentReview?.length > 0 &&
                      props?.file.documentReview[0]?.reviewedDate != null &&
                      props?.file.documentReview[0]?.reviewedDate != undefined
                        ? dateTimeHelper(
                            props?.file.documentReview[0]?.reviewedDate,
                            "MMM D, YYYY"
                          )+" at "+ dateTimeHelper(
                            props?.file.documentReview[0]?.reviewedDate,
                            "LT"
                          )
                        : ""
                    }`}
              </label>
            ) : (
              <label className="form-check-label" for="fileReview">
                {props?.fileTypeOnShow !== undefined &&
                props?.fileTypeOnShow === "TCM"
                  ? "Discharge medication reviewed"
                  : `I have reviewed this document`}
              </label>
            )}
          </div>
          
            <button
              className="btn bg--blue text-white b2 px-4 br-10 py-1 shadow-none"
              onClick={() => {
                isReviewed === true ? props?.reviewFile() : (isReviewed = false);
                isReviewed = false;
                props?.fileShowModalClose();
              }}
            >
              Close
            </button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FileShowModal;
