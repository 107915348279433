import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { addTimeHelper, changeDateTimeFormat, dateTimeHelper } from "../../utils/dateTimeHelper";
import classes from "./PatientApptRerveModal.module.css";
import moment from "moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #D9E8EC",
    borderRadius: "10px",
    boxShadow: "0px 8px 16px #00000014",
    p: 4,
    justifyContent: "center",
};


const PatientApptReservedModal = (props) => {

    const navigate = useNavigate();
    // console.log(props)

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleApptReserveModal();
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // contentClassName={`${classes["appointmentmodal__style"]} `}
            dialogClassName={`${classes["custom-modal-style"]}`}
        >
            <Modal.Header closeButton style={{ border: "none" }}>
                <span className=" font-18 text--terciary mt-2 fw-sb">Appointment Reserved</span>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid br-10 border--default" style={{ borderRadius: "10px" }}>
                    <div className="row text-center justify-content-center">
                        <div className="col-12 py-2 mt-0 mb-3">
                            {/* <span className=" font-18 text--terciary fw-sb">Appointment Reserved</span> */}
                            <Typography component="span">
                                <Box
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "#336383",
                                    }}
                                >
                                    {dateTimeHelper(props?.apptData?.startDateTime, "dddd, MMM D, yyyy")}
                                    {/* Tuesday, March 15, 2022 */}
                                </Box>
                            </Typography>

                            <Typography component="span">
                                <Box
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "#336383",
                                        marginBottom: 3,
                                    }}
                                >
                                    <b>{dateTimeHelper(props?.apptData?.startDateTime, "LT")}</b> - {dateTimeHelper(props?.apptData?.endDateTime, "LT")}
                                </Box>
                            </Typography>
                            <div className="br-10 border--default py-2 px-4 my-2" style={{ backgroundColor: "#F2F7F8", border: '1px solid #D9E8EC', borderRadius: "10px" }}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Typography component="span">
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    fontFamily: "Montserrat",
                                                    fontWeight: 600,
                                                    fontSize: "12px",
                                                    color: "#336383",
                                                }}
                                            >
                                                {"Reservation valid for 15 minutes"}
                                            </Box>
                                        </Typography>
                                    </div>
                                    <div className="col-sm-12">
                                        <Typography component="span">
                                            <Box
                                                sx={{
                                                    textAlign: "center",
                                                    fontFamily: "Montserrat",
                                                    fontWeight: 400,
                                                    fontSize: "12px",
                                                    color: "#336383",
                                                }}
                                            >
                                                {"Payment is required for confirmation"}
                                            </Box>
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <button
                                className={`btn blue_task_button ${classes.blue_button}`}
                                onClick={() => { props?.handleApptReserveModal(); navigate(props?.url, { state: { ...props?.apptData, chiefComplaint: props?.chiefComplaint, type: props?.type } }); }}
                            >
                                Proceed to Payment
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PatientApptReservedModal;
