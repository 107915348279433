import GreetingMessage from "./GreetingMessage";
import classes from '../General/Stats.module.css';
import { useSelector } from "react-redux";

const Greeting = (props) => {
  const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);
  const classes = "row border-bottom "
  return (
    <div className={`${classes} ${props.className} py-3`}>
      <div id="pn-provider-greeting" className="col-12 col-sm-12 col-md-4">
        <GreetingMessage />
      </div>
      <div className="col-12 col-sm-12 col-md-8">
        <div className="row justify-content-end description-style">
          
          <div id="pn-provider-refills" className=" col-12 col-sm-12 col-md-4">
            {props?.refills ?? ""}
          </div>
          {
            userPermissions?.vpe === true ? 
              <div id="pn-provider-encounters" className="col-12 col-sm-12 col-md-4">
                {props?.encounters ?? ""}
              </div>
            :null
          }

          <div id="pn-provider-messages" className="col-12 col-sm-12 col-md-4">
            {props?.messages ?? ""}
          </div>


        </div>
      </div>
    </div>
  );
};

export default Greeting;

