import {
  Box,
  Grid,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import React, { useState } from "react";
import classes from "../../patient/PatientItem.module.css";
import { dateTimeHelper, changeDateTimeFormat } from "../../../utils/dateTimeHelper";
import { ReactComponent as ViewSummary } from '../../../assets/icons/Icon-Preview-Summary.svg';
import { ReactComponent as AddAddendum } from '../../../assets/icons/Icon-Add-Addendum.svg';
import { ReactComponent as ViewAddendum } from '../../../assets/icons/Icon-View-Addendum.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { setDiagnosis, setDisableSendRxButton, setPatientEncounterData, setPrescribedMedication, setPrescriptionNoteText, setUpdateEncounterData, setVideoCallState, updatePrescribedMedication } from "../../../redux/actions/providerActions";
import { truncate } from "../../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../../UI/Reusable/PnComponents";


const PatientEncounterItem = (props) => {
  // console.log(props);
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [encounter, setEncounter] = useState('');
  const [isLoadingEnc, setIsLoadingEnc] = useState(false);
  const [prescriberMedication, setPrescribedMedication] = useState([])

  const [patientVisitType, setPatientVisitType] = useState(props?.encounter?.appointment?.patientVisitType?.displayValue)


  const fetchPatientEncounter = async (encId) => {
    setIsLoadingEnc(false);
    const httpResponse = await sendHttpRequest({
      url: "/singleEncounter/summary",
      method: "GET",
      params: {
        EncounterId: encId
      }
    });
    if (!httpResponse.error) {
      setEncounter(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingEnc(true);
  }
  const fetchPrescribedMedication = async (encId) => {

    const httpResponse = await sendHttpRequest({
      url: "/medicationRx",
      method: "GET",
      params: {
        EncounterId: encId
      }
    });
    if (!httpResponse.error) {
      console.log("Medication Rx->", httpResponse.data);
      setPrescribedMedication(httpResponse.data);
      if (httpResponse.data.length > 0) {
        dispatch(updatePrescribedMedication(httpResponse.data));
      } else {
        dispatch(updatePrescribedMedication([]));
      }

    } else {
      console.log(httpResponse.error);
    }

  }

  const handleEncData = () => {
    props?.handleToggle();
    handleEncounterData(props?.encounter?.encounterId);
  }

  const handleEncounterData = (encId) => {
    fetchPatientEncounter(encId)
    fetchPrescribedMedication(encId)
  }
  const handlePatientEncouterData = (encounter) => {
    if (encounter?.RxNote !== undefined) {
      dispatch(setPrescriptionNoteText(encounter?.RxNote))
    } else {
      dispatch(setPrescriptionNoteText(""))
    }
    let diagnosis = {
      Note: "",
      Secondary: [],
      Primary: []
    }
    if (encounter?.Diagnosis?.Note !== undefined && encounter?.Diagnosis?.Note !== null) {
      diagnosis.Note = encounter?.Diagnosis?.Note
    }
    if (encounter?.Diagnosis?.Secondary !== undefined && encounter?.Diagnosis?.Secondary?.length > 0) {
      diagnosis.Secondary = encounter?.Diagnosis?.Secondary
    }
    if (encounter?.Diagnosis?.Primary !== undefined) {
      if (Object.keys(encounter?.Diagnosis?.Primary).length !== 0) {
        diagnosis.Primary = [encounter?.Diagnosis?.Primary]
      }
    }
    dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
    dispatch(setDiagnosis(diagnosis));
    dispatch(setPatientEncounterData({ Encounter: encounter }));
    if (encounter.status === "DISPATCHED") {
      dispatch(setDisableSendRxButton(true))
    }
    else {
      dispatch(setDisableSendRxButton(false))
    }

    // dispatch(setDiagnosis(diagnosis));
    // dispatch(setVideoCallState(false));
  }

  return (
    <Grid
      sx={{ marginBottom: "-8px" }}
      container
      p={1}

      alignItems="center"
      className={`${classes["row--border"]} mt-2  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
    >

      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {props?.createdDateTime ? dateTimeHelper(props?.createdDateTime, "MMM D, YYYY") : "Not Available"}<br />
            {props?.createdDateTime ? dateTimeHelper(props?.createdDateTime, "LT") : ""}
            {/* {props?.encounter?.appointment?.appointmentSlot?.startDateTime ? dateTimeHelper(props?.encounter?.appointment?.appointmentSlot?.startDateTime, "MMM D, YYYY - LT") : "Not Available"} */}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography component="div">
          <Box
            sx={{

              fontFamily: "Montserrat",
              fontWeight: "600",
              fontSize: "12px",
              color: "#323232",
              textAlign: "left"
            }}
          >
            <span className="text-wrap">{(props?.encounter?.providerTenantPracticeLocation?.provider?.name[0]?.prefix ?? "")}{" "}{(props?.encounter?.providerTenantPracticeLocation?.provider?.name[0]?.firstName ?? "")}{" "}{(props?.encounter?.providerTenantPracticeLocation?.provider?.name[0]?.lastName ?? "")}</span>
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // ml: 4,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {props?.encounter?.providerTenantPracticeLocation?.provider?.speciality?.length > 0 ? props?.encounter?.providerTenantPracticeLocation?.provider?.speciality[0]?.speciality : ""}

          </Box>
        </Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // ml: 4,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {props?.encounter?.appointment?.appointmentType?.title}
            {/* {props?.encounter?.appointment?.appointmentType !== "FOLLOW_UP"  ? props?.encounter?.encounter?.encounterNoteType !== "PROVIDER_NOTE" && props?.encounter?.encounter?.encounterNoteType !== "SOAP_NOTE"  ? "Consultation" : "" :  "Follow-up" } */}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {patientVisitType}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={2.4}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {props?.encounter?.encounter?.status?.toLowerCase() === 'closed' ? "Signed" : props?.encounter?.encounter?.status?.toLowerCase() === 'started' || props?.encounter?.encounter?.status?.toLowerCase() === 'dispatched' ? "Unsigned" : "" ?? "Not Available"}
          </Box>
        </Typography>
      </Grid>
      {
        props?.encounter?.encounter?.status?.toLowerCase() === "closed" || props?.status === "closed" ?
          <Grid item xs={1.6}>
            <Box
              sx={{
                textAlign: "right",

              }}
            >
              <div className="d-flex flex-row align-self-center justify-content-start">
                <PnToolTip title="Summary" arrow placement="top">
                  <button style={{
                    backgroundColor: "#FAFCFC",
                    border: "2px solid #d9e8ec",
                  }}
                    onClick={() => {
                      props?.setAddEncounterId({ encounter: props?.encounter, isSummary: true });
                      props?.handleModal(props?.encounter?.prescriptionReferenceId ?? "");
                      props?.handleToggle(); props?.setProviderNoteType(props?.encounter?.encounter?.encounterNoteType);
                    }}
                    className="btn shadow-none ms-3 br-10 position-relative"
                    hidden={userPermissions?.ves === false ? true : false}
                  >
                    <ViewSummary style={{ width: 15, height: 15, color: "#336383", fill: "#336383" }} />
                  </button>
                </PnToolTip>
                <PnToolTip title="Show Addendums" arrow placement="top">
                  <button style={{
                    backgroundColor: "#FAFCFC",
                    border: "2px solid #d9e8ec",
                  }}
                    onClick={() => {
                      props?.setAddEncounterId({ encounter: props?.encounter, isSummary: false });
                      props?.handleAddenShowModal(props?.encounter?.encounter?.encounterId ?? "");
                      props?.handleToggle();
                    }}
                    className="btn shadow-none ms-3 br-10 position-relative"
                    hidden={userPermissions?.vae === false ? true : false}
                  >
                    <AddAddendum style={{ width: 15, height: 15, color: "#336383", fill: "#336383" }} />
                  </button>
                </PnToolTip>
                {/* <PnToolTip title="Add Addendums" arrow placement="top">
                  <button style={{
                    backgroundColor: "#FAFCFC",
                    border: "2px solid #d9e8ec",
                  }}
                    onClick={() => { props?.handleAddenModal(props?.encounter?.encounter?.encounterId ?? ""); }}
                    className="btn shadow-none ms-3 br-10 position-relative">
                    <ViewAddendum style={{ width:15, height:15, color: "#336383", fill: "#336383" }} />
                  </button>
                </PnToolTip> */}
              </div>
            </Box>
          </Grid>
          : <Grid item xs={1.5}>
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <div className="align-self-center ">
                {
                  isLoadingEnc &&
                  (
                    props?.handleBackdropClose(),
                    handlePatientEncouterData(encounter),
                    navigate(`/consult/${encounter?.provider?.userId}/${encounter?.encounterId}/${encounter?.patient?.userId}`)
                  )
                }
                <button className="btn bg--blue text-white b2 px-4 br-10 py-1"
                  // onClick={() => handlePatientEncouterData()}
                  onClick={() => handleEncData()}
                >
                  Resume
                </button>

              </div>
            </Box>
          </Grid>
      }

      {/* {console.log("encounter: ",props?.encounter)} */}
    </Grid>
  );
};

export default PatientEncounterItem;
