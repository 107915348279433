export const billingAndClaimsMapKeyValueArray = [
    [0, 'Patient Information'],
    [1, 'Encounters'],
    [2, 'Invoices'],
    [3, 'Claim Submission'],
    [4, 'Claim Status'],
    [5, 'Super Bill'],
    [6, 'ERA Status'],
    [7, 'Fee Schedule'],
]

