import { Modal } from "react-bootstrap";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import CustomDatePicker from "../../General/CustomDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import { file_uploading_helping_strings } from "../../../../res/strings";
import { providerWithNpiUrl } from "../../../../res/endpointurls";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useAuth } from "../../../hooks/useAuth";
import classes from "./FileUploadModal.module.css";
import { DateTime } from "luxon";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";


const FileShowEditModal = (props) => {
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const [isUpdate, setIsUpdate] = useState(false);
  const { user } = useAuth();
  const _str = file_uploading_helping_strings.en;
  const [prescribersList, setPrescribersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTaskChecked, setIsTaskChecked] = useState(props.uploadInformation?.documentReview?.length > 0 ? true : false)

  const fecthAllPrescribers = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    }
    setIsLoading(false);
  };


  const fileTypeSelect = (event) => {
    props.setFileType(event.target.value);
    // setProviderStatus(event.target.selectedOptions[0].dataset.isreviewrequired);
  };


  const handleTaskAsignee = (event) => {
    if (event.target.checked) {
      setIsTaskChecked(true);
    } else {
      setIsTaskChecked(false);
    }
  }
  const handleProviderId = (event) => {
    onDirty();
    setIsUpdate(true);
    props.setAssignee(event.target.value);
  }


  useEffect(() => {
    fecthAllPrescribers();
  }, [])

  useEffect(() => {
    if (props?.serviceDate !== props?.uploadInformation?.serviceDate) {
      setIsUpdate(true);
      onDirty();
    }
  }, [props?.serviceDate])

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props?.setUploadInformation("");
          props.editFileModal();
        }}
        size="md"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-show "
        dialogClassName="modal-top-5"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.setUploadInformation("");
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props?.editFileModal,
                onPristine,
                props?.editFileModal
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            Edit Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form
              id="editdoc"
              onSubmit={(e) => {
                e.preventDefault();
                if (e.target.reportValidity()) {
                  onPristine();
                  props.editFileUploadRequestHandler();
                }
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="font-12 m-0">Save As <span>&#42;</span></h3>
                  <input
                    type={"text"}
                    id="saveAs"
                    required
                    defaultValue={props?.uploadInformation?.alias}
                    onChange={(e) => {
                      setIsUpdate(true);
                      props.handleDocAlias(e);
                      onDirty();
                    }}
                    className={`form-control font-13 m-0 border--default br-10 shadow-none input`}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <h3 className="font-12 m-0">Document Type</h3>
                  <select
                    className="form-select br-10 shadow-none font-13"
                    aria-label="File Type Select"
                    required
                    onChange={(e) => {
                      setIsUpdate(true);
                      fileTypeSelect(e);

                      onDirty();
                    }}
                    value={props?.fileType}
                  >
                    <option selected value="">Select File Type</option>
                    {props?.showUploadData?.map((item, index) => {
                      return (
                        <option
                          key={item?.id}
                          value={item?.id}
                          data-isReviewRequired={item.isReviewRequired}
                          // selected={
                          //   item?.id == props?.uploadInformation?.category?.id
                          // }
                        >
                          {item?.name ?? ""}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* {console.log("Upload Information: ", props?.uploadInformation)} */}
                <div className="col-6">
                  <h3 className="font-12 m-0">Service Date</h3>
                  {/* <div className="row">
                  <div className="col-12 p-0 m-0 px-1"> */}
                  <CalenderDatePicker
                    name="servicedate"
                    id={"servicedate"}
                    f13={true}
                    dobHandler={(e) => {
                      // setIsUpdate(true);
                      // onDirty();
                      props.handleServieDate(e);
                    }}
                    dateFormat="MMM d, yyyy"
                    maxDate={DateTime.now()}
                    date={
                      props?.uploadInformation?.serviceDate != "" &&
                      props?.uploadInformation?.serviceDate?.toLowerCase() != "invalid date" &&
                      props?.uploadInformation?.serviceDate != null &&
                      props?.uploadInformation?.serviceDate != undefined &&
                      calenderDateTimeHelper(props?.uploadInformation?.serviceDate, "YYYY-MM-DD")
                    }
                  />
                  {/* </div> */}
                  {/* <div className="col-3 p-0 px-2">
                    <button
                      type="button"
                      style={{ height: 34 }}
                      className={`btn px-2 br-10 border--default bg--disabled shadow-none`}
                    >
                      <CalendarMonthOutlinedIcon
                        style={{
                          marginBottom: 6,
                          color: "#336383",
                          fill: "#336383",
                          height: 18,
                          width: 18,
                        }}
                      />
                    </button>
                  </div> */}
                  {/* </div> */}
                </div>
                {
                  props.uploadInformation?.documentReview?.length > 0 ?
                    <div className="">
                      <div className="form-check form-check-inline mt-3">
                        <input
                          className="form-check-input cursor--pointer"
                          type="checkbox"
                          // name="orderType"
                          id={"consent"}
                          onChange={(e) => {
                            handleTaskAsignee(e);
                          }}
                          defaultChecked={props.uploadInformation?.documentReview?.length > 0 ? true : false}
                        />
                        <label
                          className="form-check-label font-12 align-self-center"
                          for={"consent"}
                        >
                          {_str.task_review_str}
                        </label>
                      </div>
                      <div className="mt-1" hidden={!isTaskChecked}>
                        <label className="text--terciary b2 fw-sb">
                          {_str.asignee_str} <span>&#42;</span>
                        </label>

                        <select
                          className={`form-select font-12 shadow-none ${classes["custom-select"]}`}
                          aria-label="Provider select"
                          style={{
                            border: "1px solid #D9E8EC",
                            // height: "48px",
                            borderRadius: "10px",
                            // background: "#FAFCFC",
                          }}
                          required
                          onChange={(e) => handleProviderId(e)}
                        >
                          <option value="" disabled selected hidden>
                            {"Select "} {_str.asignee_str.toLowerCase()}
                          </option>
                          {prescribersList !== undefined &&
                            prescribersList?.length > 0 &&
                            prescribersList?.map((prescriberItem) => {
                              return (
                                <option
                                  value={prescriberItem?.userId}
                                  key={prescriberItem?.userId}
                                  selected={
                                    props?.uploadInformation?.documentReview[0]?.assignedTo?.userId ==
                                    prescriberItem?.userId
                                  }
                                >
                                  {prescriberItem?.name[0]?.prefix}{" "}
                                  {prescriberItem?.name[0]?.firstName}{" "}
                                  {prescriberItem?.name[0]?.lastName}{" "}

                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    : null
                }


              </div>
            </form>

          </div>
        </Modal.Body>

        <Modal.Footer style={{ border: "none" }}>
          <button
            form="editdoc"
            className="btn text-white b2 px-4 br-10 py-1 blue_task_button"
            disabled={isUpdate === false ? true : props?.isUploading === true ? true : false}

          >
            {props?.isUploading === true ? "Updating..." : "Update"}
          </button>
        </Modal.Footer>
        {/* {console.log(props?.uploadInformation)} */}
        {/* {console.log("isUpdate: ",isUpdate)} */}
        {/* {console.log("props?.uploadInformation?.serviceDate: ", props?.uploadInformation?.serviceDate)} */}
      </Modal>
    </>
  );
};

export default FileShowEditModal;
