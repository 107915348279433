import { Box, Skeleton, Stack, Switch, Typography,Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import RolesTapMap from "../../utils/helpers/RolesTabMap";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { fieldNames } from "../../utils/helpers/ErrorFields";
import { useDispatch, useSelector } from "react-redux";
import MultipleSelectFacility from "../UI/Modals/PatientModals/MuiMuiltiSelectFacility";
import ErrorMessage from "../UI/Reusable/ErrorMessage";
import * as yup from "yup";
import { useAuth } from "../hooks/useAuth";
import { providerWithNpiUrl } from "../../res/endpointurls";
import PhoneNumberInput from "../UI/Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import _ from "lodash";
import { ValidationErrorMessage } from "../validations/ValidationErrorMessage";
import {
  ERROR_MESSAGES,
  FIELD_NAMES,
  MIN_MAX_VALUES,
} from "../validations/validationschemas";
import { createTheme, ListItemButton, ThemeProvider } from "@mui/material";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Search } from "react-bootstrap-icons";
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { "aria-label": "TwoFactorSwitch" } };


const TwoFactorSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#004FD4",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 11 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    borderRadius: 11 / 2,
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    color: "#fff",
  },
}));
const propertyMapping = {
  primaryContactFirstName: 'firstName',
  primaryContactLastName: 'lastName',
  primaryContactEmail: 'email',
  enrollmentContactFirstName: 'firstName',
  enrollmentContactLastName: 'lastName',
  enrollmentContactEmail: 'email'
};


const Demo = styled("div")(({ theme }) => ({
  backgroundColor: "#FAFCFC",
}));

const theme = createTheme({
  components: {
    MuiListItemButton: {
      root: {
        "&$selected": {
          color: "#5FBB7D",
          backgroundColor: "red",
        },
      },
    },
  },
});
const PayerListItem = (props) => {
  const { id, handlePayerClick, isSelected, payerName, /* other props */ } = props;

  return (
    <ListItemButton
      disableRipple
      id={id}
      className="py-1"
      // style={{
      //   backgroundColor: isSelected ? "white" : "",
      //   borderRight: isSelected ? "3px solid #EC609B" : "",
      //   borderTopRightRadius: isSelected ? "3px solid #EC609B" : "",
      //   boxShadow: isSelected ? "0px 6px 16px #0000000F" : "",
      //   borderBottom: "1px solid #d9e8ec",
      // }}
      onClick={() => handlePayerClick(id)}
    >
      {/* <Checkbox
        checked={isSelected}
        onChange={() => handlePayerClick(id)}
      /> */}
      <div>
        <ListItemText
          id={id}
          secondary={
            <Typography
              id={id}
              sx={{
                fontFamily: "Montserrat",
                color: "#323232",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {payerName}
            </Typography>
          }
        />
      </div>
    </ListItemButton>
  );
};
export function PayerList({selectedPayers,setSelectedPayers,tenantPage,isPayerSelected,setIsPayerSelected,disabled}){
  const [payersList,setPayersList] = useState([]);
  const [isPayerListLoading,setIsPayerListLoading] = useState(false);
  const [filteredPayersList,setFilteredPayersList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  

  const fetchPayerList = async()=>{
    setIsPayerListLoading(true);
    const httpResponse = await sendHttpRequest({
      url:`/claimsManagement/payers/enrollment-required`,
      // url:`/claimsManagement/payers/apexEdi/load`,
      method:"GET"
    })
    if(!httpResponse.error){
      setPayersList(httpResponse.data)
    }else{
      setPayersList([])
    }
    setIsPayerListLoading(false);
  }

  const [filteredPayers,setFilteredPayers] = useState([])
  const handleSearchTerm = (event) => {
    // setSelectedIndex(-1);
    setSearchTerm(event.target.value);
    let searchItemObject = event.target.value;
    /* Filtering the payers array based on the search term. */
    if(searchItemObject !== ""){
      setFilteredPayers(
        payersList?.filter(function (payer) {
          // console.log("payer: ",payer);
          let payerFullName = payer?.name?.toLowerCase();
          // console.log("payerFullName: ",payerFullName,searchTerm);
          let filteredResults = payerFullName?.includes(searchItemObject?.toLowerCase());
          // console.log("filteredResults: ",filteredResults,searchItemObject);
          return filteredResults;
        })
      )
    }else{setFilteredPayers([])}
  };

  const handlePayerClick = (id) => {
    const selectedIndex = selectedPayers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPayers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPayers.slice(1));
    } else if (selectedIndex === selectedPayers.length - 1) {
      newSelected = newSelected.concat(selectedPayers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPayers.slice(0, selectedIndex),
        selectedPayers.slice(selectedIndex + 1)
      );
    }

    setSelectedPayers(newSelected);
  };
  const handleIsPayerSelected = (event)=>{
    let isChecked = event.target.checked;
    if(isChecked){
      setIsPayerSelected(p=>true);
    }else{
      setIsPayerSelected(p=>false)
      setSelectedPayers([])
    }
  }

  useEffect(()=>{
    fetchPayerList()
  },[])

  return(
    <>  
      <div className="row">
        <div className={`col-12`}>
          {
            isPayerListLoading ? <Skeleton/> : 

            <ThemeProvider theme={theme}>
              <Box
                className="noscrollbar"
                sx={{
                  flexGrow: 1,
                  maxWidth: 752,
                  maxHeight: "99vh",
                  overflowY: "scroll",
                  zIndex: "10",
                  // borderTop: "1px solid #d9e8ec"
                }}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  className="sticky-top"
                  // style={{ zIndex: "1000", borderBottom: "1px solid #d9e8ec" }}
                >
                  <Grid item xs={6} md={6} className="align-self-center">
                    <div className="">
                    {/* disabled={filteredPayers?.length === 0} */}
                      <input disabled={disabled} defaultChecked={isPayerSelected} type="checkbox"  onChange={(e)=>{handleIsPayerSelected(e)}} className="me-2 form-check-input  cursor--pointer align-self-center shadow-none " id= "payerCheck" />
                      <label for={"payerCheck"} className="font-12 text--secondary fw-sb">{"Do you work with any of these payers?"}</label>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {/* <input type="text" placeholder="Search patient" className="font-14 form-control shadow-none br-10 border--default w-100 input my-2"/> */}
                    <div className="input-group my-2" style={{ zIndex: "10" }}>
                      <input
                        onChange={handleSearchTerm}
                        type="text"
                        style={{
                          zIndex: "10",
                          borderLeft: "1px solid #d9e8ec",
                          borderTop: "1px solid #d9e8ec",
                          borderBottom: "1px solid #d9e8ec",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          borderRight: "none",
                        }}
                        className="font-14 form-control shadow-none br-10 border--default  input "
                        id="autoSizingInputGroup"
                        placeholder="Search for payer"
                        disabled={disabled}
                      />
                      <div
                        className="input-group-text bg--background"
                        style={{
                          zIndex: "10",
                          borderRight: "1px solid #d9e8ec",
                          borderTopRightRadius: "10px",
                          borderTop: "1px solid #d9e8ec",
                          borderBottom: "1px solid #d9e8ec",
                          borderBottomRightRadius: "10px",
                          borderLeft: "none",
                          cursor: "pointer",
                        }}
                      >
                        <Search color="#336383" />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="end"
                  sx={{ zIndex: "10" }}
                >
                   <Grid item xs={6} md={6} sx={{ mx: 0, zIndex: "10" }}>
                    <List sx={{ zIndex: "10" }}>
                      {/* ... (rest of your code) */}
                      {filteredPayers?.map((payer, index) => {
                        const isSelected = selectedPayers.indexOf(index) !== -1;

                        return (
                          <Demo key={index}>
                            <PayerListItem
                              id={index}
                              isSelected={isSelected}
                              handlePayerClick={handlePayerClick}
                              payerName={payer?.name}
                              /* other props */
                            />
                          </Demo>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </ThemeProvider>
            
          }
        </div>
      </div>
    </>
  )

}

// function ClaimsSettings({ tenantSettingsData, setTenantSettingsData, setValidationErrors,
//   validationErrors,selectedPayers,setSelectedPayers,user,setUser,checkUpdateOnEditMode,editMode}) {
//   const field_Names = {
//     ...FIELD_NAMES.user.name,
//     ...FIELD_NAMES.tenantSettings,
//   };
//   const TENANT_VALIDATION = {
//     ...MIN_MAX_VALUES.name,
//     ...MIN_MAX_VALUES.tenantSettings,
//   };
//   const validationErrorMsgs = {
//     ...ERROR_MESSAGES.user.name,
//     ...ERROR_MESSAGES.tenantSettings,
//   };
//   // const [isClaimsManagementEnabledProviderWide, setIsClaimsManagementEnabledProviderWide] = useState(tenantSettingsData?.claimProcessingLevel !== null ? tenantSettingsData?.claimProcessingLevel === 't' ? true : false : false);
//   const [isClaimsManagementEnabledProviderWide, setIsClaimsManagementEnabledProviderWide] = useState(user?.dbaName?.length > 0 ? true:false);

//   function handleClaimsManagmentEnabled(e) {
//     let isChecked = e.target.checked
//     if(isChecked) {
//       setIsClaimsManagementEnabledProviderWide(true)
//     }else{
//       setIsClaimsManagementEnabledProviderWide(false)
//       setUser({...user,providerContact:[]})  
//     }
//   };

//   const primaryContact = user?.providerContact?.filter(contact => contact?.type?.internalValue === 'primary')?.at(0) ?? {};
//   const enrollmentContact = user?.providerContact?.filter(contact => contact?.type?.internalValue === 'enrollment')?.at(0) ?? {};


//   const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState();
//   const [enrollmentPhoneNumber, setEnrollmentPhoneNumber] = useState();
//   const [phoneErrors, setPhoneErrors] = useState({
//     primary: false,
//     enrollment: false,
//   });
//   const [isEnrollmentChecked,setIsEnrollmentChecked] = useState(enrollmentContact?.firstName !== undefined ? true : false);

//   const enrollmentContactPhoneHandle = (phoneValue) => {
//     setEnrollmentPhoneNumber(phoneValue);
  
//     if (
//       phoneValue !== undefined &&
//       phoneValue.length === 12 &&
//       isValidPhoneNumber(phoneValue.toString())
//     ) {
//       // Assuming user and tenantSettingsData are already declared
//       let updatedProviderContact = [...user?.providerContact];
  
//       // Assuming there's only one contact for simplicity, modify as needed
//       if (updatedProviderContact.length > 0) {
//         updatedProviderContact[0] = {
//           ...updatedProviderContact[0],
//           phoneNumber: formatPhoneNumber(phoneValue.replace("+1", "")),
//         };
  
//         setUser({
//           ...user,
//           providerContact: updatedProviderContact,
//         });
  
//         setPhoneErrors((previousSnapshot) => ({
//           ...previousSnapshot,
//           enrollment: false,
//         }));
//       } else {
//         // Case when providerContact list is empty
//         setPhoneErrors((previousSnapshot) => ({
//           ...previousSnapshot,
//           enrollment: false,
//         }));
//       }
//     } else if (
//       phoneValue !== undefined &&
//       !isValidPhoneNumber(phoneValue.toString()) &&
//       phoneValue.length > 2
//     ) {
//       setPhoneErrors((previousSnapshot) => ({
//         ...previousSnapshot,
//         enrollment: true,
//       }));
//     } else if (phoneValue === undefined || phoneValue.length === 2) {
//       setPhoneErrors((previousSnapshot) => ({
//         ...previousSnapshot,
//         enrollment: false,
//       }));
//     }
//   };
  

//   const handleInputChange = (e) => {
//     checkUpdateOnEditMode();
//     const name = e.target.name;
//     const value = e.target.value;
//     let updatedProviderContact = user?.providerContact;
//     if (['dbaName', 'previousClearingHouse'].includes(name)) {
//       setUser({ ...user, [name]: value });
//     }
//     if('enrollmentContactFirstName' === name){
//       updatedProviderContact[0]["firstName"] = value;
//       setUser({...user,providerContact:updatedProviderContact})
//     }else if('enrollmentContactLastName' === name){
//       updatedProviderContact[0]["lastName"] = value;
//       setUser({...user,providerContact:updatedProviderContact})
      
//     }else if('enrollmentContactEmail' === name){
//       updatedProviderContact[0]["email"] = value;
//       setUser({...user,providerContact:updatedProviderContact})
//     }

//   }
//   const handleEnrollmentCheckbox = (isChecked)=>{
//     if(isChecked){
//       setIsEnrollmentChecked(true)
//     }else{
//       setIsEnrollmentChecked(false)
//     }
//   }


//   useEffect(()=>{
//     if(editMode === undefined && selectedPayers?.length === 0){
//       setIsEnrollmentChecked(p=>false)
//     }
//     // else if(editMode !== undefined && enrollmentContact?.firstName !== undefined){
//     //   setIsEnrollmentChecked(p=>true)
//     // }
//   },[selectedPayers,editMode])

//   // useEffect(()=>{
//   //   if(editMode !== undefined && enrollmentContact?.firstName !== undefined){
//   //     setIsEnrollmentChecked(p=>true)
//   //   }
//   // },[editMode])

//   return (<>
//     <div className="form-check my-2 form-check-inline">
//       {console.log("user?.dbaName?.length > 0",user)}
//       {/* <input checked={tenantSettingsData?.claimProcessingLevel !== null ? tenantSettingsData?.claimProcessingLevel === 't' ? true : false : false} onChange={handleClaimsManagmentEnabled} className="form-check-input  cursor--pointer align-self-center shadow-none mb-1" type="checkbox" id="claimProcessingLevel" /> */}
//       <input defaultChecked={user?.dbaName?.length > 0 ? true:false} onChange={handleClaimsManagmentEnabled} className="form-check-input  cursor--pointer align-self-center shadow-none mb-1" type="checkbox" id="claimProcessingLevel" />
//       <label className="form-check-label form-label font-12 text--secondary m-0 fw-sb" htmlFor="claimProcessingLevel">Do you wish to enable claims management settings?</label>
//     </div>
//     <div className="row mt-2">
//       {
//         isClaimsManagementEnabledProviderWide ? 
//         <PayerList selectedPayers={selectedPayers} setSelectedPayers={setSelectedPayers}/>
//       :null
//       }
      
//      {
//       (selectedPayers?.length > 0 || user?.dbaName?.length > 0) &&
//       <>
//         <div className="col">
//           <div className="mb-3">
//             <label htmlFor="dbaName" className="form-label font-12 text--secondary m-0 fw-sb">DBA Name *</label>
//             <input
//               type="text"
//               className="form-control input font-12 shadow-none br-10"
//               id="dbaName"
//               name="dbaName"
//               value={user?.dbaName ?? ""}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//         </div>
//         <div className="col">
//           <div className="mb-3">
//             <label htmlFor="previousClearingHouse" className="form-label font-12 text--secondary m-0 fw-sb">Previous Clearing House *</label>
//             <input
//               type="text"
//               className="form-control font-12 input shadow-none br-10"
//               id="previousClearingHouse"
//               name="previousClearingHouse"
//               value={user?.previousClearingHouse ?? ""}
//               onChange={handleInputChange}
//               required
//             />
//           </div>
//         </div>
//         <div className="col-12" >
//         <div className="form-check my-2 form-check-inline">
//           <input defaultChecked={isEnrollmentChecked}  onChange={(e)=>{handleEnrollmentCheckbox(e.target.checked)}} className="form-check-input  cursor--pointer align-self-center shadow-none mb-1" type="checkbox" id="useEnrollmentContact" />
//           <label className="form-check-label form-label font-12 text--secondary m-0 fw-sb" htmlFor="useEnrollmentContact">Use Enrollment Contact Instead of Primary</label>
//         </div>
//       </div>
//       {
//         isEnrollmentChecked === true? 
//           <>
//             <div className="col-12">
//               <h2 className="font-16 fw-sb">Enrollment Contact</h2>
//             </div>

//             <div className="col">
//               <div className="mb-3">
//                 <label htmlFor="enrollmentContactFirstName" className="form-label font-12 text--secondary m-0 fw-sb">First Name *</label>
//                 <input
//                   type="text"
//                   className="form-control font-12 input br-10 shadow-none"
//                   id="enrollmentContactFirstName"
//                   name="enrollmentContactFirstName"
//                   value={enrollmentContact?.firstName}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//             </div>

//             <div className="col">
//               <div className="mb-3">
//                 <label htmlFor="enrollmentContactLastName" className="form-label font-12 text--secondary m-0 fw-sb">Last Name *</label>
//                 <input
//                   type="text"
//                   className="form-control font-12 input br-10 shadow-none"
//                   id="enrollmentContactLastName"
//                   name="enrollmentContactLastName"
//                   value={enrollmentContact?.lastName}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//             </div>
//             <div className="col">
//               <div className="mb-3">
//                 <label htmlFor="enrollmentContactEmail" className="form-label font-12 text--secondary m-0 fw-sb">Email *</label>
//                 <input
//                   type="email"
//                   className="form-control font-12 input br-10 shadow-none"
//                   id="enrollmentContactEmail"
//                   name="enrollmentContactEmail"
//                   value={enrollmentContact?.email}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//             </div>
//             <div className="col">
//               <div className="mb-3">
//                 <label htmlFor="enrollmentContactPhoneNumber" className="form-label font-12 text--secondary m-0 fw-sb">Phone Number *</label>
//                 <PhoneNumberInput
//                   onChange={enrollmentContactPhoneHandle}
//                   id="enrollmentContactPhoneNumber"
//                   key={"enrollmentContactPhoneNumber"}
//                   // reference={phoneRef}
//                   className="font-12 input"
//                   value={enrollmentPhoneNumber || enrollmentContact?.phoneNumber || ""}
//                   required={true}
//                 />
//                 <span className="text--danger  font-10 ms-2 mt-1">
//                   {phoneErrors?.enrollment === true
//                     ? "Incorrect number format"
//                     : " "}
//                 </span>
//               </div>
//             </div>
//           </>:null
//       }
//       </>
//      }
//     </div>
//     <div className="row mt-2" hidden={(selectedPayers?.length === 0 || user?.dbaName === undefined) ? true:false}>
      
//     </div>
//   </>
//   )
// }
const AddUserForm = ({
  setUserRole,
  setUser,
  user,
  serverErrors,
  removeServerError,
  setCellPhoneValue,
  setNames,
  hydrateEmail,
  hydrateSupervisor,
  setNpiFromProvider,
  setTinFromProvider,
  setSsnFromProvider,
  editMode,
  isSending,
  setIsSending,
  submitHandler,
  editHandler,
  facilitiesCheckedList,
  facilitiesIdsCheckedList,
  setHideStatus,
  validationErrors,
  setSpecialtyValue,
  setProviderContact,
  setIsValidUserData,
}) => {
  const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);
  const field_Name = FIELD_NAMES.user.name;
  const NAME_VALIDATION = MIN_MAX_VALUES.name;
  const validationErrorMsgs = ERROR_MESSAGES.user.name;
  const [nameValidationErrors, setNameValidationErrors] = useState({});

  const [firstName, setFirstName] = useState(setNames("firstName"));
  const [facilities, setFacilities] = useState([]);
  const [lastName, setLastName] = useState(setNames("lastName"));
  const [suffixName, setSuffixName] = useState(setNames("suffix"));
  const [middleName, setMiddleName] = useState(setNames("middleName"));
  const [role, setRole] = useState(user?.role);
  const [roles, setRoles] = useState([]);
  const [npi, setNpi] = useState(setNpiFromProvider());
  const [ssn,setSNN] = useState(setSsnFromProvider());
  const [tin,setTIN] = useState(setTinFromProvider());
  const [npiRequiredFlag, setNpiRequiredFlag] = useState(false);
  const [landline, setLandline] = useState(setCellPhoneValue("HT"));
  const [cellPhone, setCellPhone] = useState(setCellPhoneValue("PT"));
  const [speciality,setSpecialty] = useState(setSpecialtyValue() ?? user?.speciality ?? []);
  const [prescribersList, setPrescribersList] = useState([]);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [facilitiesIdList, setFacilitiesIdList] = useState([]);
  const [facilityNamesList, setFacilityNamesList] = useState([]);
  const [email, setEmail] = useState(
    hydrateEmail()?.length > 0 ? hydrateEmail()[0] : []
  );
  const [userActiveStatus, setUserActiveStatus] = useState(user?.status);
  const [supervisor, setSupervisor] = useState(hydrateSupervisor() || "");
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [phoneErrors, setPhoneErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
  });
  const [isSent, setIsSent] = useState(false);


  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const suffixNameRef = useRef();
  const dobRef = useRef();
  const roleRef = useRef();
  const npiRef = useRef();
  const landlineRef = useRef();
  const cellphoneRef = useRef();
  const emailRef = useRef();
  const supervisorRef = useRef();
  const formRef = useRef();
  const ssnRef = useRef();
  const tinRef =  useRef();

  const dispatch = useDispatch();
  const { getUser } = useAuth();
  const [specialties,setSpecialties] = useState([]);
  const [isSpecialtyLoading,setIsSpecialtyLoading] = useState(false);
  
  const [isTimezoneLoading, setIsTimezoneLoading] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  
  const [claimValidationErrors, setClaimValidationErrors] = useState({});
  const [selectedPayers,setSelectedPayers] = useState([]);

  if (editMode) {
    setUserRole(user?.role);
  }

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const populateFaciliyNameList = (facilities) => {
    if (facilities.length > 0) {
      facilities.forEach((facility) => {
        facilityNamesList.push(facility?.businessName);
      });
    }
  };

  const checkUpdateOnEditMode = () => {
    if (editMode !== undefined) {
      setUpdateDisabled(true);
    }
  };

  const firstNameHandler = (event) => {
    let firstName = event.target.value;
    checkUpdateOnEditMode();
    setFirstName(firstName);

    if (firstName !== "") {
      removeServerError(serverErrors, fieldNames.firstName);
    }
    if (firstName?.length > NAME_VALIDATION.max) {
      setNameValidationErrors({
        ...nameValidationErrors,
        [field_Name.firstNameMax]: validationErrorMsgs.firstNameMax,
      });
    } else {
      let temp = nameValidationErrors;
      delete nameValidationErrors.firstNameMax;
      setNameValidationErrors(temp);
    }

    let fName = firstName;
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      // if (!tempUser.name[0].firstName) {
      //   tempUser.name[0].firstName = fName;
      // }
      tempUser.name = [];
      tempUser.name.push({
        firstName: fName,
        lastName: lastName,
        middleName: middleName,
        suffix:suffixName,
        type: "cn",
      });
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const middleNameHandler = (event) => {
    checkUpdateOnEditMode();
    setMiddleName(event.target.value);

    let mName = event.target.value;
    if (mName?.length > NAME_VALIDATION.max) {
      setNameValidationErrors({
        ...nameValidationErrors,
        [field_Name.middleNameMax]: validationErrorMsgs.middleNameMax,
      });
    } else {
      let temp = nameValidationErrors;
      delete nameValidationErrors.middleNameMax;
      setNameValidationErrors(temp);
    }
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.name = [];
      tempUser.name.push({
        firstName: firstName,
        lastName: lastName,
        suffix:suffixName,
        middleName: mName,
        type: "cn",
      });
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const lastNameHandler = (event) => {
    let lastName = event.target.value;
    checkUpdateOnEditMode();
    setLastName(lastName);

    if (event.target.value !== "") {
      removeServerError(serverErrors, fieldNames.lastName);
    }
    if (lastName?.length > NAME_VALIDATION.max) {
      setNameValidationErrors({
        ...nameValidationErrors,
        [field_Name.lastNameMax]: validationErrorMsgs.lastNameMax,
      });
    } else {
      let temp = nameValidationErrors;
      delete nameValidationErrors.lastNameMax;
      setNameValidationErrors(temp);
    }

    let lName = lastName;
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      // if (!tempUser.name) {
      //   tempUser.name[0].lastName = event.target.value;
      // }
      tempUser.name = [];
      tempUser.name.push({
        firstName: firstName,
        lastName: lName,
        middleName: middleName,
        suffix:suffixName,
        type: "cn",
      });
      // tempUser.name[0].lastName = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };
  const suffixNameHandler = (event) => {
    let suffixName = event.target.value;
    checkUpdateOnEditMode();
    setSuffixName(suffixName);

    if (event.target.value !== "") {
      removeServerError(serverErrors, fieldNames.suffixName);
    }
    if (suffixName?.length > NAME_VALIDATION.suffix.max) {
      setNameValidationErrors({
        ...nameValidationErrors,
        [field_Name.suffixNameMax]: validationErrorMsgs.suffixNameMax,
      });
    } else {
      let temp = nameValidationErrors;
      delete nameValidationErrors.suffixNameMax;
      setNameValidationErrors(temp);
    }
    let sName = suffixName;
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      // if (!tempUser.name) {
      //   tempUser.name[0].lastName = event.target.value;
      // }
      tempUser.name = [];
      tempUser.name.push({
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        suffix: sName,
        type: "cn",
      });
      // tempUser.name[0].lastName = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const roleHandler = (event) => {
    checkUpdateOnEditMode();
    setNpiRequiredFlag(false);
    setRole(event.target.value);
    setUserRole(event.target.value);
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.role = event.target.value;
      if (RolesTapMap.get(RolesTextConversion(user?.role))?.length == 1) {
        setHideStatus(true);
      } else {
        setHideStatus(false);
      }
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
    checkNPIRequiredFlag(event.target.value);
  };

  const supervisorHandler = (event) => {
    checkUpdateOnEditMode();
    if (event.target.value !== "") {
      let sh = prescribersList.filter(
        (val) => val?.userId == event.target.value
      );
      setSupervisor(sh);
      if (sh.length > 0) {
        setUser((previousSnapshot) => {
          const tempUser = { ...previousSnapshot };
          tempUser.supervisor = sh[0];
          dispatch(setDirtyBitInStore(true));
          return { ...previousSnapshot, ...tempUser };
        });
      }
    } else {
      setSupervisor("");
      setUser((previousSnapshot) => {
        const tempUser = { ...previousSnapshot };
        tempUser.supervisor = null;
        dispatch(setDirtyBitInStore(false));
        return { ...previousSnapshot, ...tempUser };
      });
    }
  };

  const landlineHanlder = (phoneValue) => {
    checkUpdateOnEditMode();
    let landlineNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
    setLandline(landlineNumber);
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      if (
        landlineNumber !== null &&
        landlineNumber !== undefined &&
        landlineNumber?.length === 14
      ) {
        numberHandler(tempUser, landlineNumber, "HT");
      }

      if (
        phoneValue !== undefined &&
        isValidPhoneNumber(phoneValue?.toString()) &&
        phoneValue?.length === 12
      ) {
        dispatch(setDirtyBitInStore(true));
        setPhoneErrors((previousSnapshot) => ({
          ...previousSnapshot,
          landline: false,
        }));
      } else if (
        phoneValue !== undefined &&
        !isValidPhoneNumber(phoneValue?.toString()) &&
        phoneValue?.length > 2
      ) {
        dispatch(setDirtyBitInStore(true));
        setPhoneErrors((previousSnapshot) => ({
          ...previousSnapshot,
          landline: true,
        }));
      } else if (phoneValue === undefined || phoneValue?.length === 2) {
        setPhoneErrors((previousSnapshot) => ({
          ...previousSnapshot,
          landline: false,
        }));
      }

      return { ...previousSnapshot, ...tempUser };
    });
  };

  const cellPhoneHandler = (phoneValue) => {
    checkUpdateOnEditMode();
    let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
    setCellPhone(mobileNumber);

    if (mobileNumber !== "" && mobileNumber !== "(___) ___-____") {
      removeServerError(serverErrors, fieldNames.mobilePhone);
    }

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };

      if (
        mobileNumber !== null &&
        mobileNumber !== undefined &&
        mobileNumber?.length === 14
      ) {
        numberHandler(tempUser, mobileNumber, "PT");
      }

      // if(!isPalindrome(phoneValue)){
      if (
        phoneValue !== undefined &&
        isValidPhoneNumber(phoneValue?.toString()) &&
        phoneValue?.length === 12
      ) {
        dispatch(setDirtyBitInStore(true));
        setPhoneErrors((previousSnapshot) => ({
          ...previousSnapshot,
          primary: false,
        }));
      } else if (
        phoneValue !== undefined &&
        !isValidPhoneNumber(phoneValue?.toString()) &&
        phoneValue?.length > 2
      ) {
        dispatch(setDirtyBitInStore(true));
        setPhoneErrors((previousSnapshot) => ({
          ...previousSnapshot,
          primary: true,
        }));
      } else if (phoneValue === undefined || phoneValue?.length === 2) {
        setPhoneErrors((previousSnapshot) => ({
          ...previousSnapshot,
          primary: false,
        }));
      }

      return { ...previousSnapshot, ...tempUser };
    });
  };

  const numberHandler = (tempUser, value, type) => {
    let numbers = tempUser?.communicationNumbers?.numbers;

    if (numbers?.length === 0) {
      numbers?.push({
        number: value,
        type: type,
      });
    } else if (numbers?.length >= 1) {
      if (numbers[0]?.number === "") {
        numbers.splice(0, 1);
      }
      if (numbers[0]?.type === type) {
        numbers.splice(0, 1);
        numbers?.push({
          number: value,
          type: type,
        });
      } else {
        if (value === "(___) ___-____" || value === "") {
          numbers.splice(1, 1);
        } else if (value !== "(___) ___-____") {
          numbers.splice(1, 1);
          numbers?.push({
            number: value,
            type: type,
          });
        }
      }
    }

    tempUser.communicationNumbers.numbers = numbers;
  };

  const emailHandler = (event) => {
    checkUpdateOnEditMode();
    setEmail(event.target.value);

    if (event.target.value !== "") {
      removeServerError(serverErrors, fieldNames.email);
    }

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      if (!tempUser.communicationNumbers) {
        tempUser.communicationNumbers = {};
      }
      tempUser.communicationNumbers.electronicMail = [];
      tempUser.communicationNumbers.electronicMail.push(event.target.value);
      tempUser.username = event.target.value;
      tempUser.password = "abc123";
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const facilityHandler = (facilityList) => {
    checkUpdateOnEditMode();
    setFacilitiesIdList(facilityList);

    if (facilityList.length > 0) {
      removeServerError(serverErrors, fieldNames.facility);
    }

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.facility = facilityList;
      tempUser.providerTenantPracticeLocationList = facilityList;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };
  
  const npiHandler = (event) => {
    checkUpdateOnEditMode();
    setNpi(event.target.value);

    if (event.target.value !== "") {
      removeServerError(serverErrors, fieldNames.npi);
    }

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.identification.npi = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });

  };
  const ssnHandler = (event) => {
    checkUpdateOnEditMode();
    setSNN(event.target.value);

    if (event.target.value !== "") {
      removeServerError(serverErrors, fieldNames.ssn);
    }

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.identification.socialSecurity = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });

  };
  const tinHandler = (event) => {
    checkUpdateOnEditMode();
    setTIN(event.target.value);

    if (event.target.value !== "") {
      removeServerError(serverErrors, fieldNames.tin);
    }

    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.identification.taxIdNumber = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });

  };

  const handleProviderSpecialty = (event)=>{
    // console.log("speciality: ",event.target.value);
    // console.log("speciality id: ",);

    checkUpdateOnEditMode();
    let specialityObject = {speciality:event.target.value, specialityId:parseInt(event.target[event.target.selectedIndex].getAttribute("data")) };
    setSpecialty(specialityObject)
    // console.log("specialityObject: ",specialityObject);
    // console.log("tempUser: ",user);
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.speciality = [specialityObject]
      // tempUser.speciality.specialityId = specialityObject?.specialityId;
      // tempUser.speciality.speciality = specialityObject?.speciality;
      
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  }
  const twoFactorSwitch = (event) => {
    checkUpdateOnEditMode();
    setUserActiveStatus(event.target.checked);
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.status = event.target.checked;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const fetchRoles = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/roles",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setRoles(httpResponse.data);
    } else {
      // console.log(httpResponse.error);
    }
  };

  const fetchPhysicians = async () => {
    checkUpdateOnEditMode();
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(getUser()?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      // console.log(httpResponse.error);
    }
  };

  const fetchAllFacilities = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/facilities/tenant/${getUser()?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("facilities-> ", httpResponse.data);
      populateFaciliyNameList(httpResponse.data);
      setFacilitiesList(httpResponse.data);
    } else {
      setFacilitiesList([]);
      // console.log(httpResponse.error);
    }
  };

  const fetchSpecialties = async ()=>{
    setIsSpecialtyLoading(true);
    const httpResponse = await sendHttpRequest({
      url:'/specialities',
      method:"GET"
    })
    if(!httpResponse.error){
      setSpecialties(httpResponse.data);
    }
    setIsSpecialtyLoading(true);
  }

  const fetchTimeZone = async () => {
    setIsTimezoneLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimeZoneList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsTimezoneLoading(false);
  };

  const clearState = () => {
    setFirstName("");
    setLastName("");
    setRole("");
    setRoles("");
    setEmail("");
    setCellPhone("");
    setLandline("");
  };

  const clearInputs = () => {
    firstNameRef.current.value = "";
    lastNameRef.current.value = "";
    npiRef.current.value = "";
    emailRef.current.value = "";
    cellphoneRef.current.value = "";
    landlineRef.current.value = "";
    supervisorRef.current.value = "";
    roleRef.current.value = "";
  };

  const handleUserAddSnacbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSending(false);
  };

  const errorHandling = (attr) => {
    let error = [];
    serverErrors.forEach((element) => {
      if (element.name === attr) {
        error.push(element.value);
      }
    });

    return error;
  };
  const handleTimeZone = (event)=>{
    checkUpdateOnEditMode();
    let timezoneOffset = event.target[event.target.selectedIndex].getAttribute("data-offset");
    let timezone = event.target.value;
    setUser((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.timezone =   timezone;
      tempUser.timezoneOffset = timezoneOffset
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    })
  }

  const checkNPIRequiredFlag = (role) => {
    if (["Physician", "NursePractitioner", "PhysicianAssistant"].includes(role)) {

      if (user?.tenant?.skipIdentityVerification) {
        setNpiRequiredFlag(true);
        // setNpiRequiredFlag(checkNpiAvailable());
      } else {
        setNpiRequiredFlag(false);
      }
    }
    // else if (["RegisteredNurse"].includes(role)) {
    //   setNpiRequiredFlag(checkNpiAvailable());
    // }
  }

  // const checkNpiAvailable = () => {
  //   if (user?.npi?.available) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  function shouldEnableSubmitButton(editMode, updateDisabled, nameValidationErrors, phoneErrors) {
    if (editMode) {
      return (
        (!updateDisabled || Object?.keys(nameValidationErrors)?.length !== 0) ||
        phoneErrors?.emergency === true ||
        phoneErrors?.landline === true ||
        phoneErrors?.primary === true
      );
    } else {
      return (
        phoneErrors?.emergency === true ||
        phoneErrors?.landline === true ||
        phoneErrors?.primary === true ||
        Object?.keys(nameValidationErrors)?.length !== 0 ||
        user?.name[0]?.firstName?.length === 0 ||
        user?.name[0]?.lasttName?.length === 0 ||
        user?.communicationNumbers.electronicMail[0] === "" ||
        user?.communicationNumbers?.numbers[0]?.number === undefined ||
        user.role === "" ||
        (checkNPIRequiredFlag(user.role) && user.identification.socialSecurity?.length === 0) ||
        user?.facility?.length === 0
      );
    }
  }
  
  useEffect(() => {
    firstNameRef.current.focus();
    fetchRoles();
    fetchPhysicians();
    fetchAllFacilities();
    setUpdateDisabled(false);
    fetchSpecialties();
    if(timeZoneList?.length === 0){
      
      fetchTimeZone();
    }
    // createProviderObject();
    return () => {
      // clearState(); // This worked for me
      if(shouldEnableSubmitButton(editMode,updateDisabled,nameValidationErrors,phoneErrors)){
        setIsValidUserData(false)
      }else{
        setIsValidUserData(true)
      }
    };
  }, []);

  return (
    <>
      <form
        ref={formRef}
        id="userAddForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="row">
          <div className="col-sm-3">
            <div>
              <label
                htmlFor="FirstName"
                className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
              >
                First Name <span>&#42;</span>
              </label>
              <input
                type="text"
                onChange={firstNameHandler}
                // onKeyDown={handleKeyDown}
                ref={firstNameRef}
                defaultValue={firstName}
                className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                id="FirstName"
                required
                // minLength={nameConstraints.min}
                // maxLength={nameConstraints.max}
              />
              <ErrorMessage
                serverErrorsArray={serverErrors}
                fieldName={fieldNames.firstName}
              />
              <ValidationErrorMessage
                errors={nameValidationErrors}
                fieldName={field_Name.firstNameMax}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div>
              <label
                htmlFor="MiddleName"
                className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
              >
                Middle Name
              </label>
              <input
                type="text"
                onChange={middleNameHandler}
                // onKeyDown={handleKeyDown}
                defaultValue={middleName}
                className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                id="MiddleName"
                // minLength={nameConstraints.min}
                // maxLength={nameConstraints.max}
                // required
              />
              <ValidationErrorMessage
                errors={nameValidationErrors}
                fieldName={field_Name.middleNameMax}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div>
              <label
                htmlFor="LastName"
                className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
              >
                Last Name <span>&#42;</span>
              </label>
              <input
                type="text"
                onChange={lastNameHandler}
                // onKeyDown={handleKeyDown}
                ref={lastNameRef}
                defaultValue={lastName}
                autoComplete={false}
                className="form-control font-14 border--default br-10 shadow-none input"
                id="LastName"
                required
                // minLength={nameConstraints.min}
                // maxLength={nameConstraints.max}
              />
              <ErrorMessage
                serverErrorsArray={serverErrors}
                fieldName={fieldNames.lastName}
              />
              <ValidationErrorMessage
                errors={nameValidationErrors}
                fieldName={field_Name.lastNameMax}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div>
              <label
                htmlFor="suffix"
                className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
              >
                Suffix <span>&#42;</span>
              </label>
              <input
                type="text"
                onChange={suffixNameHandler}
                // onKeyDown={handleKeyDown}
                ref={suffixNameRef}
                defaultValue={suffixName}
                autoComplete={false}
                className="form-control font-14 border--default br-10 shadow-none input"
                id="suffix"
                required
                // minLength={nameConstraints.min}
                // maxLength={nameConstraints.max}
              />
              <ErrorMessage
                serverErrorsArray={serverErrors}
                fieldName={fieldNames.suffixName}
              />
              <ValidationErrorMessage
                errors={nameValidationErrors}
                fieldName={field_Name.suffixNameMax}
              />
            </div>
          </div>

          <div className="col-sm-4">
            <div className="pt-2">
              <label
                htmlFor="Email"
                className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
              >
                Email <span>&#42;</span>
              </label>
              <input
                type="email"
                onChange={emailHandler}
                ref={emailRef}
                defaultValue={email}
                disabled={editMode}
                autoComplete={false}
                className={
                  editMode
                    ? "form-control bg--disabled font-14 border--default br-10 shadow-none input"
                    : "form-control font-14 border--default br-10 shadow-none input"
                }
                id="Email"
                required
              />
              <ErrorMessage
                serverErrorsArray={serverErrors}
                fieldName={fieldNames.email}
              />
              <ErrorMessage
                serverErrorsArray={serverErrors}
                fieldName={fieldNames.emailAlreadyExist}
              />
            </div>
          </div>

          <div className="col-sm-4">
            <div className="pt-2">
              <label
                htmlFor="CellPhone"
                className="form-label m-0 ms-1 fw-sb font-12 text--secondary"
              >
                Mobile Phone <span>&#42;</span>
              </label>
              <div>
                <PhoneNumberInput
                  onChange={cellPhoneHandler}
                  id="CellPhone"
                  reference={cellphoneRef}
                  required={true}
                  value={cellPhone || ""}
                />
              </div>
              <span className="text--danger py-1 font-10 d-block">
                {phoneErrors?.primary === true ? (
                  "Incorrect number format"
                ) : (
                  <ErrorMessage
                    serverErrorsArray={serverErrors}
                    fieldName={fieldNames.mobilePhone}
                  />
                )}
              </span>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="pt-2">
              <label
                htmlFor="Landline"
                className="form-label m-0 ms-2 fw-sb font-12 text--secondary"
              >
                Home Phone
              </label>
              <PhoneNumberInput
                onChange={landlineHanlder}
                id="Landline"
                reference={landlineRef}
                value={landline}
                required={false}
              />
              <ErrorMessage serverErrorsArray={serverErrors} fieldName={""} />
              <span className="text--danger d-block font-10 ms-2 mt-1">
                {phoneErrors?.landline === true
                  ? "Incorrect number format"
                  : " "}
              </span>
            </div>
          </div>
        </div>
        {/* User role row */}
        <div className="row pt-2">
          <div className="col-sm-4">
            <label
              htmlFor="UserRoles"
              className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
            >
              User Role <span>&#42;</span>
            </label>
            <select
              onChange={roleHandler}
              className={`form-select  font-14 border--default br-10 shadow-none input ${editMode ? 'bg--disabled':''}`}
              aria-label="UserRoles"
              id="UserRoles"
              required
              disabled={editMode ===  true ? true:false }
              ref={roleRef}
              style={{ borderRadius: "10px" }}
            >
              <option value="" hidden selected disabled>
                Select
              </option>
              {roles.length > 0
                ? roles.map((item, index) => {
                    return (
                      <option
                        className="font-14"
                        key={index}
                        value={item?.value}
                        selected={item?.value === role}
                      >
                        {item?.name}
                      </option>
                    );
                  })
                : ""}
            </select>
            <ErrorMessage
              serverErrorsArray={serverErrors}
              fieldName={fieldNames.role}
            />
          </div>

          {
            ["Physician", "NursePractitioner", "PhysicianAssistant", "RegisteredNurse"].includes(role) ?
            <div className="col-sm-4">
              <div>
                <label
                  htmlFor="NPI"
                  className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
                >
                {npiRequiredFlag === true ? <span>NPI &#42;</span> : "NPI"}
                </label>
                <input
                  type="text"
                  onChange={npiHandler}
                  // onKeyDown={handleKeyDown}
                  ref={npiRef}
                  defaultValue={npi}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="NPI"
                  required={npiRequiredFlag}
                  // minLength={nameConstraints.min}
                  maxLength={10}
                />
                <ErrorMessage
                  serverErrorsArray={serverErrors}
                  fieldName={fieldNames.npi}
                />
                <ValidationErrorMessage
                  errors={nameValidationErrors}
                  fieldName={field_Name.npi}
                />
              </div>
            </div>
            : ""}

          {/* <div className="col-sm-6">
            <label
              htmlFor="supervisor"
              className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
            >
              Supervising Physician
            </label>
            <select
              onChange={supervisorHandler}
              className="form-select font-14 border--default br-10 shadow-none input"
              aria-label="supervisor"
              id="supervisor"
              // required
              ref={supervisorRef}
              style={{ borderRadius: "10px" }}
            >
              <option value="" key={"0"} className="font-14">
                Select Supervisor
              </option>
              {prescribersList?.length > 0
                ? prescribersList?.map((prescriberItem) => {
                  return (
                    prescriberItem?.role?.trim() === "Physician" && (
                      <option
                        selected={
                          prescriberItem?.userId == supervisor?.userId
                        }
                        className="font-14"
                        value={prescriberItem?.userId ?? ""}
                        key={prescriberItem?.userId ?? ""}
                      >
                        {prescriberItem?.name[0]?.prefix}{" "}
                        {prescriberItem?.name[0]?.firstName}{" "}
                        {prescriberItem?.name[0]?.lastName}{" "}
                      </option>
                    )
                  );
                })
                : ""}
            </select>
          </div>
           */}
          {
            role === "Physician" ? 
            <div className="col-sm-4">
              <label
                htmlFor="specialty"
                className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
              >
                Specialty &#42;
              </label>
              <select
                onChange={handleProviderSpecialty}
                className="form-select font-14 border--default br-10 shadow-none input"
                aria-label="specialty"
                // id="specialty"
                required
                // ref={supervisorRef}
                style={{ borderRadius: "10px" }}
              >
                <option value="" key={"0"} className="font-14">
                  {"Specialty"} 
                </option>
                {specialties?.length > 0
                  ? specialties?.map((specialtyItem) => {
                    
                      return (
                          <option
                            selected={
                              editMode ?  specialtyItem?.specialityId === parseInt(speciality[0]?.specialityId)  ? true:false: speciality[0] !== undefined &&  specialtyItem?.specialityId === parseInt(speciality[0]?.specialityId)}
                            className="font-14"
                            value={specialtyItem?.speciality ?? ""}
                            key={specialtyItem?.specialityId ?? ""}
                            data = {specialtyItem?.specialityId}
                          >
                            {specialtyItem?.speciality}
                          </option>
                        )
                      
                    })
                  : ""}
              </select>
            </div>
            :null
          }
        </div>
        {/* Facility row */}
        <div className="row pt-2">
          <div className="col-sm-8">
            <label
              htmlFor="Facility"
              className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
            >
              Facility <span>&#42;</span>
            </label>

            {/* <div> */}
              <MultipleSelectFacility
                facilitiesList={facilitiesList}
                setFacilitiesIdList={setFacilitiesIdList}
                facilityNamesList={facilityNamesList}
                facilityHandler={facilityHandler}
                facilitiesCheckedList={facilitiesCheckedList}
                facilitiesIdsCheckedList={facilitiesIdsCheckedList}
                required={role !== 'PracticeAdmin'}
                edit={editMode}
              />
            {/* </div> */}
            <ErrorMessage
              serverErrorsArray={serverErrors}
              fieldName={fieldNames.facility}
            />
          </div>
          <div className="col-sm-4">
            <label
              htmlFor="Timezone"
              className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
            >
              Time Zone<span>&#42;</span>
            </label>
            {
              isTimezoneLoading ? <Skeleton/> :
            <select
              onChange={handleTimeZone}
              className={
                "form-select font-14 border--default br-10 shadow-none input"
              }
              aria-label="Timezone"
              id="Timezone"
              required
              // disabled={editMode}
              // ref={roleRef}
              style={{ borderRadius: "10px" }}
            >
              <option className="fw-sb" disabled selected hidden value="">
                    Select Timezone
              </option>
              { timeZoneList?.length > 0 ? (
                    timeZoneList?.map((item, index) => {
                      // console.log("item: ",item,user?.timezoneOffset);
                      return (
                        <option
                          key={item?.name}
                          value={item?.name}
                          data-offset={item?.offset}
                          selected={editMode ? item?.name == user?.timezone ? true : false :item?.name == user?.timezone}
                          className=""
                        >
                          {item?.name} &nbsp; {item?.offset}
                          {/* {moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')} */}
                          {/* {Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
                        </option>
                      );
                    })
                  ) : (
                    ""
                  )}
            </select>
            }
            <ErrorMessage
              serverErrorsArray={serverErrors}
              fieldName={fieldNames.role}
            />
          </div>
        </div>
        
        {/* SSN and TIN */}
        <div className="row pt-2">
        {
            ["Physician", "NursePractitioner", "PhysicianAssistant", "RegisteredNurse"].includes(role) ?
            <>
              <div className="col-sm-4">
                <div>
                  <label
                    htmlFor="SSN"
                    className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
                  >
                  { <span>SSN &#42;</span>}
                  </label>
                  <input
                    type="text"
                    onChange={ssnHandler}
                    // onKeyDown={handleKeyDown}
                    ref={ssnRef}
                    defaultValue={ssn}
                    autoComplete={false}
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="SSN"
                    required={npiRequiredFlag}
                    maxLength={10}
                    // minLength={nameConstraints.min}
                    // maxLength={nameConstraints.max}
                  />
                  <ErrorMessage
                    serverErrorsArray={serverErrors}
                    fieldName={fieldNames.npi}
                  />
                  <ValidationErrorMessage
                    errors={nameValidationErrors}
                    fieldName={field_Name.npi}
                  />
                </div>
              </div>
              
            </>
            : ""
        }

        </div>
        {/* Horizontal rule */}
        <div className="row mt-5">
          <div className="col-sm-12">
            <div className="horizontal-rule" />
          </div>
        </div>

        <div className="row mt-3"  hidden = {userPermissions?.adp === false ?  true:false}>
          <div className="col-sm-4">
            <Stack direction="column">
              <label
                htmlFor="UserStatus"
                className="form-label ms-2 font-12 text--secondary fw-sb mb-0"
              >
                User Status
              </label>
              <Stack direction="row" justifyContent={"between"} spacing={2}>
                <div
                  className="col-sm-12"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #D9E8EC",
                    borderRadius: "10px",
                  }}
                >
                  <div className="row">
                    <div className="col-sm-8 justify-content-start align-self-center">
                      <Typography component="span">
                        <Box
                          sx={{
                            m: 1,
                            fontFamily: "Montserrat",
                            fontWeight: "600",
                            fontSize: "12px",
                            color: "#323232",
                          }}
                        >
                          {userActiveStatus === true ? "Active" : "Inactive"}
                        </Box>
                      </Typography>
                    </div>
                    <div className="col-sm-4 justify-content-end text-end align-self-center">
                      <TwoFactorSwitch
                        {...label}
                        checked={userActiveStatus}
                        onChange={twoFactorSwitch}
                      />
                    </div>
                  </div>
                </div>
              </Stack>
            </Stack>
          </div>
        </div>
          <div className="row pt-1 justify-content-center">
            <div className="col-12">
              {/* <ClaimsSettings 
                setValidationErrors={setClaimValidationErrors}
                validationErrors={claimValidationErrors} 
                tenantSettingsData={""} 
                setTenantSettingsData={""} 
                selectedPayers = {selectedPayers}
                setSelectedPayers = {setSelectedPayers}
                user={user}
                setUser={setUser}
                checkUpdateOnEditMode={checkUpdateOnEditMode}
                editMode = {editMode}
                /> */}
            </div>
          </div>
      </form>
      <div className="row my-3 justify-content-end">
        <div className={`col-sm-2`}>
          <button
            onClick={
              editMode
                ? _.debounce(editHandler, 400, { leading: true })
                : _.debounce(submitHandler, 400, { leading: true })
            }
            className={`btn blue_task_button`}
            disabled={
              editMode
                ? !updateDisabled ||
                  Object?.keys(nameValidationErrors)?.length !== 0
                  ? true
                  : false ||
                    isSending ||
                    phoneErrors?.emergency === true ||
                    phoneErrors?.landline === true ||
                    phoneErrors?.primary === true
                  ? true
                  : false
                : isSending ||
                  phoneErrors?.emergency === true ||
                  phoneErrors?.landline === true ||
                  phoneErrors?.primary === true
                ? true
                : Object?.keys(nameValidationErrors)?.length !== 0
                ? true
                : false
            }
          >
            {editMode
              ? isSending
                ? "Updating..."
                : "Update"
              : isSending
              ? "Adding..."
              : "Add"}
          </button>
        </div>
      </div>
      {/* {console.log("nameValidationErrors: ",nameValidationErrors)} */}
    </>
  );
};

export default AddUserForm;
