import { Backdrop, CircularProgress, Table, TableContainer, TableHead } from "@mui/material"
import { PnToolTip, StyledTableCell, StyledTableRow } from "../../components/UI/Reusable/PnComponents"
import AddIcon from '@mui/icons-material/Add';
import AddFeeScheduleModal from "./AddFeeScheduleModal";
import useModal from "../../components/hooks/useModal";
import { useAuth } from "../../components/hooks/useAuth";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import { currencyFormatter, getCurrencySymbol, initCap, truncate } from "../../utils/helpers/HelperFunctions";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
import DeleteFeeScheduleModal from "./DeleteFeeScheduleModal";
import EditFeeScheduleModal from "./EditFeeScheduleModal";
import CurrencyInput from "react-currency-input-field";


function FeeSchedule() {
    const { open: addFeeScheduleModalOpen, handleModal: handleAddFeeScheduleModal } = useModal(false);
    const { open: deleteFeeScheduleModalOpen, handleModal: handleDeleteFeeScheduleModal } = useModal(false);
    const { open: editFeeScheduleModalOpen, handleModal: handleEditFeeScheduleModal } = useModal(false);
    const [reloadSchedules, setReloadSchedule] = useState(false);
    const [deletedFeeSchedule, setDeletedFeeSchedule] = useState(null);
    const [feeScheduleToEdit, setFeeScheduleToEdit] = useState(null);
    const { user } = useAuth();

    const [charge, setCharge] = useState('');
    const [amount, setAmount] = useState('');
    const [discount, setDiscount] = useState('');
    const [disableDiscountField, setDisableDiscountField] = useState(false);
    const [disableAmountField, setDisableAmountField] = useState(false);
    const [category, setCategory] = useState('');
    const [billCharge, setBillCharge] = useState('');
    const [editableRow, setEditableRow] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [description, setDescription] = useState('');


    const [feeSchedules, setFeeSchedules] = useState([]);
    const [isFeeScheduleLoading, setIsFeeScheduleLoading] = useState(false);

    function handleCharge(value, name) {
        if (value == 0 || value == undefined) {
            setDiscount('');
            setAmount('');
        }

        setCharge(value);

        calculateBillCharge(value, discount == 0 ? amount : amount == 0 ? discount : 0, 'charge');
    }

    function handleBillChargeForAmount(chargeAmount, discountVal) {
        const billChargeValue = chargeAmount - discountVal;
        setBillCharge(billChargeValue);
    }

    function handleBillChargeForDiscountPercentage(chargeAmount, discountVal) {
        const billChargeValue = chargeAmount - (chargeAmount * (discountVal / 100));
        setBillCharge(billChargeValue);
    }

    function calculateBillCharge(chargeAmount, discountVal, mode = 'percentage') {
        console.log(chargeAmount, discountVal, mode);
        if (mode === 'percentage') {
            if (discountVal === undefined) {
                setBillCharge(chargeAmount);
            }
            else {
                handleBillChargeForDiscountPercentage(chargeAmount, discountVal);
            }
        }
        else if (mode === 'amount') {
            if (discountVal === undefined) {
                setBillCharge(chargeAmount);
            }
            else {
                handleBillChargeForAmount(chargeAmount, discountVal);
            }
        }
        else if (mode === 'charge') {
            if (chargeAmount === undefined) {
                setDisableAmountField(false);
                setDisableDiscountField(false);
            }
            if (discount !== undefined && discount !== '') {
                handleBillChargeForDiscountPercentage(amount, discountVal);
            }
            else if (amount !== undefined && amount !== '') {
                handleBillChargeForAmount(amount, discountVal);
            }
            else {
                setBillCharge(chargeAmount);
            }
        }
    }



    async function editFeeSchedule(feeScheduleToEdit, charge, billCharge, amount, discount, category) {

        setIsSaving(true);
        const options = {
            method: 'PUT',
            url: `/claims/tenants/${user?.tenantId}/fee-schedule/${feeScheduleToEdit?.feeScheduleId}`,
            data: {
                ...feeScheduleToEdit,
                description: description,
                price: Number(billCharge),
                category: category,
                charge: Number(charge),
                amount: isNaN(amount) ? 0 : Number(amount),
                discount: isNaN(discount) ? 0 : Number(discount)
            }
        }
        const httpResponse = await sendHttpRequest(options);
        if (!httpResponse.error) {
            setReloadSchedule(p => !p);
            closeEditMode();
        }
        else {
            console.log(httpResponse.error);
            console.log(httpResponse);

        }
        setIsSaving(false);
    }

    function handleDiscount(value, name) {
        console.log(value, name);
        if (name === "percentage") {
            if (value === undefined) {
                setDisableAmountField(false);
                setDiscount('');
                calculateBillCharge(charge, value, name);
            }
            else {
                if (value >= 0 && value <= 100) {
                    setDisableAmountField(true);
                    setDiscount(value);
                    calculateBillCharge(charge, value, name);
                }
            }

        }
        else if (name === "amount") {
            if (value === undefined) {
                setDisableDiscountField(false);
                setAmount('');
                calculateBillCharge(charge, value, 'amount');
            }
            else {
                // console.log("WARNING", value, charge)

                if (value >= 0 && value <= charge) {
                    console.log("WARNING", value, charge)

                    setDisableDiscountField(true);
                    setAmount(value);
                    calculateBillCharge(charge, value, 'amount');
                }
            }
        }
    }



    async function getAllFeeSchedules(loggedInUserTenantId) {
        setIsFeeScheduleLoading(true);
        const options = {
            method: 'GET',
            url: `/claims/tenants/${loggedInUserTenantId}/fee-schedule`
        }
        const httpResponse = await sendHttpRequest(options);
        if (!httpResponse.error) {
            setFeeSchedules(httpResponse.data);
            // console.log(httpResponse);
        }
        else {
            console.log("/claims/tenants/fee-schedule ==> ", httpResponse);
        }
        setIsFeeScheduleLoading(false);
    }

    function handleDeleteFeeSchedule(feeSchedule) {
        setDeletedFeeSchedule(feeSchedule);
        handleDeleteFeeScheduleModal();
    }

    function handleEditFeeSchedule(feeSchedule) {
        setFeeScheduleToEdit(feeSchedule);
        // handleEditFeeScheduleModal();

        setEditableRow(feeSchedule?.code?.code);
        setCharge(feeSchedule?.charge);
        setDiscount(feeSchedule?.discount == 0 ? '' : feeSchedule?.discount);
        setAmount(feeSchedule?.amount == 0 ? '' : feeSchedule?.amount);
        setBillCharge(feeSchedule?.price);
        setCategory(feeSchedule?.category);
        setDescription(feeSchedule?.description);

    }

    function closeEditMode() {
        setEditableRow(null);
        setFeeScheduleToEdit(null);
        // handleEditFeeScheduleModal();
        setCharge('');
        setDiscount('');
        setAmount('');
        setBillCharge('');
        setCategory('');
    }

    function handleDescription(event) {

        const modifiedDescription = event.target.value;
        setDescription(modifiedDescription);
    }

    useEffect(() => {
        getAllFeeSchedules(user?.tenantId);
    }, [user?.tenantId, reloadSchedules])

    return (
        <>
            {addFeeScheduleModalOpen ? <AddFeeScheduleModal feeSchedules={feeSchedules} setReloadSchedule={setReloadSchedule} show={addFeeScheduleModalOpen} handleModal={handleAddFeeScheduleModal} /> : null}
            {deleteFeeScheduleModalOpen && deletedFeeSchedule !== null ? <DeleteFeeScheduleModal setReloadSchedule={setReloadSchedule} show={deleteFeeScheduleModalOpen} deletedFeeSchedule={deletedFeeSchedule} handleModal={handleDeleteFeeScheduleModal} /> : null}
            {editFeeScheduleModalOpen && feeScheduleToEdit !== null ? <EditFeeScheduleModal setReloadSchedule={setReloadSchedule} show={editFeeScheduleModalOpen} feeScheduleToEdit={feeScheduleToEdit} handleModal={handleEditFeeScheduleModal} /> : null}
            <div className="container-fluid py-3">
                <div className="row justify-content-end">
                    <div className="col-sm-3 text-end">
                        <button
                            onClick={handleAddFeeScheduleModal}
                            className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12"
                        >
                            <span className="pn-button-icon-secondary pe-1"><AddIcon color="#336383" size={14} /> </span>
                            <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Fee Schedule</span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TableContainer
                            sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
                        >
                            <Table>
                                <TableHead
                                    sx={{
                                        borderTopColor: "#FAFCFC",
                                        borderRightColor: "#FAFCFC",
                                        borderLeftColor: "#FAFCFC",
                                        borderBottom: "1px solid #D9E8EC",
                                    }}
                                >
                                    <StyledTableRow>
                                        <StyledTableCell><span className="fw-sb">Billing Code</span></StyledTableCell>
                                        <StyledTableCell sx={{ width: '40%' }}><span className="fw-sb">Description</span></StyledTableCell>
                                        <StyledTableCell><span className="fw-sb">Category</span></StyledTableCell>
                                        <StyledTableCell><span className="fw-sb">Charge</span></StyledTableCell>
                                        <StyledTableCell><span className="fw-sb">Discount</span></StyledTableCell>
                                        <StyledTableCell><span className="fw-sb">Bill Charge</span></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        {/* <StyledTableCell></StyledTableCell> */}
                                    </StyledTableRow>
                                    {isFeeScheduleLoading ? <StyledTableRow></StyledTableRow> :
                                        feeSchedules?.map((item) => {
                                            return <StyledTableRow key={item?.code?.code} id={item?.code?.code}>
                                                <StyledTableCell>{item?.code?.code}</StyledTableCell>

                                                <StyledTableCell>{editableRow === item?.code?.code ? <textarea
                                                    className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                                                    value={description}
                                                    onChange={handleDescription}
                                                    placeholder="Description" ></textarea> : <PnToolTip title={item?.description}>
                                                    <span>
                                                        {truncate(item?.description, 90)}
                                                    </span>
                                                </PnToolTip>}</StyledTableCell>

                                                <StyledTableCell>{initCap(item?.category)}</StyledTableCell>
                                                <StyledTableCell >{editableRow === item?.code?.code ? <CurrencyInput
                                                    id="charge"
                                                    name="cost"
                                                    placeholder="$0.00"
                                                    className="text--terciary simple-placeholder text-center font-12 input form-control shadow-none border--default br-10"
                                                    prefix="$"
                                                    decimalSeparator="."
                                                    allowNegativeValue={false}
                                                    groupSeparator=","
                                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                                    onValueChange={(value, name) => handleCharge(value, name)}
                                                    value={charge}
                                                // disabled = {true}
                                                /> : `${getCurrencySymbol(item?.currency)} ${item?.charge}`}</StyledTableCell>
                                                <StyledTableCell >  {editableRow === item?.code?.code ? <div className="d-flex gap-1">
                                                    <div>
                                                        <CurrencyInput
                                                            id="percentage"
                                                            name="percentage"
                                                            placeholder="0%"
                                                            allowNegativeValue={false}
                                                            className="text--terciary simple-placeholder text-center font-12 input form-control shadow-none border--default br-10"
                                                            suffix="%"
                                                            decimalSeparator="."
                                                            groupSeparator=","
                                                            onValueChange={(value, name) => handleDiscount(value, name)}
                                                            value={discount}
                                                            disabled={disableDiscountField || charge === '' || charge === undefined}
                                                        />
                                                    </div>
                                                    <div>
                                                        <CurrencyInput
                                                            id="amount"
                                                            name="amount"
                                                            placeholder="Amount"
                                                            className="text--terciary input simple-placeholder text-center font-12 input form-control shadow-none border--default br-10"
                                                            decimalSeparator="."
                                                            groupSeparator=","
                                                            allowNegativeValue={false}
                                                            onValueChange={(value, name) => handleDiscount(value, name)}
                                                            value={amount}
                                                            disabled={disableAmountField || charge === '' || charge === undefined}
                                                        />
                                                    </div>
                                                </div> : `${getCurrencySymbol(item?.currency)} ${(Number(item?.charge) - Number(item?.price)).toFixed(2)}`}</StyledTableCell>
                                                <StyledTableCell > {editableRow === item?.code?.code ? <input type="text" disabled className="form-control font-12 br-10 shadow-none input border--default disabled"
                                                    value={charge !== undefined && charge !== '' && billCharge !== '' ? currencyFormatter('USD', billCharge) : ''}
                                                /> : `${getCurrencySymbol(item?.currency)} ${item?.price}`}</StyledTableCell>



                                                < StyledTableCell
                                                    // onClick={() => handleEditDiagnosis({ diagnosisId: diagnosis?.diagnosisId, code: diagnosis?.code, description: diagnosis?.description, type: diagnosis?.type, ...(diagnosis?.startDate && { startDate: diagnosis?.startDate }), ...(diagnosis?.endDate && { endDate: diagnosis?.endDate }), status: diagnosis?.status, isPastDiagnosis: isPastDiagnosis })}
                                                    id={item?.code?.code}
                                                    sx={{ cursor: "pointer" }}
                                                >

                                                    {editableRow === item?.code?.code ? <div className="d-flex gap-3">
                                                        <div onClick={() => closeEditMode()} disabled={isSaving} className="font-12 fw-sb pn-link-button text--danger">
                                                            Cancel
                                                        </div>
                                                        <div disabled={isSaving} onClick={() => editFeeSchedule(feeScheduleToEdit, charge, billCharge, amount, discount, category)} className="font-12 fw-sb pn-link-button text--blue">
                                                            Update
                                                        </div>
                                                    </div> : <div className="d-flex gap-3">
                                                        <div id={item?.code?.code}
                                                            onClick={() => handleDeleteFeeSchedule(item)}
                                                        >
                                                            <img
                                                                src={TrashButtonIcon}
                                                                alt={"trashIcon"}
                                                                id={item?.code?.code}
                                                                style={{ width: "15px", height: "15px" }}
                                                            ></img>
                                                        </div>

                                                        <div id={item?.code?.code}
                                                            onClick={() => handleEditFeeSchedule(item)}
                                                        >
                                                            <img
                                                                src={EditButtonIcon}
                                                                alt={"editButtonIcon"}
                                                                id={item?.code?.code}
                                                                style={{ width: "15px", height: "15px" }}
                                                            ></img>
                                                        </div>
                                                    </div>}


                                                </StyledTableCell>

                                            </StyledTableRow>
                                        })

                                    }

                                </TableHead>
                            </Table>

                        </TableContainer>
                    </div>
                </div>
            </div>

            {isFeeScheduleLoading ? <Backdrop
                sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFeeScheduleLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> : null}
        </>
    )
}

export default FeeSchedule