
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const VerticalIcon = (props) => {
    const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
        <div >
            <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                aria-label="more"
                hidden={userPermissions.csa === false && userPermissions.rap === false}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                dense
                onClose={handleClose}
                disableScrollLock={true}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
            <MenuItem
                dense
                onClick={() => {
                handleClose();
                props.handleRescheduleModalShow();
                }}
                sx={{ fontFamily: "Montserrat" }}
                disabled={props?.appointmentItemData?.status?.toLowerCase() !== "pending" ? true : false}
                hidden={userPermissions.rap === false ? true : false}
            >
                Edit
            </MenuItem>

            <MenuItem
                dense
                onClick={() => {
                props.handleModalShow();
                handleClose();
                }}
                sx={{ fontFamily: "Montserrat" }}
                disabled={props?.appointmentItemData?.status?.toLowerCase() !== "pending" ? true : false}
                hidden={userPermissions.csa === false ? true : false}
            >
                Cancel
            </MenuItem>
            </Menu>
        </div>
    );
}


export default VerticalIcon;