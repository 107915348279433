import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useDispatch, useSelector } from "react-redux";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { useNavigate } from "react-router-dom";
import {
  setAllPhysicalSystemsStatus,
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import {
  addTimeHelper,
  dateTimeHelper,
  getAgeByDateOfBirth,
} from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import classes from "./AppointmentItem.module.css";
import { truncate } from "../../utils/helpers/HelperFunctions";
import placeholder from "../../assets/images/placeholder.png";
import { useState } from "react";
import { encounterSummaryUrl } from "../../res/endpointurls";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { useContext } from "react";
import VerticalIcon from "../UI/IconComponentsUI/VerticalIcon";
import CancelAppointmentModal from "../UI/Modals/CancelAppointmentModal";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import AppointmentRescheduleModal from "../UI/Modals/ProviderModals/AppointmentRescheduleModal";
import useMediaQuery from "../hooks/useMediaQuery";

const AppointmentItem = (props) => {
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const [openApptRescheduleModal, setOpenApptRescheduleModal] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1345px)");
  const [
    openRescheduleAppointmentSnackbar,
    setOpenRescheduleAppointmentSnackbar,
  ] = useState(false);
  const [rescheduleAptErrorMsg, setRescheduleAptErrorMsg] = useState();
  const [isAppointmentRescheduled, setIsAppointmentRescheduled] =
    useState(false);
  const [isAppointmentCancelled, setIsAppointmentCancelled] = useState(false);
  const [isAppointmentCancelling, setIsAppointmentCancelling] = useState(false);
  const [appointmentCancellationFail, setAppointmentCancellationFail] =
    useState(false);
  const [appointmentCancellationError, setApppointmentCancellationError] =
    useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const appointmentType = props?.appointment?.appointmentType?.key?.toLowerCase();
  // console.log(`Appointment type: ${appointmentType}`);
  const videoCallContext = useContext(VideoCallContext);
  const dispatch = useDispatch();
  const [isLoadingEnc, setIsLoadingEnc] = useState();
  const [encounter, setEncounter] = useState("");
  const navigate = useNavigate();
  const [isAppointmentStartLoading, setIsAppointmentStartLoading] =
    useState(false);

  let appointmentStatus = props?.appointment?.status;
  let FirstName =
    props?.appointment?.appointmentSlot?.patient?.name[0]?.firstName;
  let LastName =
    props?.appointment?.appointmentSlot?.patient?.name[0]?.lastName;
  let Gender = props?.appointment?.appointmentSlot?.patient?.gender;

  // let PatientType = props?.appointment?.encounter?.patientType;
  let VisitType = props?.appointment?.appointmentType?.title;
  let visitType = VisitType;
  let patientVisitType =
    props?.appointment?.patientVisitType?.displayValue ?? "";
  // let patientType = PatientType;
  let ChiefComplaint = props?.appointment?.chiefComplaint;
  let startDateTime = props?.appointment?.appointmentSlot?.startDateTime.trim();
  let duration = props?.appointment?.appointmentSlot?.duration;
  let date = dateTimeHelper(startDateTime, "MMM D, YYYY");
  let startTime = dateTimeHelper(startDateTime, "LT");
  let endTime = addTimeHelper(startTime, duration);
  let encounterId =
    props?.appointment?.prescriptionReference?.prescriptionReferenceId;
  let patientId = props?.appointment?.appointmentSlot?.patient?.userId;
  let profilePicture =
    props?.appointment?.appointmentSlot?.patient?.profilePicture;
  let dob = props?.appointment?.appointmentSlot?.patient?.dateOfBirth;

  let appointmentItemTooltipText =
    FirstName !== undefined && LastName !== undefined && Gender !== undefined
      ? FirstName +
      " " +
      LastName +
      " | " +
      Gender +
      " -" +
      getAgeByDateOfBirth(dob) +
      " years"
      : "";
  let visitStatus =
    props?.appointment?.patientVisit?.patientStatus?.displayValue ||
    "Scheduled";

  const sendStartAppointmentRequest = async () => {
    setIsAppointmentStartLoading(true);
    let appointmentId = props?.appointment?.id;
    videoCallContext.updateAppointmentData(appointmentId);
    videoCallContext.updateAppointmentObject(props?.appointment);
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${appointmentId}/start`,
      method: "GET",
      params: {
        providerId: props.providerId,
      }
    });
    if (!httpResponse.error) {
      videoCallContext.updateAppointmentData(appointmentId);
      setEncounter(httpResponse.data?.prescriptionReference?.encounter);
    }
    // setIsSessionUpdated(false);
    setIsAppointmentStartLoading(false);
    props?.handleBackdropClose();
  };
  const fetchPrescribedMedication = async (encId) => {
    const httpResponse = await sendHttpRequest({
      url: "/medicationRx",
      method: "GET",
      params: {
        EncounterId: encId,
      },
    });
    if (!httpResponse.error) {
      // console.log("Medication Rx->",httpResponse.data);

      // if(httpResponse.data.length > 0){
      //   dispatch(updatePrescribedMedication(httpResponse.data));
      // }else{
      dispatch(updatePrescribedMedication([]));
      // }
    } else {
      console.log(httpResponse.error);
    }
  };
  // const handleEncounterData = (encId)=>{
  //   fetchPrescribedMedication(encId)
  // }
  const fetchPatientEncounter = async (encId) => {
    setIsLoadingEnc(false);
    const httpResponse = await sendHttpRequest({
      url: encounterSummaryUrl(encId),
      method: "GET",
    });
    if (!httpResponse.error) {
      let encounterData = httpResponse.data.appointment;
      // console.log("encounterData", {
      //   ...encounterData,
      //   encounter: { ...httpResponse.data },
      // });
      setEncounter({
        ...encounterData,
        prescriptionReference: { ...httpResponse.data },
      });
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingEnc(true);
  };
  const handleEncData = () => {
    props?.handleToggle();
    handleEncounterData(encounterId);
  };

  const handleEncounterData = (encId) => {
    fetchPatientEncounter(encId);
    // fetchPrescribedMedication(encId)
    dispatch(updatePrescribedMedication([]));
  };

  const handlePatientEncouterData = async () => {
    // dispatch(setJoinPage(true))
    // console.log("Start Appointment", props?.appointment);
    props?.handleToggle();
    await sendStartAppointmentRequest();



    videoCallContext.updateAppointmentData(props?.appointment?.id);
    videoCallContext.updateAppointmentObject(props?.appointment);
    dispatch(setPrescriptionNoteText(""));
    dispatch(setPatientEncounterData({}));
    dispatch(setDisableSendRxButton(false));
    dispatch(updatePrescribedMedication([]));
    dispatch(setPatientEncounterData(props?.appointment));
    // dispatch(setVideoOpen(true));
    // dispatch(setConsulationInProgress(true));
    dispatch(
      setUpdateEncounterData({
        Diagnosis: {
          Note: "",
          Primary: [],
          Secondary: [],
        },
      })
    );
    dispatch(
      setDiagnosis({
        Note: "",
        Primary: [],
        Secondary: [],
      })
    );
    if (appointmentType === "follow_up") {
      navigate(`/all-patients/${patientId}/soap-note`, {
        state: {
          prescriptionReferenceId:
            props?.appointment?.prescriptionReference?.prescriptionReferenceId,
          encounterId: encounterId,
          appointmentId: props.appointment.id,
        },
      });
    } else {
      navigate(`/consult/${props.providerId}/${encounterId}/${patientId}`, {
        state: {
          prescriptionReferenceId:
            props.appointment?.prescriptionReference?.prescriptionReferenceId,
        },
      });
    }
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleCancelModalShow = () => {
    setCancelModal(true);
  };

  const handleApptClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAppointmentCancelled(false);
    setAppointmentCancellationFail(false);
    setOpenRescheduleAppointmentSnackbar(false);
  };

  const handleResumePatientEncouterData = (data) => {
    // console.log("data: ", data);
    // console.log("Diagnosis : ",data?.prescriptionReference?.encounter?.diagnosis);
    videoCallContext.updateAppointmentData(props?.appointment?.id);
    videoCallContext.updateAppointmentObject(props?.appointment);
    dispatch(setPrescriptionNoteText(""));
    let diagnosis = {
      Note: "",
      Secondary: [],
      Primary: [],
    };
    if (
      data?.prescriptionReference?.patientChart?.diagnosis !== undefined &&
      data?.prescriptionReference?.patientChart?.diagnosis !== null &&
      data?.prescriptionReference?.patientChart?.diagnosis?.length > 0
    ) {
      diagnosis.Note =
        data?.prescriptionReference?.patientChart?.diagnosis[0]?.note;
      data?.prescriptionReference?.patientChart?.diagnosis.forEach(
        (diagnosisItem) => {
          if (diagnosisItem?.type?.toLowerCase() === "p") {
            diagnosis.Primary = [diagnosisItem];
          } else {
            diagnosis.Secondary.push(diagnosisItem);
          }
        }
      );
    }
    dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
    // console.log("Diagnosis in state", diagnosis);
    dispatch(setDiagnosis(diagnosis));
    // dispatch(setPatientEncounterData({ ...data?.prescriptionReference, notes: data?.prescriptionReference?.encounter?.notes || "" }));
    dispatch(
      setPatientEncounterData({
        ...data,
        prescriptionReference: {
          ...data?.prescriptionReference,
          encounter: {
            ...data?.prescriptionReference?.encounter,
            notes: data?.prescriptionReference?.encounter?.notes || "",
          },
        },
      })
    );
    dispatch(
      setAllPhysicalSystemsStatus(
        data?.prescriptionReference?.encounter?.allSystemsOk
      )
    );
    if (data?.encounter?.status === "DISPATCHED") {
      dispatch(setDisableSendRxButton(true));
    } else {
      dispatch(setDisableSendRxButton(false));
    }
    // dispatch(setConsulationInProgress(true));
    // dispatch(setVideoOpen(true));
  };

  const handleApptRescheduleModal = () => {
    setOpenApptRescheduleModal(!openApptRescheduleModal);
  };

  return (
    <>
      {cancelModal && (
        <CancelAppointmentModal
          show={cancelModal}
          handleCancelModalClose={handleCancelModalClose}
          firstName={FirstName}
          lastName={LastName}
          date={dateTimeHelper(startDateTime, "dddd, MMM D, YYYY")}
          startDateTime={dateTimeHelper(startDateTime, "LT")}
          endTime={dateTimeHelper(
            props?.appointment?.appointmentSlot?.endDateTime,
            "LT"
          )}
          prescriberPrefix={
            props?.appointment?.appointmentSlot?.providerTenantPracticeLocation
              ?.provider?.name[0]?.prefix
          }
          prescriberFirstName={
            props?.appointment?.appointmentSlot?.providerTenantPracticeLocation
              ?.provider?.name[0]?.firstName
          }
          prescriberLastName={
            props?.appointment?.appointmentSlot?.providerTenantPracticeLocation
              ?.provider?.name[0]?.lastName
          }
          appointmentId={props?.appointment?.id}
          setIsAppointmentCancelled={setIsAppointmentCancelled}
          setIsAppointmentCancelling={setIsAppointmentCancelling}
          setAppointmentCancellationFail={setAppointmentCancellationFail}
          setApppointmentCancellationError={setApppointmentCancellationError}
          isAppointmentCancelling={isAppointmentCancelling}
          fecthAppointments={props.fetchAppointments}
        />
      )}
      {openApptRescheduleModal && (
        <AppointmentRescheduleModal
          show={openApptRescheduleModal}
          handleModal={handleApptRescheduleModal}
          appointmentItemData={props.appointment}
          fecthAppointments={props.fetchAppointments}
          isAppointmentRescheduled={isAppointmentRescheduled}
          setIsAppointmentRescheduled={setIsAppointmentRescheduled}
          setOpenRescheduleAppointmentSnackbar={
            setOpenRescheduleAppointmentSnackbar
          }
          setRescheduleAptErrorMsg={setRescheduleAptErrorMsg}
          providerDashboard={true}
        />
      )}
      {/* {console.log("props.appointment.Encounter.Patient.PatientId",props.appointment.Encounter.Patient.PatientId)} */}
      <div
        className={`d-flex  ${!props.isLast && classes["appointment__item__border--bottom"]
          } ${isMobile ? 'gap-2' : 'gap-3'} justify-content-between flex-column flex-sm-row ${isMobile ? 'p-1' : 'p-2'}  align-items-center`}
        hidden={true}
      >
        <div className="align-self-center">
          <div className="d-flex">
            <div>
              <img
                src={profilePicture ? profilePicture : placeholder}
                width="44"
                height={44}
                alt="props.PatientName"
                className="rounded-circle"
                draggable={false}
              />
            </div>
            <div className="ms-2 g-0 align-self-center">
              {/* +' '+props.Encounter.Patient.Name[0].LastName */}
              <PnToolTip title={appointmentItemTooltipText}>
                <span
                  className="d-block p-0 font-16 fw-sb"
                  style={{ marginBottom: "-8px" }}
                >
                  {FirstName != undefined
                    ? truncate(FirstName + " " + LastName, 40)
                    : "User name not found"}{" "}

                </span>

              </PnToolTip>
              <div className="mt-0">
                <span className="m-0 p-0 text--terciary font-12 fw-thin d-block">
                  <small
                    className="lh-0 text--secondary  fw-thin"
                    style={{ fontSize: "11px" }}
                  >
                    {Gender !== undefined && Gender !== "" && Gender} -{" "}
                    {getAgeByDateOfBirth(dob)} years
                  </small>


                  {/* Chief complaint 1, chief complaint 2 */}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="align-self-center">
          <span
            className="font-12 d-block text--secondary fw-sb"
            style={{ marginBottom: "-5px" }}
          >
            {date}
          </span>
          <span className="font-12 d-block text--secondary mb-0">
            {startTime} &ndash; {endTime}
          </span>
        </div>
        <div className={`align-self-center col-3 text--secondary font-12 ${isMobile ? 'd-none' : ''}`}>
          {/* <span className="d-block" style={{ marginBottom: "-8px" }}>
            {patientType}
          </span> */}

          <span className="d-flex  gap-2 font-12" style={{ marginBottom: '-5px' }}>
            {patientVisitType === "In-Patient"
              ? visitType !== undefined
                ? visitType
                : ""
              : patientVisitType}

            <span className="p-0 m-0">{patientVisitType === "In-Person" ? <PnToolTip title={"In-Person"}><PersonOutlineIcon sx={{ fontSize: 19, margin: 0 }} /> </PnToolTip> : <PnToolTip title={"Virtual"}><PhotoCameraFrontIcon sx={{ fontSize: 19 }} /> </PnToolTip>}</span>
          </span>
          <span className="d-block m-0">
            <PnToolTip title={ChiefComplaint}>
              <span className="font-12 mb-0">
                {ChiefComplaint
                  ? truncate(ChiefComplaint, 30)
                  : "No chief complaint found"}
              </span>
            </PnToolTip>
          </span>

        </div>
        <div className="col-2">
          <div className={"row justify-content-between"}>
            <div className={"col-6 align-self-center"}>
              {/* {props?.appointment?.patientVisit?.patientStatus?.displayValue ?? <Attachment
                style={{ transform: "rotate(320deg)", color: "#a2d1de" }}
              />} */}

              <span
                className={`badge rounded-pill ${props?.appointment?.patientVisit?.patientStatus
                  ?.displayValue?.length > 20 ? 'px-3 text-wrap' : ''} ${props?.appointment?.status !== "cancelled" ? visitStatus === "Checked-in"
                    ? "bg--d9e8ec"
                    : visitStatus === "Scheduled"
                      ? "bg--mild"
                      : "bg--a2d1de"
                    : "bg--severe"} text--terciary  fw-thin font-12 py-1 px-2`}
              >
                {props?.appointment?.status !== "cancelled" ? props?.appointment?.patientVisit?.patientStatus
                  ?.displayValue ?? "Scheduled" : "Canceled"}
              </span>
            </div>
            <div className={"col-3 align-self-center"}>
              <VerticalIcon
                appointmentItemData={props.appointment}
                handleRescheduleModalShow={handleApptRescheduleModal}
                handleModalShow={handleCancelModalShow}
              />
            </div>
          </div>
        </div>
        <div className="align-self-center  text-end">
          {
            appointmentStatus?.toLowerCase()?.trim() === "pending" ? (
              <>
                <button
                  disabled={
                    videoCallContext.getIsVideoCallActive() &&
                    videoCallContext.appointmentData !== null &&
                    videoCallContext.appointmentData !== props.appointment.id
                  }
                  className="btn blue_task_button text-white b2 px-4 br-10 py-1"
                  onClick={() => handlePatientEncouterData()}
                  hidden={userPermissions?.spc === false ? true : false}
                >
                  Start
                </button>
              </>
            ) : (
              <>
                {isLoadingEnc &&
                  (appointmentType !== "follow_up") &&
                  (props?.handleBackdropClose(),
                    handleResumePatientEncouterData(props?.appointment),
                    navigate(
                      `/consult/${props.providerId}/${encounterId}/${patientId}`,
                      {
                        state: {
                          prescriptionReferenceId:
                            props?.appointment?.prescriptionReference
                              ?.prescriptionReferenceId,
                        },
                      }
                    ))}

                {isLoadingEnc &&
                  appointmentType === "follow_up" &&
                  (props?.handleBackdropClose(),
                    handleResumePatientEncouterData(props?.appointment),
                    navigate(`/all-patients/${patientId}/soap-note`, {
                      state: {
                        isResume: props?.appointment?.prescriptionReference?.soapNote !== undefined ? true : false,
                        prescriptionReferenceId:
                          props?.appointment?.prescriptionReference
                            ?.prescriptionReferenceId,
                        encounterId: encounterId,
                        appointmentId: props.appointment.id,
                      },
                    }))}

                <button
                  disabled={
                    videoCallContext.getIsVideoCallActive() &&
                    videoCallContext.appointmentData !== null &&
                    videoCallContext.appointmentData.data !==
                    props.appointment.id
                  }
                  className="btn blue_task_button"
                  onClick={() => handleEncData()}
                  hidden={userPermissions?.rpe === false ? true : false}
                >
                  Resume
                </button>
              </>
            )
            // <Link to={{
            //   pathname:`/consult/${props.providerId}/${encounterId}/${patientId}`
            // }} >
            // </Link>
          }
        </div>

        {/* {console.log("props.appointment",props.appointment)} */}
      </div>

      <CustomizedSnackbars
        message={"Appointment canceled successfully"}
        severity="success"
        open={isAppointmentCancelled}
        handleClose={handleApptClose}
      />
      <CustomizedSnackbars
        message={appointmentCancellationError}
        severity="error"
        open={appointmentCancellationFail}
        handleClose={handleApptClose}
      />
      <CustomizedSnackbars
        message={rescheduleAptErrorMsg}
        severity="error"
        open={openRescheduleAppointmentSnackbar}
        handleClose={handleApptClose}
      />
      <CustomizedSnackbars
        message={"Appointment rescheduled successfully"}
        severity="success"
        open={isAppointmentRescheduled}
        handleClose={handleApptClose}
      />
    </>
  );
};

export default AppointmentItem;
