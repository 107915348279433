import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { calenderDateTimeHelper, getAgeByDateOfBirth } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import placeholder from "../../assets/images/placeholder.png";
import { numbersEnum, orderPrescriptionStrings } from "../../res/strings";
import { useAuth } from "../hooks/useAuth";
import { fecthPatientInformationURL, fetchPatientGeneralInfoURL, fetchSocialHistoryUrl } from "../../res/endpointurls";

const styles = {
  menue_reset_blue: {
    backgroundColor: "#004FD4",
    borderRadius: "10px",
    color: "#fff",
  },
  menue_reset_default: {
    // backgroundColor: "#004FD4",
    // borderRadius: "10px",
    border: "1px solid #d9e8ec",
    borderRadius: "10px",
    color: "#336383",
  },
};

const PatientStaticProfileHeader = (props) => {
  const userPermissions = useSelector((state) => state.UserPermissionData.permissionsMap);
  const user  = props?.user || require("../hooks/useAuth")?.user;
  let { patientId } = useParams();
  const dispatch = useDispatch();
  // const isPatientEdited = useSelector(
  //   (state) => state.isPatientEdited.isPatientEdited
  // );
  const viewRef = useRef();
  const [isPatientInformationFetched, setIsPatientInformationFetched] =
    useState(true);
  
  const [patientInformation, setPatientInformation] = useState();
  const [isSmoker, setIsSmoker] = useState(2);
  const emergencyContactNumber = patientInformation?.communicationNumbers?.emergencyContact?.find((numberItem) => {
    return numberItem?.phoneNumber !== "";
  })?.phoneNumber || "";

  // let patientInformation = patientInformation?.name[0];
  // const emergencyContactNumber = patientInformation?.primaryTelephone;


  const [anchorEl, setAnchorEl] = React.useState(null);
  const fecthPatientInformation = async () => {
    props?.setIsPatientNotFound(false);
    setIsPatientInformationFetched(true);

    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/patients/${patientId !== undefined ? patientId:props?.patientId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPatientInformation(httpResponse.data);
      console.log("httpResponse.data: ",httpResponse.data);

    }
    else {
      if (httpResponse.status === 404) {
        props?.setIsPatientNotFound(true);
      }
    }
    setIsPatientInformationFetched(false);
  };


  // const setSmokerStatus = (socialHistory) => {
  //   if (socialHistory != undefined && socialHistory != null && socialHistory != "" && socialHistory?.length > 0) {
  //     const smokingQuestion = socialHistory.find((socialHistory) => (socialHistory?.question?.questionId === 1));
  //     const smokeValue = smokingQuestion?.answer?.length > 0 ? (smokingQuestion?.answer[0]) : null;
  //     if (smokeValue !== null) {
  //       //Value greater than 2 means the patient is a smoker
  //       if (smokeValue > 2) {
  //         setIsSmoker(1);
  //       }
  //       else {
  //         setIsSmoker(0);
  //       }
  //     }
  //     else {
  //       setIsSmoker(-1);
  //     }
  //   }
  // };

  useEffect(() => {
    console.log("props?.patientId: ",props?.patientId);
    fecthPatientInformation(props?.patientId);
    console.log("useefect called======================================");
  }, [props?.patientId, patientId]);
  return (
    <>
     

      <div
        ref={viewRef}

        className="d-flex py-3 px-4 justify-content-between bg-white">
        <div className="d-flex">
          {isPatientInformationFetched === true ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar
              src={
                patientInformation?.profilePicture !== undefined
                  ? patientInformation?.profilePicture
                  : placeholder
              }
            ></Avatar>
          )}
          <div>
            {isPatientInformationFetched === true ? (
              <Skeleton variant="text" width="80px" />
            ) : (
              isPatientInformationFetched === false && (
                <div className="px-2 mt-1">
                  <h3 className="font-16 m-0 p-0 fw-sb align-self-center" style={{ lineHeight: '16px' }}>
                    {`${patientInformation?.firstName || ''} ${patientInformation?.middleName !== null ? patientInformation?.middleName : ''} ${patientInformation?.lastName || ''}`}
                  </h3>
                  <div className="d-flex gap-2">
                    {isPatientInformationFetched ? (
                      <Skeleton variant="text" height="50px" />
                    ) : (<div className="d-flex gap-2">
                      <div className="font-14 text-center fw-thin text--secondary">
                        {"PID: "} {patientInformation?.patientTenantIdentification}
                      </div>
                      <div className="border--right">&nbsp;</div>
                      <div className="font-14 text-center fw-thin text--secondary">
                        {patientInformation?.gender ?? "N/A"} &ndash;{" "}
                        {getAgeByDateOfBirth(patientInformation?.dob) +
                          " years"}
                      </div>
                      <div className="border--right">&nbsp;</div>

                      <div className="font-14 text-center fw-thin text--secondary">
                        {"DOB: " +
                          calenderDateTimeHelper(patientInformation?.dob, "MMM DD, YYYY")}
                      </div>
                      {
                        isSmoker === 1 && (
                          <div className={`border--right`}>&nbsp;</div>
                        )
                      }

                      {patientInformation?.RecentEncounters?.length > 0 ?
                        <>
                          <div className="font-14 text-center fw-thin text--secondary">
                            {patientInformation?.RecentEncounters?.length > 0
                              ? patientInformation?.RecentEncounters[
                                patientInformation?.RecentEncounters?.length - 1
                              ]?.patientType
                              : ""}
                          </div>
                          {
                            isSmoker === 1 && (
                              <div className={`border--right`}>&nbsp;</div>
                            )
                          }
                        </>
                        : null}

                      {emergencyContactNumber !== undefined && emergencyContactNumber !== '' ? <>
                        <div className="border--right">&nbsp;</div>
                        <div className="font-14 text-center fw-thin text--secondary">
                          Emergency Contact:{" "}
                          <span className="text--terciary fw-sb">
                            {formatPhoneNumber(emergencyContactNumber)}
                          </span>
                        </div>
                      </> : null}


                    </div>)}
                  </div>
                  {/* <h4>{patientInformation?.HumanPatient?.PatientId}</h4> */}
                </div>
              )
            )}
          </div>

        </div>
        {/* <div className="d-flex gap-2 align-items-center">
          {
            // isLoadingFromSession && (
            <div
              // hidden={
              //   user?.role?.toLowerCase() === "practiceadmin"
              //     ? false
              //     : true
              // }
              hidden={userPermissions?.rpp === false ? true : false}
            >
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className={`ms-4 `}
                aria-label="more"
                sx={
                  open
                    ? styles.menue_reset_blue
                    : styles.menue_reset_default
                }
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                dense
                onClose={handleCloseMenue}
                disableScrollLock={true}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  dense
                  onClick={() => {
                    handleCloseMenue();
                    initiatePasswordReset();
                  }}
                  sx={{ fontFamily: "Montserrat" }}
                >
                  Reset Password
                </MenuItem>
              </Menu>
            </div>
            // )
          }

          {!isPatientInformationFetched ? (userPermissions?.spa === true || userPermissions?.orx === true ||
            userPermissions?.apa === true || userPermissions?.olp === true) && (
              <PrescriptionAndAppointmentDropdown
                handleNewAppointment={handleNewAppointment}
                handleNewPrescription={handleNewPrescription}
                handleLabRequisitionModal={handleLabRequisitionModal}
                handleImagingOrderModal={handleImagingOrderModal}
                patientId={props.patientId !== undefined ? props.patientId : patientId}
              />
            ) : (
            <Skeleton width={180} animation="wave" />
          )}
        </div> */}

      </div>
      {console.log("patientInformation: ",patientInformation)}
     

      {/* {console.log("patientId: ",patientId)} */}
    </>
  );
};

export default PatientStaticProfileHeader;
