import { useState, useEffect } from "react";

import { numbersEnum, order_strings } from "../../res/strings";
import SignatureCanvas from "react-signature-canvas";
import classes from "./OrderPrescription.module.css";
import SearchMedication from "../UI/General/SearchMedication";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
  getDMYformate,
} from "../../utils/dateTimeHelper";
import SigSelection from "./SigSelection";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import {
  setDirtyBitInStore,
  setDisableSendRxButton,
  setPrescribedMedication,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import { useParams } from "react-router-dom";
import OrderMedicationItem from "./OrderMedicationItem";
import { reusable_strings, orderPrescriptionStrings } from "../../res/strings";
import {
  fieldValidation,
  formatPhoneNumber,
  formatPostalCode,
  pharmacySpecialty,
  // rxFillIndicatorLov,
} from "../../utils/helpers/HelperFunctions";
import PrescriptionList from "./PrescriptionList";
import SearchPharmacy from "../UI/General/SearchPharmacy";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../UI/Reusable/PnComponents";
import { useAuth } from "../hooks/useAuth";
import {
  acceptRenewalRequestUrl,
  fetchCurrentMedicationURL,
  fetchPatientObservationsURL,
  providerSettingSignaturesUrl,
} from "../../res/endpointurls";

import { featureFlagMap } from "../../utils/helpers/ConfigMap";
import { WarningIcon } from "../UI/Reusable/WarningIcon";
import { useFlags } from "launchdarkly-react-client-sdk";

const rxRenewalResponseApiCall = async (
  userId,
  taskId,
  action,
  prescription,
  spi
) => {
  const body = {
    taskId: taskId,
    action: action,
    prescription: prescription,
  };
  const httpResponse = await sendHttpRequest({
    url: acceptRenewalRequestUrl(userId, spi),
    method: "POST",
    data: body,
  });
  return httpResponse;
};

const OrderPrescription = (props) => {
  const { minorPatientVitalCheckSoapNote, soapNoteMinorVitalCheck } = useFlags();
  const _str = order_strings.en;
  const _numbString = numbersEnum.en;
  const __order_prescription_str__ = orderPrescriptionStrings.en;
  const { user } = useAuth();
  const dispatch = useDispatch();
  const prescribedMedications = useSelector(
    (state) => state.PrescribedMedication.prescribedDrugList
  );
  const patientPharmacy = useSelector(
    (state) => state.UpdateEncounter?.Encounter?.Pharmacy
  );
  const drawerState = useSelector((state) => state.DrawerState);
  const disableSendRxButton = useSelector(
    (state) => state.DisableSendRxButton.disabled
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment?.encounter
  );
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const featureFlags = featureFlagMap();

  const [medicationExpanded, setMedicationExpanded] = useState("medication");
  const [pharmacyExpanded, setPharmacyExpanded] = useState("");
  const [summaryExpanded, setSummaryExpanded] = useState("");

  const [sig, setSig] = useState([]);
  const [medicineName, setMedicineName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState("");
  const [prescribedList, setPrescribedList] = useState([]);
  const [ndc, setNdc] = useState("");
  const [ddid, setDDID] = useState("");
  const [sigTextValue, setSigTextValue] = useState("");
  const [selectedSig, setSelectedSig] = useState();
  const [isLoadingdc, setIsLoadingdc] = useState(true);
  const [durCheckData, setDurCheckData] = useState([]);
  const [durCheckShow, setDurCheckShow] = useState(false);
  let { providerId, encounterId, patientId } = useParams();
  const [updateCheck, setUpdateCheck] = useState(false);
  const [patientData, setPatientData] = useState();
  const [providerData, setProviderData] = useState();
  const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);
  const [isProviderDataLoading, setIsProviderDataLoading] = useState(false);
  const [patientObservations, setPatientObservations] = useState("");
  const [filename, setFilename] = useState(null);
  const [minorPatientError, setMinorPatientError] = useState("");


  const [drugData, setDrugData] = useState({
    DDID: "",
    drugDescription: "",
    Sig: {
      SigText: "",
    },
    Quantity: "",
    daySupply: "",
    NumberOfRefills: 0,
    RefillAsNeeded: false,
    DispenseAsWritten: false,
    Note: "",
    DoseForm: "",
    PrescriptionStartDate: {
      Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
    },
    EarliestFillDate: {
      Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
    },
    WrittenDate: {
      Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
    },
    alertPhysician72: false,
    PatientDirections: "",
    customSigFlag: false,
  });
  const [availableStrengths, setAvailableStrengths] = useState([]);
  const [isAvailablestrengthsLoading, setIsAvailablestrengthsLoading] =
    useState(false);
  const [orderPharmacy, setOrderPharmacy] = useState();
  const [preferredPharmacy, setPreferredPharmacy] = useState();
  const [preferredPharmacyFlag, setPreferredPharmacyFlag] = useState(false);
  const [pharmacyId, setPharmacyId] = useState(-1);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [rxSuccessMessage, setRxSuccessMessage] = useState("");
  const [rxMessageSeverity, setRxMessageSeverity] = useState("");
  // const [rxFillIndicator, setRxFillIndicator] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [rxConsent, setRxConsent] = useState(true);
  const [prescriberFacility, setPrescriberFacility] = useState();
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [constrolSubstanceCount, setConstrolSubstanceCount] = useState([]);
  let rxMedicationsList = patientEncounterInformation?.encounter?.medicationRx;
  const [rxMedications, setRxMedications] = useState(rxMedicationsList || []);
  const [addPharmacyBtnText, setAddPharmacyBtnText] = useState("Add Pharmacy");
  const [isPreferredPharmacyLoading, setIsPreferredPharmacyLoading] =
    useState(false);
  const [deleteMedId, setDeleteMedId] = useState();
  const [sigPad, setSigPad] = useState();

  const [currentMedication, setCurrentMedication] = useState([]);
  const [orderType, setOrderType] = useState(
    featureFlags.get("surescript") === "true" ? "ePrescription" : "faxRx"
  );
  const [rxButtonText, setRxButtonText] = useState(props?.referenceFrom ? "Proceed" : "Send Rx");
  // const [orderType, setOrderType] = useState();
  const [reOrderPharmacyFlag, setReOrderPharmacyFlag] = useState(false);
  const [reOrderPharmacy, setReOrderPharmacy] = useState([]);

  const prescribeDrug = async (selectedDrug) => {
    setIsLoading(true);

    // console.log("selectedDrug", selectedDrug);
    setSelectedMedicine(selectedDrug);
    setSigTextValue("");
    setDDID(selectedDrug.drugDbCode);

    const httpResponse = await sendHttpRequest({
      url: `/drugs/${selectedDrug.drugDbCode}/sig`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setIsLoading(false);
      setSig(p => httpResponse.data.concat([{ textValue: "Enter sig manually", drugOrderId: -1 },]));

      if (updateCheck === true) {
        setDrugData({
          ...drugData,
          DDID: selectedDrug.drugDbCode,
          drugDbCode: selectedDrug.drugDbCode,
          drugDescription: selectedDrug.drugDescription,
          customSigFlag:
            selectedDrug?.customSigFlag !== undefined
              ? selectedDrug?.customSigFlag
              : false,
          ...(selectedDrug?.ndc && { ndc: selectedDrug?.ndc }),
        });
      } else {
        setDrugData({
          drugNameCode: httpResponse.data.drugNameCode,
          DDID: selectedDrug.drugDbCode,
          drugDbCode: selectedDrug.drugDbCode,
          drugDescription: selectedDrug.drugDescription,
          Sig: {
            SigText: "",
          },
          Quantity: "",
          daySupply: "",
          NumberOfRefills: props?.replaceRequest === true ? 1 : 0,
          RefillAsNeeded: false,
          DispenseAsWritten: false,
          Note: "",
          DoseForm: "",
          PrescriptionStartDate: {
            Date: getDMYformate(new Date(), "YYYY-MM-DD"),
          },
          EarliestFillDate: {
            Date: getDMYformate(new Date(), "YYYY-MM-DD"),
          },
          WrittenDate: {
            Date: getDMYformate(new Date(), "YYYY-MM-DD"),
          },
          alertPhysician72: false,
          PatientDirections: "",
          ...selectedDrug,
          customSigFlag:
            selectedDrug?.customSigFlag !== undefined
              ? selectedDrug?.customSigFlag
              : false,
          ...(selectedDrug?.ndc && { ndc: selectedDrug?.ndc }),
        });
      }

      setSelectedSig("");
    } else {
      // console.log("else part of sig selection ");
      setIsLoading(false);
      setDrugData({
        ...drugData,
        ...selectedDrug,
        DDID: selectedDrug.drugDbCode,
        ...(selectedDrug?.ndc && { ndc: selectedDrug?.ndc }),
      });
      setSig(p => [{ textValue: "Enter sig manually", drugOrderId: -1 }]);
      setIsError(true);
      // console.log("Something went wrong...");
    }
    setMedicineName("");
    setIsLoading(false);
    setIsError(false);
  };

  const fetchPatientData = async (userId, setUserData, setIsDataLoading) => {
    setIsDataLoading(true);
    // setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: "/patient/information",
      method: "GET",
      params: {
        patientId: userId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setUserData(httpResponse.data);
      await fetchPatientObservations(userId);
    }
    setIsDataLoading(false);
  };

  const fetchProviderData = async (userId, setUserData, setIsDataLoading) => {
    setIsDataLoading(true);
    // setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${userId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setUserData(httpResponse.data);
    }
    setIsDataLoading(false);
  };

  const fetchAvailableStrengths = async (drugItem) => {
    setIsAvailablestrengthsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/drugs/${drugItem.drugDbCode}/available-strengths`,
      method: "GET",
    });
    if (!httpResponse.error) {
      if (props?.replaceRequest === true && httpResponse.data?.length > 0) {
        httpResponse.data.forEach((medItem) => {
          if (medItem?.drugDbCode === prescribedMedications[0]?.drugDbCode) {
            let prescribedMedication = prescribedMedications[0];
            prescribedMedication.drugDescription = medItem?.drugDescription;
            updatePrescribedMedication([prescribedMedication]);
          }
        });
      }
      let data = httpResponse.data
      // setDrugData({...drugData,drugNameCode:data[0].drugNameCode})
      setAvailableStrengths(data);
    } else {
      setAvailableStrengths([]);
    }
    setIsAvailablestrengthsLoading(false);
  };

  const fetchPreferredPharmacy = async () => {
    setIsPreferredPharmacyLoading(true);
    let patientIdValue = props?.patientId;
    const httpResponse = await sendHttpRequest({
      url: "/patient/pharmacy",
      method: "GET",
      params: {
        patientId: props?.newPrescriptionRequest
          ? patientIdValue
          : props?.reOrderPrescription
            ? patientIdValue
            : patientId,
      },
    });
    if (!httpResponse.error) {
      setIsPreferredPharmacyLoading(false);
      if (
        httpResponse.data !== undefined &&
        httpResponse.data?.standardizedOrganizationName?.length > 0 &&
        featureFlags.get("surescript") === "true"
      ) {
        setPreferredPharmacy(httpResponse.data);
        if (
          props?.reOrderPrescription === true &&
          httpResponse.data?.organizationId ===
          props?.orderPharmacy?.organizationId
        ) {
          // console.log("I'm in if setReOrderPharmacy");
          setPreferredPharmacyFlag(true);
          setReOrderPharmacyFlag(true);
          setReOrderPharmacy([]);
        } else if (props?.reOrderPrescription === true) {
          // console.log("I'm in if else setReOrderPharmacy");
          setPreferredPharmacyFlag(false);
          setReOrderPharmacyFlag(true);
          if (props?.orderPharmacy !== undefined) {
            setReOrderPharmacy([props?.orderPharmacy]);
          }
        }
        return true;
      } else {
        if (props?.reOrderPrescription === true) {
          // console.log("I'm in else setReOrderPharmacy");
          setPreferredPharmacyFlag(false);
          setReOrderPharmacyFlag(true);
          setReOrderPharmacy(props?.orderPharmacy ?? []);
        }
        return false;
      }
    }
    setIsPreferredPharmacyLoading(false);
    return false;
  };


  const durCheckHandler = async () => {
    if (updateCheck === true) {
      deleteMedFromPrescriptionList(deleteMedId);
    }
    addMedicationToList();
    setIsLoadingdc(true);
    const httpResponse = await sendHttpRequest({
      url: "/drugInteractionChecks",
      method: "POST",
      data: {
        // ndc: "00115981101",
        ddid: ddid,
        // EncounterId: '50',
        EncounterId: encounterId || props?.encounterId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data.length);
      if (httpResponse.data.length > 0) {
        setDurCheckData(httpResponse.data);
        setDurCheckShow(true);
      } else {
        // addMedicationToList(); temporarily commented
      }
    } else {
      setIsError(true);
      console.log("Something went wrong...");
    }
    setIsLoadingdc(false);
    setIsError(false);
  };
  // console.log(providerData);
  const handleMedicationData = () => {
    // console.log("prescribedMedications[i]", prescribedMedications[0]);

    let Data = [];
    for (let i = 0; i < prescribedMedications.length; i++) {
      // console.log("prescribedMedications[i]: ",prescribedMedications[i]);
      let sigText = "";
      if (
        prescribedMedications[i].textValue !== undefined &&
        prescribedMedications[i].textValue !== null
      ) {
        sigText += prescribedMedications[i].textValue.trim();
      }
      sigText += " " + prescribedMedications[i].PatientDirections?.trim();
      Data.push({
        // alerts: prescribedMedications[i].alerts,
        // Medication: {
        drugDescription: prescribedMedications[i].drugDescription,
        ndc: prescribedMedications[i].ndc,
        strengthValue: prescribedMedications[i].strengthValue,
        // strengthFormCode: prescribedMedications[i].strengthForm,
        strengthUnitOfMeasureCode: prescribedMedications[i].strengthUomCode,
        drugId: prescribedMedications[i].drugDbCode,
        quantity: parseFloat(
          Number(prescribedMedications[i].Quantity).toFixed(3)
        ),
        quantityUnitOfMeasureCode: prescribedMedications[i].quantityUomCode !== undefined ? prescribedMedications[i].quantityUomCode : prescribedMedications[i].quantityUnitOfMeasureCode,
        quantityUnitOfMeasure: prescribedMedications[i].quantityUom,
        daysSupply: prescribedMedications[i].daySupply,
        sigText: sigText,
        Sig: { SigText: sigText },
        textValue: sigText,
        refills: prescribedMedications[i].NumberOfRefills,
        prescriptionStartDate: calenderDateTimeHelper(
          prescribedMedications[i]?.PrescriptionStartDate?.Date,
          "YYYY-MM-DD"
        ),
        earliestFillDate: calenderDateTimeHelper(
          prescribedMedications[i]?.EarliestFillDate?.Date,
          "YYYY-MM-DD"
        ),
        pharmacyNote: prescribedMedications[i].Note,
        alertPhysician: prescribedMedications[i].alertPhysician72,
        substitution: prescribedMedications[i].DispenseAsWritten,
        pharmacy: {
          organizationId: pharmacyId,
        },
        customSigFlag: prescribedMedications[i]?.customSigFlag ?? false,
        drugOrderId: prescribedMedications[i]?.customSigFlag === true ? "-1" : "",
        workflowType: orderType === "ePrescription" ? 'SURESCRIPTS' : orderType === "faxRx" ? 'FAX' : 'PRINT',
        highDoseForm: prescribedMedications[i]?.highDoseForm ?? "",
        highFrequency: prescribedMedications[i]?.highFrequency ?? "",
        // },
        // IsMailOrder: false, // default value due to new design
      });
    }
    return Data;
  };
  const handleUpdatePrescribeMedications = () => {
    if (props?.reOrderPrescription === true) {
      dispatch(updatePrescribedMedication([...prescribedMedications.slice(1,)]));
    } else {
      dispatch(updatePrescribedMedication([]));
    }
  }
  const handleSendRx = async () => {
    handleToggle();

    let medicationData = handleMedicationData();
    // console.log("medicationData", medicationData);
    let endPointUrl = "/new-prescription";
    let dataObject = {
      pharmacyOrganisationId: pharmacyId,
      medicationRx: medicationData,
      patientId: props?.patientId !== undefined ? props?.patientId : patientId,
      providerId: user?.userId,
      tenantId: user?.tenantId,
      spi: user?.spi,
      prescriptionReferenceId: props?.newPrescriptionRequest === true ? (props?.assessmentPlan === true ? props?.prescriptionRefId : props?.referenceFrom === "provider-note" ? props?.prescriptionReference : null) : props?.prescriptionRefId,
    };

    let optionsObject = {}

    if (props?.editMedicationModeFromProviderNote !== undefined && props?.editMedicationModeFromProviderNote === true) {
      if (props?.providerNote) {
        optionsObject = {
          url: endPointUrl,
          method: 'PUT',
          data: dataObject,
        }
      }
    }
    else {
      optionsObject = {
        url: endPointUrl,
        method: 'POST',
        data: dataObject,
      }
    }

    dispatch(setDisableSendRxButton(true));
    const httpResponse = await sendHttpRequest(optionsObject);
    if (!httpResponse.error) {
      // console.log(httpResponse.data);

      //The following piece of code is used to maintain the summary view for VeeOneHealth --- START
      if (props?.setCurrentPrescriptionData) {
        props?.setCurrentPrescriptionData(dataObject);
      }
      //---END---

      setOrderPharmacy();
      fetchPrescribedMedication();
      // updatePharmacyToSession();
      setPrescribedList([]);
      handleUpdatePrescribeMedications();
      handleBackdropClose();
      setRxSuccessMessage(__order_prescription_str__.eprescribe_msg);
      setRxMessageSeverity("success");
      if (props.newPrescriptionRequest === true) {
        props?.setIsPrescriptionSent(Math.random());
        if (props?.referenceFrom) {
          props?.handleSetIsSent(false);
        }
        else {
          props?.handleSetIsSent(true);
        }
        props?.handleNewPrescriptionModal();
        if (props?.setCallApi !== undefined && props?.setCallApi !== null) {
          props?.setCallApi(prev => !prev);
        }
      } else {
        setIsSent(true);
      }
      setPharmacyExpanded(false);
      setSummaryExpanded("medication");
    } else {
      // setRxSuccessMessage(httpResponse.errorMessage?.error);
      setRxSuccessMessage(
        "We experienced an error processing your request. Please review the script before retrying once again."
      );
      setRxMessageSeverity("error");
      dispatch(setDisableSendRxButton(false));
      setIsSent(true);
    }
    setRxConsent(true);
    // setRxConsent(true);
    let rxConsentRef = document.getElementById("orderConsent");
    if (rxConsentRef !== null) {
      rxConsentRef.checked = false;
    }
    if (props?.reOrderPrescription === true && !httpResponse.error) {
      props?.handleNewPrescriptionModal();
      props?.fetchPrescriptionHistory();
    }
    handleBackdropClose();
  };


  const fetchPrecriberFaiclity = async () => {
    setIsFacilityLoading(true);
    let pId = patientId ?? props?.patientId
    let url = `/provider-tenant-practice-locations/for-prescription/?patientId=${pId}&tenantId=${user?.tenantId}&providerId=${user?.userId}`
    // let url = `/prescription-references/${props?.prescriptionRefId}/provider-tenant-practice-location/for-prescription`
    if (props?.prescriptionRefId !== undefined && encounterId === props?.prescriptionRefId) {
      url = `/prescription-references/${props?.prescriptionRefId}/provider-tenant-practice-location/for-prescription`
    }
    const httpResponse = await sendHttpRequest({
      url: url,
      method: "GET",
      // params: {
      //   prescriberId: providerId,
      // },
    });
    console.log("httpResponse", httpResponse);

    if (!httpResponse.error) {
      setPrescriberFacility(httpResponse.data)
    }
    setIsFacilityLoading(false);
  };

  const getPharmacyFromStore = async () => {
    if (
      patientPharmacy !== undefined &&
      patientPharmacy?.standardizedOrganizationName?.length > 0
    ) {
      setPharmacyId(patientPharmacy?.organizationId);
      setOrderPharmacy(patientPharmacy);
      setAddPharmacyBtnText("Update Pharmacy");
    }

    return true;
  };

  const changeOrderType = (event) => {
    // console.log(event.target.id);
    setOrderType(event.target.id);
    if (props?.referenceFrom === "provider-note") {
      setRxButtonText("Proceed");
      return;
    }
    else if (event.target.id === "printRx") {
      setRxButtonText("Print Rx");
    } else {
      setRxButtonText("Send Rx");
    }
  };

  const fetchPrescribedMedication = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/encounter/${encounterId}/medicationRx`,
      method: "GET",
    });
    // console.log("httpResponse", httpResponse);
    if (!httpResponse.error) {
      // console.log("medicationRx: ", httpResponse.data);
      if (httpResponse.data !== null && httpResponse.data !== undefined) {
        setRxMedications(httpResponse.data);
      } else {
        setRxMedications([]);
      }

    }
  };

  // Handlers
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
  };

  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };
  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const addPrescriptionHandler = () => {
    setReOrderPharmacyFlag(false)
    durCheckHandler();
    // addMedicationToList();
  };

  function clearStates() {
    setOrderPharmacy();
    fetchPrescribedMedication();
    // updatePharmacyToSession();
    setPrescribedList([]);
    handleUpdatePrescribeMedications();
    handleBackdropClose();
    props?.setIsPrescriptionSent(Math.random());
    props?.handleSetIsSent(true);
    props?.handleNewPrescriptionModal();
  }

  const handleSigSelection = (event) => {
    const drugOrderId = event.target.id;
    let highDoseForm = event.target.getAttribute("highdoseform");
    let highFrequency = event.target.getAttribute("highfrequency");
    console.log("HighDoseForm and HighFrequency: ", highDoseForm, highFrequency);
    const selectSig = {
      ...sig
        .filter((s) => parseInt(s.drugOrderId) === parseInt(drugOrderId))
        .pop(),
    };
    // console.log("selectSig: ",{...selectSig});
    setSigTextValue(event.target.value);
    setSelectedSig(selectSig);
    setDrugData(p => ({
      ...drugData,
      ...selectSig,
      Sig: {
        SigText: drugOrderId !== "-1" ? event.target.value : "",
      },
      textValue: drugOrderId !== "-1" ? event.target.value : "",
      DoseForm: selectSig.doseForm,
      highDoseForm,
      highFrequency,
      customSigFlag: drugOrderId !== "-1" ? false : true,
      drugOrderId: event.target.id,
      // daySupply: selectSig.DaySupply,
    }));
  };


  const handleSigText = (event) => {
    setSigTextValue(event.target.value);
    if (selectedSig) {
      setSelectedSig({ ...selectedSig, TextValue: event.target.value });
    } else {
      setSelectedSig({ TextValue: event.target.value });
    }
    setDrugData({
      ...drugData,
      Sig: {
        SigText: event.target.value,
      },
    });
  };


  const addMedicationToList = (alerts) => {
    // console.log("updateCheck: ", updateCheck);
    if (prescribedMedications?.length > 0 && updateCheck === false) {
      let result = prescribedMedications.filter((medicine) => {
        return medicine.drugDbCode !== selectedMedicine?.drugDbCode;
      });
      console.log("result+++++++++++++++++++++++++++++++: ", result);
      // console.log("selectedMedicine: ", selectedMedicine);
      dispatch(updatePrescribedMedication(result));
    }
    if (props?.replaceRequest === true) {
      // handleContinueToSummary("summary");
      handleContinueToPharmacy("pharmacy");
    }
    let tempMedication = {};
    if (alerts !== undefined) {
      tempMedication = {
        // ...selectedMedicine,
        ...drugData,
        alerts: [alerts],
      };
    } else {
      tempMedication = {
        // ...selectedMedicine,
        ...drugData,
        alerts: [],
      };
    }
    // console.log("tempMedication", tempMedication);
    if (
      tempMedication?.controlledSubstanceCode !== "" &&
      tempMedication?.controlledSubstanceCode !== "1" &&
      tempMedication?.controlledSubstanceCode !== "0" &&
      tempMedication?.controlledSubstanceCode !== undefined
    ) {
      // console.log("Control substance count: ",tempMedication?.controlledSubstanceCode);
      constrolSubstanceCount.push(tempMedication?.controlledSubstanceCode);
    }
    setPrescribedList([...prescribedList, tempMedication]);
    // console.log("[...prescribedMedications,tempMedication]",[...prescribedMedications,{drugData:tempMedication}])
    dispatch(setPrescribedMedication({ ...tempMedication }));
    setSelectedMedicine("");
    setSelectedSig({});
    setDrugData({
      DDID: "",
      drugDescription: "",
      Sig: {
        SigText: "",
      },
      Quantity: "",
      daySupply: "",
      NumberOfRefills: 0,
      RefillAsNeeded: false,
      DispenseAsWritten: false,
      Note: "",
      DoseForm: "",
      PrescriptionStartDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      EarliestFillDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      WrittenDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      alertPhysician72: false,
      PatientDirections: "",
      customSigFlag: false,
    });
    setUpdateCheck(false);
  };


  const handleCancelMedication = () => {
    setReOrderPharmacyFlag(false);
    setAvailableStrengths([]);
    setSig([]);
    setSelectedMedicine("");
    setSelectedSig({});
    setDrugData({
      DDID: "",
      drugDescription: "",
      Sig: {
        SigText: "",
      },
      Quantity: "",
      daySupply: "",
      NumberOfRefills: 0,
      RefillAsNeeded: false,
      DispenseAsWritten: false,
      Note: "",
      DoseForm: "",
      PrescriptionStartDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      EarliestFillDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      WrittenDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      alertPhysician72: false,
    });
    setUpdateCheck(false);
  };


  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === "medication") {
      setMedicationExpanded(newExpanded ? panel : false);
    } else if (panel === "pharmacy") {
      setPharmacyExpanded(newExpanded ? panel : false);
    } else if (panel === "summary") {
      setSummaryExpanded(newExpanded ? panel : false);
    }
  };
  const handlePreferredPharmacyFlag = (event) => {
    setPreferredPharmacyFlag(event.target.checked);
    if (event.target.checked === false) {
      setPharmacyId(-1);
    } else {
      // setPharmacyId(event.target.id);
      // setPharmacyToSession(preferredPharmacy)
      let allCheckboxRef = document.getElementsByClassName("selectedPharmacy");
      if (allCheckboxRef !== undefined) {
        for (let i = 0; i < allCheckboxRef.length; i++) {
          allCheckboxRef[i].checked = false;
        }
      }
    }
  };
  const closeMedicationAccordion = () => {
    setMedicationExpanded(false);
  };
  const handleContinueToPharmacy = () => {
    closeMedicationAccordion();
    setPharmacyExpanded("pharmacy");
  };
  const handleContinueToSummary = () => {
    // if(props?.replaceRequest === true){
    //   addPrescriptionHandler();
    // }
    setPharmacyExpanded(false);
    setSummaryExpanded("summary");
    // else {
    //   setPharmacySelectionError("Please select a pharmacy");
    // }
  };
  const handleRxConsent = (event) => {
    setRxConsent(!event.target.checked);
  };
  const clearDrugData = () => {
    setDrugData({
      // ...drugData,
      Sig: {
        SigText: "",
      },
      Quantity: "",
      daySupply: "",
      NumberOfRefills: 0,
      RefillAsNeeded: false,
      DispenseAsWritten: false,
      Note: "",
      DoseForm: "",
      PrescriptionStartDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      EarliestFillDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      WrittenDate: {
        Date: dateTimeHelper(new Date(), "YYYY-MM-DD"),
      },
      alertPhysician72: false,
    });
  };

  let firstPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(fetchPreferredPharmacy());
    });
  };
  let secondPromise = () => {
    return new Promise((resolve, reject) => {
      resolve(getPharmacyFromStore());
    });
  };

  const fetchPharmacyData = async () => {
    let promise = await Promise.all([firstPromise(), secondPromise()]);

    if (promise.length === 2) {
      if (orderPharmacy === undefined && promise[0] === true) {
        // console.log("I'm in undefined part");
        setPreferredPharmacyFlag(true);
        setPharmacyId(preferredPharmacy?.organizationId);
      } else {
        if (
          orderPharmacy !== undefined &&
          preferredPharmacy !== undefined &&
          orderPharmacy?.organizationId === preferredPharmacy?.organizationId
        ) {
          // console.log("I'm in organizationId part");
          setPharmacyId(preferredPharmacy?.organizationId);
          setPreferredPharmacyFlag(true);
        } else {
          // console.log("I'm in else part");
          setPreferredPharmacyFlag(false);
        }
      }
    }
  };

  const deleteMedFromPrescriptionList = (ddid) => {
    // console.log("DELETE ID", ddid);
    let result = prescribedMedications.filter((medicine) => {
      return medicine.drugDbCode !== ddid;
    });

    if (result.length === 0) {
      dispatch(updatePrescribedMedication([]));
    } else {
      dispatch(updatePrescribedMedication([...result]));
    }
    setDeleteMedId("");
  };

  const fetchPatientObservations = async (patientId) => {
    const httpResponse = await sendHttpRequest({
      url: fetchPatientObservationsURL(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setMinorPatientError(undefined);
      setPatientObservations(httpResponse.data);
    } else {
      if (minorPatientVitalCheckSoapNote === true && props?.soapNoteKey === true && props?.vitals !== undefined && props?.vitals?.height !== undefined && props?.vitals?.weight !== undefined) {
        setMinorPatientError(undefined)
      } else {
        setMinorPatientError(httpResponse?.errorMessage?.message);
      }
    }
  };

  const handleReplaceRequest = async () => {
    handleToggle();
    // console.log(prescribedMedications);
    let prescription = {
      drugDescription: prescribedMedications[0]?.drugDescription,
      ndc: prescribedMedications[0]?.ndc,
      strengthValue: prescribedMedications[0]?.strengthValue,
      strengthUnitOfMeasureCode: prescribedMedications[0].strengthUomCode,
      drugId: prescribedMedications[0].drugDbCode,
      quantity: prescribedMedications[0].Quantity,
      quantityUnitOfMeasureCode:
        prescribedMedications[0].quantityUnitOfMeasureCode !== undefined
          ? prescribedMedications[0].quantityUnitOfMeasureCode
          : prescribedMedications[0].quantityUomCode,
      quantityUnitOfMeasure: prescribedMedications[0].quantityUom,
      daysSupply: prescribedMedications[0].daySupply,
      sigText: prescribedMedications[0].Sig?.SigText,
      refills: prescribedMedications[0].NumberOfRefills,
      pharmacyNote: prescribedMedications[0].Note,
      alertPhysician: prescribedMedications[0].alertPhysician72,
      substitution: prescribedMedications[0].DispenseAsWritten,
      prescriptionStartDate: calenderDateTimeHelper(
        prescribedMedications[0]?.PrescriptionStartDate?.Date,
        "YYYY-MM-DD"
      ),
      earliestFillDate: calenderDateTimeHelper(
        prescribedMedications[0]?.EarliestFillDate?.Date,
        "YYYY-MM-DD"
      ),
    };
    //Check  Refills

    const response = await rxRenewalResponseApiCall(
      user?.userId,
      props?.task,
      "replaced",
      prescription,
      user?.spi
    );
    // console.log("HTTPResponse==>", response);
    if (response.error === false) {
      props.handleModal();
      props.showMessage();
      props.reloadTasks();
    } else {
      props.showError();
    }
    handleBackdropClose();
  };

  const editMedicine = (drugObject) => {
    setDeleteMedId(drugObject?.DDID);
    setUpdateCheck(true);
    prescribeDrug(drugObject);
    fetchAvailableStrengths(drugObject);
  };

  const fetchPatientEncounter = async () => {
    if (encounterId === undefined) return;
    const httpResponse = await sendHttpRequest({
      url: `/encounter/${encounterId}/resume`,
      method: "GET",
    });
    if (!httpResponse.error) {
      let medicationRx = httpResponse.data?.medicationRx;
      // console.log("httpResponse.data", medicationRx);
      if (medicationRx && medicationRx?.length > 0) {
        setRxMedications(medicationRx);
      }
    }
  };

  const handleFaxRx = async () => {
    // alert("Fax sent")
    handleToggle();

    let medicationData = handleMedicationData();
    let endPointUrl = "/send-fax"; // encounter tabs
    let dataObject = {
      pharmacyOrganisationId: pharmacyId,
      medicationRx: medicationData,
      patientId: props?.patientId !== undefined ? props?.patientId : patientId,
      providerId: user.userId,
      // prescriptionReferenceId: props?.prescriptionRefId ?? props?.prescriptionReference,
      prescriptionReferenceId: props?.newPrescriptionRequest === true ? (props?.assessmentPlan === true ? props?.prescriptionRefId : props?.referenceFrom === "provider-note" ? props?.prescriptionReference : null) : props?.prescriptionRefId,
    };
    if (props?.newPrescriptionRequest === true) {
      // endPointUrl = "/patient/prescription"; // for order only
      endPointUrl = "/send-fax"; // for order only
    }
    dispatch(setDisableSendRxButton(true));
    const httpResponse = await sendHttpRequest({
      url: endPointUrl,
      method: "POST",
      data: dataObject,
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);

      setOrderPharmacy();
      fetchPrescribedMedication();
      // updatePharmacyToSession();
      handleUpdatePrescribeMedications();
      handleBackdropClose();
      setRxSuccessMessage("Rx fax sent successfully");
      setRxMessageSeverity("success");
      if (props.newPrescriptionRequest === true) {
        props?.setIsPrescriptionSent(Math.random());
        if (props?.referenceFrom) {
          props?.handleSetIsSent(false);
        }
        else {
          props?.handleSetIsSent(true);
        }
        props?.handleNewPrescriptionModal();
        if (props?.setCallApi !== undefined && props?.setCallApi !== null) {
          props?.setCallApi(prev => !prev);
        }
      } else {
        setIsSent(true);
      }
      setPharmacyExpanded(false);
      setSummaryExpanded("medication");
    } else {
      // setRxSuccessMessage(httpResponse.errorMessage?.error);
      setRxSuccessMessage(
        "We experienced an error processing your request. Please review the script before retrying once again."
      );
      setRxMessageSeverity("error");
      dispatch(setDisableSendRxButton(false));
      setIsSent(true);
    }
    setRxConsent(true);
    // setRxConsent(true);
    let rxConsentRef = document.getElementById("orderConsent");
    if (rxConsentRef !== null) {
      rxConsentRef.checked = false;
    }
    if (props?.reOrderPrescription === true && !httpResponse.error) {
      props?.handleNewPrescriptionModal();
      props?.fetchPrescriptionHistory();
    }
    handleBackdropClose();
  };
  const handlePrintRx = async () => {
    // alert("Rx printed");
    handleToggle();

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `Rx_${props?.patientId !== undefined ? props?.patientId : patientId
      }`;

    let medicationData = handleMedicationData();
    let endPointUrl = "/print-pdf"; // encounter tabs
    let dataObject = {
      pharmacyOrganisationId: pharmacyId,
      medicationRx: medicationData,
      patientId: props?.patientId !== undefined ? props?.patientId : patientId,
      providerId: user.userId,
      // prescriptionReferenceId: props?.prescriptionRefId ?? props?.prescriptionReference,
      prescriptionReferenceId: props?.newPrescriptionRequest === true ? (props?.assessmentPlan === true ? props?.prescriptionRefId : props?.referenceFrom === "provider-note" ? props?.prescriptionReference : null) : props?.prescriptionRefId,
    };
    if (props.newPrescriptionRequest === true) {
      endPointUrl = "/print-pdf"; // order only
      // endPointUrl = "/patient/prescription";   // order only
    }
    dispatch(setDisableSendRxButton(true));
    const httpResponse = await sendHttpRequest({
      url: endPointUrl,
      method: "POST",
      responseType: "arraybuffer",
      data: dataObject,
    });
    if (!httpResponse.error) {
      //The following piece of code is used to maintain the summary view for VeeOneHealth --- START
      if (props?.setCurrentPrescriptionData) {
        props?.setCurrentPrescriptionData(dataObject);
      }
      //---END---

      // console.log(httpResponse);
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      if (props?.referenceFrom == undefined || props?.referenceFrom == null) {
        link.href = href;
        link.click();
        link.remove();
        URL.revokeObjectURL(href);

      }
      setOrderPharmacy();
      fetchPrescribedMedication();
      // updatePharmacyToSession();
      handleUpdatePrescribeMedications();
      handleBackdropClose();
      // setRxSuccessMessage("Rx will be printed shortly");
      // setRxMessageSeverity("success");
      if (props.newPrescriptionRequest === true) {
        props?.setIsPrescriptionSent(Math.random());
        if (props?.referenceFrom) {
          props?.handleSetIsSent(false);
        }
        else {
          props?.handleSetIsSent(true);
        }
        props?.handleNewPrescriptionModal();
        if (props?.setCallApi !== undefined && props?.setCallApi !== null) {
          props?.setCallApi(prev => !prev);
        }
      }
      //  else {
      //   setIsSent(true);
      // }
      setPharmacyExpanded(false);
      setSummaryExpanded("medication");
    } else {
      // setRxSuccessMessage(httpResponse.errorMessage?.error);
      setRxSuccessMessage(
        "We experienced an error processing your request. Please review the script before retrying once again."
      );
      setRxMessageSeverity("error");
      dispatch(setDisableSendRxButton(false));
      setIsSent(true);
    }
    setRxConsent(true);
    // setRxConsent(true);
    let rxConsentRef = document.getElementById("orderConsent");
    if (rxConsentRef !== null) {
      rxConsentRef.checked = false;
    }
    if (props?.reOrderPrescription === true && !httpResponse.error) {
      props?.handleNewPrescriptionModal();
      props?.fetchPrescriptionHistory();
    }

    handleBackdropClose();
  };

  let medicationData = handleMedicationData();
  let endPointUrl = "/new-prescription";
  let dataObject = {
    pharmacyId: pharmacyId,
    medicationRx: medicationData,
    patientId: props?.patientId !== undefined ? props?.patientId : patientId,
    providerId: user?.userId,
    prescriptionReferenceId: props?.prescriptionRefId ?? null,
  };

  const convertToImage = () => {
    let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
    // console.log(trimmedDataURL);
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "signature.png", {
          type: mimeType,
        });
        setFilename(file);
      });
  };

  const saveSignature = async () => {
    if (
      providerData?.providerSetting === undefined ||
      providerData?.providerSetting === "" ||
      providerData?.providerSetting === null ||
      providerData?.providerSetting?.signaturePath === undefined ||
      providerData?.providerSetting?.signature === null ||
      providerData?.providerSetting?.signature === undefined
      // providerData?.providerSetting?.signaturePath === "" ||
      // providerData?.providerSetting?.signaturePath === null
    ) {
      const fd = new FormData();
      if (filename !== null) {
        fd.append("file", filename);
        fd.append("printedRx", true);
      }
      const httpResponse = await sendHttpRequest({
        url: providerSettingSignaturesUrl(
          providerId === undefined ? providerData?.userId : providerId
        ),
        method: "POST",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });
      if (!httpResponse.error) {
        rxAction();
      }
    } else {
      rxAction();
    }
  };

  const rxAction = () => {
    // console.log("props?.soapNoteKey: ",props?.soapNoteKey);
    dispatch(setDirtyBitInStore(false));
    if (props?.soapNoteKey === true) {
      let medicationData = handleMedicationData();

      let dataObject = {
        pharmacyOrganisationId: pharmacyId,
        medicationRx: medicationData,
        patientId: props?.patientId !== undefined ? props?.patientId : patientId,
        providerId: user?.userId,
        tenantId: user?.tenantId,
        spi: user?.spi,
      };
      props?.setPrescriptionData(dataObject);
      props?.setSelectedPharmacy(orderPharmacy);
      props?.handleNewPrescriptionModal();
    } else {
      props?.replaceRequest === true
        ? handleReplaceRequest()
        : orderType === "ePrescription"
          ? handleSendRx()
          : orderType === "faxRx"
            ? handleFaxRx()
            : orderType === "printRx" && handlePrintRx();
    }
  };

  const fetchCurrentMedications = async () => {
    const httpResponse = await sendHttpRequest({
      url: fetchCurrentMedicationURL(props?.patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setCurrentMedication(httpResponse.data);
    }
  };

  let colClass =
    props?.replaceRequest === true ? "col-sm-12" : "col-sm-9 col-12 ";
  // let rowClass =
  //   props?.replaceRequest === true ? "col-sm-12" : "col-sm-9 col-12 ";
  // useEffect(() => {
  //   getPharmacyFromSession();
  // }, [pharmacyId]);

  const PharmacyComponent = ({ pharmacyObject }) => {
    return (
      <>
        <div className="col-sm-12 col-12 font-12">
          <div className="fw-sb">
            <span>{pharmacyObject?.standardizedOrganizationName}</span>
          </div>
          <div>
            <span>
              {pharmacyObject !== undefined &&
                pharmacyObject?.standardizedAddressLine1?.length > 0
                ? "".concat(
                  pharmacyObject?.standardizedAddressLine1?.trim(),
                  ", ",
                  pharmacyObject.standardizedAddressLine2?.length > 0
                    ? pharmacyObject?.standardizedAddressLine2?.trim() + ", "
                    : "",
                  pharmacyObject?.standardizedCity?.trim(),
                  ", ",
                  pharmacyObject?.stateProvince?.trim(),
                  " ",
                  formatPostalCode(
                    pharmacyObject?.postalCode?.trim() ??
                    pharmacyObject?.standardizedPostalCode?.trim()
                  )
                )
                : " "}
            </span>
          </div>
          <div>
            <span>
              {pharmacyObject?.primaryTelephone !== null
                ? "Phone: " +
                formatPhoneNumber(pharmacyObject?.primaryTelephone)
                : " "}
            </span>
          </div>
          <div>
            <span>
              {pharmacyObject?.fax !== undefined
                ? "".concat("Fax: ", formatPhoneNumber(pharmacyObject?.fax))
                : ""}
            </span>
          </div>
          <div>
            <span>
              {"Specialty:"} {pharmacySpecialty(pharmacyObject?.speciality)}
            </span>
          </div>
        </div>
      </>
    );
  };

  // const handleRxFillIndicator = (event) => {
  //   if (fieldValidation(event.target.value, "all")) {
  //     setRxFillIndicator(event.target.value);
  //   } else {
  //     setRxFillIndicator(null);
  //   }
  // }

  useEffect(async () => {
    // console.log("props?.assessmentPlan: ",props?.assessmentPlan);
    fetchPatientEncounter();
    fetchPrecriberFaiclity();
    if (
      props.newPrescriptionRequest === true ||
      props.reOrderPrescription === true
    ) {
      // getDataFromSession();
      fetchProviderData(
        user.userId,
        setProviderData,
        setIsProviderDataLoading
      );
      fetchPatientData(
        props?.patientId,
        setPatientData,
        setIsPatientDataLoading
      );
    } else {
      fetchProviderData(providerId, setProviderData, setIsProviderDataLoading);
      fetchPatientData(patientId, setPatientData, setIsPatientDataLoading);
    }
    // console.log("props?.orderPharmacy in use effect",props?.orderPharmacy);
    if (props.replaceRequest === true || props.reOrderPrescription === true) {
      // console.log("I'm in true part ");
      // setPreferredPharmacyFlag(false);
      setDeleteMedId(prescribedMedications[0]?.drugId);
      setOrderPharmacy(props.orderPharmacy);
      setPharmacyId(props.orderPharmacy?.organizationId);
    } else {
      if (props.soapNoteKey !== true) {
        fetchPharmacyData();
      }
      if (
        props.newPrescriptionRequest !== true &&
        props.reOrderPrescription !== true
      ) {
        fetchPrescribedMedication();
      }
    }
    if (props.replaceRequest === true || props.reOrderPrescription === true) {
      setDrugData({
        DDID: prescribedMedications[0]?.drugId,
        drugDbCode: prescribedMedications[0]?.drugId,
        drugDescription: prescribedMedications[0]?.drugDescription,
        Sig: {
          SigText: prescribedMedications[0]?.sigText,
        },
        textValue: prescribedMedications[0]?.sigText,
        Quantity: prescribedMedications[0]?.quantity,
        daySupply: prescribedMedications[0]?.daysSupply,
        NumberOfRefills: prescribedMedications[0]?.refills,
        RefillAsNeeded: false,
        DispenseAsWritten: false,
        Note: "",
        DoseForm: prescribedMedications[0]?.quantityUnitOfMeasure || "Tablet",
        PrescriptionStartDate: {
          Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
        },
        EarliestFillDate: {
          Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
        },
        WrittenDate: {
          Date: calenderDateTimeHelper(
            prescribedMedications[0]?.writtenDate,
            "YYYY-MM-DD"
          ),
        },
        alertPhysician72: prescribedMedications[0]?.alertPhysician,
        PatientDirections: "",
        flag: true,
        quantityUom: prescribedMedications[0]?.quantityUnitOfMeasure,
      });
    }

    if (props.replaceRequest === true || props.reOrderPrescription === true) {
      editMedicine(prescribedMedications[0]);
      if (props.reOrderPrescription !== true) {
        setPreferredPharmacyFlag(true);
      }
    } else {
      if (props.newPrescriptionRequest !== true) {
        fetchPrescribedMedication();
      }
    }

    async function callFetchPreferredPharmacy() {
      await fetchPreferredPharmacy();
    }
    if (props.reOrderPrescription === true || props.soapNoteKey === true) {
      await callFetchPreferredPharmacy();
    }
    if (props.soapNoteKey === true || props.providerNote === true) {
      console.log("props?.prescriptionData?.medicationRx", props?.prescriptionData);
      dispatch(updatePrescribedMedication(props?.prescriptionData?.medicationRx ?? []))
      setOrderPharmacy(props?.selectedPharmacy);
      setReOrderPharmacyFlag(true);
      if (props?.selectedPharmacy !== undefined) {
        setReOrderPharmacy([props?.selectedPharmacy]);
      }
    }

    fetchCurrentMedications();
    // console.log("props?.prescriptionData", props.prescriptionData)
  }, []);
  return (
    <>
      <div className={`row justify-content-center ${classes["scroll__bar"]}`} style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
        <div className={colClass}>
          <div className="font-12 text--danger ms-3 mt-2">
            {featureFlags.get("surescript") === "true" &&
              minorPatientError !== undefined ? (
              <h6 className="font-12 m-0">
                <WarningIcon
                  style={{ height: "18px", fill: "#f8a02e" }}
                  hidden={
                    minorPatientError === undefined || minorPatientError === ""
                      ? true
                      : false
                  }
                />
                {minorPatientError}
              </h6>
            ) : (
              ""
            )}
            {(
              fieldValidation(drugData?.controlledSubstanceCode, "all")
                ? Number(drugData?.controlledSubstanceCode) > 1
                : prescribedMedications.find(
                  (medication) =>
                    Number(medication?.controlledSubstanceCode) > 1
                )
            ) ? (
              <h6 className="font-12 m-0">
                <WarningIcon style={{ height: "18px", fill: "#f8a02e" }} />
                Prescription orders for controlled substance medications are not
                supported at this time
              </h6>
            ) : (
              ""
            )}
          </div>
          <Accordion
            expanded={medicationExpanded === "medication"}
            onChange={handleChange("medication")}
            defaultExpanded={true}
            className={props?.replaceRequest === true ? "mt-0" : "mt-4"}
          >
            <AccordionSummary
              aria-controls="panelmedication-content"
              id="panelmedication-header"
            >
              <h2 className="font-18 fw-sb m-0" id="medication">
                {_str.medication_accordion_title}
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className={`row  ${props.newPrescriptionRequest
                  ? "mt-1"
                  : props.replaceRequest
                    ? "mt-0"
                    : "mt-1"
                  }`}
              >
                <div className="col-sm-12 col-12">
                  <SearchMedication
                    focus={true}
                    setMedicineName={setMedicineName}
                    setSig={setSig}
                    disableSendRxButton={disableSendRxButton}
                    prescribeDrug={prescribeDrug}
                    fetchAvailableStrengths={fetchAvailableStrengths}
                    updateCheck={updateCheck}
                    clearDrugData={clearDrugData}
                    replaceRequest={
                      props.replaceRequest === true ? true : false
                    }
                    setUpdateCheck={setUpdateCheck}
                  />
                </div>

                {/* Sig Selection */}
                <div className="col-sm-12 col-12 mt-3">
                  {selectedMedicine && (
                    <SigSelection
                      sig={sig}
                      handleSigSelection={handleSigSelection}
                      sigTextValue={sigTextValue}
                      handleSigText={handleSigText}
                      addPrescriptionHandler={addPrescriptionHandler}
                      selectedMedicine={selectedMedicine}
                      selectedSig={selectedSig}
                      drugData={drugData}
                      setDrugData={setDrugData}
                      setSelectedMedicine={setSelectedMedicine}
                      updateCheck={updateCheck}
                      setUpdateCheck={setUpdateCheck}
                      prescribeDrug={prescribeDrug}
                      availableStrengths={availableStrengths}
                      isLoading={isLoading}
                      isAvailablestrengthsLoading={isAvailablestrengthsLoading}
                      handleCancelMedication={handleCancelMedication}
                      setIsLoading={setIsLoading}
                      replaceRequest={props.replaceRequest}
                      handleContinueToSummary={handleContinueToSummary}
                      reOrderPrescription={props.reOrderPrescription}
                      reOrderPharmacyFlag={reOrderPharmacyFlag}
                      calCustomSigKey={props.soapNoteKey === true ? true : false}
                    // rxFillIndicator={rxFillIndicatorLov()}
                    // handleRxFillIndicator={handleRxFillIndicator}
                    />
                  )}
                  {/* Prescription List */}

                  {props?.replaceRequest === true
                    ? null
                    : prescribedMedications.concat(rxMedications).length >
                    0 && (
                      <div className="col-sm-12 col-12 mt-3">
                        <h2 className="font-12">
                          {_str.prescribe_medication_title}
                        </h2>
                        <PrescriptionList
                          key={"Prescription List"}
                          prescribedList={prescribedMedications}
                          prescribeDrug={prescribeDrug}
                          setUpdateCheck={setUpdateCheck}
                          encounterId={props?.encounterId}
                          patientId={props?.patientId}
                          newPrescriptionRequest={
                            props?.newPrescriptionRequest
                          }
                          handleNewPrescriptionModal={
                            props?.handleNewPrescriptionModal
                          }
                          handleSetIsSent={props?.handleSetIsSent}
                          pharmacyId={pharmacyId}
                          setDrugData={setDrugData}
                          drugData={drugData}
                          fetchAvailableStrengths={fetchAvailableStrengths}
                          updateCheck={updateCheck}
                          constrolSubstanceCount={constrolSubstanceCount}
                          rxMedications={rxMedications}
                          setDeleteMedId={setDeleteMedId}
                          patientData={patientData}
                          currentMed={false}
                          providerNote={props?.providerNote ?? false}
                          reOrderPrescription={props.reOrderPrescription === true ? true : false}
                          setPrescriptionData={props?.setPrescriptionData}
                          currentMedicationList={currentMedication}
                          setReOrderPharmacy={setReOrderPharmacy}
                          reOrderPharmacy={reOrderPharmacy}
                          setReOrderPharmacyFlag={setReOrderPharmacyFlag}

                        />
                      </div>
                    )}
                  {/* Current Medications */}
                  {props.reOrderPrescription !== true && currentMedication?.length > 0 && (
                    <div className="col-sm-12 col-12 mt-3">
                      <h2 className="font-12">
                        {_str.current_medication_title}
                      </h2>
                      <PrescriptionList
                        key={"Current Medications"}
                        rxMedications={currentMedication}
                        patientId={props?.patientId}
                        newPrescriptionRequest={props?.newPrescriptionRequest}
                        handleNewPrescriptionModal={
                          props?.handleNewPrescriptionModal
                        }
                        handleSetIsSent={props?.handleSetIsSent}
                        patientData={patientData}
                        updateCheck={updateCheck}
                        currentMed={true}
                        setDeleteMedId={setDeleteMedId}
                        setUpdateCheck={setUpdateCheck}
                        fetchAvailableStrengths={fetchAvailableStrengths}
                        prescribeDrug={prescribeDrug}
                        setReOrderPharmacy={setReOrderPharmacy}
                        setReOrderPharmacyFlag={setReOrderPharmacyFlag}
                        reOrderPharmacy={reOrderPharmacy}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {prescribedMedications.length > 0 ? (
                    <div className="text-end mt-3">
                      <button
                        hidden={props?.replaceRequest === true}
                        className="btn blue_task_button"
                        onClick={() => handleContinueToPharmacy("pharmacy")}
                      >
                        {"Continue"}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* {props?.replaceRequest === true ? null : ( */}
          <Accordion
            expanded={pharmacyExpanded === "pharmacy"}
            onChange={handleChange("pharmacy")}
            defaultExpanded={false}
            className="mt-4"
          >
            <AccordionSummary
              aria-controls="panelpharmacy-content"
              id="panelpharmacy-header"
            >
              <h2 className="font-18 fw-sb m-0">
                {_str.pharmacy_accordion_title}
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row ">
                {/* Preferrd Pharmacy */}
                {featureFlags.get("surescript") === "true" && (
                  <div className="col-sm-12 col-12">
                    {props?.replaceRequest ? (
                      <PharmacyComponent pharmacyObject={orderPharmacy} />
                    ) : (
                      preferredPharmacy !== undefined &&
                      preferredPharmacy?.standardizedAddressLine1?.length >
                      0 && (
                        <>
                          <div className="col-sm-12 col-12">
                            <h2 className="font-12">Patient Preferred</h2>
                          </div>
                          <div className="col-sm-12 col-12 font-12 text--terciary  bg--disabled py-1 br-10">
                            <div className="row g-0 py-2">
                              {isPreferredPharmacyLoading ? (
                                <Skeleton />
                              ) : (
                                <>
                                  {preferredPharmacy?.standardizedAddressLine1
                                    ?.length > 0 && (
                                      <>
                                        <div
                                          className={`col-sm-1 col-1 text-center px-2 ${classes["checkBox__container"]}`}
                                        >
                                          <input
                                            className={`form-check-input cursor--pointer shadow-none ${classes["checkBox"]}`}
                                            type="checkbox"
                                            checked={
                                              preferredPharmacyFlag ? true : false
                                            }
                                            onChange={(e) =>
                                              handlePreferredPharmacyFlag(e)
                                            }
                                            id={
                                              preferredPharmacy !== undefined
                                                ? preferredPharmacy?.organizationId
                                                : -1
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-11 col-11 px-3">
                                          <PharmacyComponent
                                            pharmacyObject={preferredPharmacy}
                                          />
                                        </div>
                                      </>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                )}
                {/* Search Pharmacy Component */}.
                <div className="col-sm-12 col-12">
                  {/* <p className="font-12 fw-sb text--terciary mb-0">
                      Search Pharmacy
                    </p> */}
                  {isPreferredPharmacyLoading ? <Skeleton /> :

                    <SearchPharmacy
                      setPreferredPharmacy={setPreferredPharmacy}
                      setOrderPharmacy={setOrderPharmacy}
                      setPreferredPharmacyFlag={setPreferredPharmacyFlag}
                      handleContinueToSummary={handleContinueToSummary}
                      setPharmacyId={setPharmacyId}
                      pharmacyId={pharmacyId}
                      patientId={
                        props?.newPrescriptionRequest
                          ? props?.patientId
                          : props?.reOrderPrescription
                            ? props?.patientId
                            : patientId
                      }
                      newPrescriptionRequest={props?.newPrescriptionRequest}
                      preferredPharmacy={preferredPharmacy}
                      orderPharmacy={orderPharmacy}
                      preferredPharmacyFlag={preferredPharmacyFlag}
                      addPharmacyBtnText={addPharmacyBtnText}
                      setAddPharmacyBtnText={setAddPharmacyBtnText}
                      featureFlag={featureFlags.get("surescript")}
                      replaceRequest={props?.replaceRequest}
                      zipCode={patientData?.address[0]?.postalCode || ""}
                      reOrderPrescription={props?.reOrderPrescription}
                      reOrderPharmacy={reOrderPharmacy}
                      reOrderPharmacyFlag={reOrderPharmacyFlag}
                    />
                  }

                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* )} */}

          <Accordion
            expanded={summaryExpanded === "summary"}
            onChange={handleChange("summary")}
            defaultExpanded={false}
            className={props?.replaceRequest === true ? "mt-0" : "mt-4"}
          >
            <AccordionSummary
              aria-controls="panelsummary-content"
              id="panelsummary-header"
            >
              <h2 className="font-18 fw-sb m-0">
                {_str.order_summary_accordion_title}
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              {prescribedMedications.length > 0 &&
                prescribedMedications.map((prescriptionItem, index) => {
                  return (
                    <OrderMedicationItem
                      index={index + 1}
                      prescriptionItem={prescriptionItem}
                    />
                  );
                })}
              <div className="horizontal-rule my-2" />

              {/* Patient Information */}
              <div className="container-fluid g-0">
                {isPatientDataLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <div className="row ">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.patient_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {patientData !== undefined
                            ? patientData?.name[0]?.firstName?.concat(
                              " ",
                              patientData?.name[0]?.middleName || "",
                              " ",
                              patientData?.name[0]?.lastName
                            )
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.gender_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {patientData !== undefined ? patientData?.gender : ""}
                        </span>
                      </div>
                    </div>
                    {patientObservations?.height !== null &&
                      patientObservations?.height !== undefined &&
                      patientObservations?.weight !== null &&
                      patientObservations?.weight !== undefined && (
                        <>
                          <div className="row">
                            <div className="col-sm-3">
                              <span className="font-12 text--secondary">
                                {reusable_strings.height_str}
                              </span>
                            </div>
                            <div className="col-sm-9">
                              <span className="font-14 text--terciary fw-sb">
                                {patientObservations?.height !== undefined
                                  ? "".concat(
                                    patientObservations?.height,
                                    " inches"
                                  )
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-3">
                              <span className="font-12 text--secondary">
                                {reusable_strings.weight_str}
                              </span>
                            </div>
                            <div className="col-sm-9">
                              <span className="font-14 text--terciary fw-sb">
                                {patientObservations?.weight !== undefined
                                  ? "".concat(
                                    patientObservations?.weight,
                                    " lbs"
                                  )
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.date_of_birth_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {patientData !== undefined
                            ? getDMYformate(
                              patientData?.dateOfBirth,
                              "MMM DD, YYYY"
                            )
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.email_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {patientData !== undefined
                            ? patientData?.communicationNumbers
                              ?.electronicMail[0]
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.phone_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {patientData !== undefined
                            ? formatPhoneNumber(
                              patientData?.communicationNumbers?.numbers?.find(
                                (numberItem) => {
                                  return (
                                    numberItem?.type ===
                                    _numbString.cellPhoneExt
                                  );
                                }
                              )?.number
                            )
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.address_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {/* 221B Bakers, Street, 25454, NYC */}
                          {patientData !== undefined &&
                            patientData?.address?.length > 0
                            ? "".concat(
                              patientData?.address[0]?.addressLine1?.length >
                                0
                                ? patientData?.address[0]?.addressLine1 + ", "
                                : "",
                              patientData?.address[0]?.addressLine2?.length >
                                0
                                ? patientData?.address[0]?.addressLine2 + ", "
                                : "",

                              patientData?.address[0]?.city?.length > 0
                                ? patientData?.address[0]?.city + ", "
                                : "",

                              patientData?.address[0]?.stateProvince || "",
                              " ",
                              patientData?.address[0]?.postalCode
                            )
                            : ""}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="horizontal-rule my-2" />
              {/* Pharmacy Information */}
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col-sm-3">
                    <span className="font-12 text--secondary">
                      {reusable_strings.pharmacy_str}
                    </span>
                  </div>
                  <div className="col-sm-9">
                    {orderPharmacy !== undefined &&
                      orderPharmacy?.organizationId === pharmacyId &&
                      pharmacyId !== -1 &&
                      orderPharmacy.standardizedOrganizationName?.length > 0 ? (
                      <span className="font-14 text--terciary fw-sb">
                        {orderPharmacy?.standardizedOrganizationName}
                        <br />{" "}
                        {"".concat(
                          orderPharmacy?.standardizedAddressLine1,
                          ", ",
                          orderPharmacy?.standardizedAddressLine2?.length > 0
                            ? orderPharmacy?.standardizedAddressLine2 + ", "
                            : "",
                          orderPharmacy?.standardizedCity || "",
                          ", ",
                          orderPharmacy?.stateProvince || "",
                          " ",
                          formatPostalCode(
                            orderPharmacy?.postalCode ??
                            orderPharmacy?.standardizedPostalCode
                          ) || ""
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {orderPharmacy !== undefined &&
                  orderPharmacy?.organizationId === pharmacyId &&
                  pharmacyId !== -1 &&
                  orderPharmacy?.standardizedOrganizationName?.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-3">
                      <span className="font-12 text--secondary">
                        {reusable_strings.npi_str}
                      </span>
                    </div>
                    <div className="col-sm-9">
                      <span className="font-14 text--terciary fw-sb">
                        {orderPharmacy?.npi}
                      </span>
                    </div>
                  </div>
                ) : null}
                {orderPharmacy !== undefined &&
                  orderPharmacy?.organizationId === pharmacyId &&
                  pharmacyId !== -1 &&
                  orderPharmacy?.standardizedOrganizationName?.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-3">
                      <span className="font-12 text--secondary">
                        {reusable_strings.phone_str}
                      </span>
                    </div>
                    <div className="col-sm-9">
                      <span className="font-14 text--terciary fw-sb">
                        {formatPhoneNumber(orderPharmacy?.primaryTelephone)}
                      </span>
                    </div>
                  </div>
                ) : null}
                {orderPharmacy !== undefined &&
                  orderPharmacy?.organizationId === pharmacyId &&
                  pharmacyId !== -1 &&
                  orderPharmacy.standardizedOrganizationName?.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-3">
                      <span className="font-12 text--secondary">
                        {reusable_strings.fax_str}
                      </span>
                    </div>
                    <div className="col-sm-9">
                      <span className="font-14 text--terciary fw-sb">
                        {formatPhoneNumber(orderPharmacy?.fax)}
                      </span>
                    </div>
                  </div>
                ) : null}
                {orderPharmacy !== undefined &&
                  orderPharmacy?.organizationId === pharmacyId &&
                  pharmacyId !== -1 &&
                  orderPharmacy.standardizedOrganizationName?.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-3">
                      <span className="font-12 text--secondary">
                        {reusable_strings.specialty_str}
                      </span>
                    </div>
                    <div className="col-sm-9">
                      <span className="font-14 text--terciary fw-sb">
                        {pharmacySpecialty(orderPharmacy?.speciality)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="horizontal-rule my-2" />
              {/* Prescriber Information */}
              <div className="container-fluid g-0">
                {isProviderDataLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.prescribing_physician_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {"".concat(
                            providerData?.name[0]?.prefix || "",
                            " ",
                            providerData?.name[0]?.firstName || "",
                            " ",
                            providerData?.name[0]?.lastName || ""
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.npi_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {providerData?.identification?.npi}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                {/* Provider Facility */}
                {isFacilityLoading ? (
                  <Skeleton width="100%" />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-sm-3">
                        <span className="font-12 text--secondary">
                          {reusable_strings.prescribing_facility_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="font-14 text--terciary fw-sb">
                          {prescriberFacility !== undefined ? (
                            <>
                              {"".concat(
                                prescriberFacility?.facility?.businessName || ""
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        {/* <span className="font-12 text--secondary">
                          {reusable_strings.address_str}
                        </span> */}
                      </div>
                      <div className="col-sm-9">
                        <span className="text--terciary font-14 fw-sb">
                          {prescriberFacility !== undefined
                            ? "".concat(
                              prescriberFacility
                                ?.facility?.addressLine1,
                              " ",
                              prescriberFacility
                                ?.facility?.addressLine2 || ""
                            )
                            : null}{" "}
                          {prescriberFacility !== undefined
                            ? "".concat(
                              prescriberFacility
                                ?.facility?.city || "",
                              ", ",
                              prescriberFacility
                                ?.facility?.state || "",
                              " ",
                              prescriberFacility
                                ?.facility?.zipCode || ""
                            )
                            : null}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <span className="text--secondary font-12">
                          {reusable_strings.phone_str}
                        </span>
                      </div>
                      <div className="col-sm-9">
                        <span className="text--terciary font-14  fw-sb">
                          {providerData !== undefined
                            ? formatPhoneNumber(
                              providerData.communicationNumbers?.numbers?.find(
                                (numberItem) => {
                                  return (
                                    numberItem?.type ===
                                    _numbString.cellPhoneExt
                                  );
                                }
                              )?.number
                            )
                            : null}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="horizontal-rule my-2" />

                <div className="row">
                  <div className="col-sm-3 mt-2">
                    <span className="font-12 text--secondary">
                      {reusable_strings.order_type_str}
                    </span>
                  </div>
                  <div className="col-sm-9 mt-2">
                    <div
                      className="form-check form-check-inline"
                      hidden={
                        featureFlags.get("surescript") === "true" ? false : true
                      }
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="orderType"
                        id={_str?.ePrescription}
                        value={_str?.ePrescription}
                        onChange={changeOrderType}
                        checked={orderType === _str?.ePrescription}
                      />
                      <label
                        className="form-check-label font-12"
                        htmlFor={_str?.ePrescription}
                      >
                        {_str?.ePrescribe}
                      </label>
                    </div>

                    <div className="form-check form-check-inline" hidden={userPermissions?.ofx === false ? true : false}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="orderType"
                        id="faxRx"
                        value="faxRx"
                        onChange={changeOrderType}
                        checked={orderType === "faxRx"}
                      />
                      <label className="form-check-label font-12" htmlFor="faxRx">
                        {_str?.fax}
                      </label>
                    </div>

                    <div className="form-check form-check-inline" hidden={userPermissions?.omp === false ? true : false}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="orderType"
                        id="printRx"
                        value="8"
                        onChange={changeOrderType}
                      />
                      <label className="form-check-label font-12" htmlFor="printRx">
                        {_str?.print}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="horizontal-rule my-2" />

                <div
                  className="row"
                  hidden={
                    providerData !== "" &&
                    providerData?.providerSetting &&
                    ((providerData?.providerSetting?.signaturePath !==
                      "" &&
                      providerData?.providerSetting?.signaturePath !==
                      null) || (providerData?.providerSetting?.signature !== null))
                  }
                >
                  <div className="col-sm-3 mt-3">
                    <span className="font-12 text--secondary">
                      {reusable_strings.signature_str}
                    </span>
                  </div>
                  <div className="col-sm-8 mt-2">
                    <div style={{ height: "180px" }} className="row py-2">
                      <div className="h-100 d-inline-block mt-1 bg--white border--default br-10 col-sm p-1 ms-2">
                        <div className="text-center align-content-center">
                          <SignatureCanvas
                            penColor="green"
                            ref={(ref) => {
                              setSigPad(ref);
                            }}
                            onBegin={() => {
                              // onDirty();
                            }}
                            onEnd={() => {
                              convertToImage();
                            }}
                            canvasProps={{
                              width: 485,
                              height: 150,
                              className: "sigCanvas",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Order Medication Section */}
              <div className="container-fluid g-0 mt-3">
                <div className=" d-flex flex-row justify-content-between">
                  <div className="">
                    <div className="form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="orderConsent"
                        id="orderConsent"
                        disabled={
                          minorPatientError !== undefined ? true : false
                        }
                        // defaultChecked = {rxConsent===true ? false:true}
                        onClick={(e) => {
                          handleRxConsent(e);
                        }}
                      />
                      <label
                        htmlFor="orderConsent"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          lineHeight: "20px",
                          color: "#323232",
                        }}
                        className="form-check-label ms-2 "
                      >
                        I confirm accuracy of the above information and
                        authorize its transmission to <br /> the pharmacy for
                        dispensing.
                      </label>
                    </div>
                  </div>

                  {console.log({ "user?.spi": user?.spi, "orderType": orderType, constrolSubstanceCount: constrolSubstanceCount, rxConsent, pharmacyId, prescribedMedications: prescribedMedications?.length, "providerSettting": providerData?.providerSetting, "sign": providerData?.providerSetting?.signature, "file": filename })}

                  <div className=" d-flex flex-row ">
                    <button
                      className={`btn blue_task_button nowrap px-4 py-2 ${classes["order__prescription__btn"]}`}
                      id={rxButtonText}
                      onClick={() => {
                        {
                          if (props?.soapNoteKey === true) {
                            props?.setOrderType(orderType);
                          }
                          saveSignature();
                        }
                      }}
                      disabled={
                        (user?.spi === undefined || user?.spi === null) &&
                          orderType === _str.ePrescription
                          ? true
                          : constrolSubstanceCount > 0 &&
                            orderType !== _str.ePrescription &&
                            orderType !== _str.faxRx
                            ? rxConsent
                            : rxConsent
                              ? true
                              : pharmacyId === -1
                                ? true
                                : constrolSubstanceCount.length > 0
                                  ? true
                                  : prescribedMedications.length === 0
                                    ? true
                                    : providerData?.providerSetting === undefined ||
                                      providerData?.providerSetting === null ||
                                      providerData?.providerSetting?.signature === null ||
                                      providerData?.providerSetting?.signature === undefined
                                      ? filename === null || filename === ""
                                        ? true
                                        : false
                                      : false

                      }
                    >
                      {rxButtonText !== undefined ? rxButtonText : ""}
                    </button>

                    {/* <div>
                      <OrderPrescriptionDropdown
                        disabled={
                          rxConsent
                            ? true
                            : pharmacyId === -1
                              ? true
                              : constrolSubstanceCount.length > 0
                                ? true
                                : prescribedMedications.length === 0
                                  ? true
                                  : false
                        }
                        setRxButtonText={setRxButtonText}
                        rxButtonText={rxButtonText}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <CustomizedSnackbars
        message={rxSuccessMessage}
        severity={rxMessageSeverity}
        open={isSent}
        handleClose={handleClose}
      />
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* {console.log("preferredPharmacy",preferredPharmacy)}
      {console.log("orderPharmacy",orderPharmacy)}
      {console.log("pharmacyId",pharmacyId)} */}
      {/* {console.log("drugData", drugData)} */}
      {/* {console.log("orderPharmacy",orderPharmacy)}
      {console.log("preferred pharmacy",preferredPharmacy)} */}
      {/* {console.log("preferredPharmacyFlag",preferredPharmacyFlag)} */}
      {/* {console.log("Sig: ",sig)} */}
      {/* {console.log("updateCheck",updateCheck)} */}
      {/* {console.log("Prescribed Medications", prescribedMedications)}
      
      {console.log("rxConsent", rxConsent)}
      {console.log("constrolSubstanceCount.length", constrolSubstanceCount.length)}
      {console.log("prescribedMedications.length", prescribedMedications.length)}
      {console.log("isPreferredPharmacyLoading",isPreferredPharmacyLoading)} */}
      {/* {console.log("isPreferredPharmacyLoading",isPreferredPharmacyLoading)} */}
      {/* {console.log("prescribedMedications", prescribedMedications)} */}
      {/* {console.log("prescribedList",prescribedList)} */}
      {/* {console.log("RxMedications",rxMedications)} */}
      {/* {console.log("minorPatientError :",minorPatientError)} */}
      {/* {console.log("rxConsent", rxConsent)}
      {console.log("orderType: ",orderType)} */}
      {/* 
      {console.log("reOrderPharmacy flag: ",reOrderPharmacyFlag)} */}
      {/* {console.log("pharmacyId", pharmacyId)}
      {console.log("orderPharmacy: ",orderPharmacy)} */}
    </>
  );
};

export default OrderPrescription;
