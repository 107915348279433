import React from "react";
import { Modal } from "react-bootstrap";
// import AddButtonIcon from "../../assets/icons/Icon-Add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { X } from "react-bootstrap-icons";
import TextArea from "../../../forms/TextArea";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { changeDateTimeFormat } from "../../../../utils/dateTimeHelper";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import SearchPatient from "../../Header/SearchPatient";
import "./newMessage.css";
import FileUploadModal from "./FileUploadModal";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import SearchProvider from "../../General/SearchProvider";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import SearchIcon from "@mui/icons-material/Search";
import {
  setPatientID,
  setUnreadMessageCount,
} from "../../../../redux/actions/providerActions";
import { useAuth } from "../../../hooks/useAuth";
import { truncate } from "../../../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../../Reusable/PnComponents";

function NewMessageModal(props) {
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const [prescriberList, setPrescriberList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [fileType, setFileType] = useState("");
  const [showUploadData, setShowUploadData] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isPrescriber, setIsPrescriber] = useState(false);
  const [isPatient, setIsPatient] = useState(false);
  const [addToChart, setAddToChart] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState(props?.forward && props?.messageData?.body);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState([]);
  let patientId = useSelector((state) => state.searchedPatientId.patientId);
  const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "PDF"];
  const [patientName, setPatientName] = useState("");
  const dispatch = useDispatch();
  const { user } = useAuth();
  const apiData = new FormData();
  const [requiredFieldErorrMessage, setRequiredFieldErorrMessage] = useState()

  const sendFileAndType = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      convertToMultipart(file, e.target.result);
    };
  };

  const convertToMultipart = (fileV, trimmedDataURL) => {
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];
    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name, {
          type: mimeType,
        });
        console.log(file);
        setFile(file);
      });
  };


  const fetchUploadData = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/attachmentModalObjects",
      method: "GET",
    });
    if (!httpResponse.error) {
      setShowUploadData(httpResponse.data);
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
    }
    // setIsLoading(false);
  };

  const setInPrescriberList = (provider) => {
    const tempList = [...prescriberList];
    tempList.push(provider);
    const unique = [
      ...new Map(tempList.map((item) => [item.userId, item])).values(),
    ];
    setPrescriberList(unique);
  };

  const setInPatientList = (patient) => {
    const tempList = [...patientList];
    tempList.push(patient);
    const unique = [
      ...new Map(tempList.map((item) => [item.id, item])).values(),
    ];
    setPatientList(unique);
  };

  const handleIsChart = (event) => {
    setAddToChart(event.target.checked);
  };

  const handleSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleIsUrgent = (event) => {
    setUrgent(event.target.checked);
  };

  const handleMessage = (value) => {
    setMessage(value);
  };

  const handleFileInput = (file) => {
    const temp = [];
    temp.push(file);
    setFileName(temp);
    sendFileAndType(file);
    // console.log(temp);
  };

  const handleRemove = (id, userList, setUserList) => {
    const dummy = userList.filter((uName) => uName.userId !== id);
    console.log(dummy);
    setUserList(dummy);
  };

  const handleCloseModal = () => {
    clearState();
    props.handleMessageModal();
  };

  const clearState = () => {
    setPatientList([]);
    setPrescriberList([]);
    setIsLoading(true);
    setIsUploading(false);
    setFileUploadModal(false);
    setFileType("");
    setShowUploadData("");
    setSelectedFile("");
    setIsPrescriber(true);
    setIsPatient(false);
    setAddToChart(false);
    setUrgent(false);
    setRequiredFieldErorrMessage("");
    // setIsMessageSent(false);
    setIsMessageSending(false);
    setSubject("");
    setMessage("");
    setFile("");
    setFileName([]);
    setPatientName("");
  };

  const removeFile = (event) => {
    const dummy = fileName.filter((file, index) => index != event.target.id);
    setFileName(dummy);
    setFile("");
  };

  const createRecipientIdList = (recipeints) => {
    let recipientList = recipeints.map((recipientItem) => recipientItem.userId.toString());
    return recipientList;
  };

  const mesasgeHandler = async () => {
    setIsMessageSending(true);
    let recipientList = []

    if (isPatient) {
      recipientList = createRecipientIdList([...patientList, ...prescriberList]);
    } else {
      recipientList = createRecipientIdList(prescriberList);
    }
    const setApiData = () => {
      apiData.append("chart", addToChart);
      apiData.append(`urgency`, urgent);
      apiData.append("from", user?.userId);
      apiData.append("subject", subject);
      apiData.append("body", message ?? '');
      apiData.append("to", recipientList);
      apiData.append("tenantId", user?.tenantId);
      if (fileName !== null && fileName?.length !== 0) {
        apiData.append("file", file);
      }
    };
    if (patientId !== -1) {
      apiData.append("patientId", patientId);
    } else {
      if (
        props?.messageData?.patientId !== null &&
        props?.messageData?.patientId !== undefined
      ) {
        apiData.append("patientId", props?.messageData?.patientId);
      }
    }

    if (props?.reply === true) {
      apiData.append("patientId", props?.messageData?.id)
    }

    setApiData();
    if (apiData.get("to")?.length > 0) {
      const httpResponse = await sendHttpRequest({
        url: "/messages",
        method: "POST",
        data: apiData,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });

      if (!httpResponse.error) {
        props.setIsMessageSent(true);
        // fecthUnreadMessagesCount();
        setPrescriberList([]);
        // props.fetchSentMessagesCount();
        props.fetchSentMessages();

        handleCloseModal();
      } else {
        props.setIsMessageSent(false);
        props.setIsMessageSentError(true);
        // console.log(httpResponse.error);
      }
    } else {
      console.log(apiData.get("to"));
      setRequiredFieldErorrMessage("Please select a recipient")
    }
    setIsMessageSending(false);
  };
  const handleSendTo = (event) => {
    clearState()
    if (event.target.id === "practice") {
      setIsPrescriber(true);
      setIsPatient(false);
    } else if (event.target.id === "patient") {
      setIsPatient(true);
      setIsPrescriber(false);
    }
  };
  const getProviderData = async (pid) => {
    const httpResponse = await sendHttpRequest({
      url: "/prescriber",
      method: "GET",
      params: {
        id: pid,
      },
    });
    if (!httpResponse?.error) {
      setInPrescriberList({
        id: httpResponse?.data?.NonVeterinarian?.id,
        ...httpResponse?.data?.NonVeterinarian?.Name,
      });
    } else {
      console.log(httpResponse?.error);
    }
  };
  const fetchPatientData = async (userId) => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/user",
      method: "GET",
      params: {
        UserId: userId,
      },
    });
    if (!httpResponse.error) {
      patientId = httpResponse.data.HumanPatient?.id;
      setPatientName(
        httpResponse.data.HumanPatient?.Name?.FirstName +
        " " +
        httpResponse.data.HumanPatient?.Name?.LastName
      );
      setInPatientList({ id: patientId, ...httpResponse.data.HumanPatient?.Name });
    } else {
      console.log(httpResponse.error);
    }
  };
  const downloadAttachment = async (documentId) => {
    const httpResponse = await sendHttpRequest({
      url: "/patient/document",
      method: "GET",
      params: {
        documentId: documentId,
      },
    });
    if (!httpResponse.error) {
      downloadFile(httpResponse.data.filePath, httpResponse.data.name);
    } else {
      console.log(httpResponse.error);
    }
  };
  function downloadFile(url, fileName) {
    fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
      .then(res => res.blob())
      .then(res => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }
  const fecthUnreadMessagesCount = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/messages/unread",
      method: "GET",
      params: {
        userId: user?.userId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      dispatch(setUnreadMessageCount(httpResponse?.data));
    } else {
      console.log(httpResponse.error);
    }
  };
  const handleFileUploadModal = () => {
    setFileUploadModal(!fileUploadModal);
  };

  const handleFileUploadModalClose = () => {
    setFileUploadModal(false);
  };

  const handleDelete = () => {
    // setFileUploadModal(false);
  };

  useEffect(()=>{
    if(userPermissions?.smu === true){
      setIsPrescriber(true);
    }else if(userPermissions?.smu === false && userPermissions?.smp === true){
      setIsPatient(true);
    }
  },[])
  useEffect(() => {
    dispatch(setPatientID(-1));
    fetchPatientData(props?.messageData?.patientId);
    if (props?.reply === true) {
      setSubject(props?.messageData?.subject);
      if (props?.inbox === true) {
        getProviderData(props?.messageData?.from);
      } else {
        getProviderData(props?.messageData?.to);
      }
    }
    if (props?.forward === true) {
      if (props?.messageData?.subject.toLowerCase().indexOf('fw') === -1) {
        setSubject("FW:" + props?.messageData?.subject);
      } else {
        setSubject(props?.messageData?.subject);
      }
      if (props?.messageData?.attachment !== null) {
        downloadAttachment(props?.messageData?.attachment);
      }
    }
    return () => {
      //flush states on component unmount
      clearState();
    };
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          dispatch(setPatientID(-1));
          handleCloseModal();
        }}
        // size="md"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        backdrop="static"
        centered
      // style={{style}}
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(setPatientID(-1));
              handleCloseModal();
            }}
          >
            {<CloseIcon />}
          </span>

          <Modal.Title className="font-20 fw-sb text--terciary">
            {props?.reply
              ? "Reply Message"
              : props?.forward
                ? "Forward Message"
                : "New Message"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid px-3">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <form
                  id="messageSubmitForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    mesasgeHandler();
                  }}
                >
                  <div className="row mt-3">
                    <label
                      htmlFor="send to"
                      className="form-label m-0 mb-2 font-12 text--secondary"
                    >
                      Send to <span className="text--danger">&#42;</span>
                    </label>
                    <Stack direction="row" component="div">
                      <div
                        className="col-md-12 mb-2"
                        style={{
                          height: "38px",
                          padding: 6,
                          backgroundColor: "#D9E8EC",
                          border: "1px solid #D9E8EC",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="row justify-content-center flex-row">
                          <div
                            className="col-md-6"
                            onClick={handleSendTo}
                            style={{ marginTop: -3, cursor: "pointer" }}
                            hidden = {userPermissions?.smu === false ? true:false}
                          >
                            <div
                              className="row justify-content-center font-12 fw-sb"
                              style={
                                isPrescriber
                                  ? {
                                    marginLeft: 0,
                                    backgroundColor: "#ffffff",
                                    borderRadius: "8px",
                                  }
                                  : { marginLeft: 0, borderRadius: "8px" }
                              }
                            >
                              <div
                                id="practice"
                                className="col-md-11 text-center"
                                style={{ paddingTop: 5, height: "30px" }}
                              >
                                {"In Practice"}
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-6"
                            onClick={handleSendTo}
                            style={{ marginTop: -3, cursor: "pointer" }}
                            hidden = {userPermissions?.smp === false ? true:false}
                          >
                            <div
                              className="row justify-content-center font-12 fw-sb"
                              style={
                                isPatient
                                  ? {
                                    marginRight: 0,
                                    backgroundColor: "#ffffff",
                                    borderRadius: "8px",
                                  }
                                  : { marginRight: 0, borderRadius: "8px" }
                              }
                            >
                              <div
                                id="patient"
                                className="col-md-11 text-center"
                                style={{ paddingTop: 5, height: "30px" }}
                              >
                                {"Patient"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Stack>
                    {/* <Stack direction="row" component="div" style={{ marginTop: 7 }}> */}
                    <div>

                      {isPatient ? (
                        <>
                        <label className="text-danger font-12">{requiredFieldErorrMessage !== undefined && requiredFieldErorrMessage}</label>
                          <div>
                            {props?.reply === true ||
                              props?.forward === true ? (
                              <div
                                className="input-group   border--default br-10"
                                disabled={true}
                                style={{
                                  opacity: "1px",
                                  width: "396px",
                                }}
                              >
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control shadow-none border-0 ms-1 font-14"
                                  defaultValue={
                                    patientName !== undefined ? patientName : ""
                                  }
                                  style={{
                                    backgroundColor: "#F2F7F8",
                                    height: "40px",
                                  }}
                                />
                                <button className="btn shadow-none">
                                  <SearchIcon style={{ color: "#336383" }} />
                                </button>
                              </div>
                            ) : (
                              <SearchPatient
                                disable={patientList.length > 0 ? true : false}
                                disableSearch={patientList.length > 0 ? true : false}
                                navigate={false}
                                handlePatientsAdd={setInPatientList}
                              />
                            )}
                          </div>
                          <div>
                            <div className="col-md-12">
                              <div className="d-flex mt-2 flex-row flex-wrap gap-2">
                                {patientList.length > 0 &&
                                  patientList.map((patientItem, index) => {
                                    return (
                                      <PnToolTip title={patientItem?.FirstName + " " + patientItem?.LastName}>
                                        <div key={patientItem?.id}>
                                          <span className="badge font-12 fw-sb text--terciary bg--d9e8ec py-1 px-2 br-10">
                                            {truncate(patientItem?.FirstName + " " + patientItem?.LastName, 25)}
                                            {props?.reply ? (
                                              ""
                                            ) : (
                                              <span
                                                className="cross-sign"
                                                onClick={() =>
                                                  handleRemove(patientItem?.id, patientList, setPatientList)
                                                }
                                              >
                                                <X
                                                  color="#336383"
                                                  style={{ marginBottom: "2px" }}
                                                  size={24}
                                                />
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      </PnToolTip>
                                    );
                                  })}
                                {/* </Stack> */}
                              </div>
                            </div>
                            <Stack>
                              <hr />
                            </Stack>
                          </div>



                        </>
                      ) : (
                        <>
                          <label className="text-danger font-12">{requiredFieldErorrMessage !== undefined && requiredFieldErorrMessage}</label>
                          {props?.reply ? (
                            <SearchProvider
                              disable={true}
                              inMessage={true}
                              handleProvidersAdd={setInPrescriberList}
                            />
                          ) : (
                            <SearchProvider
                              inMessage={true}
                              handleProvidersAdd={setInPrescriberList}
                            />
                          )}
                          {/* </Stack> */}
                          <div className="col-md-12">
                            <div className="d-flex mt-2 flex-row flex-wrap gap-2">
                              {prescriberList.length > 0 &&
                                prescriberList.map((provider, index) => {
                                  return (
                                    <div key={provider?.userId}>
                                      <span className="badge font-12 fw-sb text--terciary bg--d9e8ec py-1 px-2 br-10">
                                        {provider?.name[0]?.firstName + " "}
                                        {provider?.name[0]?.lastName}
                                        {props?.reply ? (
                                          ""
                                        ) : (
                                          <span
                                            className="cross-sign"
                                            onClick={() =>
                                              handleRemove(provider?.userId, prescriberList, setPrescriberList)
                                            }
                                          >
                                            <X
                                              color="#336383"
                                              style={{ marginBottom: "2px" }}
                                              size={24}
                                            />
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  );
                                })}
                              {/* </Stack> */}
                            </div>
                          </div>
                          <Stack>
                            <hr />
                          </Stack>
                        </>

                      )}
                    </div>

                    <div className="col-md-12 mb-2">
                      {isPatient ? (
                        <>
                          <div className="mt-3 text--secondary font-12">
                            <label htmlFor="Cc">Cc</label>
                          </div>

                          {props?.reply ? (
                            <SearchProvider
                              disable={true}
                              inMessage={true}
                              handleProvidersAdd={setInPrescriberList}
                            />
                          ) : (
                            <SearchProvider
                              inMessage={true}
                              handleProvidersAdd={setInPrescriberList}
                            />
                          )}
                          {/* </Stack> */}
                          <div className="col-md-12">
                            <div className="d-flex mt-2 flex-row flex-wrap gap-2">
                              {/* <div className="d-flex flex-wrap flex-row" > */}
                              {/* <Stack direction="row" component="div" style={{ marginTop: 10, marginBottom: 7 }}> */}
                              {prescriberList?.length > 0 &&
                                prescriberList?.map((prescriber, index) => {
                                  return (
                                    <div key={prescriber?.userId}>
                                      <span className="badge font-12 fw-sb text--terciary bg--d9e8ec py-1 px-2 br-10">
                                        {prescriber?.name[0]?.firstName + " "}
                                        {prescriber?.name[0]?.lastName}
                                        {props?.reply ? (
                                          ""
                                        ) : (
                                          <span
                                            className="cross-sign"
                                            onClick={() =>
                                              handleRemove(prescriber?.userId, prescriberList, setPrescriberList)
                                            }
                                          >
                                            <X
                                              color="#336383"
                                              style={{ marginBottom: "2px" }}
                                              size={24}
                                            />
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  );
                                })}
                              {/* </Stack> */}
                            </div>
                          </div>
                          <Stack>
                            <hr />
                          </Stack>
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="regarding patient"
                            className="form-label m-0 font-12 text--secondary"
                          >
                            Regarding Patient
                          </label>
                          <div>
                            {props?.reply === true ||
                              props?.forward === true ? (
                              <div
                                className="input-group   "
                                disabled={true}
                                style={{
                                  border: "1px solid #D9E8EC",
                                  borderRadius: "10px",
                                  opacity: "1px",
                                  width: "396px",
                                }}
                              >
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control shadow-none border-0 ms-1 font-14"
                                  defaultValue={
                                    patientName !== undefined ? patientName : ""
                                  }
                                  style={{
                                    backgroundColor: "#F2F7F8",
                                    height: "40px",
                                  }}
                                />
                                <button className="btn shadow-none">
                                  <SearchIcon style={{ color: "#336383" }} />
                                </button>
                              </div>
                            ) : (
                              <SearchPatient disable={false} navigate={false} />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isPrescriber && (
                      <div className="col-12 mt-2">
                        <div class="form-check">
                          <input
                            class="form-check-input shadow-none"
                            onChange={handleIsChart}
                            type="checkbox"
                            value=""
                            id="add_to_chart"
                            disabled={
                              patientId === -1 ||
                                props?.reply === true ||
                                props?.forward === true
                                ? true
                                : false
                            }
                          // disabled={patientId !== -1 ? true:false}
                          />
                          <label
                            class="form-check-label font-10 text--secondary"
                            for="add_to_chart"
                          >
                            Add to Chart
                          </label>
                        </div>
                      </div>
                    )}

                    <div className="col-md-12 mt-2 mb-2">
                      <label
                        htmlFor="Subject"
                        className="form-label m-0 font-12 text--secondary"
                      >
                        Subject <span className="text--danger">&#42;</span>
                      </label>
                      <input
                        defaultValue={subject}
                        type="text"
                        className={
                          props?.reply
                            ? "msg-input-disabled form-control text--terciary  font-12 border--default br-10 shadow-none"
                            : " form-control text--terciary msg-input font-12 border--default br-10 shadow-none"
                        }
                        id="Subject"
                        required
                        onChange={handleSubject}
                        disabled={props?.reply ? true : false}
                      />
                    </div>

                    <div className="col-12 mt-2">
                      <label
                        htmlFor="note"
                        className="form-label m-0 font-12 text--secondary"
                      >
                        Message
                      </label>
                      <TextArea
                        // type="text"
                        className={`form-control font-12 m-0 border--default text--terciary br-10 shadow-none msg-input`}
                        id="message"
                        maxLength="2048"
                        gettext={handleMessage}
                        rows={4}
                        defaultValue={
                          props?.forward && props?.messageData?.body
                        }
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <input
                        class="form-check-input  shadow-none"
                        onChange={handleIsUrgent}
                        type="checkbox"
                        value=""
                        id="urgent"
                      />
                      <label
                        class="form-check-label font-10 text--secondary ms-1"
                        for="urgent"
                        style={{ verticalAlign: 'middle' }}
                      >
                        Urgent
                      </label>
                    </div>
                    <div className="col-12  font-12 mt-3">
                      <FileUploader
                        handleChange={handleFileInput}
                        name="file"
                        label="Drop a file or click here"
                        types={fileTypes}
                      >
                        <div
                          className="py-3 border--dashed bg--white br-10 d-flex justify-content-center"
                        >
                          <div>
                            Drag and drop files or{" "}
                            <u className="file-link">select one</u>
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                    <div className="col-12">
                      {fileName.length > 0
                        ? fileName.map((file, index) => {
                          return (
                            <>
                              <div className="row mt-3 justify-content-between">
                                <div className="col-sm-8 font-12 text--terciary">
                                  {file?.name && file.name}
                                </div>
                                <div
                                  key={index}
                                  className="col-sm-2"
                                  style={{
                                    marginRight: -30,
                                    marginTop: -11,
                                    cursor: "pointer",
                                  }}
                                >
                                  <TrashIcon
                                    id={index}
                                    style={{ color: "red", height: 13 }}
                                    onClick={(e) => removeFile(e)}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })
                        : ""}
                    </div>
                    <div className="col-12 mt-4 ">
                      <div className="d-flex flex-row  justify-content-between">
                        <div className="">
                          <button
                            type="button"
                            className={`btn fw-sb font-12 d9e8ec_button text--blue  br-10  `}
                            id={""}
                            value={false}
                            disabled={isMessageSending}
                            onClick={(e) => {
                              handleCloseModal(e);
                            }}
                          >
                            {"Cancel"}
                          </button>
                        </div>
                        <div className=" ">
                          {/* <Stack direction="row" component="div"> */}
                          <button
                            type="submit"
                            className={`btn fw-sb font-12 send_button text--white  br-10 `}
                            form="messageSubmitForm"
                            disabled={isMessageSending}
                            value={true}
                          // onClick={(e) => { props.refillRequestStatusHandler(e) }}
                          >
                            {"Send"}
                          </button>
                          {/* </Stack> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <Divider /> */}
            {/* {console.log("patientId", patientId)} */}
          </div>
        </Modal.Body>
      </Modal>

      {/* {console.log("Message Data ", props?.messageData)} */}
      {/* {console.log("patientName",patientName)} */}
      {/* {console.log(patientId)} */}
    </>
  );
}

export default NewMessageModal;
