import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from "luxon";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fieldValidation, getUTCFromTimezone, timezoneLov } from "../../../utils/helpers/HelperFunctions";
import { useRef } from "react";
import { useImperativeHandle, forwardRef } from "react";
import { convertUTCtoTimzoneLuxon } from "../../../utils/dateTimeHelper";

const styles = theme => ({
    notchedOutline: {
        borderColor: '#d9e8ec !important',
    },
    notchedErrorOutline: {
        borderColor: 'red !important',
    },
    notchedOutWhiteline: {
        borderColor: '#FFFFFF !important',
        '&:hover': {
            borderColor: '#FFFFFF !important'
        },
        '&:focus': {
            borderColor: '#FFFFFF !important'
        },
        '&:active': {
            borderColor: '#FFFFFF !important'
        },
    },
});


const CalenderDatePicker = forwardRef((props, ref) => {
    const { dobHandler } = props;
    // const { user } = useAuth();
    const user  = props?.user || require("../../hooks/useAuth")?.user;
    const [timezone, setTImezone] = useState(getUTCFromTimezone());
    const [startDate, setStartDate] = useState(props?.date);
    const [value, setValue] = useState(null);
    // const [value2, setValue2] = useState(props?.date !== undefined ? DateTime.fromISO(props?.date, { setZone: true, zone: timezone }).setLocale('en-us').toFormat(user?.tenant?.dateFormat ?? "MMM dd, yyyy") : null);
    const [error, setError] = useState(false);
    const [borderColor, setBorderColor] = useState(props?.borderColor);
    const fontWeight = props?.borderColor ? 600 : 400;
    const dateRef = useRef();

    const { classes } = props;
    // commenting this code to for service date
    // if (props?.date > startDate) {
    //   setStartDate(props?.date);
    // } 
    useEffect(() => {
        if (props?.prescribeDrug) {
            setStartDate(props?.date);
        }
    }, [props?.date, props?.prescribeDrug]);

    useEffect(() => {
        setStartDate(startDate);
    }, [startDate, props?.prescribeDrug])

    // console.log(props?.date);
    // console.log(startDate);

    const yearLimit = (date) => {
        if (props?.publishSchedule) {
            return !(date.year <= DateTime.fromISO(props?.minDate, { setZone: true, zone: timezone }).setLocale('en-us').year + 3);
        } else if (props?.viewSchedule) {
            return !(date.year <= DateTime.fromISO(startDate, { setZone: true, zone: timezone }).setLocale('en-us').year + 3) || !(date.year >= DateTime.fromISO(startDate, { setZone: true, zone: timezone }).setLocale('en-us').year - 3);
        } else if (props?.prescribeDrug) {
            return !(date.year <= DateTime.fromISO(startDate, { setZone: true, zone: timezone }).setLocale('en-us').year + 1);
        } else {
            return false;
        }
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#336383",
                light: "#336383",
                dark: "#336383",
            },
        },
        typography: {
            fontFamily: "Montserrat",
        },
    });

    useImperativeHandle(ref, () => ({
        removeDateHandle() {
            handleDate(false);
        }
    }));

    const handleDate = (date) => {
        if (date === false) {
            setValue(null);
        } else {
            setStartDate(date?.toFormat('yyyy-MM-dd'));
            if (date != null) {
                setDateValue(DateTime.fromISO(date).setLocale('en-us').toFormat('yyyy-MM-dd'));
                setValue(DateTime.fromISO(date, { setZone: true, zone: timezone }).setLocale('en-us'));
                // setValue2(DateTime.fromISO(date, { setZone: true, zone: timezone }).setLocale('en-us').toFormat(user?.tenant?.dateFormat ?? "MMM dd, yyyy"));
            } else {
                setDateValue(undefined);
            }
        }
        // if (!date.isValid) {
        //     setValue2(user?.tenant?.dateFormat ?? "MMM dd, yyyy");
        // }
    };

    const handleError = (error) => {
        if (error !== null) {
            setDateValue(undefined);
        }
    }

    const setDateValue = date => {
        if (dobHandler !== undefined) {
            dobHandler(date);
        }
    }

    const handlingDate = date => {
        if (fieldValidation(date, "un")) {
            let newDate = convertUTCtoTimzoneLuxon(date, timezone);
            // console.log(newDate);
            return newDate;
        } else {
            return '';
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'en-us'}>
                <DatePicker
                    fixedWeekNumber={6}
                    // defaultValue={DateTime.fromISO(convertUTCtoTimzone(startDate, user?.timezone, "YYYY-MM-DD"), {setZone: true, zone: timezone}).setLocale('en-us')}
                    maxDate={DateTime.fromISO(handlingDate(props?.maxDate), { setZone: true, zone: timezone }).setLocale('en-us')}
                    minDate={DateTime.fromISO(handlingDate(props?.minDate), { setZone: true, zone: timezone }).setLocale('en-us')}
                    inputRef={dateRef}
                    disableFuture={props?.disableFuture}
                    format={user?.tenant?.dateFormat !== null ? user?.tenant?.dateFormat : "MMM dd, yyyy"}
                    timezone={timezone}
                    required={props?.required}
                    // inputFormat={user?.tenant?.dateFormat !== null ? user?.tenant?.dateFormat : "MMM dd, yyyy"}
                    reduceAnimations={true}
                    sx={{
                        backgroundColor: "white",
                        width: '100%',
                        borderRadius: '10px'
                    }}
                    shouldDisableYear={
                        yearLimit
                    }
                    slotProps={{
                        textField: {
                            inputProps: {
                                required: props?.required,
                                placeholder: user?.tenant?.dateFormat ?? "MMM dd, yyyy",
                                defaultValue: user?.tenant?.dateFormat ?? "MMM dd, yyyy",
                                // value: value2,
                                // ...props
                                // readOnly: true
                            },
                            InputProps: {
                                classes: {
                                    // root: classes.root,
                                    // focused: classes.cssFocused,
                                    notchedOutline: borderColor === undefined ? error ? classes.notchedErrorOutline : classes.notchedOutline : classes.notchedOutWhiteline,
                                },
                                sx: {
                                    fontFamily: 'Montserrat',
                                    fontSize: props?.f12 ? "12px" : props?.f13 ? "13px" : "14px",
                                    maxWidth: "100%",
                                    fontWeight: fontWeight,
                                    fieldset: {
                                        borderRadius: "10px",
                                        border: borderColor !== undefined && "0px",
                                    },
                                }
                            },
                            // variant: "outlined",
                            size: 'small',
                        },
                        closeOnSelect: false,
                        actionBar: {
                            actions: ['clear', 'cancel'],
                        },
                        // toolbar: {
                        //     // Customize value display
                        //     toolbarFormat: 'YYYY',
                        //     // Change what is displayed given an empty value
                        //     toolbarPlaceholder: '??',
                        //     // Show the toolbar
                        //     hidden: false,
                        // },
                    }}
                    value={
                        props?.date !== undefined
                            ? DateTime.fromISO(startDate, { setZone: true, zone: timezone }).setLocale('en-us')
                            : value
                    }
                    views={['month', 'year', 'day']}
                    onChange={handleDate}
                    onError={handleError}
                    disabled = {props?.disabled ? true:false}
                />
            </LocalizationProvider>
        </ThemeProvider>
    )
})

export default withStyles(styles)(CalenderDatePicker);