import { useEffect, useState } from "react";
import {
  file_uploading_helping_strings,
  numbersEnum,
  tenantSettingStrings,
  common_strings
} from "../../res/strings";
import sendHttpRequest, { search } from "../hooks/sendHttpRequest";
import classes from "./TenantSettingsPage.module.css";
import { Backdrop, CircularProgress, LinearProgress, Skeleton, Tab, Tabs } from "@mui/material";
import PhoneNumberInput from "../UI/Reusable/PhoneNumberInput";
import { FileUploader } from "react-drag-drop-files";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useAuth } from "../hooks/useAuth";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import { useNavigate } from "react-router";
import AddIcon from '@mui/icons-material/Add';
import PageHeader from "../../patientportal/generic/PageHeader";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import {
  formatPhoneNumber,
  formatPostalCode,
  truncate,
  validateEmail,
} from "../../utils/helpers/HelperFunctions";
import {
  ERROR_MESSAGES,
  FIELD_NAMES,
  MIN_MAX_VALUES,
} from "../validations/validationschemas";
import { ValidationErrorMessage } from "../validations/ValidationErrorMessage";
import { useDispatch } from "react-redux";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { isValidPhoneNumber } from "react-phone-number-input";
import { WarningIcon } from "../UI/Reusable/WarningIcon";
import { Typeahead } from "react-bootstrap-typeahead";
import options from "../../utils/data";
import { useFlags } from "launchdarkly-react-client-sdk";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
import { PnToolTip, TabPanel, theme } from "../UI/Reusable/PnComponents";
import { debounce } from "lodash";
import Sticky from "react-stickynode";
import { ThemeProvider } from "@mui/material";
import { searchProviderUrl } from "../../res/endpointurls";
import SearchIcon from "@mui/icons-material/Search";
import EnrollmentStatusModal from "../UI/Modals/ProviderModals/EnrollmentStatusModal";
import FeeSchedule from "../../billing-and-claims/fee-schedule/FeeSchedule";

// List Item
const ProviderListItem = ({ list, handleDelete, type, hideAction }) => {
  return (
    <>
      {list?.length > 0
        ? list.map((item) => {
          return (
            <div className="d-flex flex-row justify-content-between font-12 text--terciary mt-2" key={item?.userId}>
              <div className="">
                <span>
                  {
                    item?.name[0]?.prefix.concat(" ", item?.name[0]?.firstName, " ", item?.name[0]?.lastName)
                  }
                </span>
              </div>
              <div>
                {hideAction !== true ? (
                  <img
                    src={TrashButtonIcon}
                    alt={"deleteIcon"}
                    id={
                      type === "exam" ? item?.labOrderTestId : item?.code
                    }
                    className="me-2 cursor--pointer"
                    style={{ width: "15px", height: "15px" }}
                    onClick={(e) => {
                      handleDelete(item);
                    }}

                  ></img>
                ) : null}
              </div>
            </div>
          );
        })
        : null}
    </>
  );
};
const SelectProvider = (props) => {
  const { user } = useAuth();
  const [providersList, setProvidersList] = useState([])
  const [isProviderListLoading, setIsProviderListLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [suggestions, setSuggestions] = useState([])
  const [searchInputValue, setSearchInputValue] = useState("")
  const [providerTempList, setProviderTempList] = useState(props?.selectedListOfProviders?.length > 0 ? props?.selectedListOfProviders : [])

  // console.log("providerTempList: ",providerTempList);


  const searchProvider = async (providerName) => {
    setIsProviderListLoading(true);
    setIsError(false);
    let apiUrl = searchProviderUrl(user?.tenantId);
    let param = {
      query: providerName
    }
    let httpResponse = await search({
      url: apiUrl,
      method: "GET",
      params: param,
    });
    if (!httpResponse?.error) {
      setSuggestions(httpResponse?.data);
      // console.log(httpResponse);
    }
    else {
      // setSuggestions(httpResponse?.data);
      setIsError(true);
    }
    setIsProviderListLoading(false);
  }
  const handleOnChange = (inputValue) => {

    if (inputValue !== "") {
      // setProvider(inputValue)
      searchProvider(inputValue);
      setSearchInputValue(inputValue);
    } else {
      // setProvider('')
      setSuggestions([]);
      setIsProviderListLoading(false);
      setIsError(false);
      setSearchInputValue('')
    }
  };

  const handleProviderList = (providerObject) => {
    let ischecked = document.getElementById(providerObject.userId);
    let providerList = providerTempList?.filter((item) => {
      return item.userId !== providerObject.userId;
    });
    if (ischecked.checked === false) {
      if (providerList?.length === 0) {
        setProviderTempList([providerObject]);
      } else {
        setProviderTempList([...providerList, providerObject]);
      }
    } else {
      setProviderTempList([...providerList]);
    }
    ischecked.checked = !ischecked.checked;
  };
  const handleSearchIconBtn = (event) => {
    event.preventDefault();
    // console.log("event.target.value", searchInputValue);
    handleOnChange(searchInputValue);
  };
  const handleProviderSearch = (provider) => {
    // console.log("Patient ID: ", patient.id);
    // props?.handleProvidersAdd(provider);
    setSearchInputValue("");
  };

  useEffect(() => {
    setProviderTempList(props?.selectedListOfProviders ?? [])
  }, [props?.selectedListOfProviders])

  return (
    <>

      <label className="font-12 text--secondary fw-sb">{"Select providers to process claims at an individual level"}</label>
      <div className=" col-sm-6 col-6 mb-3">
        <div
          className={`input-group border--default  br-10 ${classes["search__container"]}`}
        >
          <input
            type="text"
            className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
            id="providerSearch"
            name="providerSearch"
            // ref={searchRef}
            autoComplete="off"
            placeholder="Search for providers"
            value={searchInputValue}
            disabled={props?.disabled}
            onChange={(event) =>
              handleOnChange(event.target.value)
            }
          />
          <button
            className={`btn br-10 shadow-none`}
            type="submit"
            value={searchInputValue}
          // onClick={() => handleExamSearchBtn(searchInputValue)}
          // disabled={props?.updateCheck ? true : false}
          // onBlur={() => {
          //   setTimeout(() => {
          //     setSuggestions([]);
          //     //   setIsError(false);
          //   }, 5);
          // }}
          >
            <SearchIcon className=" text--secondary" />
          </button>
        </div>

        <div
          className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
          style={{
            width: "28%",
          }}
        >
          {isProviderListLoading ? (
            <>
              <li>Loading...</li>
            </>
          ) : (
            searchInputValue?.length > 0 &&
              suggestions?.length > 0 ?
              suggestions.map((suggestion) => {
                return (
                  <li
                    className={`border--default ${classes.li}`}
                    // onClick={() => props.prescribeDrug(props.selectedDrug)}
                    onMouseDown={() => {
                      handleProviderList(suggestion)
                    }}
                    key={suggestion?.userId}
                  >
                    <input
                      type="checkbox"
                      className="cursor--pointer"
                      id={suggestion?.userId}
                      onChange={() =>
                        handleProviderList(suggestion)
                      }
                    />
                    <label className="ms-1">
                      {suggestion?.name[0]?.prefix + " " + suggestion?.name[0]?.firstName + " " + suggestion?.name[0]?.lastName}
                    </label>
                  </li>
                )
              }) :
              searchInputValue?.length > 1 &&
              (<li key={"not-found"}>{"Not Found"}</li>)
          )}
          {searchInputValue?.length > 0 &&
            suggestions?.length > 0 &&
            suggestions[0]?.code !== "-1" ? (
            <div
              style={{
                position: "sticky",
                display: "flex",
                justifyContent: "center",
                padding: "8px",
                backgroundColor: "white",
                gap: "4px",
                bottom: 0,
                left: 0,
              }}
            >
              <button
                className="btn  d9e8ec--button"
                style={{ height: "2.9 em !important" }}
                onClick={() => {
                  setSuggestions([]);
                }}
              >
                {"Cancel"}
              </button>
              <button
                className="btn blue_task_button"
                onClick={() => {
                  setSuggestions([]);
                  props?.setTenantSettingsData({ ...props?.tenantSettingsData, claimExcludedProviders: providerTempList })
                  props?.setSelectedListOfProviders(providerTempList);
                  setSearchInputValue("");
                }}
                disabled={providerTempList?.length === 0}
              >
                {"Add"}
              </button>
            </div>
          ) : null}
        </div>
      </div>

    </>
  )
}

const PracticeInformationInfo = ({
  tenantSettingsData,
  setTenantSettingsData,
  setSelectedFile,
  setFileType,
  setValidationErrors,
  validationErrors,
  phoneErrors,
  setPhoneErrors,
}) => {
  const dispatch = useDispatch();

  const [practiceCodes, setPracticeCodes] = useState([]);
  const [practiceCodesValue, setPracticeCodesValue] = useState();
  const [isPracticeCodesLoading, setIsPracticeCodesLoading] = useState(false);
  const __str = {
    ...tenantSettingStrings.en,
    ...numbersEnum.en,
    ...file_uploading_helping_strings.en,
    ...common_strings.en
  };
  const [cellPhoneValue, setCellPhoneValue] = useState(
    tenantSettingsData?.contactPerson?.communicationNumbers?.numbers?.find(
      (numberItem) => {
        return numberItem?.type === __str.cellPhoneExt;
      }
    )?.number || ""
  );
  const [fileName, setFileName] = useState([]);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [isFileValid, setIsFileValid] = useState(false);
  const [selectedFilePreview, setSelectedFilePreview] = useState(null);
  const MAX_UPLOAD_SIZE = parseInt(process.env.REACT_APP_LOGO_UPLOAD_FILE_SIZE);
  const [docAlias, setDocAlias] = useState("");

  // Field Validations
  const field_Names = {
    ...FIELD_NAMES.user.name,
    ...FIELD_NAMES.tenantSettings,
  };
  const TENANT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.tenantSettings,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.tenantSettings,
  };

  const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

  const fetchPracticeTypeCodes = async () => {
    setIsPracticeCodesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/claimsManagement/practiceTypeCodes/list",
      method: "GET",
    });
    if (!httpResponse.error) {
      const dataArray = Object.keys(httpResponse.data).map((key) => {
        return {
          name: key,
          code: httpResponse.data[key],
        };
      });

      // Sorting the array by code
      dataArray.sort((a, b) => {
        return a.code - b.code; // Assuming code is numeric, for strings, use localeCompare()
      });
      let practiceValue = dataArray?.find((s) => s.code === Number(tenantSettingsData?.practiceTypeCode))
      if (practiceValue !== undefined) {
        setPracticeCodesValue(`${practiceValue?.code} - ${practiceValue?.name}`);
      }


      // console.log("dataArray:", dataArray);
      setPracticeCodes(dataArray);
    }
    setIsPracticeCodesLoading(false);
  };

  const cellPhoneHandler = (phoneValue) => {
    setCellPhoneValue(phoneValue);
    if (
      phoneValue !== undefined &&
      phoneValue?.length == 12 &&
      isValidPhoneNumber(phoneValue?.toString())
    ) {
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      let comNumbers =
        tenantSettingsData?.contactPerson?.communicationNumbers?.numbers;
      comNumbers = [{ ...comNumbers[0], number: mobileNumber }];
      // console.log("comNumbers", comNumbers);
      dispatch(setDirtyBitInStore(true));
      setTenantSettingsData({
        ...tenantSettingsData,
        contactPerson: {
          ...tenantSettingsData?.contactPerson,
          communicationNumbers: {
            ...tenantSettingsData?.contactPerson?.communicationNumbers,
            numbers: comNumbers,
          },
        },
      });
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    }
  };
  const handleTenantBusinessName = (event) => {
    event.preventDefault();
    let tenantName = event.target.value;
    dispatch(setDirtyBitInStore(true));
    if (tenantName?.length > TENANT_VALIDATION.businessName.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.businessName]: validationErrorMsgs.businessName,
      });
    } else {
      let temp = validationErrors;
      delete temp.businessName;
      setValidationErrors(temp);
    }
    setTenantSettingsData({ ...tenantSettingsData, businessName: tenantName });
  };
  const handlePracticeTypeCode = (practiceCode) => {
    dispatch(setDirtyBitInStore(true));
    setTenantSettingsData({
      ...tenantSettingsData,
      practiceTypeCode: practiceCode[0]?.code,
    });
  };
  const handleTitle = (event) => {
    dispatch(setDirtyBitInStore(true));
    setTenantSettingsData({
      ...tenantSettingsData,
      contactPerson: {
        ...tenantSettingsData?.contactPerson,
        name: [
          {
            ...tenantSettingsData?.contactPerson?.name[0],
            prefix: event.target.value,
          },
        ],
      },
    });
  };
  const handleInputs = (event) => {
    let id = event.target.id;
    let value = event.target.value;
    dispatch(setDirtyBitInStore(true));

    if (id === "first-name") {
      if (value?.length > TENANT_VALIDATION.max) {
        setValidationErrors({
          ...validationErrors,
          [field_Names.firstNameMax]: validationErrorMsgs.firstNameMax,
        });
      } else {
        let temp = validationErrors;
        delete temp.firstNameMax;
        setValidationErrors(temp);
      }
      setTenantSettingsData({
        ...tenantSettingsData,
        contactPerson: {
          ...tenantSettingsData?.contactPerson,
          name: [
            {
              ...tenantSettingsData?.contactPerson?.name[0],
              firstName: event.target.value,
            },
          ],
        },
      });
    } else if (id === "last-name") {
      if (value?.length > TENANT_VALIDATION.max) {
        setValidationErrors({
          ...validationErrors,
          [field_Names.lastNameMax]: validationErrorMsgs.lastNameMax,
        });
      } else {
        let temp = validationErrors;
        delete temp.lastNameMax;
        setValidationErrors(temp);
      }
      setTenantSettingsData({
        ...tenantSettingsData,
        contactPerson: {
          ...tenantSettingsData?.contactPerson,
          name: [
            {
              ...tenantSettingsData?.contactPerson?.name[0],
              lastName: event.target.value,
            },
          ],
        },
      });
    } else if (id === "email") {
      let isValidEmail = validateEmail(value);
      // console.log("validEmail: ",isValidEmail);
      if (isValidEmail) {
        setTenantSettingsData({
          ...tenantSettingsData,
          contactPerson: {
            ...tenantSettingsData?.contactPerson,
            communicationNumbers: {
              ...tenantSettingsData?.contactPerson?.communicationNumbers,
              electronicMail: [value],
            },
          },
        });
        let temp = validationErrors;
        delete temp.emailError;
        setValidationErrors(temp);
      } else {
        setValidationErrors({
          ...validationErrors,
          ["emailError"]: "Invalid email address",
        });
      }
    }
    else if (id === "addressLine1") {
      // if (value?.length > TENANT_VALIDATION.addressLine1.max) {
      //   setValidationErrors({
      //     ...validationErrors,
      //     [field_Names.addressLine1]: validationErrorMsgs.addressLine1,
      //   });
      // } else {
      //   let temp = validationErrors;
      //   delete temp.addressLine1;
      //   setValidationErrors(temp);
      // }
      setTenantSettingsData({
        ...tenantSettingsData,
        addressLine1: value,
      });
    }
    else if (id === "addressLine2") {
      setTenantSettingsData({
        ...tenantSettingsData,
        addressLine2: value,
      });
    }
    else if (id === "npi") {
      // console.log(/^\d+$/.test(value));
      if (value?.length > TENANT_VALIDATION.npi.max) {
        setValidationErrors({
          ...validationErrors,
          [field_Names.npi]: validationErrorMsgs.npi,
        });
      } else {
        let temp = validationErrors;
        delete temp.npi;
        setValidationErrors(temp);
      }
      setTenantSettingsData({
        ...tenantSettingsData,
        groupNpi: value,
      });
    } else if (id === "city") {
      setTenantSettingsData({
        ...tenantSettingsData,
        city: value,
      });
    } else if (id === "zipCode") {
      value = value.replace("-", "");
      if (value?.length <= 9) {
        setTenantSettingsData({
          ...tenantSettingsData,
          postalCode: value,
        });
      }
    }
  };
  const setSaveAs = (fileName) => {
    // document.getElementById("saveAs").value = fileName;
    setDocAlias(fileName);
  };
  const handleFileInput = (file) => {
    dispatch(setDirtyBitInStore(true));
    if (file.size < MAX_UPLOAD_SIZE) {
      let filePath = file.name;
      var allowedExtensions = /(\.png|\.jpeg|\.jpg\.PNG|\.JPEG|\.JPG)$/i;
      if (!allowedExtensions.exec(filePath)) {
        file = "";
        setInvalidFileType(true);
      } else {
        setIsFileValid(true);
        const temp = [];
        temp.push(file);
        setFileName(temp);
        setSelectedFile(file);
        setInvalidFileType(false);
        setSaveAs(file.name);
        setSelectedFilePreview(file);
      }
      setInvalidFileSize(false);
    } else {
      file = "";
      setInvalidFileSize(true);
      setIsFileValid(false);
    }
  };

  const handlePreset = (stateValue) => {
    dispatch(setDirtyBitInStore(true));
    setTenantSettingsData({
      ...tenantSettingsData,
      state: stateValue[0]?.code,
    });
  };

  useEffect(() => {
    fetchPracticeTypeCodes();
  }, []);
  return (
    <>
      <div className="container p-3  br-10">
        <div className="row">
          {/* <div className="col-12">
            <h2 className="text--terciary font-18 ">
              {__str.practice_info_str}
            </h2>
          </div> */}
          {/* Practice Name */}
          <div className="col-6 mt-2">
            <label
              htmlFor="practice-name"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.practice_name_str}
            </label>
            <input
              type="text"
              autoComplete={false}
              className="form-control font-12 border--default br-10 shadow-none input"
              id="practice-name"
              defaultValue={tenantSettingsData?.businessName ?? ""}
              onChange={(e) => {
                handleTenantBusinessName(e);
              }}
            />
            <ValidationErrorMessage
              fieldName={field_Names.businessName}
              errors={validationErrors}
            />
          </div>
          {/* Practice User Type Code */}
          {/* <div className="col-6 mt-2">
            <label
              htmlFor="practice-type-code"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.practice_type_code_str}
            </label>
            {isPracticeCodesLoading ? (
              <Skeleton />
            ) : (
              <select
                className={`form-select  fst-italic font-12  border--default br-10 shadow-none input`}
                aria-label="action select"
                style={{
                  border: "1px solid #D9E8EC",
                  //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                  height: "36px",
                  // fontStyle:"italic"
                }}
                onChange={(e) => handlePracticeTypeCode(e)}
              >
                <option
                  className="text--terciary"
                  value={""}
                  key={1}
                  selected
                  disabled
                  hidden
                >
                  {"Select Practice Type"}
                </option>
                {practiceCodes !== undefined &&
                  practiceCodes?.map((practiceCodeItem) => {
                    return (
                      <option
                        key={practiceCodeItem?.code}
                        value={practiceCodeItem?.code}
                        id={practiceCodeItem?.code}
                        selected={
                          Number(tenantSettingsData?.practiceTypeCode) ===
                          Number(practiceCodeItem?.code)
                        }
                        className={"fst-normal"}
                      >
                        {"".concat(
                          practiceCodeItem?.code,
                          " - ",
                          practiceCodeItem?.name
                        )}
                      </option>
                    );
                  })}
              </select>
            )}
          </div> */}
          <div className="col-6 mt-2">
            <label
              htmlFor="State"
              className="form-label fw-sb m-0 font-12 text--secondary"
            >
              Select Practice Type
            </label>
            {
              isPracticeCodesLoading ? <Skeleton /> :
                <Typeahead
                  id="practiceTypeCode"
                  name="practiceTypeCode"
                  labelKey={(option) => `${option.code} - ${option.name}`}
                  onChange={handlePracticeTypeCode}
                  options={practiceCodes ?? []}
                  className="rbt-menu my-select font-12"
                  // selected={tenantSettingsData?.state !== undefined ? options.find(s => s.code === tenantSettingsData?.state)?.code : null}
                  defaultInputValue={practiceCodesValue ?? ""}
                  style={{ borderRadius: "10px" }}
                  autoComplete="off"
                  inputProps={{ className: "font-14" }}
                />
            }
          </div>
          {/* Title */}
          <div className="col-2 mt-2">
            <label
              htmlFor="title"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.title_str}
            </label>
            <select
              className={`form-select br-10 border--default font-12 shadow-none fst-italic`}
              aria-label="action select"
              style={{
                height: "36px",
              }}
              onChange={(e) => {
                handleTitle(e);
              }}
            >
              <option
                value=""
                id="select-title"
                key="select-title"
                selected
                hidden
                disabled
              >
                {"Select Title"}
              </option>
              {["Dr.", "Mr.", "Ms."].map((item) => {
                return (
                  <option
                    value={item}
                    id={item}
                    key={item}
                    selected={
                      tenantSettingsData?.contactPerson?.name?.length > 0
                        ? tenantSettingsData?.contactPerson?.name[0]?.prefix ===
                        item
                        : false
                    }
                    className={"fst-normal"}
                  >
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {/* First name */}
          <div className="col-4 mt-2">
            <label
              htmlFor="first-name"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.first_name_str}
            </label>
            <input
              type="text"
              autoComplete={false}
              className="form-control font-14 border--default br-10 shadow-none input"
              id="first-name"
              defaultValue={
                tenantSettingsData?.contactPerson?.name?.length > 0
                  ? tenantSettingsData?.contactPerson?.name[0]?.firstName
                  : ""
              }
              onChange={(e) => {
                handleInputs(e);
              }}
            />
            <ValidationErrorMessage
              fieldName={field_Names.firstNameMax}
              errors={validationErrors}
            />
          </div>
          {/* Last name */}
          <div className="col-6 mt-2">
            <label
              htmlFor="last-name"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.last_name_str}
            </label>
            <input
              type="text"
              autoComplete={false}
              className="form-control font-14 border--default br-10 shadow-none input"
              id="last-name"
              defaultValue={
                tenantSettingsData?.contactPerson?.name?.length > 0
                  ? tenantSettingsData?.contactPerson?.name[0]?.lastName
                  : ""
              }
              onChange={(e) => {
                handleInputs(e);
              }}
            />
            <ValidationErrorMessage
              fieldName={field_Names.lastNameMax}
              errors={validationErrors}
            />
          </div>
          {/* Email */}
          <div className="col-6 mt-2">
            <label
              htmlFor="email"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.email_str}
            </label>
            <input
              type="email"
              autoComplete={false}
              className="form-control font-14 border--default br-10 shadow-none input"
              id="email"
              defaultValue={
                tenantSettingsData?.contactPerson?.communicationNumbers
                  ?.electronicMail[0] !== undefined
                  ? tenantSettingsData?.contactPerson?.communicationNumbers
                    ?.electronicMail[0]
                  : ""
              }
              onChange={(e) => {
                handleInputs(e);
              }}
            />
            <ValidationErrorMessage
              fieldName={"emailError"}
              errors={validationErrors}
            />
          </div>
          {/* Phone */}
          <div className="col-6 mt-2">
            <label
              htmlFor="phone"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.phone_str}
            </label>
            <div>
              <PhoneNumberInput
                onChange={cellPhoneHandler}
                id="phone"
                key={"phone"}
                // reference={phoneRef}
                value={cellPhoneValue || ""}
                required={false}
              />
              <span className="text--danger  font-10 ms-2 mt-1">
                {phoneErrors?.primary === true
                  ? "Incorrect number format"
                  : " "}
              </span>
            </div>
          </div>

          {/* Address */}
          <div className="col-6 mt-2">
            <label
              htmlFor="addressLine1"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.addressLine1}
            </label>
            <div>
              <input
                type="text"
                autoComplete={false}
                className="form-control font-14 border--default br-10 shadow-none input"
                id="addressLine1"
                defaultValue={tenantSettingsData?.addressLine1 ?? ""}
                onChange={(e) => {
                  handleInputs(e);
                }}
              />
            </div>
            <ValidationErrorMessage
              fieldName={field_Names.addressLine1}
              errors={validationErrors}
            />
          </div>
          <div className="col-6 mt-2">
            <label
              htmlFor="addressLine2"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.addressLine2}
            </label>
            <div>
              <input
                type="text"
                autoComplete={false}
                className="form-control font-14 border--default br-10 shadow-none input"
                id="addressLine2"
                defaultValue={tenantSettingsData?.addressLine2 ?? ""}
                onChange={(e) => {
                  handleInputs(e);
                }}
              />
            </div>
            <ValidationErrorMessage
              fieldName={field_Names.addressLine1}
              errors={validationErrors}
            />
          </div>
          {/* City */}
          <div className="col-md-6 mt-2">
            <label
              htmlFor="city"
              className="form-label fw-sb m-0 font-12 text--secondary"
            >
              City
            </label>
            <input
              type="text"
              onChange={(e) => {
                handleInputs(e);
              }}
              value={tenantSettingsData?.city ?? ""}
              // onBlur={() => handleStandardAddress()}
              // onFocus={() => handleStandardAddress()}
              autoComplete="off"
              className="form-control font-14 border--default br-10 shadow-none input"
              id="city"
              name="city"
            />
          </div>
          {/* State */}
          <div className="col-md-3 mt-2">
            <label
              htmlFor="State"
              className="form-label fw-sb m-0 font-12 text--secondary"
            >
              State
            </label>
            <Typeahead
              id="state"
              name="state"
              labelKey="name"
              onChange={handlePreset}
              options={options}
              className="rbt-menu my-select font-12"
              // selected={tenantSettingsData?.state !== undefined ? options.find(s => s.code === tenantSettingsData?.state)?.code : null}
              defaultInputValue={
                tenantSettingsData?.state !== undefined
                  ? options.find((s) => s.code === tenantSettingsData?.state)
                    ?.name
                  : tenantSettingsData?.state
              }
              style={{ borderRadius: "10px" }}
              autoComplete="off"
              inputProps={{ className: "font-14" }}
            />
          </div>
          {/* Zip Code */}
          <div className="col-md-3 mt-2">
            <label
              htmlFor="zipCode"
              className="form-label fw-sb m-0 font-12 text--secondary"
            >
              Zip Code
            </label>
            <input
              type="text"
              onChange={(e) => handleInputs(e)}
              defaultValue={
                tenantSettingsData?.postalCode !== undefined
                  ? formatPostalCode(tenantSettingsData?.postalCode)
                  : ""
              }
              // onBlur={handleStandardAddress}
              // onFocus={handleStandardAddress}
              className="form-control font-14 border--default br-10 shadow-none input"
              id="zipCode"
              name="zipCode"
              maxLength={10}
            />
          </div>

          {/* Group NPI */}
          <div className="col-6 mt-2">
            <label
              htmlFor="npi"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.group_npi_str}
            </label>
            <div>
              <input
                type="number"
                autoComplete={false}
                className="form-control font-14 border--default br-10 shadow-none input"
                id="npi"
                // maxLength={10}
                min={0}
                pattern={"[0-9]*"}
                value={tenantSettingsData?.groupNpi ?? ""}
                onChange={(e) => {
                  handleInputs(e);
                }}
              />
            </div>
            <ValidationErrorMessage
              fieldName={field_Names.npi}
              errors={validationErrors}
            />
          </div>
          {/* File Uploader */}
          <div className="col-12 mt-2">
            <label
              htmlFor="npi"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.company_logo_str}
            </label>
            <div>
              <FileUploader
                handleChange={(f) => {
                  handleFileInput(f);
                  // onDirty();
                }}
                name="file"
                label="Drop a file or click here"
                types={fileTypes}
                onTypeError={
                  () => setInvalidFileType(true)
                }
                required
              >
                <div className={`py-4 border--dashed  bg--white ${tenantSettingsData?.imagePath !== null ? 'flex-column' : ''} br-10 d-flex justify-content-center`}>
                  {tenantSettingsData?.imagePath !== null && selectedFilePreview === null ? <div className="text-center">
                    <img
                      alt="Tenant Logo"
                      className="img img-fluid w-50  img-thumbnail"
                      src={tenantSettingsData.imagePath}
                    ></img>

                  </div> : selectedFilePreview !== null ? <div className={`text-center`}><img className="img img-fluid w-50  img-thumbnail" src={URL.createObjectURL(selectedFilePreview)} alt={selectedFilePreview.name} /></div> : null
                  }
                  <div className={`font-12 ${tenantSettingsData?.imagePath !== null ? 'text-center' : ''}`}>
                    <span className="ms-4">
                      <u className="file-link font-12">{"select a file"}</u>
                      &nbsp;
                      {"or drag and drop here"}
                    </span>
                    <br />
                    {"(recommended resolution: 300"} &#215; {"60 pixels)"}
                  </div>
                </div>
              </FileUploader>
              {
                <span className="font-12 text--secondary fw-sb d-block">
                  {docAlias !== undefined ? docAlias : ""}
                </span>
              }
              {invalidFileType ? (
                <>

                  <span className="font-12 d-block fw-sb my-1 text-danger">
                    File type is invalid
                  </span>
                </>
              ) : (
                ""
              )}
              {invalidFileSize ? (
                <>

                  <span className="font-12 d-block fw-sb my-1 text-danger">
                    File size exceeded the maximum limit of 5 MB
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AppointmentSettings = ({
  tenantSettingsData,
  setTenantSettingsData,
  validationErrors,
  setValidationErrors,
  setIsDateFormatUpdated,
}) => {
  const { appointmentPricing } = useFlags();
  const __str = tenantSettingStrings.en;
  const [isTimezoneLoading, setIsTimezoneLoading] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const dispatch = useDispatch();
  // Field Validations
  const field_Names = {
    ...FIELD_NAMES.user.name,
    ...FIELD_NAMES.tenantSettings,
  };
  const TENANT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.tenantSettings,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.tenantSettings,
  };

  const fetchTimeZone = async () => {
    setIsTimezoneLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimeZoneList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsTimezoneLoading(false);
  };
  const handleDateFormat = (event) => {
    dispatch(setDirtyBitInStore(true));
    setTenantSettingsData({
      ...tenantSettingsData,
      dateFormat: event.target.value,
    });
    setIsDateFormatUpdated(true);
  };
  const handleEligibilityCheck = (event) => {
    dispatch(setDirtyBitInStore(true));
    setTenantSettingsData({
      ...tenantSettingsData,
      checkEligibility: event.target.checked,
    });
  };
  const handleEligibilityHours = (event) => {
    dispatch(setDirtyBitInStore(true));
    setTenantSettingsData({
      ...tenantSettingsData,
      checkEligibilityTime: event.target.value,
    });
  };

  const handleInputs = (event) => {
    dispatch(setDirtyBitInStore(true));
    let id = event.target.id;
    let value = event.target.value;
    if (id === "consult-length") {
      if (value > TENANT_VALIDATION.consultMints.max) {
        setValidationErrors({
          ...validationErrors,
          [field_Names.consultMints]: validationErrorMsgs.consultMints,
        });
      } else {
        let temp = validationErrors;
        delete temp.consultMints;
        setValidationErrors(temp);
      }
      setTenantSettingsData({
        ...tenantSettingsData,
        consultationLengthMinutes: value,
      });
    } else if (id === "length-follow-up") {
      if (value > TENANT_VALIDATION.followUp.max) {
        setValidationErrors({
          ...validationErrors,
          [field_Names.followUp]: validationErrorMsgs.followUp,
        });
      } else {
        let temp = validationErrors;
        delete temp.followUp;
        setValidationErrors(temp);
      }
      setTenantSettingsData({
        ...tenantSettingsData,
        followUpMinutes: value,
      });
    } else if (id === "timezone") {
      let timezone = value;
      let offset =
        event.target[event.target.selectedIndex].getAttribute("data-offset");
      setTenantSettingsData({
        ...tenantSettingsData,
        timezone: timezone,
        timezoneOffset: offset,
      });
    } else if (id === "consult-price") {
      setTenantSettingsData({
        ...tenantSettingsData,
        consultationPrice: value,
      });
    } else if (id === "price-follow-up") {
      setTenantSettingsData({
        ...tenantSettingsData,
        followUpPrice: value,
      });
    }
  };

  // console.log("tenantSettingsData?.checkEligibilityTime:",tenantSettingsData?.checkEligibilityTime,typeof(tenantSettingsData?.checkEligibilityTime));

  useEffect(() => {
    fetchTimeZone();
  }, []);

  return (
    <>
      <div className="row p-3 br-10">
        {/* <div className="col-12">
          <h2 className="text--terciary font-18 ">{__str.appt_settings_str}</h2>
        </div> */}
        {/* <Divider sx={{ border: "1px solid #d9e8ec" }} /> */}
        <AddServiceItems
          tenantSettingsData={tenantSettingsData}
          setTenantSettingsData={setTenantSettingsData}
        />
        {/* Date format */}
        <div className="col-4 mt-3">
          <label
            htmlFor="length-follow-up"
            className="form-label font-12 text--secondary m-0 fw-sb"
          >
            {__str.date_format_str}
          </label>
          <select
            className={`form-select br-10 font-12 shadow-none input fst-italic`}
            aria-label="action select"
            style={{
              border: "1px solid #D9E8EC",
              height: "36px",
            }}
            onChange={(e) => handleDateFormat(e)}
          >
            <option
              key="date-format"
              id="date-format"
              value=""
              selected
              disabled
              hidden
            >
              {"Select Date Format"}
            </option>
            {["MM/dd/yyyy", "dd/mm/yyyy", "MMM dd, yyyy", "dd/MM/yyyy"].map(
              (item) => {
                return (
                  <option
                    key={item}
                    id={item}
                    value={item.replace("mm", "MM")}
                    selected={tenantSettingsData?.dateFormat === item}
                    className={"fst-normal"}
                  >
                    {item}
                  </option>
                );
              }
            )}
          </select>
        </div>
        {/* Timezone */}
        <div className="col-4 mt-3">
          <label
            htmlFor="Timezone"
            className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
          >
            {__str.defualt_timezone_str}
          </label>
          {isTimezoneLoading ? (
            <Skeleton />
          ) : (
            <select
              // onChange={handleTimeZone}
              className={
                "form-select font-12 border--default br-10 shadow-none input br-10 fst-italic"
              }
              aria-label="Timezone"
              id="timezone"
              required
              // disabled={editMode}
              // ref={roleRef}
              onChange={(e) => {
                handleInputs(e);
              }}
            >
              <option className="fw-sb" disabled selected hidden value="">
                Select Time Zone
              </option>
              {timeZoneList?.length > 0
                ? timeZoneList?.map((item, index) => {
                  // console.log("item: ",item,user?.timezoneOffset);
                  return (
                    <option
                      key={item?.name}
                      value={item?.name}
                      data-offset={item?.offset}
                      selected={
                        item?.name == tenantSettingsData?.timezone
                          ? true
                          : false
                      }
                      className={"fst-normal"}
                    >
                      {item?.name} &nbsp; {item?.offset}
                    </option>
                  );
                })
                : ""}
            </select>
          )}
        </div>
        {/* Eligibility Check */}
        <div className="col-8 mt-2">
          <label
            htmlFor=""
            className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
          >
            &nbsp;
          </label>
          <div className="d-flex">
            <input
              key={"false"}
              className="form-check-input cursor--pointer align-self-center shadow-none mb-1"
              type="checkbox"
              name="eligibility-check"
              id={"eligibility-check"}
              onChange={(e) => {
                handleEligibilityCheck(e);
              }}
              defaultChecked={tenantSettingsData?.checkEligibility ?? false}
            />
            <label
              className="form-check-label font-12 align-self-center ms-2 me-2"
              htmlFor={"eligibility-check"}
            >
              {__str.patient_eligibility_str1}
            </label>
            <div className="col-1 g-0 m-0 p-0">
              <input
                type="number"
                id={"eligibility-hours"}
                className="form-control font-14 border--default br-10 shadow-none input"
                defaultValue={tenantSettingsData?.checkEligibilityTime}
                onChange={(e) => {
                  handleEligibilityHours(e);
                }}
              />
            </div>
            <label
              className="font-12 align-self-center ms-2"
              htmlFor="eligibility-hours"
            >
              {__str.patient_eligibility_str2}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

const NotificationSettings = ({
  tenantSettingsData,
  setTenantSettingsData,
  validationErrors,
  setValidationErrors,
  phoneErrors,
  setPhoneErrors,
}) => {
  const __str = tenantSettingStrings.en;
  const dispatch = useDispatch();
  const [cellPhoneValue, setCellPhoneValue] = useState(
    tenantSettingsData?.smsNumber ?? ""
  );

  const notification_email_hrs = tenantSettingsData?.notificationSetting?.find(
    (item) => {
      return item.durationUnit === "h" && item.sendType === "email";
    }
  );
  const notification_email_mins = tenantSettingsData?.notificationSetting?.find(
    (item) => {
      return item.durationUnit === "m" && item.sendType === "email";
    }
  );
  const notification_sms_hrs = tenantSettingsData?.notificationSetting?.find(
    (item) => {
      return item.durationUnit === "h" && item.sendType === "sms";
    }
  );
  const notification_sms_mins = tenantSettingsData?.notificationSetting?.find(
    (item) => {
      return item.durationUnit === "m" && item.sendType === "sms";
    }
  );

  // Field Validations
  const field_Names = {
    ...FIELD_NAMES.user.name,
    ...FIELD_NAMES.tenantSettings,
  };
  const TENANT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.tenantSettings,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.tenantSettings,
  };

  const cellPhoneHandler = (phoneValue) => {
    setCellPhoneValue(phoneValue);
    if (
      phoneValue !== undefined &&
      phoneValue?.length == 12 &&
      isValidPhoneNumber(phoneValue?.toString())
    ) {
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));

      dispatch(setDirtyBitInStore(true));
      setTenantSettingsData({
        ...tenantSettingsData,
        smsNumber: mobileNumber,
      });
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        notification: false,
      }));
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        notification: true,
      }));
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        notification: false,
      }));
    }
  };

  const handleInputs = (durationUnit, sendType, value, type) => {
    let tenantNotificationsTemp = tenantSettingsData.notificationSetting;
    let index = tenantNotificationsTemp.findIndex(
      (item) => item.durationUnit === durationUnit && item.sendType === sendType
    );
    if (type === "checkbox") {
      tenantNotificationsTemp[index].active = value;
    } else {
      tenantNotificationsTemp[index].duration = value;
    }
    setTenantSettingsData({
      ...tenantSettingsData,
      notificationSetting: [...tenantNotificationsTemp],
    });
  };
  const handleChange = (event) => {
    let id = event.target.id;
    if (id === "email-hrs-checkbox") {
      handleInputs("h", "email", event.target.checked, "checkbox");
    } else if (id === "email-mins-checkbox") {
      handleInputs("m", "email", event.target.checked, "checkbox");
    } else if (id === "email-hrs-input") {
      handleInputs("h", "email", event.target.value, "input");
    } else if (id === "email-mins-input") {
      handleInputs("m", "email", event.target.value, "input");
    } else if (id === "sms-hrs-checkbox") {
      handleInputs("h", "sms", event.target.checked, "checkbox");
    } else if (id === "sms-mins-checkbox") {
      handleInputs("m", "sms", event.target.checked, "checkbox");
    } else if (id === "sms-hrs-input") {
      handleInputs("h", "sms", event.target.value, "input");
    } else if (id === "sms-mins-input") {
      handleInputs("m", "sms", event.target.value, "input");
    } else if (id === "notification-email") {
      setTenantSettingsData({
        ...tenantSettingsData,
        emailAddress: event.target.value,
      });
    }
  };
  return (
    <>
      <div className="row p-3  br-10">
        <div className="col-sm-12 col-md-12">
          {/* <h2 className="font-18 text--terciary py-3">
            {__str.notification_settings_str}
          </h2> */}
          {/* Email input */}
          <div className="col-6">
            <label
              htmlFor="consult-length"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.from_email_str}
            </label>
            <input
              type="email"
              autoComplete={false}
              className="form-control font-14 border--default br-10 shadow-none input"
              id="notification-email"
              defaultValue={
                tenantSettingsData?.notificationSetting?.length > 0
                  ? tenantSettingsData?.notificationSetting[0]?.emailAddress
                  : ""
              }
              disabled
            />
          </div>
          {/* Email Reminder */}
          <div className="g-1 row row-cols-lg-auto align-items-center align-self-center  py-1 mt-2">
            <div className="col-12">
              <div className="form-check my-auto">
                <input
                  className="form-check-input cursor--pointer align-self-center shadow-none"
                  type="checkbox"
                  id="email-hrs-checkbox"
                  defaultChecked={notification_email_hrs?.active}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label className="form-check-label font-12">Send email</label>
              </div>
            </div>
            <div className="col-12">
              <input
                value={notification_email_hrs?.duration}
                type="number"
                // disabled
                id="email-hrs-input"
                className="form-control d-inline br-10 font-12  border--default shadow-none input"
                style={{ width: "13%", marginRight: "1%" }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <label className="form-check-label  font-12">
                hours before appointment
              </label>
            </div>
          </div>
          <div className=" g-1 row row-cols-lg-auto align-items-center align-self-center  py-1 mt-2">
            <div className="col-12">
              <div className="form-check my-auto">
                <input
                  className="form-check-input cursor--pointer align-self-center shadow-none"
                  type="checkbox"
                  id="email-mins-checkbox"
                  defaultChecked={notification_email_mins?.active}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label className="form-check-label font-12">Send email</label>
              </div>
            </div>
            <div className="col-12">
              <input
                type="number"
                value={notification_email_mins?.duration}
                // disabled
                id="email-mins-input"
                className="form-control d-inline br-10 font-12  border--default shadow-none input"
                style={{ width: "12%", marginRight: "1%" }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <label className="form-check-label  font-12">
                minutes before appointment
              </label>
            </div>
          </div>

          {/* SMS input */}
          <div className="col-6 mt-2">
            <label
              htmlFor="consult-length"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.from_number_str}
            </label>
            <div>
              <PhoneNumberInput
                onChange={cellPhoneHandler}
                id="phone"
                key={"phone"}
                // reference={phoneRef}
                value={cellPhoneValue || ""}
                required={false}
                disabled={false}
              />
              <span className="text--danger  font-10 ms-2 mt-1">
                {phoneErrors?.notification === true
                  ? "Incorrect number format"
                  : " "}
              </span>
            </div>
          </div>
          {/* SMS Reminder */}
          <div className="g-1 row row-cols-lg-auto align-items-center align-self-center  py-1 mt-2">
            <div className="col-12">
              <div className="form-check my-auto">
                <input
                  className="form-check-input cursor--pointer align-self-center shadow-none"
                  type="checkbox"
                  id="sms-hrs-checkbox"
                  defaultChecked={notification_sms_hrs?.active}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label className="form-check-label font-12">
                  Send reminder
                </label>
              </div>
            </div>
            <div className="col-12">
              <input
                value={notification_sms_hrs?.duration}
                className="form-control d-inline br-10 font-12  border--default shadow-none input"
                style={{ width: "13%", marginRight: "1%" }}
                id="sms-hrs-input"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <label className="form-check-label  font-12">
                hours before appointment
              </label>
            </div>
          </div>
          <div className=" g-1 row row-cols-lg-auto align-items-center align-self-center  py-1 mt-2">
            <div className="col-12">
              <div className="form-check my-auto">
                <input
                  className="form-check-input cursor--pointer align-self-center shadow-none"
                  type="checkbox"
                  id="sms-mins-checkbox"
                  defaultChecked={notification_sms_mins?.active}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label className="form-check-label font-12">
                  Send reminder
                </label>
              </div>
            </div>
            <div className="col-12">
              <input
                value={notification_sms_mins?.duration}
                className="form-control d-inline br-10 font-12  border--default shadow-none input"
                style={{ width: "13%", marginRight: "1%" }}
                id="sms-mins-input"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <label className="form-check-label  font-12">
                minutes before appointment
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const SubscriptionDetails = ({
  tenantSettingsData,
  setTenantSettingsData,
  validationErrors,
  setValidationErrors,
}) => {
  const __str = tenantSettingStrings.en;
  // Field Validations
  const field_Names = {
    ...FIELD_NAMES.user.name,
    ...FIELD_NAMES.tenantSettings,
  };
  const TENANT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.tenantSettings,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.tenantSettings,
  };

  const handleSkipIndentification = (event) => {
    setTenantSettingsData({
      ...tenantSettingsData,
      skipIdentityVerification: event.target.checked,
    });
  };
  const handleNoOfUsers = (event) => {
    let tenantConfiguration =
      tenantSettingsData?.tenantConfiguration?.length > 0
        ? tenantSettingsData?.tenantConfiguration[0]
        : [];
    tenantConfiguration.numberOfProviders = Number(event.target.value);

    if (
      Number(event.target.value) >
      TENANT_VALIDATION.numberOfPrescribingUsers.max
    ) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.numberOfPrescribingUsers]:
          validationErrorMsgs.numberOfPrescribingUsers,
      });
    } else {
      let temp = validationErrors;
      delete temp.numberOfPrescribingUsers;
      setValidationErrors(temp);
    }
    setTenantSettingsData({
      ...tenantSettingsData,
      tenantConfiguration: [tenantConfiguration],
    });
  };
  const handleContractStartDate = (dateValue) => {
    // console.log("dateValue: ", dateValue);
    let tenantConfiguration = tenantSettingsData?.tenantConfiguration[0] ?? [];
    tenantConfiguration.contractStartDate = dateValue;
    setTenantSettingsData({
      ...tenantSettingsData,
      tenantConfiguration: [tenantConfiguration],
    });
  };
  const handleContractEndDate = (dateValue) => {
    let tenantConfiguration = tenantSettingsData?.tenantConfiguration[0] ?? [];
    tenantConfiguration.contractEndDate = dateValue;
    setTenantSettingsData({
      ...tenantSettingsData,
      tenantConfiguration: [tenantConfiguration],
    });
  };
  return (
    <>
      <div className="row p-3  br-10">
        <div className="col-sm-12 col-md-12">
          {/* <h2 className="font-18 text--terciary py-3">
            {__str.subscription_str}
          </h2> */}
        </div>
        {/* prescribing-users */}
        <div className="col-6 mt-2">
          <label
            htmlFor="prescribing-users"
            className="form-label font-12 text--secondary m-0 fw-sb"
          >
            {__str.prescribing_users_str}
          </label>
          <input
            type="number"
            autoComplete={false}
            className="form-control font-14 border--default br-10 shadow-none input"
            id="prescribing-users"
            min={0}
            defaultValue={
              tenantSettingsData?.tenantConfiguration?.length > 0
                ? tenantSettingsData?.tenantConfiguration[0]?.numberOfProviders
                : ""
            }
            onChange={(e) => {
              handleNoOfUsers(e);
            }}
            disabled
          />
          <ValidationErrorMessage
            fieldName={field_Names.numberOfPrescribingUsers}
            errors={validationErrors}
          />
        </div>
        {/* Identification */}
        <div className="col-6 mt-2">
          <div className="">
            <label
              className="form-label "
              htmlFor={"identification-verification"}
            >
              &nbsp;
            </label>
          </div>
          <input
            // key={"false"}
            className="form-check-input cursor--pointer align-self-center shadow-none shadow-none"
            type="checkbox"
            name="identification-verification"
            id={"identification-verification"}
            onChange={(e) => {
              handleSkipIndentification(e);
            }}
            defaultChecked={tenantSettingsData?.skipIdentityVerification}
          />
          <label
            className="form-check-label font-12 align-self-center ms-2 me-2"
            htmlFor={"identification-verification"}
          >
            {__str.verification_str}
          </label>
        </div>
        {/* Contract Dates */}

        <div className="col-6 mt-2">
          <div className="col-6" disabled>
            {/* <h3 className="font-12 m-0">{}</h3> */}
            <label
              htmlFor="startDate"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.contract_start_str}
            </label>
            <CalenderDatePicker
              key={"startDate"}
              name="startDate"
              id={"startDate"}
              f13={true}
              date={calenderDateTimeHelper(
                tenantSettingsData?.tenantConfiguration?.length > 0
                  ? tenantSettingsData?.tenantConfiguration[0]
                    ?.contractStartDate
                  : new Date(),
                "YYYY-MM-DD"
              )}
              dobHandler={(e) => {
                handleContractStartDate(e);
              }}
              dateFormat="MMM d, yyyy"
              // maxDate={new Date()}
              disabled={true}
            />
          </div>
        </div>
        {/* Contract end date */}
        <div className="col-6 mt-2">
          <div className="col-6">
            {/* <h3 className="font-12 m-0">{}</h3> */}
            <label
              htmlFor="contractEndDate"
              className="form-label font-12 text--secondary m-0 fw-sb"
            >
              {__str.contract_end_str}
            </label>
            <CalenderDatePicker
              key={"contractEndDate"}
              name="contractEndDate"
              id={"contractEndDate"}
              f13={true}
              dobHandler={(e) => {
                handleContractEndDate(e);
              }}
              date={calenderDateTimeHelper(
                tenantSettingsData?.tenantConfiguration?.length > 0
                  ? tenantSettingsData?.tenantConfiguration[0]?.contractEndDate
                  : new Date(),
                "YYYY-MM-DD"
              )} // dobHandler={(e) => {
              dateFormat="MMM d, yyyy"
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PaymentSettings = ({
  tenantSettingsData,
  setTenantSettingsData,
  validationErrors,
  setValidationErrors,
}) => {
  const __str = tenantSettingStrings.en;

  const hanndleInputs = (event) => {
    let { id, value } = event.target;

    if (id === "merchant_id") {
      setTenantSettingsData({
        ...tenantSettingsData,
        brainTreeMerchantId: value,
      });
    } else if (id === "public-key") {
      setTenantSettingsData({
        ...tenantSettingsData,
        brainTreePublicKey: value,
      });
    } else if (id === "private-key") {
      setTenantSettingsData({
        ...tenantSettingsData,
        brainTreePrivateKey: value,
      });
    }
  };
  return (
    <>
      <div className="row p-3  br-10">
        <div className="col-sm-12 col-md-12">
          {/* <h2 className="font-18 text--terciary py-3">
            {__str.paypal_settings_str}
          </h2> */}
        </div>
        {/*  Merchant id */}
        <div className="col-6 mt-2">
          <label
            htmlFor="merchant_id"
            className="form-label font-12 text--secondary m-0 fw-sb"
          >
            {__str.merchant_id_str}
          </label>
          <input
            type="text"
            autoComplete={false}
            className="form-control font-14 border--default br-10 shadow-none input"
            id="merchant_id"
            // min={0}
            defaultValue={
              tenantSettingsData?.brainTreeMerchantId?.length > 0
                ? tenantSettingsData?.brainTreeMerchantId
                : ""
            }
            onChange={(e) => {
              hanndleInputs(e);
            }}
          />
        </div>
        {/*  Public Key */}
        <div className="col-6 mt-2">
          <label
            htmlFor="public-key"
            className="form-label font-12 text--secondary m-0 fw-sb"
          >
            {__str.public_key_str}
          </label>
          <input
            type="text"
            autoComplete={false}
            className="form-control font-14 border--default br-10 shadow-none input"
            id="public-key"
            defaultValue={
              tenantSettingsData?.brainTreePublicKey?.length > 0
                ? tenantSettingsData?.brainTreePublicKey
                : ""
            }
            onChange={(e) => {
              hanndleInputs(e);
            }}
          />
        </div>
        {/*  Public Key */}
        <div className="col-6 mt-2">
          <label
            htmlFor="private-key"
            className="form-label font-12 text--secondary m-0 fw-sb"
          >
            {__str.private_key_str}
          </label>
          <input
            type="text"
            autoComplete={false}
            className="form-control font-14 border--default br-10 shadow-none input"
            id="private-key"
            defaultValue={
              tenantSettingsData?.brainTreePrivateKey?.length > 0
                ? tenantSettingsData?.brainTreePrivateKey
                : ""
            }
            onChange={(e) => {
              hanndleInputs(e);
            }}
          />
        </div>
      </div>
    </>
  );
};
const ServiceRowItem = ({
  item,
  index,
  tenantSettingsData,
  setTenantSettingsData,
  activeRowIndex,
}) => {
  const [activeRow, setActiveRow] = useState(false);
  const [activeTitle, setActiveTitle] = useState(false);
  const [activeDescription, setActiveDescription] = useState(false);
  const [activeDuration, setActiveDuration] = useState(false);
  const [activePrice, setActivePrice] = useState(false);

  const handleInputs = (value, itemObject, tagId) => {
    setTenantSettingsData((prevData) => {
      const updatedServices = prevData.tenantServices.map((item) => {
        if (item.title === itemObject.title && tagId === "title") {
          return { ...item, title: value };
        } else if (item.title === itemObject.title && tagId === "desc") {
          return { ...item, shortDescription: value };
        } else if (item.title === itemObject.title && tagId === "duration") {
          return { ...item, durationMinutes: value };
        } else if (item.title === itemObject.title && tagId === "price") {
          return { ...item, price: value };
        }
        return item;
      });

      return { ...prevData, tenantServices: updatedServices };
    });
  };

  const handleDelete = (item) => {
    let result = tenantSettingsData?.tenantServices.filter((serviceObject) => {
      return serviceObject.title !== item.title;
    });
    // setServiceItemsList([...result])
    setTenantSettingsData({
      ...tenantSettingsData,
      tenantServices: [...result],
    });
  };
  const debouncedHandleInputs = debounce(handleInputs, 100);
  return (
    <>
      {/* <div
         key={item?.title}
         className={`row border--bottom`}
      > */}
      <div className="col-2 mt-2 text--terciary font-12">
        {index > 1 && activeTitle ? (
          <input
            type={"text"}
            autoComplete="off"
            className={`form-control font-14 border--default br-10 shadow-none input ${classes["service__charges"]}`}
            defaultValue={item?.title}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                debouncedHandleInputs(e.target.value, item, "title");
                setActiveTitle(false);
              }
            }}
          />
        ) : (
          <PnToolTip title={item?.title}>
            <span
              className={`${index > 1 ? 'cursor--pointer' : ''} `}
              onClick={() => {
                setActiveTitle((pre) => true);
              }}
            >
              {truncate(item?.title, 14)}
            </span>
          </PnToolTip>
        )}
      </div>
      <div className="col-5 mt-2 text--terciary font-12">
        {index > 1 && activeDescription ? (
          <textarea
            type={"text"}
            autoComplete="off"
            className={`form-control font-14 border--default br-10 shadow-none input ${classes["service__charges"]}`}
            defaultValue={item?.shortDescription}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                debouncedHandleInputs(e.target.value, item, "desc");
                setActiveDescription(false);
              }
            }}
          />
        ) : (
          <PnToolTip title={item?.shortDescription}>
            <span
              className={`${index > 1 ? 'cursor--pointer' : ''}`}
              onClick={() => {
                setActiveDescription((pre) => true);
              }}
            >
              {item?.shortDescription === undefined ||
                item?.shortDescription?.length === 0 ? (
                <span>&nbsp;</span>
              ) : (
                truncate(item?.shortDescription, 100)
              )}{" "}
            </span>
          </PnToolTip>
        )}
      </div>
      <div className="col-2 mt-2 text--terciary font-12 ">
        {
          activeDuration ? (
            <input
              type={"number"}
              autoComplete="off"
              className={`form-control font-14 border--default br-10 shadow-none input ${classes["service__charges"]}`}
              defaultValue={item?.durationMinutes}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  debouncedHandleInputs(e.target.value, item, "duration");
                  setActiveDuration(false);
                }
              }}
            />
          ) : (
            // <PnToolTip title={item?.durationMinutes}>
            <span
              className={`${index > 1 ? 'cursor--pointer' : ''} px-1`}
              onClick={() => {
                setActiveDuration((pre) => true);
              }}
            >
              {item?.durationMinutes === undefined ? (
                <p>&nbsp;</p>
              ) : (
                item?.durationMinutes
              )}
            </span>
          )
          //  </PnToolTip>
        }
      </div>
      <div className="col-2 mt-2 text--terciary font-12 ">
        {/* <span>{"$ ".concat(parseFloat(item?.price).toFixed(2))}</span> */}
        {activePrice ? (
          <input
            type={item.type}
            autoComplete="off"
            className={`form-control font-14 border--default br-10 shadow-none input ${classes["service__charges"]}`}
            id={"serviceCharges"}
            placeholder={"$0.00"}
            defaultValue={item?.price}
            // onValueChange={(value) => {
            //   if(activeRowIndex === index){
            //     handleInputs(value, item,"price");
            //   }
            // }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                debouncedHandleInputs(e.target.value, item, "price");
                setActivePrice(false);
              }
            }}
          // disabled = {true}
          />
        ) : (
          <span
            className={`${index > 1 ? 'cursor--pointer' : ''} px-1`}
            onClick={() => {
              setActivePrice((pre) => true);
            }}
          >
            {"$ ".concat(parseFloat(item?.price).toFixed(2))}
          </span>
        )}
      </div>
      <div className={`col-1 mt-2 text-end align-self-end`}>
        <img
          src={TrashButtonIcon}
          alt={"deleteIcon"}
          id={item?.title}
          className="me-2 cursor--pointer"
          style={{ width: "15px", height: "15px" }}
          onClick={(e) => {
            e.preventDefault();
            // console.log("item", e.target);
            handleDelete(item);
          }}
          hidden={index <= 1 ? true : false}
        ></img>
      </div>
      {/* </div> */}
    </>
  );
};
const AddServiceItems = ({ tenantSettingsData, setTenantSettingsData }) => {
  const __str = tenantSettingStrings.en;

  const serviceItems = [
    {
      label: __str.add_service_item_str,
      col: "col-2",
      type: "text",
      require: true,
    },
    {
      label: __str.description_str,
      col: "col-5",
      type: "text",
      require: false,
    },
    { label: __str.duration_str, col: "col-2", type: "number", require: true },
    {
      label: __str.service_charges_str,
      col: "col-2",
      type: "number",
      require: true,
    },
  ];
  const [serviceItemsList, setServiceItemsList] = useState([]);
  const dispatch = useDispatch();
  const [serviceItem, setServiceItem] = useState();
  const [serviceDescription, setServiceDescription] = useState();
  const [serviceCharges, setServiceCharges] = useState();
  const [serviceDuration, setServiceDuration] = useState();
  const [isValidInput, setIsValidInput] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const handleServiceItem = () => {
    const newItem = {
      title: serviceItem,
      shortDescription: serviceDescription,
      price: serviceCharges,
      durationMinutes: serviceDuration,
    };
    setIsValidInput(true);
    if (tenantSettingsData?.tenantServices !== undefined) {
      setTenantSettingsData({
        ...tenantSettingsData,
        tenantServices: [...tenantSettingsData?.tenantServices, newItem],
      });
    } else {
      setTenantSettingsData({
        ...tenantSettingsData,
        tenantServices: [newItem],
      });
    }
    // setServiceItemsList([...serviceItemsList, newItem]);

    // Clear the input fields
    setServiceItem("");
    setServiceDescription("");
    setServiceCharges("");
    setServiceDuration("");
  };

  const checkValidInput = (value) => {
    if (value === undefined) {
      setIsValidInput(false);
    } else {
      setIsValidInput(true);
    }
  };
  const handleInputs = (e, item) => {
    dispatch(setDirtyBitInStore(true));
    let value = e.target?.value;

    if (item.label === __str.add_service_item_str) {
      setServiceItem(value);
    } else if (item.label === __str.description_str) {
      setServiceDescription(value);
    } else if (item.label === __str.duration_str) {
      setServiceDuration(value);
    } else if (item.label === __str.service_charges_str) {
      setServiceCharges(value);
    }
  };

  const handleServiceCharges = (value, itemObject) => {
    console.log("Called");
    const newItem = tenantSettingsData?.tenantServices?.find((item) => {
      return item.serviceCharges === itemObject.serviceCharges;
    });
    newItem.price = value;
    let tempTenantServices = tenantSettingsData?.tenantServices?.map((item) =>
      item.price == itemObject.price ? newItem : item
    );
    setTenantSettingsData({
      ...tenantSettingsData,
      tenantServices: [...tempTenantServices],
    });
  };
  const handleDelete = (item) => {
    let result = tenantSettingsData?.tenantServices.filter((serviceObject) => {
      return serviceObject.title !== item.title;
    });
    // setServiceItemsList([...result])
    setTenantSettingsData({
      ...tenantSettingsData,
      tenantServices: [...result],
    });
  };

  // console.log("serviceItemsList: ",serviceItemsList);
  return (
    <>
      <form
        id="service-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleServiceItem();
        }}
      >
        {/* Inputs  */}
        <div className="row ">
          {serviceItems.map((item) => {
            return (
              <div className={`${item.col}  mt-2`}>
                <label
                  htmlFor={item.label}
                  className="form-label font-12 text--secondary m-0 fw-sb"
                >
                  {item.label}
                </label>
                <input
                  type={item.type}
                  autoComplete="off"
                  className={`form-control font-14 border--default br-10 shadow-none input ${classes["service__charges"]}`}
                  id={item.label}
                  placeholder={
                    item.label === __str.service_charges_str ? "$0.00" : ""
                  }
                  value={
                    item.label === __str.add_service_item_str
                      ? serviceItem
                      : item.label === __str.description_str
                        ? serviceDescription
                        : item.label === __str.service_charges_str
                          ? serviceCharges
                          : serviceDuration
                  }
                  onChange={(e) => {
                    handleInputs(e, item);
                  }}
                  min={0}
                  required={item.require}
                />
              </div>
            );
          })}
          <div className="col-1 mt-2 align-self-end">
            {/* <button
              type="submit"
              form="service-form"
              // disabled={!isValidInput}
              className="blue_task_button"
            >
              {"Add"}
            </button> */}
            <button
              form="service-form"
              type="submit"
              // disabled
              className="btn shadow-none text-decoration-underline text--blue fw-sb font-14"
            >
              Add
            </button>
          </div>
        </div>
        {/* <div className="col-12"> */}
        {tenantSettingsData?.tenantServices?.map((item, index) => {
          return (

            <div className="row border--bottom ">
              <ServiceRowItem
                key={index}
                item={item}
                index={index}
                tenantSettingsData={tenantSettingsData}
                setTenantSettingsData={setTenantSettingsData}
                activeRowIndex={index}
              />
            </div>
          );
        })}
        {/* </div> */}
      </form>
    </>
  );
};

function BottomNavigation(props) {
  return (
    <div className="position-fixed row bg-white bottom-0 mx-auto bottom__nav--shadow" style={{ width: "100%" }}>
      <LinearProgress variant="determinate" style={{
        backgroundColor: "#D9E8EC",
        height: "1px"
      }} value={props?.value} />

      <div className="col-12 p-3 text-end">
        <div className="row">
          <div className="col-1 text-end offset-8 ">
            <button
              className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button"
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
              onClick={() => {
                props?.sendFileAndType();
                props?.updateTenantSettings()
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const propertyMapping = {
  primaryContactFirstName: 'firstName',
  primaryContactLastName: 'lastName',
  primaryContactEmail: 'email',
  enrollmentContactFirstName: 'firstName',
  enrollmentContactLastName: 'lastName',
  enrollmentContactEmail: 'email'
};





// export function ClaimsSettings({ tenantSettingsData, setTenantSettingsData, setValidationErrors,
//   validationErrors ,selectedPayers,
//   setSelectedPayers,providerPage,fetchTenantSettings,isValidPatientData}) {
export function ClaimsSettings(props) {
  const field_Names = {
    ...FIELD_NAMES.user.name,
    ...FIELD_NAMES.tenantSettings,
  };
  const TENANT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.tenantSettings,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.tenantSettings,
  };
  const [isClaimsManagementEnabledTenantWide, setIsClaimsManagementEnabledTenantWide] = useState(props?.tenantSettingsData?.groupNpi ? true : props?.tenantSettingsData?.claimProcessingLevel !== null ? props?.tenantSettingsData?.claimProcessingLevel === 't' ? true : false : false);
  // const [isClaimsManagementEnabledTenantWide, setIsClaimsManagementEnabledTenantWide] = useState(false);

  function handleClaimsManagmentEnabled(e) {
    console.log("e.target.checked: ", e.target.checked);
    setIsClaimsManagementEnabledTenantWide(e.target.checked);
    props?.setTenantSettingsData({ ...props?.tenantSettingsData, "claimProcessingLevel": e.target.checked ? 't' : 'p', claimProcessingTenantLevel: e.target.checked });
  };

  const getContact = (type) => {
    if (props?.providerPage) {
      return props?.tenantSettingsData?.providerContact?.filter(contact => contact?.type?.internalValue === type)
    } else {
      return props?.tenantSettingsData?.tenantContact?.filter(contact => contact?.type?.internalValue === type)
    }
  }
  let primaryContact = getContact('primary')?.at(0) ?? {};
  let enrollmentContact = getContact('enrollment')?.at(0) ?? {};


  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState();
  const [enrollmentPhoneNumber, setEnrollmentPhoneNumber] = useState();
  const [phoneErrors, setPhoneErrors] = useState({
    primary: false,
    enrollment: false,
  });

  const [isEnrollmentChecked, setIsEnrollmentChecked] = useState(enrollmentContact?.firstName?.length > 0 ? true : false);
  const [selectedListOfProviders, setSelectedListOfProviders] = useState(props?.tenantSettingsData?.claimExcludedProviders ?? []);
  // const [isClaimprocessingOrg,setIsClaimprocessingOrg] = useState(tenantSettingsData?.claimExcludedProviders?.length > 0 ? false: false);
  const [isClaimprocessingOrg, setIsClaimprocessingOrg] = useState(false);
  const [isPayerSelected, setIsPayerSelected] = useState(enrollmentContact?.firstName?.length > 0 ? true : false);
  const [openEnrollmentModal, setOpenEnrollmentModal] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState(props?.tenantSettingsData?.enrollmentStatus)
  console.log("tenant USer: ", props?.tenantSettingsData);

  const primaryContactPhoneHandle = (phoneValue) => {
    setPrimaryPhoneNumber(phoneValue);
    if (
      phoneValue !== undefined &&
      phoneValue?.length == 12 &&
      isValidPhoneNumber(phoneValue?.toString())
    ) {
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      let updatedPrimaryContact = []

      if (props?.providerPage) {
        updatedPrimaryContact = [...props?.tenantSettingsData?.providerContact]
      } else {
        updatedPrimaryContact = [...props?.tenantSettingsData?.tenantContact]
      }
      // console.log("updatedPrimaryContact: ",updatedPrimaryContact);
      // Find the index of the object with internalValue 'primary' in the array
      const objectIndex = updatedPrimaryContact.findIndex(contact => contact.type?.internalValue === 'primary');

      // console.log("objectIndex: ",objectIndex);
      if (objectIndex !== -1) {
        updatedPrimaryContact[objectIndex] = {
          ...updatedPrimaryContact[objectIndex],
          "phoneNumber": mobileNumber
        };
      } else {
        updatedPrimaryContact.push({
          type: {
            internalValue: 'primary'
          },
          "phoneNumber": mobileNumber
        });
      }
      if (props?.providerPage) {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, providerContact: updatedPrimaryContact });
      } else {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, tenantContact: updatedPrimaryContact });
      }

      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    }
  };

  const enrollmentContactPhoneHandle = (phoneValue) => {
    setEnrollmentPhoneNumber(phoneValue);
    if (
      phoneValue !== undefined &&
      phoneValue?.length == 12 &&
      isValidPhoneNumber(phoneValue?.toString())
    ) {
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      let updatedPrimaryContact = [];
      if (props?.providerPage) {
        updatedPrimaryContact = [...props?.tenantSettingsData?.providerContact]
      } else {
        updatedPrimaryContact = [...props?.tenantSettingsData?.tenantContact]
      }

      // Find the index of the object with internalValue 'primary' in the array
      const objectIndex = updatedPrimaryContact.findIndex(contact => contact.type?.internalValue === 'enrollment');

      if (objectIndex !== -1) {
        updatedPrimaryContact[objectIndex] = {
          ...updatedPrimaryContact[objectIndex],
          "phoneNumber": mobileNumber
        };
      } else {
        updatedPrimaryContact.push({
          type: {
            internalValue: 'enrollment'
          },
          "phoneNumber": mobileNumber
        });
      }
      if (props?.providerPage) {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, providerContact: updatedPrimaryContact });
      } else {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, tenantContact: updatedPrimaryContact });
      }
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        enrollment: false,
      }));
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        enrollment: true,
      }));
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        enrollment: false,
      }));
    }
  };

  const handlePreset = (stateValue) => {
    let address = props?.tenantSettingsData?.address;
    props?.setTenantSettingsData({
      ...props?.tenantSettingsData,
      address: {
        ...address,
        stateProvince: stateValue[0]?.code,
      }
    });
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;


    if (['groupNpi', 'dbaName', 'previousClearingHouse', 'stateLicenseNumber'].includes(name)) {
      props?.setTenantSettingsData({ ...props?.tenantSettingsData, [name]: value });
    }
    else if (["taxIdNumber"].includes(name)) {
      if (props?.providerPage) {
        let identification = props?.tenantSettingsData?.identification;
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, identification: { ...identification, [name]: value } });
      } else {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, [name]: value });
      }
    }
    else if (["upin"].includes(name)) {
      let identification = props?.tenantSettingsData?.identification;
      props?.setTenantSettingsData({ ...props?.tenantSettingsData, identification: { ...identification, [name]: value } });
    }
    else if (["addressLine1", "addressLine2", "city", "postalCode"].includes(name)) {
      let address = props?.tenantSettingsData?.address;
      props?.setTenantSettingsData({ ...props?.tenantSettingsData, address: { ...address, [name]: value } });
    }
    else {
      // Assuming you have the name of the property and its value stored in variables: name and value
      const indexValue = name.includes("primary") ? 'primary' : 'enrollment';
      // Create a copy of the existing tenantContact array
      let updatedPrimaryContact = "";
      if (props?.providerPage) {
        updatedPrimaryContact = props?.tenantSettingsData?.providerContact;
      }
      else {
        updatedPrimaryContact = props?.tenantSettingsData?.tenantContact;
      }

      // Find the index of the object with internalValue 'primary' in the array
      const objectIndex = updatedPrimaryContact.findIndex(contact => contact.type?.internalValue === indexValue);

      // Update the primary contact object if it exists, or create a new object
      if (objectIndex !== -1) {
        updatedPrimaryContact[objectIndex] = {
          ...updatedPrimaryContact[objectIndex],
          [propertyMapping[name]]: value
        };
      } else {
        updatedPrimaryContact.push({
          type: {
            internalValue: indexValue
          },
          [propertyMapping[name]]: value
        });
      }

      // Update the state with the modified tenantContact array
      if (props?.providerPage) {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, providerContact: updatedPrimaryContact });
      } else {
        props?.setTenantSettingsData({ ...props?.tenantSettingsData, tenantContact: updatedPrimaryContact });
      }
    }



  };

  const handleEnrollmentCheckbox = (isChecked) => {
    if (isChecked) {
      setIsEnrollmentChecked(true)
    } else {
      props?.setTenantSettingsData({ ...props?.tenantSettingsData, tenantContact: [primaryContact] })
      setIsEnrollmentChecked(false)
    }
  }

  const handleDelete = (providerObject) => {

    let providerList = props?.tenantSettingsData?.claimExcludedProviders?.filter((item) => {
      return item.userId !== providerObject.userId;
    });
    props?.setTenantSettingsData({ ...props?.tenantSettingsData, claimExcludedProviders: providerList })
    setSelectedListOfProviders([...providerList]);
  }

  const handleEnrollmentModal = () => {
    setOpenEnrollmentModal(false);
  }

  useEffect(() => {
    if (props?.tenantSettingsData?.enrollmentStatus !== undefined) {
      setEnrollmentStatus(props?.tenantSettingsData?.enrollmentStatus)
    }
  }, [props?.tenantSettingsData])
  useEffect(() => {
    if (isPayerSelected === false) {
      setIsEnrollmentChecked(false);
    }
  }, [isPayerSelected])

  useEffect(() => {
    if (props?.providerPage) {
      props.setEditUser(p => props?.tenantSettingsData);
    }
  }, [])



  return (<>
    {openEnrollmentModal ?
      <EnrollmentStatusModal
        show={openEnrollmentModal}
        handleModal={handleEnrollmentModal}
        setEnrollmentStatus={setEnrollmentStatus}
        fetchTenantSettings={props?.fetchTenantSettings}
        providerPage={props?.providerPage}
      /> : null
    }
    <form id={props?.providerPage ? "userAddForm" : "claimSettingForm"} onSubmit={(e) => {
      e.preventDefault();
      props?.isValidUserData === false ? props?.setValue(0) :
        props?.editMode === true
          ? props.editHandler()
          : props.submitHandler()
    }}>
      <div className="form-check my-2 form-check-inline">
        <input defaultChecked={props?.tenantSettingsData?.groupNpi ? true : props?.tenantSettingsData?.claimProcessingLevel !== null ? props?.tenantSettingsData?.claimProcessingLevel === 't' ? true : false : false} onChange={handleClaimsManagmentEnabled} className="form-check-input  cursor--pointer align-self-center shadow-none mb-1" type="checkbox" id="claimProcessingLevel" />
        <label className="form-check-label form-label font-12 text--secondary m-0 fw-sb" htmlFor="claimProcessingLevel">{`Would you like to activate ${props?.providerPage ? "provider-wide" : "tenant-wide"} claims management settings?`}</label>
      </div>

      {
        // isClaimsManagementEnabledTenantWide ?
        <>
          {/* First Row */}
          <div className="row mt-2 border--bottom ">
            {/* Group NPI */}
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="groupNpi" className="form-label font-12 text--secondary m-0 fw-sb">{props?.providerPage ? "NPI" : 'Group NPI'} &#42;</label>
                <input
                  type="text"
                  className="form-control input font-12 shadow-none br-10"
                  id="groupNpi"
                  name="groupNpi"
                  value={props?.providerPage ? props?.tenantSettingsData?.identification?.npi : props?.tenantSettingsData?.groupNpi ?? ""}
                  onChange={handleInputChange}
                  required={true}
                  min={0}
                  pattern={"[0-9]*"}
                  disabled={enrollmentStatus?.internalValue === "pending" ? true : props?.providerPage ? true : false}
                />
                <ValidationErrorMessage
                  fieldName={"groupNpi"}
                  errors={props?.validationErrors}
                />
              </div>
            </div>
            {/* Tax ID Number */}
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="taxIdNumber" className="form-label font-12 text--secondary m-0 fw-sb">{props?.providerPage ? "TIN" : "EIN"} &#42;</label>
                <input
                  type="text"
                  className="form-control input font-12 shadow-none br-10"
                  id="taxIdNumber"
                  name="taxIdNumber"
                  defaultValue={props?.providerPage ? props.tenantSettingsData?.identification?.taxIdNumber : props?.tenantSettingsData?.taxIdNumber ?? ""}
                  onChange={handleInputChange}
                  required
                  disabled={enrollmentStatus?.internalValue === "pending"}
                />
              </div>
            </div>
            {/* UPIN */}
            <div className="col-3" hidden={!props?.providerPage}>
              <div>
                <label
                  htmlFor="UPIN"
                  className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
                >
                  {<span>UPIN</span>}
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  // onKeyDown={handleKeyDown}
                  // ref={tinRef}
                  defaultValue={props?.tenantSettingsData?.identification?.upin ?? ""}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="upin"
                  name="upin"
                  // required={npiRequiredFlag}
                  maxLength={10}
                // minLength={nameConstraints.min}
                // maxLength={nameConstraints.max}
                />
                {/* <ErrorMessage
              serverErrorsArray={serverErrors}
              fieldName={fieldNames.npi}
            />
            <ValidationErrorMessage
              errors={nameValidationErrors}
              fieldName={field_Name.npi}
            /> */}
              </div>
            </div>
            {/* State License Number */}
            {
              props?.providerPage === false ?
                <div className="col-3" hidden={props?.providerPage}>
                  <div className="mb-3">
                    <label htmlFor="stateLicenseNumber" className="form-label font-12 text--secondary m-0 fw-sb">State License Number *</label>
                    <input
                      type="text"
                      className="form-control font-12 input shadow-none br-10"
                      id="stateLicenseNumber"
                      name="stateLicenseNumber"
                      defaultValue={props?.tenantSettingsData?.stateLicenseNumber ?? ""}
                      onChange={handleInputChange}
                      required={true}

                    />
                  </div>
                </div> : null
            }
            {/* DBA */}
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="dbaName" className="form-label font-12 text--secondary m-0 fw-sb">DBA Name *</label>
                <input
                  type="text"
                  className="form-control input font-12 shadow-none br-10"
                  id="dbaName"
                  name="dbaName"
                  value={props?.tenantSettingsData?.dbaName}
                  onChange={handleInputChange}
                  required
                  disabled={enrollmentStatus?.internalValue === "pending"}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label htmlFor="previousClearingHouse" className="form-label font-12 text--secondary m-0 fw-sb">Previous Clearing House *</label>
                <input
                  type="text"
                  className="form-control font-12 input shadow-none br-10"
                  id="previousClearingHouse"
                  name="previousClearingHouse"
                  value={props?.tenantSettingsData?.previousClearingHouse}
                  onChange={handleInputChange}
                  required
                  disabled={enrollmentStatus?.internalValue === "pending"}
                />
              </div>
            </div>

          </div>
          {/* Billing address Second row */}
          <div className="row mt-2 border--bottom pb-2" hidden={!props?.providerPage}>
            <div className="col-12">
              <h2 className="font-16 fw-sb">{"Billing Address"}</h2>
            </div>
            {/* Address line 1 */}
            <div className="col-md-6 mt-2">
              <label
                htmlFor="AddressLine1"
                className="form-label fw-sb m-0 font-12 text--secondary"
              >
                Address line 1
              </label>
              <input
                type="text"
                onChange={handleInputChange}
                defaultValue={props?.tenantSettingsData?.address?.addressLine1 ?? ""}
                // onBlur={() => { handleStandardAddress() }}
                // onFocus={() => { handleStandardAddress() }}
                autoComplete="off"
                className="form-control font-14 border--default br-10 shadow-none input"
                id="addressLine1"
                name="addressLine1"
              />
            </div>
            {/* Address line 2 */}
            <div className="col-6 mt-2">
              <label
                htmlFor="AddressLine2"
                className="form-label fw-sb m-0 font-12 text--secondary"
              >
                Address line 2
              </label>
              <input
                type="text"
                onChange={handleInputChange}
                defaultValue={props?.tenantSettingsData?.address?.addressLine2 ?? ""}
                // onBlur={() => { handleStandardAddress() }}
                // onFocus={() => { handleStandardAddress() }}
                autoComplete="off"
                className="form-control font-14 border--default br-10 shadow-none input"
                id="addressLine2"
                name="addressLine2"

              />
              {/* <ErrorMessage
            serverErrorsArray={props?.serverErrors}
            fieldName={fieldNames.address}
          />
          <ErrorMessage
            serverErrorsArray={props?.serverErrors}
            fieldName={fieldNames.addressLine1}
          />
          <ValidationErrorMessage
            fieldName={field_Names.addressLine2}
            errors={validationErrors}
          /> */}
              {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "AddressLine1" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
            </div>
            {/* City */}
            <div className="col-3 mt-2">
              <label
                htmlFor="city"
                className="form-label fw-sb m-0 font-12 text--secondary"
              >
                City
              </label>
              <input
                type="text"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                defaultValue={props?.tenantSettingsData?.address?.city ?? ""}
                // onBlur={() => handleStandardAddress()}
                // onFocus={() => handleStandardAddress()}
                autoComplete="off"
                className="form-control font-14 border--default br-10 shadow-none input"
                id="city"
                name="city"
              />
            </div>
            {/* State */}
            <div className="col-3 mt-2">
              <label
                htmlFor="stateProvince"
                className="form-label fw-sb m-0 font-12 text--secondary"
              >
                State
              </label>
              {/* {console.log(tenantSettingsData?.state !== undefined
            ? options.find((s) => s.code === tenantSettingsData?.state)
              ?.name
            : tenantSettingsData?.state)} */}
              <Typeahead
                id="stateProvince"
                name="stateProvince"
                labelKey="name"
                onChange={handlePreset}
                options={options}
                className="rbt-menu my-select font-12"
                // selected={props?.tenantSettingsData?.address?.stateProvince !== undefined ? options.find(s => s.code === props?.tenantSettingsData?.address?.stateProvince)?.code : null}
                defaultInputValue={options.find((s) => s.code === props.tenantSettingsData?.address?.stateProvince)?.name
                  ?? props.tenantSettingsData?.address?.stateProvince
                }
                style={{ borderRadius: "10px" }}
                autoComplete="off"
                inputProps={{ className: "font-14" }}
              />
            </div>
            {/* Zip Code */}
            <div className="col-3 mt-2">
              <label
                htmlFor="postalCode"
                className="form-label fw-sb m-0 font-12 text--secondary"
              >
                Zip Code
              </label>
              <input
                type="text"
                onChange={(e) => handleInputChange(e)}
                defaultValue={formatPostalCode(props?.tenantSettingsData?.address?.postalCode) ?? ""}
                // onBlur={handleStandardAddress}
                // onFocus={handleStandardAddress}
                className="form-control font-14 border--default br-10 shadow-none input"
                id="postalCode"
                name="postalCode"
                maxLength={10}
              />
            </div>


          </div>

          {/* Fourth Row Primary Contact*/}
          <div className="row mt-2 border--bottom">
            <div className="col-12">
              <h2 className="font-16 fw-sb">Primary Contact</h2>
            </div>
            <div className="col">
              <div className="">
                <label htmlFor="primaryContactFirstName" className="form-label font-12 text--secondary m-0 fw-sb">First Name *</label>
                <input
                  type="text"
                  className="form-control font-12 input br-10 shadow-none"
                  id="primaryContactFirstName"
                  name="primaryContactFirstName"
                  value={primaryContact?.firstName}
                  onChange={handleInputChange}
                  required
                  disabled={enrollmentStatus?.internalValue === "pending"}
                />
              </div>
            </div>
            <div className="col">
              <div className="">
                <label htmlFor="primaryContactLastName" className="form-label font-12 text--secondary m-0 fw-sb">Last Name *</label>
                <input
                  type="text"
                  className="form-control font-12 input br-10 shadow-none"
                  id="primaryContactLastName"
                  name="primaryContactLastName"
                  value={primaryContact?.lastName}
                  onChange={handleInputChange}
                  required
                  disabled={enrollmentStatus?.internalValue === "pending"}
                />
              </div>
            </div>
            <div className="col">
              <div className="">
                <label htmlFor="primaryContactEmail" className="form-label font-12 text--secondary m-0 fw-sb">Email *</label>
                <input
                  type="email"
                  className="form-control font-12 input br-10 shadow-none"
                  id="primaryContactEmail"
                  name="primaryContactEmail"
                  value={primaryContact?.email}
                  onChange={handleInputChange}
                  required
                  disabled={enrollmentStatus?.internalValue === "pending"}
                />
              </div>
            </div>
            <div className="col">
              <div className="">
                <label htmlFor="primaryContactPhoneNumber" className="form-label font-12 text--secondary m-0 fw-sb">Phone Number *</label>
                <PhoneNumberInput
                  onChange={primaryContactPhoneHandle}
                  id="primaryPhoneNumber"
                  key={"primaryPhoneNumber"}
                  // reference={phoneRef}
                  className="font-12 input"
                  value={primaryPhoneNumber || primaryContact?.phoneNumber || ""}
                  required={true}
                  disabled={enrollmentStatus?.internalValue === "pending"}

                />
                <span className="text--danger  font-10 ms-2 mt-1">
                  {phoneErrors?.primary === true
                    ? "Incorrect number format"
                    : " "}
                </span>
              </div>
            </div>
          </div>
          {
            <>
              {/* <PayerList selectedPayers={props?.selectedPayers} setSelectedPayers={props?.setSelectedPayers} tenantPage={true} isPayerSelected= {isPayerSelected} setIsPayerSelected = {setIsPayerSelected} disabled={enrollmentStatus?.internalValue === "pending"}/> */}
              {/* {console.log("isPayerSelected: ",isPayerSelected)} */}
              <div className="row border--bottom">
                <div className="col-12 mt-2">
                  <div className="form-check form-check-inline">
                    <input disabled={enrollmentStatus?.internalValue === "pending" ? true : false} checked={isEnrollmentChecked} onChange={(e) => { handleEnrollmentCheckbox(e.target.checked) }} className="form-check-input  cursor--pointer align-self-center shadow-none mb-1" type="checkbox" id="useEnrollmentContact" />
                    <label className="form-check-label form-label font-12 text--secondary m-0 fw-sb" htmlFor="useEnrollmentContact">Use Enrollment Contact Instead of Primary</label>
                  </div>
                </div>
                {
                  isEnrollmentChecked ?
                    <>
                      <div className="col-12 mt-1">
                        <h2 className="font-16 fw-sb">Enrollment Contact</h2>
                      </div>

                      <div className="col">
                        <div className="">
                          <label htmlFor="enrollmentContactFirstName" className="form-label font-12 text--secondary m-0 fw-sb">First Name *</label>
                          <input
                            type="text"
                            className="form-control font-12 input br-10 shadow-none"
                            id="enrollmentContactFirstName"
                            name="enrollmentContactFirstName"
                            value={enrollmentContact?.firstName}
                            onChange={handleInputChange}
                            required
                            disabled={enrollmentStatus?.internalValue === "pending"}
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="">
                          <label htmlFor="enrollmentContactLastName" className="form-label font-12 text--secondary m-0 fw-sb">Last Name *</label>
                          <input
                            type="text"
                            className="form-control font-12 input br-10 shadow-none"
                            id="enrollmentContactLastName"
                            name="enrollmentContactLastName"
                            value={enrollmentContact?.lastName}
                            onChange={handleInputChange}
                            required
                            disabled={enrollmentStatus?.internalValue === "pending"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="">
                          <label htmlFor="enrollmentContactEmail" className="form-label font-12 text--secondary m-0 fw-sb">Email *</label>
                          <input
                            type="email"
                            className="form-control font-12 input br-10 shadow-none"
                            id="enrollmentContactEmail"
                            name="enrollmentContactEmail"
                            value={enrollmentContact?.email}
                            onChange={handleInputChange}
                            required
                            disabled={enrollmentStatus?.internalValue === "pending"}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="">
                          <label htmlFor="enrollmentContactPhoneNumber" className="form-label font-12 text--secondary m-0 fw-sb">Phone Number *</label>
                          <PhoneNumberInput
                            onChange={enrollmentContactPhoneHandle}
                            id="enrollmentContactPhoneNumber"
                            key={"enrollmentContactPhoneNumber"}
                            // reference={phoneRef}
                            className="font-12 input"
                            value={enrollmentContact?.phoneNumber ?? ""}
                            required={true}
                            disabled={enrollmentStatus?.internalValue === "pending"}
                          />
                          <span className="text--danger  font-10 ms-2 mt-1">
                            {phoneErrors?.enrollment === true
                              ? "Incorrect number format"
                              : " "}
                          </span>
                        </div>
                      </div>
                    </>
                    : null
                }
                {/* {console.log("openEnrollmentModal: ",openEnrollmentModal)} */}
                <div className="mb-3 mt-2">
                  <span className="font-12 text--secondary fw-sb me-2">{"Enrollment Status: "}</span>
                  <span className="font-12 text--secondary fw-sb me-3">{enrollmentStatus?.displayValue}</span>
                  <button className="btn blue_task_button px-2" disabled={props?.tenantSettingsData?.updateEnrollmentStatusVisible === false ? true : false} onClick={() => { setOpenEnrollmentModal(p => true) }}>{"Update Status"}</button>
                </div>
                {/* disabled={tenantSettingsData?.updateEnrollmentStatusVisible} */}
              </div>
              {/* Claim process Org. level */}
              <div className="row pb-3 mt-2" hidden={props?.providerPage}>
                <div className="col-sm-8 mb-3">
                  <span className="font-12 fw-sb text--secondary">
                    {"Are all provider claims processed at the organizational level?"}
                  </span>
                  <div
                    className="form-check form-check-inline ms-3"
                  >
                    <input
                      className="form-check-input shadow-none"
                      type="radio"
                      name="claimType"
                      id={"Yes"}
                      value={"Yes"}
                      defaultChecked={isClaimprocessingOrg === true ? true : false}
                      onChange={() => setIsClaimprocessingOrg(true)}
                    // disabled={enrollmentStatus?.internalValue === "pending"}
                    />
                    <label
                      className="form-check-label font-12"
                      htmlFor={"Yes"}
                    >
                      {"Yes"}
                    </label>
                  </div>
                  <div className="form-check form-check-inline" >
                    <input
                      className="form-check-input shadow-none"
                      type="radio"
                      name="claimType"
                      id="No"
                      value="No"
                      defaultChecked={isClaimprocessingOrg === false ? true : false}
                      onChange={() => setIsClaimprocessingOrg(false)}
                    // disabled={enrollmentStatus?.internalValue === "pending"}
                    />
                    <label className="form-check-label font-12" htmlFor="No">
                      {"No"}
                    </label>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  {
                    isClaimprocessingOrg === false &&
                    <SelectProvider
                      selectedListOfProviders={props?.tenantSettingsData?.claimExcludedProviders}
                      setSelectedListOfProviders={setSelectedListOfProviders}
                      tenantSettingsData={props?.tenantSettingsData}
                      setTenantSettingsData={props?.setTenantSettingsData}
                      // disabled={enrollmentStatus?.internalValue === "pending"}
                      disabled={false}
                    />
                  }
                </div>
                <div className="col-6 mb-4 pb-4">
                  {
                    isClaimprocessingOrg === false &&
                    <>
                      <span className="font-14 fw-sb text--secondary" hidden={selectedListOfProviders?.length === 0}>{"List of Providers for individual Claim Processing"}</span>
                      <div className="col-10">
                        <ProviderListItem list={selectedListOfProviders} handleDelete={handleDelete} hideAction={false} />
                      </div>
                    </>
                  }
                </div>

              </div>
            </>
          }
        </>
      }

      <div className="row my-3 justify-content-end" hidden={!props?.providerPage}>
        <div className={`col-sm-2`}>
          <button
            className={`btn blue_task_button`}
            form={props?.providerPage ? "userAddForm" : "claimSettingForm"}
            type="submit"

          >
            {props?.editMode
              ? props?.isSending
                ? "Updating..."
                : "Update"
              : props?.isSending
                ? "Adding..."
                : "Add"}
          </button>
        </div>
      </div>
    </form>
  </>
  )
}


const TenantSettingsPage = () => {
  const __str = tenantSettingStrings.en;
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabsList, setTabsList] = useState(["Practice Information", "Appointment Settings", "Notification Settings", "Paypal Settings", "Subscription Details", "Claims Settings", "Fee Schedule"]);
  const [value, setValue] = useState(0);

  // Field Vaidation
  const [validationErrors, setValidationErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    notification: false,
  });

  const [tenantSettingsData, setTenantSettingsData] = useState();
  const [isTenantDataLoading, setIsTenantDataLoading] = useState(false);
  const [isTenantDataUpdating, setIsTenantDataUpdating] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();

  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [isDateFormatUpdated, setIsDateFormatUpdated] = useState(false);

  const [selectedPayers, setSelectedPayers] = useState([]);

  const fetchTenantSettings = async () => {
    setIsTenantDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenant?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setTenantSettingsData(httpResponse.data);
    }
    setIsTenantDataLoading(false);
  };
  const updateTenantSettings = async () => {
    setIsTenantDataUpdating(true);
    handleToggle();
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenant?.tenantId}/setting`,
      method: "PUT",
      data: tenantSettingsData,
    });
    if (!httpResponse.error) {
      fetchTenantSettings();
      handleBackdropClose();
      setSeverity("success");
      setMessage("Tenant settings updated successfully");
      setIsSent(true);
      dispatch(setDirtyBitInStore(false));
    } else {
      setMessage(httpResponse?.errorMessage);
      setSeverity("error");
      setIsSent(true);
    }
    setIsTenantDataUpdating(false);
  };

  const sendFileAndType = () => {
    let reader = new FileReader();
    // console.log("Select File: ",selectedFile);
    if (selectedFile !== undefined) {
      reader.readAsDataURL(selectedFile);
      reader.onload = (e) => {
        // console.log(e.target.result);
        convertToMultipart(selectedFile, e.target.result);
        // let bsfe = e.target.result.split(',')[1];
      };
    }
  };
  const convertToMultipart = (fileV, trimmedDataURL) => {
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];
    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name, {
          type: mimeType,
        });
        setFile(file);
        //form data
        const formData = new FormData();
        formData.append("file", file);
        uploadFile(formData);
      });
    const uploadFile = async (fileData) => {
      // setIsUploading(true);
      const httpResponse = await sendHttpRequest({
        url: `/tenants/${user?.tenant?.tenantId}/logo`,
        method: "PUT",
        data: fileData,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });
      if (!httpResponse.error) {
      } else {
        // setErrorStatus(true);
      }
      // setIsUploading(false);
    };
  };

  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };


  // =================Tabs============================
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabsMap = function (tabTitle) {
    const tabsMap = new Map();

    tabsList.forEach((element, index) => {
      tabsMap.set(element, index);
      tabsMap.set(index, element);
    });
    return tabsMap.get(tabTitle);
  };

  useEffect(() => {
    fetchTenantSettings();
  }, []);
  return (
    <div className="container-fluid g-0 bg--background ">
      <PageHeader title="Settings" />

      <ThemeProvider theme={theme}>
        <Sticky enabled={true} top={70} innerZ={20} bottomBoundary={1200}>
          <div
            id="header-tabs-box"
            style={{ backgroundColor: 'white', borderBottom: '1px solid #d9e8ec' }}
          >

            <Tabs
              indicatorColor="secondary"
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              style={{
                backgroundColor: "white"
              }}
            >
              {tabsList.map((item, key) => {
                return (
                  <Tab
                    key={key}
                    label={item}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      opacity: "1",
                      textTransform: "initial",
                    }}
                  />
                );
              })}

            </Tabs>
          </div>
        </Sticky>

      </ThemeProvider>

      <ThemeProvider theme={theme}>
        {
          tenantSettingsData !== undefined ?
            <TabPanel
              value={value}
              index={TabsMap("Practice Information")}
              className="bg--background pb-5"
            >
              <div className="container">
                <div className="row pt-1 justify-content-center">
                  <div className="col-8 pb-3">
                    <PracticeInformationInfo
                      tenantSettingsData={tenantSettingsData}
                      setTenantSettingsData={setTenantSettingsData}
                      setSelectedFile={setSelectedFile}
                      setFileType={setFileType}
                      setValidationErrors={setValidationErrors}
                      validationErrors={validationErrors}
                      phoneErrors={phoneErrors}
                      setPhoneErrors={setPhoneErrors}
                    />
                  </div>
                </div>
              </div>

            </TabPanel>
            : <Skeleton />
        }
        <TabPanel
          value={value}
          index={TabsMap("Appointment Settings")}
          className="bg--background pb-5"
        >
          <div className="container">
            <div className="row pt-1 justify-content-center">
              {isDateFormatUpdated ? (
                <div className="col-8 px-4 mt-2">
                  <h6 className="font-12 m-0">
                    <WarningIcon style={{ height: "18px", fill: "#f8a02e" }} />
                    {
                      "To have the date format changes take immediate effect, please log out of the application and then log back in."
                    }
                  </h6>
                </div>
              ) : null}
              <div className="col-8">
                <AppointmentSettings
                  tenantSettingsData={tenantSettingsData}
                  setTenantSettingsData={setTenantSettingsData}
                  setValidationErrors={setValidationErrors}
                  validationErrors={validationErrors}
                  setIsDateFormatUpdated={setIsDateFormatUpdated}
                />
              </div>
            </div>
          </div>

        </TabPanel>
        <TabPanel
          value={value}
          index={TabsMap("Notification Settings")}
          className="bg--background pb-5"
        >
          <div className="container">
            <div className="row pt-1 justify-content-center">
              <div className="col-8">
                <NotificationSettings
                  tenantSettingsData={tenantSettingsData}
                  setTenantSettingsData={setTenantSettingsData}
                  setValidationErrors={setValidationErrors}
                  validationErrors={validationErrors}
                  phoneErrors={phoneErrors}
                  setPhoneErrors={setPhoneErrors}
                />
              </div>
            </div>
          </div>

        </TabPanel>
        <TabPanel
          value={value}
          index={TabsMap("Paypal Settings")}
          className="bg--background pb-5"
        >
          <div className="container">
            <div className="row pt-1 justify-content-center">
              <div className="col-8">
                <PaymentSettings
                  tenantSettingsData={tenantSettingsData}
                  setTenantSettingsData={setTenantSettingsData}
                  setValidationErrors={setValidationErrors}
                  validationErrors={validationErrors}
                />
              </div>
            </div>
          </div>

        </TabPanel>
        <TabPanel
          value={value}
          index={TabsMap("Subscription Details")}
          className="bg--background pb-5"
        >
          <div className="container">
            <div className="row pt-1 justify-content-center">
              <div className="col-8">
                <SubscriptionDetails
                  tenantSettingsData={tenantSettingsData}
                  setTenantSettingsData={setTenantSettingsData}
                  setValidationErrors={setValidationErrors}
                  validationErrors={validationErrors}
                />
              </div>
            </div>
          </div>

        </TabPanel>

        <TabPanel
          value={value}
          index={TabsMap("Claims Settings")}
          className="bg--background pb-5"
        >
          <div className="container">
            <div className="row pt-1 justify-content-center">
              <div className="col-8">
                <ClaimsSettings setValidationErrors={setValidationErrors}
                  validationErrors={validationErrors} tenantSettingsData={tenantSettingsData}
                  setTenantSettingsData={setTenantSettingsData}
                  selectedPayers={selectedPayers}
                  setSelectedPayers={setSelectedPayers}
                  providerPage={false}
                  fetchTenantSettings={fetchTenantSettings}
                />
              </div>
            </div>
          </div>

        </TabPanel>


        <TabPanel
          value={value}
          index={TabsMap("Fee Schedule")}
          className="bg--background  pb-5"
        >
          <div className="container">

            <div className="row pt-1 justify-content-center">
              <div className="col-10">
                <FeeSchedule />
              </div>
            </div>
          </div>

        </TabPanel>
      </ThemeProvider>
      <div className="row">
        <div className="col-12">
          <BottomNavigation
            value={100}
            sendFileAndType={sendFileAndType}
            updateTenantSettings={updateTenantSettings}
          // status={status}
          // setStatus={setStatus}
          // handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
          />
        </div>

      </div>

      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomizedSnackbar
        message={message}
        severity={severity}
        open={isSent}
        handleClose={handleClose}
      />

      {console.log("tenantSettingsData:", tenantSettingsData)}
      {/* {console.log("validationErrors: ",validationErrors)} */}
    </div>
  );
};

export default TenantSettingsPage;
