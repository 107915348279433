import {
  Avatar,
  Backdrop,
  CircularProgress,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import placeholder from "../../../assets/images/placeholder.png";
import { ExpandMore } from "@mui/icons-material";
import {
  changeDateTimeFormat,
  dateTimeHelper,
} from "../../../utils/dateTimeHelper";
import classes from "./PatientEncounterCard.module.css";
import { Collapse } from "react-bootstrap";
import { VideoCallContext } from "../../UI/AgoraHooks/VideoCallContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import {
  setAllPhysicalSystemsStatus,
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../../redux/actions/providerActions";
import { encounterSummaryUrl } from "../../../res/endpointurls";

const PatientEncounterCard = (props) => {
  const userPermissions = useSelector(
    (state) => state.UserPermissionData.permissionsMap
  );
  const videoCallContext = useContext(VideoCallContext);
  const dispatch = useDispatch();
  const [isLoadingEnc, setIsLoadingEnc] = useState(false);
  const [encounter, setEncounter] = useState("");
  const navigate = useNavigate();
  const [isAppointmentStartLoading, setIsAppointmentStartLoading] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  let allergiesString = "";
  let providerId =
    props.encounter?.appointment?.appointmentSlot
      ?.providerTenantPracticeLocation?.provider?.userId;
  let patientId =
    props.encounter?.appointment?.appointmentSlot?.patient?.userId;
  let appointmentId = props.encounter?.appointment?.id;
  let prescriptionReferenceId = props.encounter?.prescriptionReferenceId;
  let encounterId = props.encounter?.encounter?.encounterId;

  const [open, setOpen] = useState(false);

  const sendStartAppointmentRequest = async () => {
    setIsAppointmentStartLoading(true);
    let appointmentId = props.encounter?.appointment?.id;
    videoCallContext.updateAppointmentData(appointmentId);
    videoCallContext.updateAppointmentObject(props.encounter?.appointment);
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${appointmentId}/start`,
      method: "GET",
    });
    if (!httpResponse.error) {
      videoCallContext.updateAppointmentData(appointmentId);
      setEncounter(httpResponse.data?.prescriptionReference?.encounter);
    }
    // setIsSessionUpdated(false);
    setIsAppointmentStartLoading(false);
    handleBackdropClose();
  };
  const fetchPatientEncounter = async (encId) => {
    setIsLoadingEnc(true);
    const httpResponse = await sendHttpRequest({
      url: encounterSummaryUrl(encId),
      method: "GET",
    });
    if (!httpResponse.error) {
      let encounterData = httpResponse.data.appointment;
      // console.log("encounterData", {
      //   ...encounterData,
      //   encounter: { ...httpResponse.data },
      // });
      setEncounter({
        ...encounterData,
        prescriptionReference: { ...httpResponse.data },
      });
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingEnc(false);
  };

  const handlePatientEncouterData = async () => {
    // dispatch(setJoinPage(true))
    // console.log("Start Appointment", props.appointment);
    handleToggle();
    await sendStartAppointmentRequest();
    videoCallContext.updateAppointmentData(appointmentId);
    videoCallContext.updateAppointmentObject(props.encounter?.appointment);
    dispatch(setPrescriptionNoteText(""));
    dispatch(setDisableSendRxButton(false));
    dispatch(updatePrescribedMedication([]));
    dispatch(setPatientEncounterData(props.encounter?.appointment));
    // dispatch(setVideoOpen(true));
    // dispatch(setConsulationInProgress(true));
    dispatch(
      setUpdateEncounterData({
        Diagnosis: {
          Note: "",
          Primary: [],
          Secondary: [],
        },
      })
    );
    dispatch(
      setDiagnosis({
        Note: "",
        Primary: [],
        Secondary: [],
      })
    );


    if (props.encounter?.appointment?.appointmentType?.key?.toLowerCase() !== "follow_up"
      // props.encounter?.appointment?.appointmentType?.key.toLowerCase() ===
      // "custom"
      ) {
      navigate(
        `/consult/${providerId}/${prescriptionReferenceId}/${patientId}`,
        {
          state: {
            prescriptionReferenceId: prescriptionReferenceId,
          },
        }
      )
    }
    else {
      navigate(`/all-patients/${patientId}/soap-note`, {
        state: {
          prescriptionReferenceId: prescriptionReferenceId,
          encounterId:
            props.encounter?.encounter?.encounterId,
          appointmentId: appointmentId,
          isResume: props?.encounter?.soapNote !== undefined ? true : false,
        },
      })
    }

  };
  const handleResumePatientEncouterData = (data) => {
    // console.log("data: ", data);
    // console.log("Diagnosis : ",data?.prescriptionReference?.encounter?.diagnosis);

    videoCallContext.updateAppointmentData(appointmentId);
    videoCallContext.updateAppointmentObject(props.encounter?.appointment);
    dispatch(setPrescriptionNoteText(""));
    let diagnosis = {
      Note: "",
      Secondary: [],
      Primary: [],
    };
    if (
      data?.prescriptionReference?.patientChart?.diagnosis !== undefined &&
      data?.prescriptionReference?.patientChart?.diagnosis !== null &&
      data?.prescriptionReference?.patientChart?.diagnosis?.length > 0
    ) {
      diagnosis.Note =
        data?.prescriptionReference?.patientChart?.diagnosis[0]?.note;
      data?.prescriptionReference?.patientChart?.diagnosis.forEach(
        (diagnosisItem) => {
          if (diagnosisItem?.type?.toLowerCase() === "p") {
            diagnosis.Primary = [diagnosisItem];
          } else {
            diagnosis.Secondary.push(diagnosisItem);
          }
        }
      );
    }
    dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
    // console.log("Diagnosis in state", diagnosis);
    dispatch(setDiagnosis(diagnosis));
    // dispatch(setPatientEncounterData({ ...data?.prescriptionReference, notes: data?.prescriptionReference?.encounter?.notes || "" }));
    dispatch(
      setPatientEncounterData({
        ...data,
        prescriptionReference: {
          ...data?.prescriptionReference,
          encounter: {
            ...data?.prescriptionReference?.encounter,
            notes: data?.prescriptionReference?.encounter?.notes || "",
          },
        },
      })
    );
    dispatch(
      setAllPhysicalSystemsStatus(
        data?.prescriptionReference?.encounter?.allSystemsOk
      )
    );
    if (data?.encounter?.status === "DISPATCHED") {
      dispatch(setDisableSendRxButton(true));
    } else {
      dispatch(setDisableSendRxButton(false));
    }
    // dispatch(setConsulationInProgress(true));
    // dispatch(setVideoOpen(true));
  };

  const handleEncounterData = (encId) => {
    fetchPatientEncounter(encId);
    // fetchPrescribedMedication(encId)
    dispatch(updatePrescribedMedication([]));
  };
  const handleEncData = () => {
    handleToggle();
    handleEncounterData(prescriptionReferenceId);

    if (
      props.encounter?.appointment?.appointmentType?.key.toLowerCase() !== "follow_up" 
      // props.encounter?.appointment?.appointmentType?.key.toLowerCase() === "custom"
    ) {
      handleBackdropClose();
      handleResumePatientEncouterData(props.encounter);
      navigate(
        `/consult/${providerId}/${prescriptionReferenceId}/${patientId}`,
        {
          state: {
            prescriptionReferenceId: prescriptionReferenceId,
          },
        }
      );
    } else {
      handleBackdropClose();
      handleResumePatientEncouterData(props.encounter?.appointment);
      navigate(`/all-patients/${patientId}/soap-note`, {
        state: {
          isResume: props?.encounter?.soapNote !== undefined ? true : false,
          prescriptionReferenceId: prescriptionReferenceId,
          encounterId: encounterId,
          appointmentId: appointmentId,
        },
      });
    }
  };
  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  useEffect(() => {
    return () => {
      setOpen(false);
      setIsAppointmentStartLoading(false);
      setIsLoading(false);
      setIsLoadingEnc(false);
    };
  }, []);
  return (
    <>
      <div className={`card ${classes["patient-encounter-card-container"]}`} hidden={props.encounter?.appointment?.status
        ?.toLowerCase()
        ?.trim() === "pending" && userPermissions?.vpe === false ? true : false}>
        <div className="card-body d-flex flex-column p-0 ">
          <div className="row">
            <div className={`col-md-8 py-2 ps-4  ${classes["avatar-section"]}`}>
              <Stack direction="row">
                <Avatar src={placeholder} alt="provider picture"></Avatar>
                <Stack sx={{ ml: 1 }}>
                  {isLoading ? (
                    <span>
                      <Skeleton animation="wave" width={200} />
                    </span>
                  ) : (
                    <>
                      <span className="fw-sb font-16 text-wrap lh-0 m-0 d-block">
                        {
                          props.encounter?.appointment?.appointmentSlot
                            ?.providerTenantPracticeLocation?.provider?.name[0]
                            ?.prefix
                        }{" "}
                        {
                          props.encounter?.appointment?.appointmentSlot
                            ?.providerTenantPracticeLocation?.provider?.name[0]
                            ?.firstName
                        }{" "}
                        {
                          props.encounter?.appointment?.appointmentSlot
                            ?.providerTenantPracticeLocation?.provider?.name[0]
                            ?.lastName
                        }
                      </span>
                      <span className="font-12 m-0 d-block">
                        {props.encounter?.appointment?.appointmentSlot
                          ?.providerTenantPracticeLocation?.provider?.speciality
                          .length > 0
                          ? props.encounter?.appointment?.appointmentSlot
                            ?.providerTenantPracticeLocation?.provider
                            ?.speciality[0]?.specialization
                          : ""}
                      </span>
                    </>
                  )}
                </Stack>
              </Stack>
            </div>
            <div
              className={`col-md-4 py-2  ${classes["button-section"]}`}
            >
              <div className="d-flex justify-content-center">
                {props.encounter?.appointment?.status !== "complete" ? (
                  <>
                    {
                      props.encounter?.appointment?.status
                        ?.toLowerCase()
                        ?.trim() === "pending" ? (
                        <>

                          <button
                            disabled={
                              videoCallContext.getIsVideoCallActive() &&
                              videoCallContext.appointmentData !== null &&
                              videoCallContext.appointmentData !== appointmentId
                            }
                            className="btn blue_task_button"
                            onClick={() => handlePatientEncouterData()}
                            hidden={userPermissions?.spc === false ? true : false}
                          >
                            Start
                          </button>
                        </>
                      ) : (
                        <>
                          {/* {
                            (props.encounter?.appointment?.appointmentType.toLowerCase() === "new" ||
                              props.encounter?.appointment?.appointmentType.toLowerCase() === "custom") ?
                            (
                              ()=>{
                                handleBackdropClose();
                              handleResumePatientEncouterData(props.encounter);
                              navigate(
                                `/consult/${providerId}/${prescriptionReferenceId}/${patientId}`,
                                {
                                  state: {
                                    prescriptionReferenceId:
                                      prescriptionReferenceId,
                                  },
                                }
                                )
                              }
                            ) : null} */}

                          {/* {isLoadingEnc &&
                            props.encounter?.appointment?.appointmentType.toLowerCase() === "follow_up" &&
                            (handleBackdropClose(),
                            handleResumePatientEncouterData(props.encounter?.appointment),
                            navigate(`/all-patients/${patientId}/soap-note`, {
                              state: {
                                isResume: true,
                                prescriptionReferenceId:prescriptionReferenceId,
                                encounterId: encounterId,
                                appointmentId: appointmentId,
                              },
                            }))} */}

                          <button
                            disabled={
                              videoCallContext.getIsVideoCallActive() &&
                              videoCallContext.appointmentData !== null &&
                              videoCallContext.appointmentData.data !==
                              appointmentId
                            }
                            className="btn blue_task_button"
                            onClick={() => handleEncData()}
                            hidden={userPermissions?.rpe === false ? true : false}
                          >
                            Resume
                          </button>
                        </>
                      )
                      // <Link to={{
                      //   pathname:`/consult/${props.providerId}/${encounterId}/${patientId}`
                      // }} >
                      // </Link>
                    }
                  </>
                ) : (
                  // <button
                  //   onClick={() => {
                  //     props.handleModal(props.encounter?.prescriptionReferenceId);
                  //     props.handleToggle();
                  //   }}
                  //   className={`btn blue_task_button px-5`}
                  // >
                  //   {props.encounter?.appointment?.status === "Pending" ? "Start":"Resume"}
                  // </button>
                  <button
                    onClick={() => {
                      props.handleModal(props.encounter?.prescriptionReferenceId);
                      props.handleToggle();
                    }}
                    className={`btn d9e8ec--button px-5`}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row pb-1">
            <div className={`col-4 ps-4 `}>
              <Stack direction="column" spacing={0}>
                <label
                  className="text--secondary font-12  m-0"
                  style={{ marginBottom: "-12px" }}
                >
                  Date & Time
                </label>
                <p
                  className="m-0 text--terciary font-12"
                  style={{ marginTop: "-8px" }}
                >
                  {isLoading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <>
                      {dateTimeHelper(
                        props.encounter?.appointment?.appointmentSlot
                          ?.startDateTime,
                        "ddd, MMM D, YYYY"
                      )}
                      <br />
                      {dateTimeHelper(
                        props.encounter?.appointment?.appointmentSlot
                          ?.startDateTime,
                        "LT"
                      ) +
                        " - " +
                        dateTimeHelper(
                          props.encounter?.appointment?.appointmentSlot
                            ?.endDateTime,
                          "LT"
                        )}
                    </>
                  )}
                </p>
              </Stack>
            </div>
            <div className={`col-6 `}>
              <Stack direction="column" spacing={0}>
                <label
                  className="text--secondary font-12  m-0"
                  style={{ marginBottom: "-12px" }}
                >
                  Chief Complaint
                </label>
                <p
                  className="m-0 text--terciary font-12"
                  style={{ marginTop: "-8px" }}
                >
                  {isLoading ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <span className="text-wrap">
                      {props.encounter?.appointment?.chiefComplaint}
                    </span>
                  )}
                </p>
              </Stack>
            </div>
          </div>
          {/* <Collapse in={isExpanded}>
            <div className="row p-0 m-0 text-wrap" id={`${props.encounter?.encounter?.encounterId}`}>
              <div className="col-6">
                <Stack direction="column" spacing={0}>
                  <label
                    className="text--secondary font-12  m-0"
                    style={{ marginBottom: "-12px" }}
                  >
                    Chief Complaint
                  </label>
                  <p
                    className="m-0 text--terciary font-14"
                    style={{ marginTop: "-8px" }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      props.encounter?.encounter?.chiefComplaint
                    )}
                  </p>
                </Stack>
              </div>
              <div className="col-6">
                <Stack direction="column" spacing={0}>
                  <label
                    className="text--secondary font-12  m-0"
                    style={{ marginBottom: "-12px" }}
                  >
                    Allergies
                  </label>
                  <p
                    className="m-0 text--terciary font-14"
                    style={{ marginTop: "-8px" }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" />
                    ) : props.encounter?.patientHistory?.allergyDto !== null &&
                      props.encounter?.patientHistory?.allergyDto?.length !== 0 ? (
                      props.encounter?.patientHistory?.allergyDto?.map((allergy, index) => {
                        if (index < 3) {
                          return (
                            <span
                              key={allergy?.Id}
                              className="m-0 text--terciary font-16 d-block"
                              style={{ marginTop: "-8px" }}
                            >
                              {allergy?.drugProductCoded?.text}
                            </span>
                          );
                        }
                        if (index > 2) {
                          allergiesString +=
                            allergy?.drugProductCoded?.text + " ";
                        }
                        if (
                          props.encounter?.patientHistory?.allergyDto?.length - 3 > 0 &&
                          props.encounter?.patientHistory?.allergyDto?.length - 1 === index
                        ) {
                          return (
                            <Tooltip
                              title={
                                <>
                                  <ul className="list-unstyled ms-0">
                                    {allergiesString.split().map((item) => {
                                      return item !== " " && <li>{item}</li>;
                                    })}
                                  </ul>
                                </>
                              }
                            >
                              <p
                                key={allergy?.Id}
                                className="m-0 text--secondary font-14"
                              >
                                {props.encounter?.patientHistory?.allergyDto?.length - 3}
                                {" more..."}
                              </p>
                            </Tooltip>
                          );
                        }
                      })
                    ) : (
                      "No allergies found"
                    )}
                  </p>
                </Stack>
              </div>
            </div>
          </Collapse> */}
          {/* <div className={`card-footer mt-auto py-0 card-bg-footer`}>
            <div
              className="mt-auto"
              aria-controls={`${props.encounter.encounterId}`}
              aria-expanded={isExpanded}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            >
              <ExpandMore
                className="mx-auto d-block"
                style={{
                  color: "#004fd4",
                  transform: `${isExpanded ? "rotate(180deg)" : ""}`,
                }}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              />
            </div>
          </div> */}
        </div>
      </div>

      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PatientEncounterCard;
