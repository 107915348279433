import { Backdrop, Box, CircularProgress, Grid, LinearProgress, Tab, Tabs, ThemeProvider } from "@mui/material";
import PageHeader from "../../patientportal/generic/PageHeader"
import { useEffect, useState } from "react";
import { billingAndClaimsMapKeyValueArray } from "../BillingAndClaimsConstants";
import { TabPanel, theme } from "../../components/UI/Reusable/PnComponents";
import FeeSchedule from "../fee-schedule/FeeSchedule";
import PatientsList from "../../claim-management-module/src/components/patient/PatientsList";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../components/hooks/useAuth";
import { useSelector } from "react-redux";
import PatientCreateDropdown from "../../claim-management-module/src/components/patient/PatientCreateDropdown";
import ViewPatientProfileEncounter from "../../claim-management-module/src/components/patient/viewPatientProfile/ViewPatientProfileEncounter";
import Sticky from "react-stickynode";
import PatientStaticProfileHeader from "../../claim-management-module/src/components/patient/PatientStaticProfileHeader";
import ClaimEncountersTabPanel from "../../claim-management-module/src/components/pages/ClaimEncountersTabPanel";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";

function BottomNavigation(props) {
    return (
        <div style={{ zIndex: '1050' }} className="position-fixed pb-4  w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />
            <div className="d-flex p-3 ">
                <div className="position-fixed " style={{
                    left: "88%"
                }}>
                    <button className="btn  br-10 font-14 ms-2 mb-1 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {
                            props?.saveEncounter();
                        }}
                        hidden={props?.hideSaveButton}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

const BillingAndClaimsIndex = () => {
    const { user } = useAuth();
    const { pId } = useParams();
    const location = useLocation();

    const userPermissions = useSelector(
        (state) => state.UserPermissionData.permissionsMap
    );

    const [encounterDto, setEncounterDto] = useState({
        dateOfCurrentIllnessOrSymptom: "",
        lastMenstrualPeriodDate: "",
        initialTreatmentDate: "",
        lastSeenDate: "",
        claimProviderDataId: "",
        claimPatientDataId: "",
        claimFacilityDataId: user?.facility?.facilityId,
        claimTenantDataId: user?.tenantId,
        prescriptionRefId: "",
        diagnosis: [],
        patient: {},
        provider: {}

    });

    const [tabValue, setTabValue] = useState(0);
    const [patientId, setPatientId] = useState(location?.state?.patientId ?? pId ?? undefined);
    const [selectedPatientInformation, setSelectedPatientInformation] = useState(null);
    const [isPatientInformationLoading, setIsPatientInformationLoading] = useState(false);

    // Patients List
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [patientAddDependency, setPatientAddDependency] = useState(false);
    const [patientFacesheetDependency, setPatientFacesheetDependency] = useState(false);
    const [patientEditDependency, setPatientEditDependency] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isPatientNotFound, setIsPatientNotFound] = useState(false);
    const [isSent, setIsSent] = useState(false);

    // Maunally add new patient
    const [newPatientModal, setNewPatientModal] = useState(false);
    const [openFacesheatModal, setOpenFacesheatModal] = useState(false);

    // View Patient Profile
    const [practiceCodeTypeList, setPracticeCodeTypeList] = useState({
        isLoading: false,
        list: [],
    });
    const handlePatientEditDependency = () => {
        setPatientEditDependency(!patientEditDependency);
    };






    function handleChange(event, newValue) {
        setTabValue(newValue);
        // setEncounterDto({
        //     dateOfCurrentIllnessOrSymptom: "",
        //     lastMenstrualPeriodDate: "",
        //     initialTreatmentDate: "",
        //     lastSeenDate: "",
        //     claimProviderDataId: "",
        //     claimPatientDataId: "",
        //     claimFacilityDataId: "",
        //     claimTenantDataId: "",
        //     prescriptionRefId: "",
        //     diagnosis: [],
        //     patient: {},
        //     provider: {}

        // });
    }


    // Patient List handlers
    // const handlePatientChange = (event, newValue) => {
    //     setValue(newValue);
    //   };
    const handlePatientId = (pid) => {
        setPatientId(pid);
    };
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleSelectedIndex = (index) => {
        setSelectedIndex(index);
    };

    const handleNewPatientModal = () => {
        setNewPatientModal(true);
    };
    const handleNewPatientModalClose = () => {
        setNewPatientModal(false);
    };
    const handleFacesheetModal = () => {
        setOpenFacesheatModal(!openFacesheatModal);
    };

    async function fetchPatientInformation(patientId) {
        setIsPatientInformationLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/claims/tenants/${user?.tenantId}/patients/${patientId}`,
            method: "GET",
            params: {
                patientId: patientId,
            },
        });

        if (!httpResponse.error) {
            setSelectedPatientInformation(httpResponse.data);
            setEncounterDto((dto) => ({ ...dto, "claimPatientDataId": httpResponse?.data?.patientId, "patient": { ...encounterDto?.patient, "patientId": httpResponse?.data?.patientId } }));
            setPatientId(httpResponse?.data?.patientId);
        }
        setIsPatientInformationLoading(false);
    }


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSent(false);
    };


    async function saveEncounter() {
        const httpResponse = await sendHttpRequest({
            url: `/claims/patients/${patientId}/encounters`,
            method: "POST",
            data: encounterDto,
        });
        if (!httpResponse.error) {
            setTabValue(0);
            setIsSent(true);
        }
        else {
            console.log(httpResponse);
        }
    }

    useEffect(() => {
        if (patientId !== undefined) {
            fetchPatientInformation(patientId);
        }
    }, [patientId])

    return (
        <ThemeProvider theme={theme}>
            <>
                <PageHeader
                    title="Billing & Claims"

                >
                    <PatientCreateDropdown
                        handleNewPatientModal={handleNewPatientModal}
                        handleNewPatientModalClose={handleNewPatientModalClose}
                        handleFacesheetModal={handleFacesheetModal}
                        handleFacesheetModalClose={handleFacesheetModal}
                        patientId={patientId}
                        claimBilling={true}
                    />
                </PageHeader>
                <Grid
                    container
                    sx={{
                        backgroundColor: "#FAFCFC",
                    }}>

                    <Grid item xs={12} sm={3} sx={{
                        borderRight: "1px #d9e8ec solid",
                        height: '100vh'

                    }}>
                        <PatientsList
                            patientDependency={patientAddDependency}
                            patientEditDependency={patientEditDependency}
                            patientFacesheetDependency={patientFacesheetDependency}
                            // handleRecentEncountersList={handleRecentEncountersList}
                            patientId={patientId}
                            // handleChange={handlePatientChange}
                            handlePatientId={handlePatientId}
                            isCollapsed={isCollapsed}
                            toggleCollapse={toggleCollapse}
                            handleSelectedIndex={handleSelectedIndex}
                            setIsPatientNotFound={setIsPatientNotFound}
                            user={user}
                            claimBilling={true}
                        />
                    </Grid>
                    <Grid sx={{ backgroundColor: '#FAFCFC' }} item xs={12} sm={9} hidden={patientId === undefined}>
                        <Sticky enabled={true} top={70} innerZ={20} bottomBoundary={1200}>
                            <div
                                id="header-tabs-box"
                                style={{ backgroundColor: 'white', borderBottom: '1px solid #d9e8ec' }}
                            >
                                {
                                    user !== undefined && patientId !== undefined &&
                                    <PatientStaticProfileHeader
                                        patientId={patientId}
                                        user={user}
                                        setIsPatientNotFound={setIsPatientNotFound}
                                    />
                                }
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "#D9E8EC",
                                    }}
                                >
                                    <Tabs
                                        indicatorColor="secondary"
                                        value={tabValue}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        onChange={handleChange}
                                    >
                                        {billingAndClaimsMapKeyValueArray.map(entry => {
                                            return <Tab
                                                key={entry?.at(0)}
                                                label={entry?.at(1)}
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    textTransform: "initial",
                                                    color: "#336383",
                                                }}
                                            />
                                        })}
                                    </Tabs>
                                </Box>
                            </div>
                        </Sticky>
                        <TabPanel value={tabValue} index={0} className="bg--background h-100">
                            <div className="container-fluid">
                                {
                                    patientId !== undefined &&
                                    <ViewPatientProfileEncounter
                                        // handleRecentEncountersList={handleRecentEncountersList}
                                        practiceCodeTypeList={practiceCodeTypeList}
                                        patientId={patientId}
                                        setIsPatientNotFound={setIsPatientNotFound}
                                        handlePatientEditDependency={handlePatientEditDependency}

                                        user={user}
                                    />
                                }
                            </div>
                        </TabPanel>

                        {/* Encounters Panel */}

                        <TabPanel value={tabValue} index={1} className=" h-100">
                            <div className="container">
                                <ClaimEncountersTabPanel encounterDto={encounterDto} setEncounterDto={setEncounterDto} useAuthObject={user} selectedPatientInformation={selectedPatientInformation} />

                            </div>

                        </TabPanel>

                        <TabPanel value={tabValue} index={7} className="bg--background h-100">
                            <div className="container-fluid">
                                <FeeSchedule patientId={patientId} />
                            </div>
                        </TabPanel>
                    </Grid>
                </Grid>
                {tabValue == 1 ? <BottomNavigation saveEncounter={saveEncounter} /> : null}
            </>

            <CustomizedSnackbars
                message={"Encounter data saved successfully"}
                severity="success"
                open={isSent}
                handleClose={handleClose}
            />

            <Backdrop
                sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isPatientInformationLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </ThemeProvider>
    )
}

export default BillingAndClaimsIndex