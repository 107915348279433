import axios from "axios";
import React from "react";
import { createContext, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setDirtyBitInStore, setUserTimezoneData } from "../../redux/actions/providerActions";
import { useLocalStorage } from "./useLocalStorage";
import { strings } from "../../res/strings";
import { useEffect } from "react";
import packageJson from "../../../package.json";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [secondaryUser, setSecondaryUser] = useLocalStorage("su", null);
  const version = localStorage.getItem('version');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const __str = strings.en;


  const login = (data) => {
    selectUser(data.userId);
    setUser(data);
    setTimezone(data);
    navigate("/dashboard");
  };

  const loginWithoutNavigation = (data) => {
    selectUser(data.userId);
    setUser(data);
    setTimezone(data);
  };


  const patientRegistrationLogin = (data) => {
    selectUser(data.userId);
    setUser(data);
    // navigate("/dashboard");
  };


  const selectUser = Id => {
    setSecondaryUser({ uId: Id });
  };

  const getSecondaryUser = () => {
    return JSON.parse(localStorage.getItem("su"))?.uId;
  }

  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  }

  const updateUser = user => {
    localStorage.removeItem("user");
    setUser(user);
    setTimezone(user);
  }

  const setTimezone = user => {
    dispatch(setUserTimezoneData(user?.timezoneText));
  }

  // call this function to sign out logged in user
  const signout = () => {
    //Adding this line for clearing camera resources when we are in the patient portal.
    // console.log(window.location.pathname, location);
    if (window.location.pathname.includes('patient-portal') && (window.location.pathname.includes('consult') || window.location.pathname.includes('join'))) {
      exit();
      window.location.href = "/eportal/patient-portal/";
      return;
    }
    exit();
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const exit = () => {
    dispatch(setDirtyBitInStore(false));
    setUser(null);
    setSecondaryUser(null);
    localStorage.clear();
  }

  // call this function when seesion time is expired
  const sessionExpired = () => {

    const isFromIframe = getUser()?.isFromIframe;
    exit();
    console.log(isFromIframe);
    if (isFromIframe) {
      navigate('/veeone', { replace: true });
    }
    else {
      navigate('/', { state: { msg: __str?.sessionTimeOut } });
    }
  };

  useEffect(() => {
    if (parseFloat(version) !== parseFloat(packageJson.version)) {
      // Makes sure the page reloads. Changes are only visible after you refresh.
      localStorage.setItem('version', packageJson.version);
      window.location.reload(true);
    }
  }, [])

  const value = useMemo(
    () => (
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            const isFromIframe = JSON.parse(localStorage.getItem("user"))['isFromIframe'];
            let result = false;
            if (isFromIframe !== undefined && isFromIframe) {
              result = true;
            }
            setUser({ sessionExpired: true, isFromIframe: result });
            sessionExpired();
          }
          return Promise.reject(error);
        }
      ),
      {
        user,
        exit,
        login,
        signout,
        getUser,
        selectUser,
        updateUser,
        getSecondaryUser,
        sessionExpired,
        loginWithoutNavigation,
        patientRegistrationLogin
      }
    ),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
