import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import IconArchive from "../../assets/icons/Icon-archive.svg";
import IconFile from "../../assets/icons/Icon-file.svg";
import PrintIcon from "../../assets/icons/Icon-print.svg";
import MessageContainerItem from "./MessageContainerItem";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./MessageContainer.module.css";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import ReplyForwardContainer from "./ReplyForwardContainer";
import { useAuth } from "../hooks/useAuth";

const MessageContainer = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isthreadLoading, setIsthreadLoading] = useState(false);
  const [threadData, setThreadData] = useState([]);
  const [openReplyMessageModal, setOpenReplyMessageModal] = useState(false);
  const [openForwardMessageModal, setOpenForwardMessageModal] = useState(false);
  const [isReplyMessageSent, setIsReplyMessageSent] = useState(false);
  const [isReplyMessageSentError, setIsReplyMessageSentError] = useState(false);
  const [fileToShow, setFileToShow] = useState("");
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  const [openFileShowModal, setOpenFileShowModal] = useState(false);
  const [fileTypeOnShow, setFileTypeOnShow] = useState("");
  const [openReplyMessageContainer, setOpenReplyMessageContainer] =
    useState(false);
  const [openForwardMessageContainer, setOpenForwardMessageContainer] =
    useState(false);
  const [closeFooter, setCloseFooter] = useState(false);

  const fetchThreadData = async (thread) => {
    setIsthreadLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages/thread",
      method: "GET",
      params: {
        userId: user?.userId,
        threadId: thread,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log("httpResponse.data", httpResponse.data);
      setThreadData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsthreadLoading(false);
  };

  const downloadAttachment = async (documentId) => {
    const httpResponse = await sendHttpRequest({
      url: "/patient/document",
      method: "GET",
      params: {
        documentId: documentId,
      },
    });
    if (!httpResponse.error) {
      downloadFile(httpResponse.data.filePath, httpResponse.data.name);
    } else {
      console.log(httpResponse.error);
    }
  };

  const handleReplyMessageContainer = () => {
    setCloseFooter(!closeFooter);
    setOpenReplyMessageContainer(!openReplyMessageContainer);
  };

  const handleForwardMessageContainer = () => {
    setCloseFooter(!closeFooter);
    setOpenForwardMessageContainer(!openForwardMessageContainer);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsReplyMessageSent(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsReplyMessageSentError(false);
  };

  const handleAttachmentDownload = (event) => {
    downloadAttachment(event.target.id);
  };

  function b64toBlob(base64, type = "application/octet-stream") {
    try {
      const binStr = window.atob(base64);
      const len = binStr?.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    } catch (e) {
      setIsLoadingFetch(true);
    }
  }
  function downloadFile(url, fileName) {
    fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
      .then(res => res.blob())
      .then(res => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  };

  const fileShowModalClose = () => {
    setOpenFileShowModal(false);
  };

  const handleRFMessageContainer = () => {
    setOpenReplyMessageContainer(false);
  };

  const scrolToEnd = async () => {
    let elementRef = document.getElementById("last");
    // console.log(elementRef);
    if (elementRef === null) {
      for (let i = 0; i < 2; i++) {
        await new Promise(r => setTimeout(r, 1000));
        elementRef = document.getElementById("last");
        elementRef.scrollIntoView()
        elementRef.scrollTop = elementRef.scrollHeight;
      }
    }
    else {
      elementRef.scrollIntoView()
      elementRef.scrollTop = elementRef.scrollHeight;
    }
  }



  useEffect(() => {
    fetchThreadData(props?.threadId);
  }, [props?.threadId]);

  return (
    <div
      className="row border--right border--left"
    >
      <a id="download" download target={"_blank"} />

      <div className="col-12 p-0">
        {isthreadLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="bg--disabled  border--default text--terciary fw-sb ">
              {/* Header */}
              <div className="d-flex flex-row p-3 justify-content-between ">
                <div>{threadData[0]?.subject}</div>
                <div className="d-flex flex-row ">
                  <div className="me-3">
                    {threadData[threadData.length - 1]?.attachment !== undefined &&
                      threadData[threadData.length - 1]?.attachment !== null && (
                        <img
                          id={threadData[threadData.length - 1]?.attachment}
                          className={`${classes["icon__file"]}`}
                          src={IconFile}
                          alt={"archieve icon"}
                          onClick={(e) => {
                            handleAttachmentDownload(e);
                          }}
                        ></img>
                      )}
                  </div>
                  {/* <div className="me-3">
                    <img
                      className={`${classes["icon__archive"]}`}
                      src={IconArchive}
                      alt={"archieve icon"}
                    ></img>
                  </div> */}
                  <div>
                    <span
                      className={`${classes["icon__close"]}`}
                      onClick={() => {
                        props.setThreadId(undefined);
                      }}
                    >
                      {<CloseIcon />}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`py-2 ${classes["message__container__item"]}`}>
              {/* {"Body"} */}
              {isthreadLoading ? (
                <Skeleton />
              ) : (
                threadData.length > 0 &&
                threadData?.map((threadDataItem, index) => {
                  if (index === (threadData.length - 1)) {
                    scrolToEnd();
                  }

                  return (
                    <>
                      <MessageContainerItem
                        index={index}
                        key={index}
                        threadData={threadDataItem}
                        setThreadId={props?.setThreadId}
                        patientPortal={props?.patientPortal}
                        isLast={index === (threadData.length - 1) ? true : false}
                        inbox={props?.inbox}
                      />
                    </>
                  );
                })
              )}
              <div id="last">&nbsp;</div>
              <div className="row">
                <div className="col-12">

                  {openReplyMessageContainer && (
                    <div
                      className={`${classes["reply__forward__container"]} hide-scrolbar`}
                    >
                      <ReplyForwardContainer
                        headerText={"Reply to"}
                        setOpenContainer={setOpenReplyMessageContainer}
                        openContainer={openReplyMessageContainer}
                        setCloseFooter={setCloseFooter}
                        messageData={threadData[threadData.length - 1]}
                        reply={true}
                        inbox={props?.inbox}
                        setIsMessageSent={setIsReplyMessageSent}
                        setIsMessageSentError={setIsReplyMessageSentError}
                        patientPortal={props?.patientPortal}
                      />
                    </div>
                  )}
                  {openForwardMessageContainer && (
                    <div
                      className={`${classes["reply__forward__container"]} hide-scrolbar`}
                    >
                      <ReplyForwardContainer
                        headerText={"Forward to"}
                        setOpenContainer={setOpenForwardMessageContainer}
                        openContainer={openForwardMessageContainer}
                        setCloseFooter={setCloseFooter}
                        messageData={threadData[threadData.length - 1]}
                        forward={true}
                        inbox={props?.inbox}
                        setIsMessageSent={setIsReplyMessageSent}
                        setIsMessageSentError={setIsReplyMessageSentError}
                      />
                    </div>
                  )}
                </div>

              </div>
            </div>
            <div
              className=" bg-white "
              style={closeFooter ? { display: "none" } : {}}
            >
              {/* {"Footer"} */}
              <div
                className={`d-flex flex-row justify-content-between px-3 ${classes["container__footer"]}`}
              >
                <div className="d-flex flex-row align-self-center">
                  <div className="br-10 border--default bg--background border p-2">
                    <img
                      src={PrintIcon}
                      alt={"archieve icon"}
                      className="icon__defualt"
                    ></img>
                  </div>
                  <div className="br-10 border--default bg--background border p-2 ms-2">
                    <img
                      className="icon__defualt"
                      src={IconArchive}
                      alt={"archieve icon"}
                    ></img>
                  </div>
                  {/* <div></div> */}
                </div>
                <div className="d-flex flex-row align-self-center">
                  {
                    props?.patientPortal ? <></> :
                      <div>
                        <button
                          className="btn  text--blue d9e8ec--button fw-sb font-14 me-2 br-10 "
                          style={{ width: "160px" }}
                          onClick={() => {
                            handleForwardMessageContainer();
                          }}
                        >
                          Forward
                        </button>
                      </div>
                  }
                  <div>
                    <button
                      className=" bg--blue text--white blue_task_button font-14 fw-sb  br-10  border-0"
                      style={{ width: "160px" }}
                      onClick={() => {
                        handleReplyMessageContainer();
                      }}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* {console.log(threadData)} */}
      <CustomizedSnackbars
        message={"Message sent successfully"}
        severity="success"
        open={isReplyMessageSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={"Message could not be sent"}
        severity="error"
        open={isReplyMessageSentError}
        handleClose={handleCloseError}
      />

    </div>
  );
};

export default MessageContainer;
