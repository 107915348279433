import { MedicalServices } from '@mui/icons-material';
import { Backdrop, CircularProgress, LinearProgress, Skeleton } from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom'
import PageHeader from '../../patientportal/generic/PageHeader';
import { calenderDateTimeHelper, changeDateTimeFormat } from '../../utils/dateTimeHelper';
import { getPatientConcatenatedNameFromNameObject, getProviderConcatenatedNameFromNameObject } from '../../utils/helpers/HelperFunctions';
import ClaimService from '../claims/ClaimService';
import usePlaceOfServiceCodes from '../claims/usePlaceOfServiceCodes';
import DiagnosisSearch from '../diagnosis-search/DiagnosisSearch';
import useDiagnosisSearch from '../diagnosis-search/useDiagnosisSearch';
import usePrescriptionReference from '../hooks/usePrescriptionReference';
import PatientDemographics from '../providernote/PatientDemographics';
import { useSelector } from 'react-redux';

function BottomNavigation(props) {
    return (
        <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />
            <div className="d-flex p-3 ">
                <div className="position-fixed " style={{
                    left: "88%"
                }}>
                    <button className="btn  br-10 font-14 ms-2 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {

                        }}
                        hidden = {props?.hideSaveButton}
                        >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}


const ClaimsSubmissionPage = () => {
    const userPermissions = useSelector(
        (state) => state.UserPermissionData.permissionsMap
      );
    const location = useLocation();
    const [ids] = useState({
        patientId: location?.state?.patientId,
        encounterId: location?.state?.encounterId,
        prescriptionReferenceId: location?.state?.prescriptionReferenceId,
    });
    const [prescriptionReferenceData, isPrescriptionReferenceDataLoading] = usePrescriptionReference(location?.state?.prescriptionReferenceId);
    const [primaryInsurance, setPrimaryInsurance] = useState(null);
    const { suggestions, setSuggestions, isLoading, setIsLoading, isError, setIsError, searchInputValue, setSearchInputValue, diagnosisName, setDiagnosisName, selectedDiagnosis, setSelectedDiagnosis, searchDiagnosis, handleSearchInput, handleAddDiagnosis, handleRemoveDiagnosis } = useDiagnosisSearch();
    const [diagnosisList, setDiagnosisList] = useState([]);
    const handleDiagnosisList = (addedDiagnosis) => {
        handleAddDiagnosis(addedDiagnosis);
        setDiagnosisList(prevList => [...prevList, addedDiagnosis]);
    }

    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = usePlaceOfServiceCodes();
    const [services, setServices] = useState([1]);



    useEffect(() => {
        if (!isPrescriptionReferenceDataLoading && prescriptionReferenceData !== undefined && prescriptionReferenceData?.patientChart !== null) {
            const list = prescriptionReferenceData.patientChart?.diagnosis?.length > 0 ? prescriptionReferenceData.patientChart?.diagnosis : [];
            const primaryInsurance = prescriptionReferenceData?.patient?.patientInsurance?.length > 0 ? prescriptionReferenceData?.patient?.patientInsurance?.find(insurance => insurance?.type?.toLowerCase() === 'primary') : null
            setPrimaryInsurance(primaryInsurance);
            setDiagnosisList(list);
        }
    }, [prescriptionReferenceData, isPrescriptionReferenceDataLoading]);

    return (
        <>
            <PageHeader title="Create Superbill" sticky={true}>
            </PageHeader>
            <PatientDemographics patientId={ids.patientId} />
            <main>
                <div className='container py-3'>
                    <div className='row justify-content-center'>
                        <div className='col-sm-7'>
                            <div className='row px-2'>
                                <div className="col-sm-4">
                                    <span className='d-block font-12 text--secondary '>
                                        Provider
                                    </span>
                                    <span className='d-block font-14 text--terciary'>
                                        {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))}
                                    </span>
                                </div>
                                <div className="col-sm-4">
                                    <span className='d-block font-12 text--secondary '>
                                        Service Location
                                    </span>
                                    <span className='d-block font-14 text--terciary'>
                                        {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName}
                                    </span>
                                </div>
                                <div className="col-sm-4">
                                    <span className='d-block font-12 text--secondary '>
                                        Place of Service
                                    </span>
                                    {isPlaceOfServiceCodeLoading ? <Skeleton /> : <select
                                        className={`input border--default br-10 form-select font-12 shadow-none`}
                                        onChange={(e) => {
                                            //   handlePracticeTypeCode(e);
                                        }}
                                    // onInvalid={(e) => {
                                    // //   e.target.setCustomValidity(
                                    // //     "Please select practice type from the list"
                                    // //   );
                                    // }}
                                    // onInput={(e) => {
                                    //   e.target.setCustomValidity("");
                                    // }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Select Service Type
                                        </option>
                                        {placeOfServiceCodes.map((place) => {
                                            return <option value={place.code}>{place.code} - {place.name}</option>
                                        })}
                                    </select>}


                                </div>
                            </div>
                            <div className='row px-2'>
                                {services.map((s) => <ClaimService key={s} serviceNumber={s} diagnoses={diagnosisList} />)}
                                <div className='my-3 text-end'>
                                    <button
                                        onClick={() => {
                                            const slist = [...services];
                                            slist.push(slist[slist.length - 1] + 1);
                                            setServices(p => slist);
                                        }}
                                        className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12">
                                        <span className="pn-button-icon-secondary pe-1"><MedicalServices color="#336383" size={14} /> </span>
                                        <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Service</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4 border--left'>
                            <DiagnosisSearch
                                suggestions={suggestions}
                                setSuggestions={setSuggestions}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                isError={isError}
                                setIsError={setIsError}
                                searchInputValue={searchInputValue}
                                setSearchInputValue={setSearchInputValue}
                                diagnosisName={diagnosisName}
                                setDiagnosisName={setDiagnosisName}
                                selectedDiagnosis={selectedDiagnosis}
                                setSelectedDiagnosis={setSelectedDiagnosis}
                                searchDiagnosis={searchDiagnosis}
                                handleSearchInput={handleSearchInput}
                                handleAddDiagnosis={handleDiagnosisList}
                                handleRemoveDiagnosis={handleRemoveDiagnosis}
                            />

                            <div className='py-2'>
                                {diagnosisList?.map((diagnosis, index) => (
                                    <span key={diagnosis.code} className='font-12 px-2 text--terciary d-block'>{`${diagnosis?.code} - ${diagnosis.description} ${diagnosis?.type === "P" ? (`(Primary)`) : ''}`}</span>
                                ))}
                            </div>

                            <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Patient</span>
                                    </div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Name</div>
                                    <div className='col-7 font-12'>{getPatientConcatenatedNameFromNameObject(prescriptionReferenceData?.patient?.name?.at(0))}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>DOB</div>
                                    <div className='col-7 font-12'>{calenderDateTimeHelper(prescriptionReferenceData?.patient?.dateOfBirth, "MMM DD, YYYY")}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>PID</div>
                                    <div className='col-7 font-12'>{prescriptionReferenceData?.patient?.patientTenantIdentification}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Gender</div>
                                    <div className='col-7 font-12'>{prescriptionReferenceData?.patient?.gender}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Address</div>
                                    <div className='col-7 font-12'> {prescriptionReferenceData?.patient?.address[0]?.addressLine1}<br />
                                        {prescriptionReferenceData?.patient?.address[0]?.addressLine2 !==
                                            undefined &&
                                            prescriptionReferenceData?.patient?.address[0]?.addressLine2?.length >
                                            0
                                            ? prescriptionReferenceData?.patient?.address[0]?.addressLine2 + <br />
                                            : ""}
                                        {prescriptionReferenceData?.patient?.address[0]?.city?.trim()}
                                        {prescriptionReferenceData?.patient?.address[0]?.city !== undefined &&
                                            ", "}
                                        {prescriptionReferenceData?.patient?.address[0]?.stateProvince}{" "}
                                        {prescriptionReferenceData?.patient?.address[0]?.postalCode}</div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Phone</div>
                                    <div className='col-7 font-12'>{prescriptionReferenceData?.patient?.communicationNumbers?.numbers?.find(number => number.type === 'PT')?.number}</div>
                                </div>
                            </div>

                            {primaryInsurance !== null ? <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Insurance</span>
                                    </div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Type</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.type}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Member ID</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.memberId}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Policy</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.policyNumber}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Group</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.groupNumber}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Effective Date</div>
                                    <div className='col-7 font-12'>                {primaryInsurance?.effectiveDate !== undefined && primaryInsurance?.effectiveDate !== null ? changeDateTimeFormat(primaryInsurance?.effectiveDate, "MMM D, YYYY") : "No data available"}{" - "}
                                        {primaryInsurance?.terminationDate !== undefined && primaryInsurance?.terminationDate !== null ? changeDateTimeFormat(primaryInsurance?.terminationDate, "MMM D, YYYY") : "No data available"} </div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Eligibility</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.status ? "Active" : "Inactive"}</div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Copay</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.inNetworkCopay}</div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Deductible</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.inNetworkDeductible}</div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Coinsurance</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.inNetworkCoinsurance}</div>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
                <BottomNavigation hideSaveButton = {userPermissions?.ssb === false ? true:false}/>
            </main>
            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={isPrescriptionReferenceDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default ClaimsSubmissionPage