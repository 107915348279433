import {combineReducers} from "redux"
import { setIsPatientEdited, setIsVideoCallEnd, setUserPermissions, setVideoOpen } from "../actions/providerActions";
import setAllPhysicalSystemsStatusReducer from "./allPhysicalSystemsStatusReducer";
import setDiagnosisReducer from "./diagnosisReducer";
import setSendRxButtonDisable from "./disableSendRxButtonReducer";
import setDrawerStateReducer from "./drawerStateReducer";
import setPatientEncounterDataReducer from "./patientEncounterDataReducer";
import setPatientIntakeFormStatusReducer from "./patientIntakeFormStatusReducer";
import setPrescribedMedicationReducer from "./prescribedMedicationReducer";
import setPrescriberInfoReducer from "./prescriberInfoReducer";
import setRefillRequestsReducer from "./prescriptionRequestsReducer"
import setProviderListReducer from "./providerListReducer";
import setProviderMessagesReducer from "./providerMessagesReducer";
import setUnreadMessageCountReducer from "./providerUnreadMessageCountReducer"
import setSsearchedPatientIdReducer from "./searchedPatientIdReducer";
import setUpdateEncounterDataReducer from "./updateEncounterReducer";
import setVideoCallEndStateReducer from "./videoCallEndReducer";
import setVideoCallStateReducer from "./videoCallReducer";
import setMicStatusReducer from './micStatusReducer';
import setForceShutdown from "./forceShutdownReducer";
import setIsPatientEditedReducer from "./patientEditReducer";
import setPrescriptionNoteReducer from "./prescriptionNoteReducer";
import setDirtyBitReducer from "./dirtyBitReducer";
import setRequiredDataCheckReducer from "./requiredDataCheckReducer";
import setVideoOpenReducer from "./videoOpenReducer";
import setConsulationInProgressReducer from "./consulationInProgressReducer";
import setJoinPageReducer from "./joinPageReducer";
import setUserPermissionsDataReducer from "./userPermissionsReducer";
import setTimezoneReducer from "./setTimezoneReducer";

const reducers = combineReducers({
    RefillRequests:setRefillRequestsReducer,
    ProviderMessages:setProviderMessagesReducer,
    ProviderUnreadMessageCount:setUnreadMessageCountReducer,
    PatientDiagnosis:setDiagnosisReducer,
    PrescribedMedication:setPrescribedMedicationReducer,
    PrecriberData:setPrescriberInfoReducer,
    DrawerState:setDrawerStateReducer,
    PatientEncounterData:setPatientEncounterDataReducer,
    UpdateEncounter:setUpdateEncounterDataReducer,
    DisableSendRxButton:setSendRxButtonDisable,
    CheckAllPhysicalSystems:setAllPhysicalSystemsStatusReducer,
    isPatientIntakeFormRead:setPatientIntakeFormStatusReducer,
    isVideoCall:setVideoCallStateReducer,
    searchedPatientId:setSsearchedPatientIdReducer,
    videoCallEndState:setVideoCallEndStateReducer,
    providerList:setProviderListReducer,
    micStatus:setMicStatusReducer,
    isForcedShutdown:setForceShutdown,
    isPatientEdited:setIsPatientEditedReducer,
    prescriptionNote:setPrescriptionNoteReducer,
    dirtyStatus:setDirtyBitReducer,
    requiredDataCheck:setRequiredDataCheckReducer,
    isVideoOpen:setVideoOpenReducer,
    isConsultationInProgress:setConsulationInProgressReducer,
    isJoinPageVisible:setJoinPageReducer,
    UserPermissionData:setUserPermissionsDataReducer,
    UserTimeZoneStatus:setTimezoneReducer
})

export default reducers;
